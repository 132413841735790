import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable, catchError, from, switchMap, tap, throwError, EMPTY, of } from "rxjs";
import { ApiProfileService } from '../profile/api-profile.service';
import { UserService } from '../user/user.service';
import { AuthService } from "./auth.service";
import { TokenResponse } from "angular-oauth2-oidc";
import { UserProfile } from "@common/profile/profile.interfaces";
import { checkUrl } from "@common/auth/auth.helper";

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  private get authService(): AuthService {
    return this.injector.get(AuthService);
  }

  private get userService(): UserService {
    return this.injector.get(UserService);
  }

  private get profileService(): ApiProfileService {
    return this.injector.get(ApiProfileService);
  }

  private refreshRequest$: Observable<TokenResponse>;

  constructor(private readonly injector: Injector) { }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(!checkUrl(req.url)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && this.authService.authorized) {
          return this.handleAuthorizationError$().pipe(
            switchMap((token: TokenResponse) => this.updateAuthDataAndInjectToken$(req, next, token)),
            catchError((err) => {
              this.authService.safeLogout();
              return throwError(err);
            })
          );
        }

        return throwError(error);
      })
    );
  }

  private handleAuthorizationError$(): Observable<TokenResponse>{
    if (!this.refreshRequest$) {
      this.refreshRequest$ = from(this.authService.refreshToken());
    }

    return this.refreshRequest$;
  }

  private updateAuthDataAndInjectToken$(
    req: HttpRequest<unknown>,
    next: HttpHandler,
    token: TokenResponse
  ): Observable<HttpEvent<any>> {
    return this.injectAccessToken(req, next, token).pipe(
      tap(() => { this.refreshRequest$ = null }),
      switchMap(() => this.updateAuthData$())
    );
  }

  private updateAuthData$(): Observable<HttpEvent<any>> {
    if(this.userService.userData) return EMPTY;

    return this.profileService.getUserProfileAndScope().pipe(
      tap((data: { profile: UserProfile, scope: string } ) => this.userService.updateAuthData(data)),
      switchMap(() => of({} as HttpEvent<any>))
    );
  }

  private injectAccessToken(
    req: HttpRequest<unknown>,
    next: HttpHandler,
    token: TokenResponse,
  ): Observable<HttpEvent<any>> {
    const headers = req.headers.set('Authorization', 'Bearer ' + token.access_token);

    const newReq = req.clone({ headers });
    return next.handle(newReq);
  }
}
