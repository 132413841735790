import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AemUniversalHeroComponent } from './aem-universal-hero.component';
import { BreadcrumbsModule } from "@kit/breadcrumbs/breadcrumbs.module";
import { BackButtonModule } from "@kit/back-button/back-button.module";
import { SafeHtmlModule } from "@kit/safe-html/safe-html.module";
import { DateModule } from "@kit/date/date.module";
import { TranslateModule } from "@ngx-translate/core";
import {
  TranslateMembershipPipeModule
} from "@kit/translate/translate-membership-pipe/translate-membership-pipe.module";

@NgModule({
  declarations: [AemUniversalHeroComponent],
  imports: [
    CommonModule,
    BreadcrumbsModule,
    BackButtonModule,
    SafeHtmlModule,
    DateModule,
    TranslateModule,
    TranslateMembershipPipeModule,
  ],
})
export class AemUniversalHeroModule { }
