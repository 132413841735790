import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { AemImageBlockComponent } from './image-block.component';

@NgModule({
  declarations: [AemImageBlockComponent],
  exports: [AemImageBlockComponent],
  imports: [
    CommonModule,
    ImgAltDirectiveModule
  ]
})
export class AemImageBlockModule { }
