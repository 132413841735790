<div class="checkbox" [class.disabled]="disabled" (click)="toggle()">
  <div class="checkbox_container"
       [class.focused]="focused"
       [class.disabled]="disabled">
    <input type="checkbox"
           class="checkbox_field"
           [placeholder]="placeholder"
           [formControl]="innerControl"
           (focus)="onFocus()"
           (blur)="onBlur()">
    <div class="checkbox_checkmark">
      <app-icon *ngIf="checked"
                class="icon"
                icon="done"
                size="large"
                theme="lighten">
      </app-icon>
    </div>
  </div>

  <div class="checkbox_label text-tiny">
    <ng-content></ng-content>
  </div>
</div>
