import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { QuotationResponseContractDocumentType } from "src/app/common/booking-path/booking-path.interfaces";

@Pipe({ name: 'appDocumentTypeTranslate' })
export class DocumentTypeTranslatePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}
  public transform(documentType: QuotationResponseContractDocumentType) {
    return this.translateService.instant(`forms.BOOKING_PATH.DOCUMENT_TYPES.${documentType}`)
  }
}