export interface BookingPath {
  tripDetails: BookingTripDetails;
  selectedProduct?: SelectedProduct;
  travelersDetails?: BookingTravelersDetails;
  iframeResponse?: PaymentDetailsDTO;
  contractNumber?: string;
}

export interface CompleteInsuranceInfo {
  contractNumber: string;
  insuranceDetails: BookingPath;
}

export interface SelectedProduct {
  productOfferingName: string;
  productOfferingSign: string;
  sign: string;
  grossPremium: QuotationResponseContractRecommendedPremiumGrossPremium;
  originalGrossPremium: QuotationResponseContractRecommendedPremiumOriginalGrossPremium;
  documents: QuotationResponseContractDocuments[];
  requestDate?: string;
}

export interface BookingTripDetails {
  marketScope: string;
  startDate: string;
  endDate: string;
  tripBookDate: string;
  destinationLocation: {
    names: string[];
  };
  travellers: DateOfBirth[];
  coverage: CoverageTypeField;
  totalTripPrice: number;
  residenceCountryCode: string;
  promoCode: string;
}

export interface BookingTravelersDetails {
  travellers: TravelerInfo[];
  policyHolder: PolicyHolder;
  termsAccept: boolean;
  marketingAccept: boolean;
}

export interface PaymentDetailsDTO {
  paymentResponse: {
    status: string;
    sessionID: string;
    transactionID: string;
    paymentTokenId: string;
    expireDate: CardExpiration;
    paymentHolder: {
      firstName: string;
      lastName: string;
    }
  }
}

export interface CardExpiration {
  month: string;
  year: string;
}

export interface DateOfBirth {
  dateOfBirth: string;
}

export enum CoverageType {
  CANCELLATION = 'Comprehensive',
  NOT_SURE = 'Med-Assist'
}

export interface CoverageTypeField {
  coverageType: CoverageType;
}

export interface TravelerInfo extends DateOfBirth {
  firstName: string;
  lastName: string;
}

export interface PolicyHolder extends TravelerInfo {
  address: string;
  city: string;
  postCode: string;
  email: string;
  country: string;
  phoneNumber: string;
}

export interface QuotationResponse {
  contract?: QuotationResponseContract;
}

export interface QuotationResponseContract {
  /**
   * Technical information - Unique identifier that is used during the quotation service
   */
  self?: string;
  publicationId?: string;
  /**
   * The final customer string value ID on Business Partner’s website.
   */
  deviceSessionId?: string;
  contractList?: Array<QuotationResponseContractContractList>;
}

export interface QuotationResponseContractContractList {
  language?: string;
  /**
   * List of insurance offers qualification. Possible values : standard or upsell
   */
  productOfferingType?: 'standart' | 'upsell';
  paymentFrequency?: string;
  numberOfPayments?: string;
  travelersRelationShipType?: string;
  salesCampaign?: QuotationResponseContractSalesCampaign;
  /**
   * Offer (unique) code
   */
  productOfferingSign?: string;
  /**
   * Offer’s label.
   */
  categorySortingNumber?: string;
  productOffering?: QuotationResponseContractProductOffering;
  recommendedPremium?: QuotationResponseContractRecommendedPremium;
  travels?: Array<QuotationResponseContractTravels>;
  insuredParties?: Array<QuotationResponseContractInsuredParties>;
  /**
   * product card name
   */
  productOfferingName?: string;
  /**
   * main benefits in product card
   */
  productOfferingDescription?: string;
  /**
   * benefit detail block, displayed as accordion
   */
  coverages?: Array<QuotationResponseContractCoverages>;
  underwritingConditions?: Array<string>;
  /**
   * pdf files at the bottom of product on Step 2 of booking path
   */
  documents?: Array<QuotationResponseContractDocuments>;
}

export interface QuotationResponseContractCoverages {
  /**
   * order number
   */
  coverageSortingNumber?: string;
  coverageContent?: string;
  name?: string;
  description?: string;
}

export interface QuotationResponseContractDocuments {
  documentInfo?: QuotationResponseContractDocumentInfo;
}

export interface QuotationResponseContractInsuredParties {
  categoryName?: Array<QuotationResponseContractCategoryName>;
}

export interface QuotationResponseContractProductOffering {
  /**
   * short|fixed|long
   */
  productOfferingCategory?: 'short' | 'fixed' | 'long';
  /**
   * if the product is renewable (single - no, annual - yes)
   */
  renewability?: string;
  duration?: QuotationResponseContractProductOfferingDuration;
  sign?: Array<string>;
  additionalInfos?: Array<string>;
  /**
   * This tag indicates that the corresponding offer must be highlighted.
   */
  highlightedOffer?: boolean;
}

export interface QuotationResponseContractRecommendedPremium {
  discounts?: Array<string>;
  monthlyPremium?: QuotationResponseContractRecommendedPremiumMonthlyPremium;
  premiumCategory?: string;
  originalGrossPremium?: QuotationResponseContractRecommendedPremiumOriginalGrossPremium;
  taxes?: Array<string>;
  commission?: any;
  annualPremium?: QuotationResponseContractRecommendedPremiumAnnualPremium;
  grossPremium?: QuotationResponseContractRecommendedPremiumGrossPremium;
}

export interface QuotationResponseContractSalesCampaign {
  marketingGroup?: QuotationResponseContractSalesCampaignMarketingGroup;
}

export interface QuotationResponseContractTravels {
  destinations?: Array<QuotationResponseContractDestinations>;
  journeys?: Array<string>;
  season?: string;
  /**
   * The type of the trip, possible values include (1) one-way-trip ( a single trip with departure and return) (2) Long-term ( longer than 90 days, out of scope of target 1), (3) Annual-trip
   */
  travelType?: string;
  numberOfInsuredParties?: number;
}

export interface QuotationResponseContractDocumentInfo {
  documentType?: QuotationResponseContractDocumentType;
  url?: string;
  language?: string;
}

export enum QuotationResponseContractDocumentType {
  TAndC = 'TAndC',
  IDD = 'IDD',
}

export interface QuotationResponseContractCategoryName {
  perTravelerWithAgeBand?: QuotationResponseContractPerTravelerWithAgeBand;
}

export interface QuotationResponseContractPerTravelerWithAgeBand {
  /**
   * partyLink: 1 refers to self: 1
   */
  self?: string;
  medicalScreening?: QuotationResponseContractPerTravelerWithAgeBandMedicalScreening;
  /**
   * Must follow this rule: age <= 1 infant, age <= 17 child, age <= 30 youth, age <= 50 adult, age <= 65 senior1, age <= 80 senior2, age > 80 senior3
   */
  ageCategory?: string;
  /**
   * Always one, loop on object
   */
  numberOfTravelers?: number;
  /**
   * Travel price per traveler – associated with a currency
   */
  price?: number;
  currency?: string;
  recommendedPremium?: QuotationResponseContractPerTravelerWithAgeBandRecommendedPremium;
}

export interface QuotationResponseContractPerTravelerWithAgeBandMedicalScreening {
  status?: string;
}

export interface QuotationResponseContractPerTravelerWithAgeBandRecommendedPremium {
  discounts?: Array<string>;
  /**
   * Premium category defined in the quotation engine - Possible values : quotation Premium (in the currency used to quote) / display premium (in the customer currency)
   */
  premiumCategory?: string;
  originalGrossPremium?: QuotationResponseContractRecommendedPremiumGrossPremium;
  /**
   * Part of the premium that correspond to the taxes applied (depending on the country) AND related to a traveler.
   */
  taxes?: Array<string>;
  /**
   * Part of the premium that correspond to retribution of B-partner and/or intermediary for selling a contract AND related to a traveler.
   */
  commission?: any;
  grossPremium?: QuotationResponseContractPerTravelerWithAgeBandRecommendedPremiumGrossPremium;
}

export interface QuotationResponseContractPerTravelerWithAgeBandRecommendedPremiumGrossPremium {
  currency?: string;
  amount?: number;
}

export interface QuotationResponseContractRecommendedPremiumGrossPremium {
  currency?: string;
  amount?: number;
}

export interface QuotationResponseContractProductOfferingDuration {
  unit?: string;
  number?: string;
}

export interface QuotationResponseContractRecommendedPremiumAnnualPremium {
  discountsAmount?: string;
  currency?: string;
  amount?: string;
}

export interface QuotationResponseContractRecommendedPremiumGrossPremium {
  currency?: string;
  amount?: number;
}

export interface QuotationResponseContractRecommendedPremiumMonthlyPremium {
  discountsAmount?: string;
  currency?: string;
  amount?: number;
}

export interface QuotationResponseContractRecommendedPremiumOriginalGrossPremium {
  currency?: string;
  amount?: string;
}

export interface QuotationResponseContractSalesCampaignMarketingGroup {
  groupMarketingCode?: string;
  groupMarketingName?: string;
  groupMarketingInfo?: string;
  groupSortingNumber?: string;
}

export interface QuotationResponseContractTravels {
  destinations?: Array<QuotationResponseContractDestinations>;
  journeys?: Array<string>;
  season?: string;
  /**
   * The type of the trip, possible values include (1) one-way-trip ( a single trip with departure and return) (2) Long-term ( longer than 90 days, out of scope of target 1), (3) Annual-trip
   */
  travelType?: string;
  numberOfInsuredParties?: number;
}

export interface QuotationResponseContractDestinations {
  /**
   * Always country for a single trip. For an annual trip it must be zone
   */
  destinationLocationType?: string;
  /**
   * Country code for single trip. For annual trip it must be one of the following : Domestic, Europe, Worldwide, USCA
   */
  destinationLocationName?: string;
}
