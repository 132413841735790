import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, HostBinding, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TuiDestroyService,
  ]
})
export class FieldWrapperComponent implements AfterViewInit {
  @Input() public label = '';
  @Input() public clarification = '';
  @Input() public required = false;
  @Input() public hint: string;

  @ContentChild(NgControl)
  public control: NgControl;

  @HostBinding('class.form-field_label-disabled')
  public get disabled(): boolean {
    return this.control?.disabled;
  }

  @HostBinding('class.form-field_label-invalid')
  public get invalid(): boolean {
    return this.control?.invalid;
  }

  @HostBinding('class.form-field_label-touched')
  public get touched(): boolean {
    return this.control?.touched;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private onDestroy$: TuiDestroyService,
    ) { }

  ngAfterViewInit(): void {
    if (this.control) {
      this.control.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.cdr.markForCheck();
      })
    }
  }
}
