import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Folder, Document, DownloadLink, Status } from './api-documents.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiDocumentsService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
  ) {}

  public getFolders(fileStatus = Status.UPLOADED): Observable<Folder> {
    const params = new URLSearchParams();
    params.append('fileStatus', fileStatus.toString());

    return this.http.get<Folder>(`${this.baseUrl}/folders?${params.toString()}`);
  }

  public downloadDocument(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get(url, { responseType: "blob",  observe: 'response'});
  }

  public getDocuments(pageSize: number, pageNumber: number, folderId: number | null, fileStatus = Status.UPLOADED): Observable<Document> {
    const params = new URLSearchParams()

    params.append('pageSize', pageSize.toString());
    params.append('pageNumber', pageNumber.toString());
    if (folderId) params.append('folderId', folderId.toString());
    params.append('fileStatus', fileStatus.toString());

    return this.http.get<Document>(`${this.baseUrl}/documents?${params.toString()}`);
  }

  public getDocumentDownloadLink(documentId: string): Observable<DownloadLink> {
    return this.http.get<DownloadLink>(`${this.baseUrl}/documents/${documentId}/download-link`);
  }

  public deleteDocument(documentId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/documents/${documentId}`);
  }
}
