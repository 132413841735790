<div class="shell">
  <app-back-button (goBack)="back()" class="back-button"></app-back-button>
  <div class="h1 m-h1">
    {{ 'pages.MY_INSURANCE.TITLES.ADD_EXISTING_POLICY' | translate }}
  </div>

  <ng-container [ngSwitch]="step">
    <app-insurance-number-input
      *ngSwitchCase="PolicySteps.CODE"
      [contractNumber]="contractNumber"
      (complete)="saveCodeStep($event)"
      (boscoloValidationError)="handlePoliceValidationError($event, PolicyValidationInputs.BookingReference)"
      (leclercValidationError)="handlePoliceValidationError($event, PolicyValidationInputs.DateOfBirth)">
    </app-insurance-number-input>

    <app-insurance-select-email
      *ngSwitchCase="PolicySteps.EMAIL"
      [policyData]="policyData"
      (complete)="saveEmailStep($event)"
      (expireTimer)="navigateToAddPolicy()">
    </app-insurance-select-email>

    <app-insurance-register-pin
      *ngSwitchCase="PolicySteps.PIN"
      [policyCode]="policyData.code"
      [email]="policyData.selectedEmail"
      (complete)="savePinAndCodeStep($event)"
      (expireTimer)="navigateToAddPolicy()">
    </app-insurance-register-pin>

    <app-insurance-user-data
      *ngSwitchCase="PolicySteps.USER_DATA"
      [contractNumber]="contractNumber"
      [policyValidationParameter]="policyValidationParameter"
      (complete)="saveUserDataStep($event)">
    </app-insurance-user-data>

    <app-insurance-add-succeeded
      *ngSwitchCase="PolicySteps.SUCCESS"
      [policyCode]="policyData.code">
    </app-insurance-add-succeeded>
  </ng-container>
</div>

