<div class="input form-field"
     [class.form-field_focused]="focused"
     [class.form-field_disabled]="disabled">
  <textarea class="input_field t-small"
            [placeholder]="placeholder"
            [formControl]="innerControl"
            [attr.maxlength]="maxLength"
            [rows]="rows"
            [ngClass]="resize"
            (focus)="onFocus()"
            (blur)="onBlur()">
  </textarea>
  <app-icon *ngIf="!disabled && clearButton && innerControl.value"
            class="input_icon input_clear"
            size="large"
            icon="close"
            (click)="clear()"
            theme="lighten">
  </app-icon>
</div>
