import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { DIALOG_REF } from '@kit/dialog/dialog.const';
import { DialogRef } from '@kit/dialog/dialog.interfaces';
import { environment } from "../../../../../../environments/environment";
import { LanguageService } from "@common/language/language.service";

@Component({
  selector: 'app-membership-dialog-notification',
  templateUrl: './membership-dialog-notification.component.html',
  styleUrls: ['./membership-dialog-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembershipDialogNotificationComponent {
  email = (environment.upgradeDialogEmail as any)[this.langService.countryCode.toLowerCase()];
  url = `mailto:${this.email}?subject=${this.translateService.instant('global.MEMBER_ALLIANZ_TRAVEL_DIALOG.EMAIL_SUBJECT')}`;

  constructor(
    private translateService: TranslateService,
    public bootstrapService: BootstrapService,
    public langService: LanguageService,
     @Inject(DIALOG_REF) public dialogRef: DialogRef<MembershipDialogNotificationComponent>,
  ) { }
}
