import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { ButtonModule } from '@kit/button/button.module';
import { RegisterFormModule } from '@kit/register-form/register-form.module';
import { AemRegisterComponent } from './register.component';

@NgModule({
  declarations: [AemRegisterComponent],
  exports: [AemRegisterComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RegisterFormModule,
    ButtonModule,
    AemButtonModule,
    AemRouterModule,
  ]
})
export class AemAccountRegisterModule { }
