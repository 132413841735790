import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconList } from "@kit/icon/icon.interface";

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SlideToggleComponent),
    multi: true
  }],
})
export class SlideToggleComponent implements ControlValueAccessor {
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() icon: IconList = 'done';
  @Output() valueChange = new EventEmitter<boolean>();

  constructor(private cdr: ChangeDetectorRef) {}

  onChangeValue(event: Event) {
    const { checked } = (event.target as HTMLInputElement);

    this.onChange(checked);
    this.valueChange.emit(checked);
    this.cdr.markForCheck();
  }

  public onChange = (value: boolean) => {};
  public onTouched = () => {};

  public writeValue(value: boolean): void {
    this.checked = value;
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  public onBlur() {
    this.onTouched();
  }
}
