import { AsyncValidatorFn, ValidatorFn } from "@angular/forms";

export interface Policy {
  productName: string;
  number: string;
  endDate: string;
  startDate: string;
  contractTypeName?: string;
  origin?: string;
  openPolicyNumber?: string;
}

export interface PolicyDetails {
  policyNumber: string;
  policyName: string;
  startDate: string;
  endDate: string;
  purchaseDate: string;
  idToken?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  email?: string;
  phone?: string;
  insuredParties?: InsuredTraveler[];
  documents?: PolicyDocument[];
}

export interface InsuredTraveler {
  firstName: string;
  lastName: string;
  birthDate: string;
}
//deprecated
export interface PolicyDocument {
  id: string;
  name: string;
}
//deprecated
export interface PolicyDocumentResponse {
  id: string;
  content: string;
}
//deprecated
export interface PolicyPinCode {
  code: string;
  pin?: number;
}

export interface MaskedEmails {
  maskedEmails: MaskedEmail[];
}

export interface MaskedEmail {
  id: string;
  email: string;
}

export interface PolicyValidationData {
  code: string;
  maskedEmails?: MaskedEmail[];
  selectedEmail?: MaskedEmail;
}

export interface PolicyRequestData {
  contractNumber: string;
  pinCode?: string;
  firstName?: string;
  lastName?: string;
  bookingReference?: string;
  dateOfBirth?: string;
}

export interface PolicyUserDataForm {
  firstName?: (string | AsyncValidatorFn | ValidatorFn[])[];
  lastName?: (string | AsyncValidatorFn | ValidatorFn[])[];
  bookingReference?: (string | AsyncValidatorFn | ValidatorFn[])[];
  dateOfBirth?: (string | AsyncValidatorFn | ValidatorFn[])[];
}

export enum PolicyValidationInputs {
  BookingReference = 'bookingReference',
  DateOfBirth = 'dateOfBirth',
}
