<div class="shell">
  <div class="wrapper">
    <video [controls]="!data.hideControl"
           [autoplay]="data.autoplay"
           [loop]="data.loop"
           [muted]="data.autoplay || data.muted"
           [poster]="data.poster"
           #video
           playsinline
           class="video">
      <source [src]="data.link">
    </video>
  </div>
</div>
