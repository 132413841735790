import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';
import { IS_BROWSER_PLATFORM } from '../utils/ssr.utils';

const DEFAULT_OFFSET = 10;
const DEFAULT_POSITION = 'left';
const DEFAULT_FONT_SIZE = 'normal';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() set appTooltip(value: string) {
    this.content = value;
  }

  @Input() tooltipDisabled = false;
  @Input() tooltipOffset: number = DEFAULT_OFFSET;
  @Input() tooltipPosition: 'left' | 'right' | 'top' | 'bottom' = DEFAULT_POSITION;
  @Input() tooltipOnlyExtraContent = false;
  @Input() tooltipFontSize: 'normal' | 'small' = DEFAULT_FONT_SIZE;

  private content: string;
  private popupElement: HTMLElement;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(IS_BROWSER_PLATFORM) private isBrowser: boolean,
  ) {}

  @HostListener('mouseenter')
  onShow(): void {
    if (this.isBrowser) {
      this.createTooltipPopup();
    }
  }

  @HostListener('window:scroll')
  @HostListener('mouseleave')
  hide(): void {
    if (this.isBrowser && this.popupElement) {
      this.renderer.removeChild(this.document.body, this.popupElement);
    }
  }

  ngOnDestroy(): void {
    if (this.popupElement) {
      this.hide();
      this.popupElement = null;
    }
  }

  private createTooltipPopup(): void {
    if (this.popupElement) {
      this.hide();
    }

    if (this.tooltipDisabled || (this.tooltipOnlyExtraContent && this.allContentAvailable())) {
      return;
    }

    this.popupElement = document.createElement('div');
    this.popupElement.setAttribute('class', `tooltip-container tooltip-font-${this.tooltipFontSize}`);

    this.popupElement.innerText = this.content;
    document.body.appendChild(this.popupElement);

    this.setTooltipPosition();
  }

  private setTooltipPosition() {
    switch (this.tooltipPosition) {
      case 'right':
        this.showRight();
        break;
      case 'left':
        this.showLeft();
        break;
      case 'top':
        this.showTop();
        break;
      case 'bottom':
        this.showBottom();
        break;

      default:
        break;
    }
  }

  private showTop(): void {
    let { left, top } = this.elementRef.nativeElement.getBoundingClientRect();
    this.renderer.addClass(this.popupElement, 'tooltip-container_top');

    left += this.elementRef.nativeElement.offsetWidth / 2 - this.popupElement.offsetWidth / 2;
    top -= this.popupElement.offsetHeight + this.tooltipOffset;

    if (top < 0) {
      top = 0;
    }

    if (left + this.popupElement.offsetWidth > window.screen.width) {
      left = window.screen.width - this.popupElement.offsetWidth;
    }

    this.renderer.setStyle(this.popupElement, 'top',`${top}px` );
    this.renderer.setStyle(this.popupElement, 'left', `${left}px`);
  }

  private showBottom(): void {
    let { left, top } = this.elementRef.nativeElement.getBoundingClientRect();
    this.renderer.addClass(this.popupElement, 'tooltip-container_bottom');

    left += this.elementRef.nativeElement.offsetWidth / 2 - this.popupElement.offsetWidth / 2;
    top += this.elementRef.nativeElement.offsetHeight;

    if (top + this.popupElement.offsetHeight > window.screen.height) {
      top = window.screen.height - this.popupElement.offsetHeight;
    }

    if (left + this.popupElement.offsetWidth > window.screen.width) {
      left = window.screen.width - this.popupElement.offsetWidth;
    }

    this.renderer.setStyle(this.popupElement, 'top',`${top}px` );
    this.renderer.setStyle(this.popupElement, 'left', `${left}px`);
  }

  private showRight(): void {
    let { left, top } = this.elementRef.nativeElement.getBoundingClientRect();
    this.renderer.addClass(this.popupElement, 'tooltip-container_right');

    left += this.elementRef.nativeElement.offsetWidth + this.tooltipOffset;
    top += this.elementRef.nativeElement.offsetHeight / 2 - this.popupElement.offsetHeight / 2;

    if (left + this.popupElement.offsetWidth > window.screen.width) {
      left = window.screen.width - this.popupElement.offsetWidth;
    }

    if (top < 0) {
      top = 0;
    } else if (top + this.popupElement.offsetHeight > window.screen.height) {
      top = window.screen.height - this.popupElement.offsetHeight;
    }

    this.renderer.setStyle(this.popupElement, 'top',`${top}px` );
    this.renderer.setStyle(this.popupElement, 'left', `${left}px`);
  }

  private showLeft(): void {
    let { left, top } = this.elementRef.nativeElement.getBoundingClientRect();
    this.renderer.addClass(this.popupElement, 'tooltip-container_left');

    left -= this.popupElement.offsetWidth + this.tooltipOffset;
    top += this.elementRef.nativeElement.offsetHeight / 2 - this.popupElement.offsetHeight / 2;

    if (left < 0) {
      left = 0;
    }

    if (top < 0) {
      top = 0;
    } else if (top + this.popupElement.offsetHeight > window.screen.height) {
      top = window.screen.height - this.popupElement.offsetHeight;
    }

    this.renderer.setStyle(this.popupElement, 'top',`${top}px` );
    this.renderer.setStyle(this.popupElement, 'left', `${left}px`);
  }

  private allContentAvailable(): boolean {
    const { scrollWidth, clientWidth, scrollHeight, clientHeight } = this.elementRef.nativeElement;
    return scrollWidth <= clientWidth && scrollHeight <= clientHeight;
  }
}
