<ng-container *tuiLet="mobileView$ | async as mobileView">
  <div *ngIf="!mobileView" class="slider">
    <swiper [config]="config$ | async">
      <ng-template *ngFor="let tpl of tplList" swiperSlide>
        <ng-container *ngTemplateOutlet="tpl"></ng-container>
      </ng-template>
    </swiper>
  </div>

  <div *ngIf="mobileView">
    <ng-container *ngFor="let tpl of tplList">
      <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </ng-container>
  </div>
</ng-container>
