<section class="aem-step">
  <div class="shell">
    <div class="aem-step__header">
      <h2 class="aem-step__header-title zero-margin h2 m-h2" [class.full-width]="!aemData.text">{{ aemData.title }}</h2>
      <p class="aem-step__header-text t-large m-t-normal" *ngIf="aemData.text">{{ aemData.text }}</p>
    </div>
    <div class="aem-step__body">
      <app-slider class="step-cards" [showAllInMobile]="true" [itemsCentered]="true">
        <ng-template *ngFor="let item of aemData.items" #swiperSlide>
          <app-slider-item [navigateTo]="item.cta" class="step-card">
            <app-step [step]="item" [showCounter]="aemData.showCounter"></app-step>
          </app-slider-item>
        </ng-template>
      </app-slider>
    </div>
  </div>
</section>
