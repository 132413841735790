import { Injectable, TransferState } from '@angular/core';
import { BUILD_TIME_STAMP_STATE } from "@common/start-up/start-up.const";

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private buildTimeStamp: number = this.transfer.get(BUILD_TIME_STAMP_STATE, null);

  public get iconSpriteLink(): string {
    return `./assets/img/sprite.${this.buildTimeStamp}.svg#`
  }

  constructor(private readonly transfer: TransferState) { }
}
