<div class="step-card__header" [class.step-card__show-counter]="showCounter">
  <div class="step-card__image-container">
    <img class="step-card__img"
         *ngIf="step.image"
         [src]="step.image.imagePath"
         [attr.alt]="step.image.altText"
         [appImgAlt]="step.image.altText"
         [appImgAltData]="step">
  </div>
</div>
<div [style.background-color]="backgroundColor" class="step-card__body">
  <div class="step-card__content">
    <h6 class="step-card__title h6">{{ step.title }}</h6>
    <div class="step-card__text t-small m-t-normal" *ngIf="step.text"><app-safe-html [content]="step.text"></app-safe-html></div>
  </div>
  <app-aem-button class="step-card__button" *ngIf="step.cta" [cta]="step.cta" type="full-width" size="m"></app-aem-button>
</div>
