import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { Observable } from 'rxjs';
import { FaqThematic } from '@common/faq/faq.interface';
import { FaqService } from '@common/faq/faq.service';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { FAQThematics } from '@common/model/fAQThematics';

@Component({
  selector: 'app-faq-thematics',
  templateUrl: './faq-thematics.component.html',
  styleUrls: ['./faq-thematics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemFaqThematicsComponent extends AemBaseBlockComponent {
  public thematics$: Observable<FaqThematic[]> = this.faqService.thematic$;

  constructor(
    @Inject(AEM_DATA) public override data: FAQThematics,
    private readonly faqService: FaqService,
  ) {
    super(data);
  }
}


