import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "src/app/kit/button/button.module";
import { DialogModule } from "src/app/kit/dialog/dialog.module";
import { PhoneModule } from "src/app/kit/phone/phone.module";
import { FieldErrorModule } from "@kit/field-error/field-error.module";
import { InputModule } from "@kit/input/input.module";
import { LabelModule } from "@kit/label/label.module";
import { PasswordSecurityModule } from "@kit/password-security/password-security.module";
import { PasswordModule } from "@kit/password/password.module";
import { TooltipModule } from "@kit/tooltip/tooltip.module";
import { ManageAccountComponent } from "./manage-account.component";

@NgModule({
  declarations: [ManageAccountComponent],
  exports: [ManageAccountComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputModule,
    LabelModule,
    ButtonModule,
    FieldErrorModule,
    PasswordModule,
    PhoneModule,
    DialogModule,
    PasswordSecurityModule,
    TranslateModule,
    TooltipModule,
  ]
})
export class ManageAccountModule {}
