import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { DIALOG_DATA, DIALOG_REF } from '@kit/dialog/dialog.const';
import { DialogRef } from '@kit/dialog/dialog.interfaces';
import { AirDoctorDialogData } from './air-doctor-dialog.interface';

@Component({
  selector: 'app-air-doctor-dialog',
  templateUrl: './air-doctor-dialog.component.html',
  styleUrls: ['./air-doctor-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AirDoctorDialogComponent {

  constructor(
    public bootstrapService: BootstrapService,
    @Inject(DIALOG_REF) public dialogRef: DialogRef<AirDoctorDialogComponent>,
    @Inject(DIALOG_DATA) public data: AirDoctorDialogData,
  ) {}

  public close(): void {
    this.dialogRef.close();
  }

  public goToSite(): void {
    this.dialogRef.close();
    window.open(this.data.link, '_blank');
  }
}
