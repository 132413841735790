import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { CardsWithText } from '@common/model/cardsWithText';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-cards-with-text',
  templateUrl: './cards-with-text.component.html',
  styleUrls: ['./cards-with-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemCardsWithTextComponent extends AemBaseBlockComponent {
  constructor(
    @Inject(AEM_DATA) public override data: CardsWithText,
  ) {
    super(data);
  }
}
