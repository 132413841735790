import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SspWidgetComponent } from './ssp-widget.component';
import { ButtonModule } from '@kit/button/button.module';
import { InputModule } from '@kit/input/input.module';
import { TextareaModule } from '@kit/textarea/textarea.module';
import { LabelModule } from '@kit/label/label.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '@kit/select/select.module';

@NgModule({
  declarations: [SspWidgetComponent],
    imports: [CommonModule, ButtonModule, InputModule, TextareaModule, LabelModule, ReactiveFormsModule, SelectModule],
})
export class SspWidgetModule { }
