import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Inject, LOCALE_ID, NgModule, Optional, TransferState } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from '@common/auth/auth.module';
import { AuthService } from '@common/auth/auth.service';
import { ApiBookingPathService } from '@common/booking-path/api-booking-path.service';
import { AnonymousBookingPathService } from '@common/booking-path/booking-path.anonymous.service';
import { BookingPathService } from '@common/booking-path/booking-path.service';
import { GlobalErrorHandlerService } from '@common/global-error-handler/global-error-handler.service';
import { HttpConfigInterceptor } from '@common/http/http-config.interceptor';
import { ApiInformationService } from '@common/information/api-information.service';
import { AnonymousInformationService } from '@common/information/information.anonymous.service';
import { InformationService } from '@common/information/information.service';
import { LanguageService } from '@common/language/language.service';
import { Bootstrap, Breadcrumbs, RouterConfigurations } from './common/model/models';
import {
  SERVER_BOOTSTRAP_DATA, SERVER_BOOTSTRAP_DATA_STATE, SERVER_BREADCRUMBS_DATA,
  SERVER_BREADCRUMBS_DATA_STATE, SERVER_ROUTING_DATA, SERVER_ROUTING_DATA_STATE
} from '@common/start-up/start-up.const';
import { StartUpService } from '@common/start-up/start-up.service';
import { UserService } from '@common/user/user.service';
import { AemAdditionalServicesModule } from '@kit/aem-additional-services/aem-additional-services.module';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { AemDestinationHeroModule } from '@kit/aem-destination-hero/destination-hero.module';
import { AemEmergencyNumberModule } from '@kit/aem-emergency-number/emergency-number.module';
import { AemHeroBlockModule } from '@kit/aem-hero-block/aem-hero-block.module';
import { AemServiceCardsModule } from '@kit/aem-service-cards/aem-service-cards.module';
import { AemTitledImageModule } from '@kit/aem-titled-image/aem-titled-image.module';
import { AemBlogHeroModule } from '@kit/aem/common/aem-blog-hero/aem-blog-hero.module';
import { AemHeroOnlyTextModule } from '@kit/aem/common/aem-hero-only-text/aem-hero-only-text.module';
import { AemArticleListModule } from '@kit/aem/common/articles-list/articles-list.module';
import { AemCardsWithTextModule } from '@kit/aem/common/cards-with-text/cards-with-text.module';
import {
  AemCarouselCardsWithIconModule
} from '@kit/aem/common/carousel-cards-with-icon/carousel-cards-with-icon.module';
import { AemCodeSnippetModule } from '@kit/aem/common/code-snippet/code-snippet.module';
import { AemColumnModule } from '@kit/aem/common/column/column.module';
import { AemContentAndImageModule } from '@kit/aem/common/content-and-image/content-and-image.module';
import { AemContentBlockModule } from '@kit/aem/common/content-block/content-block.module';
import { AemCtaBlockModule } from '@kit/aem/common/cta-block/cta-block.module';
import { AemFaqModule } from '@kit/aem/common/faq/faq.module';
import { AemFileDownloadsModule } from '@kit/aem/common/file-downloads/file-downloads.module';
import { AemHomeHeroModule } from '@kit/aem/common/home-hero/home-hero.module';
import { AemImageBlockModule } from '@kit/aem/common/image-block/image-block.module';
import { AemInsuranceCarrouselModule } from '@kit/aem/common/insurance-carrousel/insurance-carrousel.module';
import { AemQuoteAndImageModule } from '@kit/aem/common/quote-and-image/quote-and-image.module';
import { AemQuoteModule } from '@kit/aem/common/quote/quote.module';
import { AemRelatedArticlesModule } from '@kit/aem/common/related-articles/related-articles.module';
import {
  AemRelatedArticlesCarouselModule
} from '@kit/aem/common/related-articles-carousel/related-articles-carousel.module';
import { AemRowArticleModule } from '@kit/aem/common/row-article/row-article.module';
import { AemShareBlockModule } from '@kit/aem/common/share-block/share-block.module';
import { AemSideBySideWithCtaModule } from '@kit/aem/common/side-by-side-with-cta/side-by-side-with-cta.module';
import { AemStepByStepWithCtaModule } from '@kit/aem/common/step-by-step/step-by-step.module';
import { AemSurveyBannerModule } from '@kit/aem/common/survey-banner/survey-banner.module';
import { dateFormatFactory, FormatDate } from '@kit/date/date-formats/date-formats.factory';
import { DateModule } from '@kit/date/date.module';
import { DialogModule } from '@kit/dialog/dialog.module';
import { FooterModule } from '@kit/footer/footer.module';
import { GlobalLoaderModule } from '@kit/global-loader/global-loader.module';
import { MembershipPlansModule } from '@kit/membership-plans/membership-plans.module';
import { SeoBlockModule } from '@kit/seo-block/seo-block.module';
import { SideBySideModule } from '@kit/side-by-side/side-by-side.module';
import { ToolbarModule } from '@kit/toolbar/toolbar.module';
import { IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';
import { AccountModule } from '@pages/account/account.module';
import { DynamicModule } from '@pages/dynamic/dynamic.module';
import { AemInsuranceModule } from '@pages/insurance/insurance.module';
import { TripModule } from '@pages/trip/trip.module';
import { dateLocaleFactory } from '@kit/date/date-locale.factory';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeNl from '@angular/common/locales/nl';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localeAt from '@angular/common/locales/de-AT';
import localePl from '@angular/common/locales/pl';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ApiPromoCodeService } from '@common/admin/promocode/api-promo-code.service';
import { AnonymousPromoCodeService } from '@common/admin/promocode/promo-code.anonymous.service';
import { PromoCodeService } from '@common/admin/promocode/promo-code.service';
import { AemDynamicWrapperBlockModule } from '@kit/aem-dynamic-wrapper/aem-dynamic-wrapper.module';
import { AEM_COMPONENTS_MAP } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AEM_COMPONENTS } from './aem-components-map.const';
import { AemEmbeddedPlayerModule } from "@kit/aem/common/aem-embedded-player/aem-embedded-player.module";
import { AemVideoPlayerModule } from "@kit/aem/common/aem-video-player/aem-video-player.module";
import { AemContentAndVideoModule } from "@kit/aem/common/content-and-video/content-and-video.module";
import { TranslationKeysSwitcherModule } from "@kit/translation-keys-switcher/translation-keys-switcher.module";
import { AltairWidgetModule } from "@kit/altair-widget/altair-widget.module";
import { AemAirDoctorHeroModule } from '@kit/aem-air-doctor-hero/aem-air-doctor-hero.module';
import {
  RoleBasedContentWrapperModule
} from "@kit/aem/common/role-based-content-wrapper/role-based-content-wrapper.module";
import { LegalConsentHttpInterceptor } from "@common/legal-consent-http/legal-consent-http.interceptor";
import { AemUniversalHeroModule } from "@kit/aem-universal-hero/aem-universal-hero.module";
import { AemRichTextModule } from "@kit/aem/common/aem-rich-text/aem-rich-text.module";
import { AemImageModule } from "@kit/aem/common/aem-image/aem-image.module";
import { AemContactFormModule } from '@kit/aem/common/aem-contact-form/aem-contact-form.module';
import { SspWidgetModule } from '@kit/ssp-widget/ssp-widget.module';
import {
  InsuranceBasedContentWrapperModule
} from '@kit/aem/common/insurance-based-content-wrapper/insurance-based-content-wrapper.module';
import { GradientCardsContentModule } from '@kit/aem/common/gradient-cards-content/gradient-cards-content.module';
import {
  UserTypeContentWrapperModule
} from '@kit/aem/common/user-type-content-wrapper/user-type-content-wrapper.module';
import { MobileAppContentCardsModule } from '@kit/aem/common/mobile-app-content-cards/mobile-app-content-cards.module';
import { LumiWidgetModule } from '@kit/lumi-widget/lumi-widget.module';
import { HEL_WIDGET_BASE_URL } from '@allianz/hel-admin-widgets';
import { environment } from '../environments/environment';
import { MobileApplicationLinksModule } from '@kit/aem/common/mobile-application-links/mobile-application-links.module';

registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeDe);
registerLocaleData(localeNl);
registerLocaleData(localeEs);
registerLocaleData(localeEn);
registerLocaleData(localeAt);
registerLocaleData(localePl);

@NgModule({
  declarations: [
    AppComponent,
    AemBaseBlockComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ToolbarModule,
    FooterModule,
    TranslateModule,
    DialogModule,
    DynamicModule,
    AuthModule,
    BrowserAnimationsModule,
    DateModule,
    AemHeroBlockModule,
    AemUniversalHeroModule,
    SeoBlockModule,
    SideBySideModule,
    TripModule,
    AemButtonModule,
    MembershipPlansModule,
    AemServiceCardsModule,
    AemAdditionalServicesModule,
    AemEmergencyNumberModule,
    AemTitledImageModule,
    AemDestinationHeroModule,
    GlobalLoaderModule,
    AccountModule,
    AemSideBySideWithCtaModule,
    AemStepByStepWithCtaModule,
    AemCarouselCardsWithIconModule,
    AemHomeHeroModule,
    AemContentBlockModule,
    AemQuoteModule,
    AemImageBlockModule,
    AemCtaBlockModule,
    AemFileDownloadsModule,
    AemContentAndImageModule,
    AemContentAndVideoModule,
    AemQuoteAndImageModule,
    AemSurveyBannerModule,
    AemFaqModule,
    AemCodeSnippetModule,
    AemHeroOnlyTextModule,
    AemCardsWithTextModule,
    AemRowArticleModule,
    AemColumnModule,
    AemShareBlockModule,
    AemArticleListModule,
    AemRelatedArticlesModule,
    AemRelatedArticlesCarouselModule,
    AemBlogHeroModule,
    AemInsuranceModule,
    AemInsuranceCarrouselModule,
    AemDynamicWrapperBlockModule,
    AemEmbeddedPlayerModule,
    AemVideoPlayerModule,
    TranslationKeysSwitcherModule,
    AltairWidgetModule,
    AemAirDoctorHeroModule,
    RoleBasedContentWrapperModule,
    UserTypeContentWrapperModule,
    AemRichTextModule,
    AemImageModule,
    AemContactFormModule,
    SspWidgetModule,
    InsuranceBasedContentWrapperModule,
    GradientCardsContentModule,
    MobileAppContentCardsModule,
    LumiWidgetModule,
    MobileApplicationLinksModule,
  ],
  providers: [
    {
      provide: FormatDate,
      useFactory: dateFormatFactory,
      deps: [TranslateService],
    },
    {
      provide: LOCALE_ID,
      useFactory: dateLocaleFactory,
      deps: [LanguageService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LegalConsentHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: ApiPromoCodeService,
      useFactory: (auth: AuthService, service: PromoCodeService, anonymousService: AnonymousPromoCodeService) => {
        return auth.authorized
          ? service
          : anonymousService;
      },
      deps: [AuthService, PromoCodeService, AnonymousPromoCodeService]
    },
    {
      provide: ApiInformationService,
      useFactory: (auth: AuthService, service: InformationService, anonymousService: AnonymousInformationService) => {
        return auth.authorized
          ? service
          : anonymousService;
      },
      deps: [AuthService, InformationService, AnonymousInformationService]
    },
    {
      provide: ApiBookingPathService,
      useFactory: (auth: AuthService, service: BookingPathService, anonymousService: AnonymousBookingPathService) => {
        return auth.authorized
          ? service
          : anonymousService;
      },
      deps: [AuthService, BookingPathService, AnonymousBookingPathService]
    },
    {
      provide: AEM_COMPONENTS_MAP,
      useValue: AEM_COMPONENTS(),
    },
    LanguageService,
    TranslateService,
    { provide: HEL_WIDGET_BASE_URL, useValue: environment.helBffURL }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    router: Router,
    userService: UserService,
    transfer: TransferState,
    startUpService: StartUpService,
    languageService: LanguageService,
    translateService: TranslateService,
    @Inject(IS_SERVER_PLATFORM) isServer: boolean,
    @Optional() @Inject(SERVER_BOOTSTRAP_DATA) bootstrap: Bootstrap,
    @Optional() @Inject(SERVER_BREADCRUMBS_DATA) breadcrumbs: Breadcrumbs,
    @Optional() @Inject(SERVER_ROUTING_DATA) routesConfig: RouterConfigurations,
  ) {
    if (isServer) {
      startUpService.initApp(bootstrap, breadcrumbs, routesConfig);
      router.initialNavigation();
    } else {
      startUpService.initApp(
        transfer.get(SERVER_BOOTSTRAP_DATA_STATE, null),
        transfer.get(SERVER_BREADCRUMBS_DATA_STATE, null),
        transfer.get(SERVER_ROUTING_DATA_STATE, null),
      );

      userService.userDataInitialized$
        .pipe(take(1))
        .subscribe(() => {
          router.initialNavigation();
        });
    }

    const lang: string = languageService.languageCode.toLowerCase();
    translateService.setDefaultLang(lang);
    translateService.use(lang);
  }
}
