<section class="cart-with-text">
  <div class="shell cart-with-text__container">
    <div class="cart-with-text__item" *ngFor="let item of data.items">
      <div class="cart-with-text__body">
        <h5 class="h5 m-h3 zero-margin cart-with-text__title">{{ item.title }}</h5>

        <app-safe-html class="cart-with-text__rich-content text-small m-t-small weight-normal" *ngIf="item.text" [content]="item.text"></app-safe-html>

        <div class="cart-with-text__actions">
          <app-aem-button *ngFor="let cta of item.ctas" [cta]="cta" type="full-width" size="l"></app-aem-button>
        </div>
      </div>
      <div class="cart-with-text__footer" *ngIf="item.bottomText || item.bottomCtas">
        <app-safe-html class="cart-with-text__bottom-content t-small m-t-small" *ngIf="item.bottomText" [content]="item.bottomText"></app-safe-html>
        
        <div class="cart-with-text__actions">
          <app-aem-button *ngFor="let cta of item.bottomCtas" [cta]="cta" type="full-width" size="l"></app-aem-button>
        </div>
      </div>
    </div>
  </div>
</section>
