import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@kit/button/button.module';
import { FieldErrorModule } from '@kit/field-error/field-error.module';
import { InputModule } from '@kit/input/input.module';
import { LabelModule } from '@kit/label/label.module';
import { AemResetPasswordComponent } from './reset-password.component';

@NgModule({
  declarations: [AemResetPasswordComponent],
  exports: [AemResetPasswordComponent],
  imports: [
    CommonModule,
    ButtonModule,
    LabelModule,
    InputModule,
    FieldErrorModule,
    ReactiveFormsModule,
    TranslateModule,
  ]
})
export class AemResetPasswordModule { }
