<section>
  <div class="section__shell shell">
    <div *tuiLet="userNotificationSettings$ | async as notification" class="cards">
      <div class="wrapper">
        <div class="cards_item">
          <div class="cards_header">
            <h2 class="cards_header-title t-large m-h3 weight-medium">
              {{ 'pages.NOTIFICATION.TITLES.MARKETING_COMMUNICATIONS' | translate }}
            </h2>
            <app-icon [appTooltip]="'pages.NOTIFICATION.TOOLTIP.MARKETING_COMMUNICATIONS' | translate"
                      icon="info-fill"
                      size="large"
                      tooltipFontSize="small"
                      tooltipPosition="bottom">
            </app-icon>
          </div>
          <div class="cards_body">
            <p class="section__text t-small m-t-normal">
              <app-safe-html [content]="'pages.NOTIFICATION.DESCRIPTIONS.MARKETING_COMMUNICATIONS' | translate">
              </app-safe-html>
            </p>

            <ng-container *tuiLet="getUserMarketingConsents(notification) as marketingConsentsObject">
              <div class="action_field" *ngIf="isGlobalMarketingConsent; else internalAndExternalConsents">
                <app-icon icon="envelope"
                          size="x-large"
                          theme="amped">
                </app-icon>
                <p class="zero-margin t-small m-t-normal">
                  {{ 'pages.NOTIFICATION.LABELS.MARKETING_COMMUNICATIONS_GLOBAL' | translate }}
                </p>
                <app-slide-toggle [checked]="marketingConsentsObject.GLOBAL_MARKETING"
                                  (click)="switchMarketingNotification(notification, consentType.GLOBAL_MARKETING, $event)"
                                  id="notification-marketing-global">
                </app-slide-toggle>
              </div>

              <ng-template #internalAndExternalConsents>
                <div class="action_field">
                  <app-icon icon="envelope" size="x-large" theme="amped">
                  </app-icon>
                  <p class="zero-margin t-small m-t-normal">
                    {{ 'pages.NOTIFICATION.LABELS.MARKETING_COMMUNICATIONS_INTERNAL' | translate }}
                  </p>
                  <app-slide-toggle [checked]="marketingConsentsObject.INTERNAL_MARKETING"
                                    (click)="switchMarketingNotification(notification, consentType.INTERNAL_MARKETING, $event)"
                                    id="notification-marketing-internal">
                  </app-slide-toggle>
                </div>

                <div class="action_field">
                  <app-icon icon="envelope" size="x-large" theme="amped">
                  </app-icon>
                  <p class="zero-margin t-small m-t-normal">
                    <app-safe-html [content]="'pages.NOTIFICATION.LABELS.MARKETING_COMMUNICATIONS_EXTERNAL' | translate ">
                    </app-safe-html>
                  </p>
                  <app-slide-toggle [checked]="marketingConsentsObject.EXTERNAL_MARKETING"
                                    (click)="switchMarketingNotification(notification, consentType.EXTERNAL_MARKETING, $event)"
                                    id="notification-marketing-external">
                  </app-slide-toggle>
                </div>

              </ng-template>
            </ng-container>

        </div>
      </div>
      <div class="wrapper">
        <div class="cards_item">
          <div class="cards_header cards_header-default">
            <h2 class="cards_header-title t-large m-h3 weight-medium">
              {{ 'pages.NOTIFICATION.TITLES.SERVICE_NOTIFICATIONS' | translate }}
            </h2>
          </div>
          <div class="cards_body">
            <h2 class="cards_header-title cards_subtitle t-large m-h3 weight-medium">
              {{ 'pages.NOTIFICATION.SUBTITLES.SERVICE_NOTIFICATIONS' | translate }}
            </h2>
            <ng-container *ngFor="let notification of notificationSelects">
              <div *ngIf="!notification.selectedConsent[0].isBlocked" class="cards_item_actions">
                <div class="t-small weight-medium m-t-normal">
                  {{ notification.label | translate }}
                </div>
                <app-select [options]="notification.selectOptions$ | async"
                            [formControl]="notification.formControl"
                            valueKey="value"
                            viewKey="name"
                            selectKey="value">
                </app-select>
                <app-icon icon="info-fill"
                          size="large"
                          theme="white"
                          [appTooltip]="notification.tooltip | translate"
                          tooltipFontSize="small"
                          tooltipPosition="bottom">
                </app-icon>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- for visa -->
      <div *ngIf="isVisaUser" class="wrapper">
        <div class="cards_item">
          <div class="cards_header cards_header-default">
            <h2 class="cards_header-title t-large m-h3 weight-medium">
              {{ 'pages.NOTIFICATION.TITLES.LANGUAGE_NOTIFICATIONS' | translate }}
            </h2>
          </div>
          <div class="cards_body">
            <div class="cards_item_actions cards_item_actions-lang">
              <app-icon icon="planet" size="x-large" theme="amped">
              </app-icon>
              <div class="t-small weight-medium m-t-normal">
                {{ 'pages.NOTIFICATION.LABELS.LANGUAGE_NOTIFICATIONS' | translate }}
              </div>
              <app-select [options]="languageNotificationSelects"
                          [formControl]="languageNotificationSelectControl"
                          valueKey="value" viewKey="name" selectKey="value">
              </app-select>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  </div>
</section>
