import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { SliderModule } from '@kit/carousel/slider.module';
import { IconModule } from '@kit/icon/icon.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemCarouselCardsWithIconComponent } from './carousel-cards-with-icon.component';

@NgModule({
  declarations: [AemCarouselCardsWithIconComponent],
  exports: [AemCarouselCardsWithIconComponent],
  imports: [
    CommonModule,
    AemButtonModule,
    SafeHtmlModule,
    SliderModule,
    IconModule,
  ]
})
export class AemCarouselCardsWithIconModule { }
