import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { catchError, map, Observable, of, switchMap, takeUntil, throwError } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { wrapValidator } from 'src/app/kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { notBlankValidator } from 'src/app/kit/utils/validators';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';
import { MaskedEmail, MaskedEmails, PolicyValidationData } from '../../policy.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerValidationError } from '@kit/server-errors/server-errors.interface';

@Component({
  selector: 'app-insurance-number-input',
  templateUrl: './insurance-number-input.component.html',
  styleUrls: ['./insurance-number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService, ServerErrorsValidationService]
})
export class InsuranceNumberInputComponent implements OnInit {
  public policyForm: UntypedFormGroup;

  @Output() complete = new EventEmitter<PolicyValidationData>();
  @Output() boscoloValidationError = new EventEmitter<string>();
  @Output() leclercValidationError = new EventEmitter<string>();
  @Input() public contractNumber: string = null;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly destroy$: TuiDestroyService,
    private readonly validationService: ServerErrorsValidationService,
    private readonly policyService: ApiInsurancePolicyService,
    public readonly bootstrapService: BootstrapService
  ) {
  }

  public ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.policyForm = this.fb.group({
      code: [
        this.contractNumber,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
        ],
        this.validationService.createValidator('policyNumber')
      ],
      shareAccept: [
        false,
        wrapValidator(Validators.requiredTrue, 'errors.CEAZ011'),
      ],
    });

    this.policyForm.addAsyncValidators(this.validationService.createValidator());
  }

  public onSubmit(): void {
    this.policyForm.markAllAsTouched();
    const { code, shareAccept } = this.policyForm.getRawValue();

    if (this.policyForm.invalid || !shareAccept) {
      return;
    }

    this.policyService.verifyContract({ contractNumber: code.trim() }).pipe(
      catchError(err => this.handlePolicyVerificationErrors(err, code)),
      switchMap((emails: MaskedEmails) => this.handlePolicyVerification(emails, code)),
      takeUntil(this.destroy$),
    ).subscribe((value: MaskedEmail[]) => {
      this.complete.emit({ code: code, maskedEmails: value, selectedEmail: value[0] })
    });
  }

  private handlePolicyVerificationErrors(err: HttpErrorResponse, code: string): Observable<never> {
    const isBoscoloValidationError = err?.error?.fault?.errors.some((err: ServerValidationError) => (err.errorCode === 'CEAZ135' || err.fieldName === 'firstName, lastName, bookingReference'));
    const isLeclercValidationError = err?.error?.fault?.errors.some((err: ServerValidationError) => (err.errorCode === 'CEAZ133' || err.fieldName === 'firstName, lastName, dateOfBirth'));

    if (isBoscoloValidationError) this.boscoloValidationError.emit(code);
    if (isLeclercValidationError) this.leclercValidationError.emit(code);

    this.validationService.handleServerError(err?.error);
    return throwError(() => err);
  }

  private handlePolicyVerification(emails: MaskedEmails, code: string): Observable<MaskedEmail[]> {
    if (emails?.maskedEmails.length === 1) {

      return this.policyService.sendPinToEmail(code.trim(), emails.maskedEmails[0].id).pipe(
        map(() => emails.maskedEmails)
      );
    }

    if (emails?.maskedEmails.length > 1) {
      const maskedEmails = emails.maskedEmails.map(email => ({ id: email.id, email: email.email }));

      return of(maskedEmails);
    }

    return of([]);
  }
}
