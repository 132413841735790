import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentButtonComponent } from './document-button.component';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [DocumentButtonComponent],
  exports: [DocumentButtonComponent],
  imports: [
    CommonModule,
    ButtonModule,
  ]
})
export class DocumentButtonModule { }
