import { NgModule } from '@angular/core';
import { AemMembershipCardsModule } from '@kit/aem/common/aem-membership-cards/membership.module';
import { AemMembershipPromotionsModule } from '@kit/aem/common/aem-membership-promotions/aem-membership-promotions.module';
import { AemBubbleAndShapedImageModule } from '@kit/aem/common/bubble-and-shaped-image/bubble-and-shaped-image.module';
import { AemAccountEditFormModule } from './components/aem-account-edit-form/account-edit-form.module';
import { AemAccountHeroBlockModule } from './components/aem-account-hero/aem-account-hero.module';
import { AemAccountRegisterModule } from './components/aem-account-register/register.module';
import { AemMyAccountModule } from './components/aem-my-account/my-account.module';
import { AemPasswordCreationsFormModule } from './components/aem-password-creations-form/password-creations-form.module';
import { AemProfileLinksModule } from './components/aem-profile-links/aem-profile-links.module';
import { AemResetPasswordModule } from './components/aem-reset-password/reset-password.module';
import { AemCoTravelerModule } from './components/co-travelers/co-travelers.module';
import { MyInsuranceModule } from './components/my-insurance/my-insurance.module';
import { AemNotificationSettingsModule } from './components/notification/notification.module';
import { AemLoungeVouchersModule } from './components/lounge-vouchers/lounge-vouchers.module';
import { AemDocumentVaultModule } from './components/document-vault/document-vault.module';
import { TermsAndConditionsModule } from './components/terms-and-conditions/terms-and-conditions.module';

@NgModule({
  exports: [
    AemAccountHeroBlockModule,
    AemProfileLinksModule,
    AemBubbleAndShapedImageModule,
    AemResetPasswordModule,
    AemAccountRegisterModule,
    AemPasswordCreationsFormModule,
    AemMyAccountModule,
    AemAccountEditFormModule,
    AemCoTravelerModule,
    AemMembershipCardsModule,
    AemMembershipPromotionsModule,
    AemNotificationSettingsModule,
    MyInsuranceModule,
    AemLoungeVouchersModule,
    AemDocumentVaultModule,
    TermsAndConditionsModule
  ]
})
export class AccountModule { }
