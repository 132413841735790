import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UserService } from "@common/user/user.service";
import { UserProfile } from "@common/profile/profile.interfaces";
import { map, Observable } from "rxjs";

@Component({
  selector: 'app-user-id-button',
  templateUrl: './user-id-button.component.html',
  styleUrls: ['./user-id-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemUserIdButtonComponent implements OnInit {
  public userId$: Observable<number>;

  constructor(
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {
    this.userId$ = this.userService.userData$
      .pipe(map((usedData: UserProfile) => usedData?.id));

  }

  public copyId(userId: number) {
    navigator.clipboard.writeText(userId.toString());
  }

}
