import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeHtmlModule } from '../../../safe-html/safe-html.module';
import { AemContentBlockComponent } from './content-block.component';

@NgModule({
  declarations: [AemContentBlockComponent],
  exports: [AemContentBlockComponent],
  imports: [
    CommonModule,
    SafeHtmlModule,
  ]
})
export class AemContentBlockModule { }
