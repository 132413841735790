import { ServerValidationError, ServerValidationErrorResponse } from '../server-errors/server-errors.interface';

export function fillForgeRockCallback(valuesMap: Map<ForgeRockCallBackItemType, unknown>, fgCallback: ForgeRockCallBack): ForgeRockCallBack {
  const entries = Array.from(valuesMap.entries());
  entries.forEach(([callbackType, value]) => {
    setFRCallbackValue(fgCallback, CALLBACK_PARAMS_BY_TYPE[callbackType], value);
  });

  return fgCallback;
}

export function setFRCallbackValue(fgCallback: ForgeRockCallBack, searchParams: { type: ForgeRockCallBackType; name?: string; idToken?: string; }, value: unknown) {
  const itemCallBack = fgCallback.callbacks.find(({ type, output, input }) => {
    const typeEquals = type === searchParams.type;
    const nameEquals = !searchParams.name ||
      output.some(({ name, value }) => name === 'name' && value === searchParams.name);
    const idTokenEquals = !searchParams.idToken ||
      input.some(({ name }) => name === searchParams.idToken);

    return typeEquals && nameEquals && idTokenEquals;
  });

  const [itemCallBackInput] = itemCallBack.input;
  itemCallBackInput.value = value;
}

export function mapFRValidationErrors(callbacks: ForgeRockCallBackItem[]): ServerValidationErrorResponse {
  const frErrors = callbacks.filter(({ type }) => type === ForgeRockCallBackType.TextOutputCallback);
  const errors: ServerValidationError[] = frErrors.map(({ output }) => {
    const [error] = output;
    const [errorField, errorCode] = error?.value?.split(':');

    return {
      errorCode: errorCode,
      fieldName: errorField.toLowerCase(),
    } as ServerValidationError;
  });

  return { fault: { errors } };
}

export interface ForgeRockCallBack {
  "authId": string,
  "callbacks": Array<ForgeRockCallBackItem>;
}

export interface ForgeRockCallBackItem {
  type: ForgeRockCallBackType;
  output: Array<{
    name: string;
    value: string;
  }>;
  input: Array<{
    name: string;
    value: unknown;
  }>;
}

export enum  ForgeRockCallBackItemType {
  CreateUsernameCallback = 'CreateUsernameCallback',
  CreateFirstNameCallback = 'CreateFirstNameCallback',
  CreateLastNameCallback = 'CreateLastNameCallback',
  PhoneCallback = 'PhoneCallback',
  PasswordCallback = 'PasswordCallback',
  DateOfBirthCallback = 'DateOfBirthCallback',
  PlatformIdCallback = 'PlatformIdCallback',
  RegistrationSourceCallback = 'RegistrationSourceCallback',
  OwnerNameCallback = 'OwnerNameCallback',
  CountryCallback = 'CountryCallback',
  PromotionCallback = 'PromotionCallback',
  RedirectionUrlCallback = 'RedirectionUrlCallback',
  MarketingConsentCallback = 'MarketingConsentCallback',
  LanguageCallback = 'LanguageCallback',
}

export enum ForgeRockCallBackType {
  NameCallback = 'NameCallback',
  StringAttributeInputCallback = 'StringAttributeInputCallback',
  ValidatedCreatePasswordCallback = 'ValidatedCreatePasswordCallback',
  PasswordCallback = 'PasswordCallback',
  ChoiceCallback = 'ChoiceCallback',
  TextOutputCallback = 'TextOutputCallback',
}

const CALLBACK_PARAMS_BY_TYPE: Record<
  ForgeRockCallBackItemType,
  {
    type: ForgeRockCallBackType;
    name?: string;
    idToken?: string;
  }
> = {
  [ForgeRockCallBackItemType.CreateUsernameCallback]: {
    type: ForgeRockCallBackType.NameCallback,
    idToken: 'IDToken1'
  },
  [ForgeRockCallBackItemType.CreateFirstNameCallback]: {
    type: ForgeRockCallBackType.StringAttributeInputCallback,
    idToken: 'IDToken2',
    name: 'givenName',
  },
  [ForgeRockCallBackItemType.CreateLastNameCallback]: {
    type: ForgeRockCallBackType.StringAttributeInputCallback,
    idToken: 'IDToken3',
    name: 'sn',
  },
  [ForgeRockCallBackItemType.PasswordCallback]: {
    type: ForgeRockCallBackType.ValidatedCreatePasswordCallback,
    idToken: 'IDToken4',
  },
  [ForgeRockCallBackItemType.CountryCallback]: {
    type: ForgeRockCallBackType.ChoiceCallback,
    idToken: 'IDToken5',
  },
  [ForgeRockCallBackItemType.PhoneCallback]: {
    type: ForgeRockCallBackType.NameCallback,
    idToken: 'IDToken6',
  },
  [ForgeRockCallBackItemType.RedirectionUrlCallback]: {
    type: ForgeRockCallBackType.NameCallback,
    idToken: 'IDToken7',
  },
  [ForgeRockCallBackItemType.DateOfBirthCallback]: {
    type: ForgeRockCallBackType.NameCallback,
    idToken: 'IDToken8',
  },
  [ForgeRockCallBackItemType.PlatformIdCallback]: {
    type: ForgeRockCallBackType.NameCallback,
    idToken: 'IDToken9',
  },
  [ForgeRockCallBackItemType.RegistrationSourceCallback]: {
    type: ForgeRockCallBackType.NameCallback,
    idToken: 'IDToken10',
  },
  [ForgeRockCallBackItemType.OwnerNameCallback]: {
    type: ForgeRockCallBackType.NameCallback,
    idToken: 'IDToken11',
  },
  [ForgeRockCallBackItemType.PromotionCallback]: {
    type: ForgeRockCallBackType.NameCallback,
    idToken: 'IDToken12',
  },
  [ForgeRockCallBackItemType.MarketingConsentCallback]: {
    type: ForgeRockCallBackType.NameCallback,
    idToken: 'IDToken13',
  },
  [ForgeRockCallBackItemType.LanguageCallback]: {
    type: ForgeRockCallBackType.NameCallback,
    idToken: 'IDToken14',
  },
}
