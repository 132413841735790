<div class="shell">
  <div class="form-container">
    <app-register-form  *ngIf="!confirmationStep"
      [title]="'pages.REGISTRATION.TITLE' | translate"
      (registered)="onFormSend($event)"
    ></app-register-form>

    <div class="confirmation_info" *ngIf="confirmationStep">
      <h2 class="confirmation_info_title">{{ 'pages.REGISTRATION.CONFIRMATION_TITLE' | translate }}</h2>
      <div class="text-normal">{{ 'pages.REGISTRATION.CONFIRMATION_MESSAGE' | translate }}</div>

      <app-login>
        <app-button
          id="action-confirmation-login"
          size="m"
          theme="black"
          type="full-width"
          icon="person-add"
        >{{ 'pages.REGISTRATION.ACTIONS.LOG_IN' | translate }}</app-button>
      </app-login>

      <app-button
        id="action-confirmation-resend"
        size="m"
        theme="bordered"
        type="full-width"
        icon="email"
        (click)="resend()"
      >{{ 'pages.REGISTRATION.ACTIONS.RESEND_CONFIRMATION' | translate }}</app-button>
    </div>
  </div>
</div>
