import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimateHeightDirective } from './animate-height.directive';

@NgModule({
  declarations: [AnimateHeightDirective],
  exports: [AnimateHeightDirective],
  imports: [CommonModule]
})
export class AnimateHeightDirectiveModule {}
