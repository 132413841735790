import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { DynamicComponentWrapperBlock } from 'src/app/common/model/dynamicComponentWrapperBlock';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { DynamicContent } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.interface';
import { DynamicRenderService } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.service';
import { AemBaseBlockComponent } from '../aem-base-block/aem-base-block';

@Component({
  selector: 'app-dynamic-wrapper-block',
  templateUrl: './aem-dynamic-wrapper.component.html',
  styleUrls: ['./aem-dynamic-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicWrapperBlockComponent extends AemBaseBlockComponent implements OnInit {
  public extra?: DynamicContent;
  public ContentAlignment = DynamicComponentWrapperBlock.ComponentAlignmentEnum;

  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly dynamicRenderService: DynamicRenderService,
    @Inject(AEM_DATA) public override data: DynamicComponentWrapperBlock,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, '--header-primary', this.data?.colourBackground, RendererStyleFlags2.DashCase);
    this.extra = this.dynamicRenderService.getComponent((this.data)?.component);
  }
}
