import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { TranslateModule } from '@kit/translate/translate.module';
import { ArticleModule } from '@kit/article/article.module';
import { AemRelatedArticlesCarouselComponent } from './related-articles-carousel.component';
import { SliderModule } from '@kit/carousel/slider.module';

@NgModule({
  declarations: [AemRelatedArticlesCarouselComponent],
  exports: [AemRelatedArticlesCarouselComponent],
  imports: [
    CommonModule,
    AemButtonModule,
    ArticleModule,
    TranslateModule,
    SliderModule,
  ]
})
export class AemRelatedArticlesCarouselModule { }
