import { Inject, Injectable, OnDestroy } from '@angular/core';
import { UserService } from "@common/user/user.service";
import { delay, filter, map, Observable, of, Subject, switchMap, takeUntil, tap } from "rxjs";
import { AnalyticsService } from "@common/analytics/analytics.service";
import { TradedoublerService } from "@common/tradedoubler/tradedoubler.service";
import { UserProfile } from "@common/profile/profile.interfaces";
import { TradedoublerEvent } from "@common/tradedoubler/tradedoublerRouteData";
import { IS_BROWSER_PLATFORM } from "@kit/utils/ssr.utils";
import { DigitalDataService } from "@common/analytics/digital-data.service";
import { PremiumDetail } from "@pages/admin/components/user-edit/premium-details.interface";
import { ApiProfileService } from "@common/profile/api-profile.service";

@Injectable({
  providedIn: 'root'
})
export class ActivationUserAnalyticsService implements OnDestroy {
  private onDestroy$ = new Subject<void>();

  constructor(
    private readonly userService: UserService,
    private readonly analyticsService: AnalyticsService,
    private readonly tradedoublerService: TradedoublerService,
    private readonly digitalDataService: DigitalDataService,
    private readonly apiProfileService: ApiProfileService,
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean,
  ) { }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public initActivationUserAnalytic(): void {
    this.userService.isAuth$.pipe(
      switchMap(() => this.userService.userData$),
      filter((profile: UserProfile) => !!profile)
    ).subscribe((profile: UserProfile) => {
      this.digitalDataService.updateDigitalData();
      this.sentTradedoublerActivationAction(profile);
      of(null).pipe(
        delay(300),
        tap(() => this.sendSingUpAnalyticsAction(profile)),
        delay(300),
        tap(() => this.sendBundleOfferAnalyticsAction(profile)),
        delay(300),
        tap(() => this.sendPromoCodeAnalyticsAction(profile)),
        takeUntil(this.onDestroy$),
      ).subscribe();
    });
  }

  private sentTradedoublerActivationAction(profile: UserProfile): void {
    if (this.isBrowserFirstAccess(profile)) {
      this.tradedoublerService.sendTradedoublerAction(TradedoublerEvent.activation);
    }
  }

  private sendSingUpAnalyticsAction(profile: UserProfile): void {
    if (!this.isBrowserFirstAccess(profile)) {
      return;
    }

    const label = profile.registrationSource === 'allyz.com' ? 'email' : profile.registrationSource;

    this.analyticsService.triggerAction({
      category: 'member_account',
      action: 'signUp',
      label: label,
      value: 1,
    });
  }

  private sendBundleOfferAnalyticsAction(profile: UserProfile): void {
    if (this.isBrowserFirstAccess(profile) && profile.registrationSource !== 'allyz.com') {
      this.getFirstUserMembership$()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((membership: PremiumDetail) => {
          if (membership?.insurance) {
            this.analyticsService.triggerAction({
              category: 'partner_subscription',
              action: 'memberUpgraged',
              label: membership?.insurance,
              value: 1,
            });
          }
        });
    }
  }

  private sendPromoCodeAnalyticsAction(profile: UserProfile): void {
    if (this.isBrowserFirstAccess(profile) && profile.registrationSource === 'allyz.com' && profile.promoCode) {
      this.analyticsService.triggerAction({
        category: 'partner_subscription',
        action: 'memberUpgraded',
        label: profile.promoCode,
        value: 1,
        timeStamp: new Date().toISOString(),
      });
    }
  }

  private isBrowserFirstAccess(profile: UserProfile): boolean {
    return this.isBrowser && profile && !profile.firstAccess && !profile.lastAccess;
  }

  private getFirstUserMembership$(): Observable<PremiumDetail> {
    return this.apiProfileService.getUserProfileMemberships().pipe(
      map((memberships: PremiumDetail[]) => {
          return memberships.sort((a: PremiumDetail, b: PremiumDetail) => a.id - b.id)[0]
        }
      ),
    );
  }
}
