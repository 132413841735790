import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { getAllRouterParams, isAbsoluteUrl, setRouterData } from '../utils/router.utils';
import { WindowRef } from '@common/window-service/window.service';

@Component({
  selector: 'app-safe-html',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SafeHtmlComponent {
  @Input() set content(html: string) {
    this.html = html ? this.domSanitizer.bypassSecurityTrustHtml(html) : '';
  }

  @HostBinding('innerHtml')
  public html: SafeHtml;

  @HostListener('click', ['$event'])
  public handleClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      event.stopPropagation();

      const route = (event.target as HTMLAnchorElement)?.getAttribute('href');
      const target = (event.target as HTMLAnchorElement)?.getAttribute('target');

      if (route && isAbsoluteUrl(route)) {
        event.preventDefault();
        event.stopPropagation();

        if (target === '_self') {
          this.windowRef.nativeWindow.location.href = route;
          return;
        }

        this.windowRef.nativeWindow.open(route);

        return;
      }

      if (route && !isAbsoluteUrl(route) && !this.isFileUrl(route) && !this.isNewTab(target)) {
        event.preventDefault();
        event.stopPropagation();
        this.router.navigateByUrl(setRouterData(route, getAllRouterParams(this.activatedRoute.snapshot)));
      }
    }
  }

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly windowRef: WindowRef,
  ) { }

  private isFileUrl(url: string): boolean {
    return url.lastIndexOf('.') > url.lastIndexOf('/');
  }

  private isNewTab(target: string): boolean {
    return target === '_blank';
  }
}
