import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Inject, inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";

export const IS_SERVER_PLATFORM = new InjectionToken<boolean>('IS_SERVER_PLATFORM', {
    factory() {
        return isPlatformServer(inject(PLATFORM_ID));
    },
    providedIn: 'root'
});

export const IS_BROWSER_PLATFORM = new InjectionToken<boolean>('IS_BROWSER_PLATFORM', {
    factory() {
        return isPlatformBrowser(inject(PLATFORM_ID));
    },
    providedIn: 'root'
});

@Injectable({
    providedIn: 'root'
})
export class AppStorage implements Storage {

    constructor(@Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean) { }

    get length(): number {
        return this.isServer ? null : localStorage.length;
    }

    clear(): void {
        !this.isServer && localStorage.clear();
    }

    getItem(key: string): string {
        return this.isServer ? null : localStorage.getItem(key);
    }

    key(index: number): string {
        return this.isServer ? null : localStorage.key(index);
    }

    removeItem(key: string): void {
        !this.isServer && localStorage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        !this.isServer && localStorage.setItem(key, value);
    }
}

@Injectable({
  providedIn: 'root'
})
export class AppSessionStorage implements Storage {

  constructor(@Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean) { }

  get length(): number {
    return this.isServer ? null : sessionStorage.length;
  }

  clear(): void {
    !this.isServer && sessionStorage.clear();
  }

  getItem(key: string): string {
    return this.isServer ? null : sessionStorage.getItem(key);
  }

  key(index: number): string {
    return this.isServer ? null : sessionStorage.key(index);
  }

  removeItem(key: string): void {
    !this.isServer && sessionStorage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    !this.isServer && sessionStorage.setItem(key, value);
  }
}
