<div class="document_button">
  <ng-container *ngIf="action === ActionType.download; else newTabAction">
    <a class="document_button-link" [attr.href]="link" download>
      <h6 class="document_button-label zero-margin h6 m-h4">{{ label }}</h6>
    </a>

    <div (click)="onClick()" class="document_button-actions">
      <div *ngIf="type || size" class="document_button-size t-small m-t-small">
        {{ type }} {{ size }}
      </div>
      <a [attr.href]="link" download>
        <app-button class="document_button-icon"
                    type="icon-button"
                    size="inherit"
                    theme="black"
                    icon="download">
        </app-button>
      </a>
    </div>
  </ng-container>

  <ng-template #newTabAction>
    <a class="document_button-link" [attr.href]="link" target="_blank">
      <h6 class="document_button-label zero-margin h6 m-h4">{{ label }}</h6>
    </a>

    <div (click)="onClick()" class="document_button-actions">
      <div *ngIf="type || size" class="document_button-size t-small m-t-small">
        {{ type }} {{ size }}
      </div>
      <a [attr.href]="link" target="_blank">
        <app-button class="document_button-icon"
                    type="icon-button"
                    size="inherit"
                    theme="black"
                    icon="download">
        </app-button>
      </a>
    </div>
  </ng-template>
</div>
