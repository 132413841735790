import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SurveyBannerModal } from '../../../common/model/surveyBannerModal';
import { DIALOG_DATA, DIALOG_REF } from '../dialog.const';
import { DialogRef } from '../dialog.interfaces';

@Component({
  selector: 'app-survey-dialog',
  templateUrl: './survey-dialog.component.html',
  styleUrls: ['./survey-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: SurveyBannerModal,
    @Inject(DIALOG_REF) private dialogRef: DialogRef<SurveyBannerModal>,
  ) { }

  cancel(): void {
    this.dialogRef.close();
  }
}
