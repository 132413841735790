<button #button
        class="app-button"
        [class.disabled]="disabled"
        [ngClass]="['app-button-type_' + type, 'app-button-theme_' + theme, 'app-button-size_' + size]">
  <app-icon *ngIf="icon"
            size="large"
            theme="inherit"
            [icon]="icon">
  </app-icon>
  <ng-content></ng-content>
</button>
