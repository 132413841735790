<div class="shell">
  <div class="manage-travelers_form" [formGroup]="manageTravalersForm">
    <div class="content-block">
      <h2 class="zero-margin h5 m-h3">
        {{ 'forms.MANAGE_CO_TRAVELERS.HEADER' | translate }}
      </h2>
      <div class="t-small m-t-normal">
        {{ 'forms.MANAGE_CO_TRAVELERS.FORM.NOTE' | translate }}
      </div>
    </div>

    <div class="content-block">
      <app-label [label]="'forms.MANAGE_CO_TRAVELERS.FORM.LABEL.FIRST_NAME' | translate" [required]="true">
        <app-input id="cotraveler-first-name"
          [placeholder]="'forms.MANAGE_CO_TRAVELERS.FORM.PLACEHOLDER.FIRST_NAME' | translate" [clearButton]="true"
          [maxLength]="50" formControlName="name">
        </app-input>
        <app-field-error controlName="name"></app-field-error>
      </app-label>
      <app-label [label]="'forms.MANAGE_CO_TRAVELERS.FORM.LABEL.LAST_NAME' | translate" [required]="true">
        <app-input id="cotraveler-last-name"
          [placeholder]="'forms.MANAGE_CO_TRAVELERS.FORM.PLACEHOLDER.LAST_NAME' | translate" [clearButton]="true"
          [maxLength]="50" formControlName="surname">
        </app-input>
        <app-field-error controlName="surname"></app-field-error>
        <app-field-error [control]="manageTravalersForm"></app-field-error>
      </app-label>
    </div>

    <ng-container *ngIf="!cotravelerId">
      <div *ngIf="'forms.MANAGE_CO_TRAVELERS.FORM.WARNING' | translate as warning" class="t-small m-t-normal">
        {{ warning }}
      </div>
    </ng-container>

    <div class="content-block actions">
      <app-button id="action-cotravaler-save-info" type="full-width" (click)="save()">
        {{ 'forms.MANAGE_CO_TRAVELERS.FORM.SAVE' | translate }}
      </app-button>
      <app-button id="action-cotravaler-cancel" type="full-width" theme="bordered"
        [appAemRouterLink]="bootstrapService.link.cotravelers">
        {{ 'forms.MANAGE_CO_TRAVELERS.FORM.CANCEL' | translate }}
      </app-button>
    </div>
  </div>
</div>
