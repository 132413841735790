import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Renderer2, ViewChild
} from '@angular/core';
import { AEM_DATA } from "@pages/dynamic/dynamic-render/dynamic-render.const";
import { Image } from "@common/model/image";
import { AemFragmentComponent } from "@kit/aem-base-block/aem-fragment";

@Component({
  selector: 'app-aem-image',
  templateUrl: './aem-image.component.html',
  styleUrls: ['./aem-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemImageComponent extends AemFragmentComponent implements AfterViewInit {
  @ViewChild('image') imageRef: ElementRef;
  @ViewChild('imageWrapper') imageWrapperRef: ElementRef;

  constructor(
    @Inject(AEM_DATA) public override data: Image,
    private readonly renderer: Renderer2,
  ) {
    super(data);
  }

  public ngAfterViewInit(): void {
    const imageLink: HTMLElement = this.imageRef.nativeElement;
    const imageWrapperLink: HTMLElement = this.imageWrapperRef.nativeElement;

    if (this.data.maxWidth) {
      this.renderer.setStyle(imageLink, 'max-width', this.data.maxWidth);
      this.renderer.setStyle(imageLink, 'overflow', 'unset');
    }
    if (this.data.alignment) {
      this.renderer.setStyle(imageWrapperLink, 'display', 'flex');
      this.renderer.setStyle(imageWrapperLink, 'justify-content', 'center');
      this.renderer.setStyle(imageWrapperLink, 'padding-bottom', '72px');
    }
  }
}
