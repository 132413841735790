import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { IS_SERVER_PLATFORM } from 'src/app/kit/utils/ssr.utils';
import { CURRENT_LOCATION } from '../seo/seo.const';
import { getCountryCode, getLanguageByCountry } from './language.const';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public countryCode: string
  public languageCode: string;
  public localizationCode: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(IS_SERVER_PLATFORM) private isServer: boolean,
    @Optional() @Inject(CURRENT_LOCATION)
    private readonly currentLocation: string,
  ) {
    const country: string = this.getCurrentCountry();
    const language: string = this.getCurrentLanguage(country);

    this.setCountryCode(country);
    this.setLanguageCode(language);
    this.setLocalizationCode(language, country);
  }

  public setCountryCode(countryCode: string): void {
    this.countryCode = countryCode;
  }

  public setLanguageCode(languageCode: string): void {
    this.languageCode = languageCode;
  }

  public getCurrentLanguage(country?: string): string {
    return getLanguageByCountry(country || this.getCurrentCountry(), this.document.location.pathname);
  }

  public isVisaEnUrl(): boolean {
    return this.localizationCode.toLowerCase() === 'en-fr';
  }

  private setLocalizationCode(language: string, country: string): void {
    this.localizationCode = `${language.toLowerCase()}-${country.toUpperCase()}`;
  }

  private getCurrentCountry(): string {
    const host: string = this.isServer
      ? new URL(this.currentLocation).host
      : this.document.location.host;

    return getCountryCode(host)
  }
}
