import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AemEmbeddedPlayerComponent } from "@kit/aem/common/aem-embedded-player/aem-embedded-player.component";

@NgModule({
  declarations: [AemEmbeddedPlayerComponent],
  exports: [AemEmbeddedPlayerComponent],
  imports: [CommonModule]
})
export class AemEmbeddedPlayerModule { }
