import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemRouterModule } from 'src/app/common/routing/aem-router.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { IllustrationModule } from '@kit/illustration/illustration.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemBubbleAndShapedImageComponent } from './bubble-and-shaped-image.component';

@NgModule({
  declarations: [AemBubbleAndShapedImageComponent],
  exports: [AemBubbleAndShapedImageComponent],
  imports: [
    CommonModule,
    IllustrationModule,
    AemButtonModule,
    AemRouterModule,
    SafeHtmlModule,
  ]
})
export class AemBubbleAndShapedImageModule { }
