import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { BreadcrumbsModule } from '@kit/breadcrumbs/breadcrumbs.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemHeroOnlyTextComponent } from './aem-hero-only-text.component';

@NgModule({
  declarations: [AemHeroOnlyTextComponent],
  exports: [AemHeroOnlyTextComponent],
  imports: [
    CommonModule,
    BreadcrumbsModule,
    AemButtonModule,
    AemRouterModule,
    SafeHtmlModule,
  ]
})
export class AemHeroOnlyTextModule { }
