import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Host, HostBinding, Inject, Input, Optional } from '@angular/core';
import { AccordionDirective } from './accordion-container.directive';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
  @Input() size: 'normal' | 'child' = 'normal';
  @Input() theme: 'normal' | 'faq' | 'admin' | 'insurance' | 'white' | 'trip' | 'grey' = 'normal';

  @HostBinding('class.accordion_disabled')
  @Input() disabled: boolean;

  @Input() set isOpen(value: boolean) {
    this.toggle(value);
  }

  public isExtended: boolean;

  constructor(
    @Host() @Optional() @Inject(AccordionDirective) private parent: AccordionDirective,
    private cdr: ChangeDetectorRef,
  ) { }

  public close(): void {
    if (this.isExtended === true) {
      this.isExtended = false;
      this.cdr.markForCheck();
    }
  }

  public toggle(isOpen: boolean): void {
    this.parent?.closeAll(isOpen);
    this.isExtended = isOpen;
    this.cdr.markForCheck();
  }

  public getToggleTheme(): 'black' | 'green' | 'purple' | 'white' | 'bordered' | 'inherit' | 'pure' {
    if (this.theme === 'trip') return 'pure'

    return this.size === 'child' ? 'black' : 'purple'
  }
}
