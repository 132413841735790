import { Inject, Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { CoTravelerResolver } from 'src/app/pages/trip/components/aem-co-traveler/co-traveler.resolver';
import { environment } from 'src/environments/environment';
import { langRoute, mainRoutes, notFoundRoute } from '../../app-routing.module';
import { RootPaths } from '../../app.const';
import { IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';
import { DynamicComponent } from '@pages/dynamic/dynamic.component';
import { ActivityResolver } from '@pages/trip/components/activity-element/activity-element.resolver';
import { FlightEditResolver } from '@pages/trip/components/flight/flight-edit/flight-edit.resolver';
import { HotelResolver } from '@pages/trip/components/hotel/hotel.resolver';
import { TripResolver } from '@pages/trip/trip.resolver';
import { LoginGuard } from '../auth/login.guard';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { RouterConfigurationPage } from '../model/routerConfigurationPage';
import { RouterConfigurationRedirect } from '../model/routerConfigurationRedirect';
import { RouterConfigurations } from '../model/routerConfigurations';
import { AuthorizedContentPlaceholderComponent } from './authorized-content-placeholder/authorized-content-placeholder.component';
import { CanRestrictedRouteGuard } from './can-restricted-route.guard';
import { ContentPreviewGuard } from './content-preview.guard';
import { RedirectGuard } from './redirect.guard';
import { RouterAlternatesResolver } from './router-alternates.resolver';
import { RouterDataResolver } from './router.data.resolver';
import { RouterResolver } from './routing-config.interface.';
import { ROOT_PATH, VISA_ROOT_PATH } from './routing.const';
import { RouterTradedoublerResolver } from "@common/routing/router-tradedoubler.resolver";
import { LanguageService } from '@common/language/language.service';

const FIRST_SLASH_REGEX = /^\//;

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  // TODO environment or AEM var
  hasMultiLanguage = false;

  constructor(
    private readonly router: Router,
    private readonly bootstrapService: BootstrapService,
    private readonly languageService: LanguageService,
    @Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean,
  ) {}

  public setRouterConfig(routerAemConfig: RouterConfigurations) {
    const routes = this.convertToAngularRouters(routerAemConfig);
    let mainRoute = this.mergeRouting(routes);

    if (this.hasMultiLanguage) {
      mainRoute = [{
        ...langRoute,
        children: mainRoute,
      }];
    }

    this.router.resetConfig(mainRoute);
  }

  private mergeRouting(router: Route[]): Route[] {
     // TODO removed after migrate all router to AEM
    const blackList: string[] = Object.values(RootPaths);
    const updatedNotFoundRoute = this.updateNotFoundPageFroVisa(notFoundRoute);
    const routerMap = ([...mainRoutes, ...router,  ...updatedNotFoundRoute]).reduce((acc, route) => {

      if (acc.has(route.path)) {
        if (blackList.includes(route.path)) {
          return acc;
        }

        console.warn(`Route "/${route.path}" overwritten`);
      }

      acc.set(route.path, route);
      return acc;
    }, new Map());

    return [...routerMap.values()];
  }

  private convertToAngularRouters(route: RouterConfigurations): Route[] {
    let routerConfig = this.sortRoutes(Object.values(route.data));

    RouterDataResolver.setRouterDataPath(routerConfig);

    const router: Route[] = routerConfig.map((rout) => {
      const path = rout.path.replace(FIRST_SLASH_REGEX, '');

      if (this.isRedirectRouteConfig(rout)) {
        return {
          path,
          pathMatch: 'full',
          component: AuthorizedContentPlaceholderComponent,
          canActivate: [RedirectGuard],
          data: {
            redirectStatus: (rout as RouterConfigurationRedirect).redirect,
            redirectLocation: (rout as RouterConfigurationRedirect).location,
          }
        };
      }

      if (
        this.isServer &&
        (rout as RouterConfigurationPage).entitlements &&
        !(rout as RouterConfigurationPage).entitlements?.anonymous
      ) {
        return {
          path,
          component: AuthorizedContentPlaceholderComponent,
          pathMatch: 'full',
          data: { uuid: (rout as RouterConfigurationPage).uuid },
        };
      }

      return {
        path,
        component: DynamicComponent,
        pathMatch: 'full',
        resolve: this.getRouterResolver(rout.path),
        canActivate: this.canActivateRoute(rout),
        data: { uuid: (rout as RouterConfigurationPage).uuid },
      };
    });

    if (environment.previewFeature) {
      router.push(...this.getPreviewRoutes(route));
    }

    return router;
  }

  private canActivateRoute(route: RouterConfigurationRedirect | RouterConfigurationPage): any[] | null {
    const guard: any[] = [];

    if (route.path === ROOT_PATH || route.path === VISA_ROOT_PATH ) {
      guard.push(LoginGuard);
    }

    if ((route as RouterConfigurationPage).entitlements) {
      guard.push(CanRestrictedRouteGuard);
    }

    if (environment.previewFeature) {
      guard.push(ContentPreviewGuard);
    }

    return guard.length ? guard : null;
  }

  private getRouterResolver(path: string): RouterResolver {
    const resolver: RouterResolver = {
      routeData: RouterDataResolver,
      alternates: RouterAlternatesResolver,
      tradedoubler: RouterTradedoublerResolver,
    };

    this.addTripResolver(path, resolver);
    this.addHotelResolver(path, resolver);
    this.addActivityResolver(path, resolver);
    this.addEditFlightResolver(path, resolver);
    this.addCoTravelerResolver(path, resolver);

    return resolver
  }

  private addTripResolver(path: string, resolver: RouterResolver): RouterResolver {
    const listPath = [
      this.bootstrapService.link.tripWallet,
      this.bootstrapService.link.tripEdit,
      this.bootstrapService.link.activityAdd,
      this.bootstrapService.link.activityEdit,
      this.bootstrapService.link.accommodationAdd,
      this.bootstrapService.link.accommodationEdit,
      this.bootstrapService.link.flightAdd,
      this.bootstrapService.link.flightAddManually,
      this.bootstrapService.link.flightEdit,
      this.bootstrapService.link.element,
    ];
    if (listPath.includes(path)) {
      resolver.currentTrip = TripResolver;
    }

    return resolver;
  }

  private addCoTravelerResolver(path: string, resolver: RouterResolver): RouterResolver {
    const listPath = [this.bootstrapService.link.flightSelectCotravelers];
    if (listPath.includes(path)) {
      resolver.coTraveler = CoTravelerResolver;
    }

    return resolver;
  }

  private addActivityResolver(path: string, resolver: RouterResolver): RouterResolver {
    const listPath = [this.bootstrapService.link.activityEdit];
    if (listPath.includes(path)) {
      resolver.activity = ActivityResolver;
    }

    return resolver;
  }

  private addHotelResolver(path: string, resolver: RouterResolver): RouterResolver {
    const listPath = [this.bootstrapService.link.accommodationEdit];
    if (listPath.includes(path)) {
      resolver.hotel = HotelResolver;
    }

    return resolver;
  }

  private addEditFlightResolver(path: string, resolver: RouterResolver): RouterResolver {
    const listPath = [this.bootstrapService.link.flightEdit];
    if (listPath.includes(path)) {
      resolver.flight = FlightEditResolver;
    }

    return resolver;
  }


  private isRedirectRouteConfig(route: RouterConfigurationPage | RouterConfigurationRedirect): boolean {
    return route._type === 'RouterConfigurationRedirect' ||
      (
        (route as RouterConfigurationPage).entitlements &&
        (route as RouterConfigurationPage).location &&
        !(
          (route as RouterConfigurationPage).entitlements?.anonymous ||
          (route as RouterConfigurationPage).entitlements?.freemium ||
          (route as RouterConfigurationPage).entitlements?.premium
        )
      );
  }

  private sortRoutes(routes: Array<RouterConfigurationRedirect | RouterConfigurationPage>): Array<RouterConfigurationRedirect | RouterConfigurationPage> {
    return routes
      .map((route) => ({
        route,
        parametersCount: route.path.split(':').length - 1,
      }))
      .sort((a, b) => a.parametersCount - b.parametersCount)
      .map(({ route }) => route);
  }

  private getPreviewRoutes(route: RouterConfigurations): Route[] {
    let routerConfig = Object.values(route.data);
    const routes: Route[] = [];

    routerConfig.forEach((config) => {
      if (config._type === 'RouterConfigurationPage' && (config as RouterConfigurationPage).jcrPath) {
        routes.push({
          path: (config as RouterConfigurationPage).jcrPath.replace(FIRST_SLASH_REGEX, ''),
          redirectTo: config.path.replace(FIRST_SLASH_REGEX, ''),
          pathMatch: 'full'
        });
      }
    });

    return routes;
  }

  private updateNotFoundPageFroVisa(notFoundRoute: any[]): any[] {
    if (this.languageService.isVisaEnUrl()) {
      const updatedRouts = notFoundRoute.map((route => {
        if (route.path === '**') return {...route, redirectTo: RootPaths.NotFoundVisa};
        if (route.path === RootPaths.NotFound) return {...route, path: RootPaths.NotFoundVisa};
        if (route.path === RootPaths.Forbidden) return {...route, path: RootPaths.ForbiddenVisa};

        return route;
      }));

      updatedRouts.unshift({ path: RootPaths.NotFound, redirectTo: RootPaths.NotFoundVisa, pathMatch: 'full' });
      updatedRouts.unshift({ path: RootPaths.Forbidden, redirectTo: RootPaths.ForbiddenVisa, pathMatch: 'full' });

      return updatedRouts;
    }

    return notFoundRoute;
  }
}
