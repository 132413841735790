import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { HomeHero } from '@common/model/homeHero';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';

@Component({
  selector: 'app-anonymous-home-hero',
  templateUrl: './anonymous-home-hero.component.html',
  styleUrls: ['./anonymous-home-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemAnonymousHomeHeroComponent  {
  constructor(
    @Inject(AEM_DATA) public data: HomeHero,
  ) { }
}
