import { Injectable } from '@angular/core';
import { filter, map, Observable, of, switchMap } from "rxjs";
import { AccountNotification, ServicePartner } from "@common/profile/profile.interfaces";
import { ConsentType } from "@pages/account/components/notification/notification-options.consts";
import { UserService } from "@common/user/user.service";
import { mapTripSourceToDto } from "@pages/trip/components/create-trip/create-trip.mapper";
import { TripService } from "@pages/trip/trip.service";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationDialogComponent } from "@kit/dialog/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogData } from "@kit/dialog/confirmation-dialog/confirmation-dialog.interface";
import { DialogService } from "@kit/dialog/dialog.service";
import { AnalyticsService } from "@common/analytics/analytics.service";
import { NotificationService } from "@pages/account/components/notification/notification.service";
import { Market } from "@common/language/language.const";
import { CreateTripSource } from "@pages/trip/components/create-trip/create-trip.interface";

@Injectable({
  providedIn: 'root'
})
export class EditTripHelperService {
  constructor(
    private readonly userService: UserService,
    private readonly tripService: TripService,
    private readonly translateService: TranslateService,
    private readonly dialogService: DialogService,
    private readonly notificationService: NotificationService,
    private readonly analyticsService: AnalyticsService,
  ) { }

  public isMarketingConsentDisabled$(): Observable<boolean> {
    return this.userService.reloadUserNotificationInfo$()
      .pipe(
        map((settings: AccountNotification[]) => {
          const partner = settings.filter(setting => setting.partnerName === ServicePartner.CRISIS24);
          const emailConsent = partner.find(partner => partner.consentType === ConsentType.EMAIL).consentValue;
          const smsConsent = partner.find(partner => partner.consentType === ConsentType.SMS).consentValue;

          return !(emailConsent || smsConsent);
        }),
      );
  }

  public createTrip(source: CreateTripSource): Observable<{tripId: number}> {
    return this.tripService.createTrip(mapTripSourceToDto(source, this.translateService, this.userService.userData.residence));
  }

  public editTrip(source: any, tripId: number): Observable<number> {
    return this.tripService.updateTrip(tripId, mapTripSourceToDto(source, this.translateService, this.userService.userData.residence));
  }

  public deleteTrip(tripId: number): Observable<void> {
    return this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.DIALOG_TITLE'),
      info: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.CONTENT_MESSAGE'),
      cancelTitle: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.CANCEL_TITLE'),
      confirmTitle: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.CONFIRMATION_TITLE'),
      reverseButtonPosition: true
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.tripService.deleteTrip(tripId)),
    );
  }

  public describeOnNotification$(isNotificationDisables: boolean, describe: boolean): Observable<AccountNotification[]> {
    if (!isNotificationDisables || !describe) return of([]);

    return this.notificationService.switchOnTripNotifications$();
  }

  public isNotUSMarket(): boolean {
    return this.userService.userData?.residence !== Market.US;
  }

  public sendCreateTripAnalyticsAction(source: CreateTripSource): void {
    this.analyticsService.triggerAction({
      category: 'travel_wallet',
      action: 'tripAdded',
      label: this.getAnalyticsLabel(source),
      value: 1,
    });
  }

  private getAnalyticsLabel(source: CreateTripSource): string {
    return `${this.getShortDate(source.from)}_${this.getShortDate(source.to)}_${source.destinationPoints[0]?.description}`
  }

  private getShortDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }
}
