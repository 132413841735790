<div class="shell">
  <div class="stepper-container">
    <div class="stepper">
      <ng-container *ngFor="let item of items; let index = index">
        <div class="stepper_item"
             *tuiLet="index + 1 as stepNumber"
             (click)="selectStep(stepNumber)">
          <div class="stepper_item_index"
               [class.stepper_item_index_active]="stepNumber <= lastAvailableStep">
            <ng-container *ngIf="stepNumber === selectedStep || stepNumber >= lastAvailableStep; else doneIcon">{{ stepNumber }}</ng-container>
            <ng-template #doneIcon><app-icon icon="done"></app-icon></ng-template>
          </div>

          <div class="stepper_item_text text-small"
               [class.stepper_item_text_active]="selectedStep === stepNumber">
            {{ item.title }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="step-content">
    <ng-container *ngFor="let item of items; let index = index">
      <ng-container *ngIf="selectedStep === index + 1"
                    [ngTemplateOutlet]="item.template">
      </ng-container>
    </ng-container>
  </div>
</div>