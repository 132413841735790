import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AdditionalServices } from '@common/model/additionalServices';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '../aem-base-block/aem-base-block';

@Component({
  selector: 'app-aem-additional-services',
  templateUrl: './aem-additional-services.component.html',
  styleUrls: ['./aem-additional-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemAdditionalServicesComponent extends AemBaseBlockComponent {

  constructor(
    @Inject(AEM_DATA) public override data: AdditionalServices,
  ) {
    super(data);
  }
}
