import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../language/language.service';
import { getAEMContentPath } from '../routing/routing.const';
import { FaqThematic, FaqThematics } from './faq.interface';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  public thematic$: Observable<FaqThematic[]> = this.loadFaqThematicData();

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
  ) {
  }

  private loadFaqThematicData(): Observable<FaqThematic[]> {
    return this.http.get<FaqThematics>(
      `${ getAEMContentPath(this.languageService.countryCode.toLocaleLowerCase(),
        this.languageService.languageCode.toLocaleLowerCase()) }/_faq.json`,
    ).pipe(
      map(data => data.thematics),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}
