<app-trip-hero-block (goBack)="goBack()" *tuiLet="countries$ | async as countries">
  <div class="add-flight_form-wrapper" [formGroup]="flightForm">
    <div class="add-flight_form">
      <div class="headline-5 add-flight_form-title">
        {{ 'forms.FLIGHT.FORM.DEPARTURE' | translate }}
        <app-icon icon="departure" theme="inherit" size="x-large"></app-icon>
      </div>
      <div class="field_pair">
        <app-label [label]="'forms.FLIGHT.LABELS.DEPARTURE_COUNTRY' | translate" [required]="true">
          <app-search-select 
            id="edit-flight-departure-country"
            [placeholder]="'forms.FLIGHT.PLACEHOLDERS.DEPARTURE_COUNTRY' | translate" 
            formControlName="departureCountry" 
            viewKey="name"
            selectKey="countryId"
            [clearButton]="true"
            [options]="countries">
          </app-search-select>
          <app-field-error controlName="departureCountry"></app-field-error>
        </app-label>
        <app-label [label]="'forms.FLIGHT.LABELS.DEPARTURE_AIRPORT' | translate" [required]="true">
          <app-search-select
            id="edit-flight-departyre-airport"
            [placeholder]="'forms.FLIGHT.PLACEHOLDERS.DEPARTURE_AIRPORT' | translate" 
            formControlName="departureAirport"
            viewKey="name"
            selectKey="code"
            [clearButton]="true"
            [options]="departureAirport$ | async">
          </app-search-select>
          <app-field-error controlName="departureAirport"></app-field-error>
        </app-label>
      </div>

      <div class="field_pair">
        <app-label [label]="'forms.FLIGHT.LABELS.DATE' | translate" [required]="true">
          <app-date-picker  id="edit-flight-departure-date" formControlName="departureDate">
          </app-date-picker>
          <app-field-error controlName="departureDate"></app-field-error>
        </app-label>
        <app-label [label]="'forms.FLIGHT.LABELS.TIME' | translate" [required]="true">
          <app-time-picker  id="edit-flight-date-time" formControlName="departureTime">
          </app-time-picker>
          <app-field-error controlName="departureTime"></app-field-error>
        </app-label>
      </div>
    </div>
    <div class="add-flight_form">
      <div class="headline-5 add-flight_form-title">
        {{ 'forms.FLIGHT.FORM.ARRIVAL' | translate }}
        <app-icon icon="arrival" theme="inherit" size="x-large"></app-icon>
      </div>
      <div class="field_pair">
        <app-label [label]="'forms.FLIGHT.LABELS.ARRIVAL_COUNTRY' | translate" [required]="true">
          <app-search-select 
            id="edit-flight-arrival-country"
            [placeholder]="'forms.FLIGHT.PLACEHOLDERS.ARRIVAL_COUNTRY' | translate" 
            formControlName="arrivalCountry"
            viewKey="name"
            selectKey="countryId"
            [clearButton]="true"
            [options]="countries">
          </app-search-select>
          <app-field-error controlName="arrivalCountry"></app-field-error>
        </app-label>
        <app-label [label]="'forms.FLIGHT.LABELS.ARRIVAL_AIRPORT' | translate" [required]="true">
          <app-search-select 
            id="edit-flight-arrival-airport"
            [placeholder]="'forms.FLIGHT.PLACEHOLDERS.ARRIVAL_AIRPORT' | translate" 
            formControlName="arrivalAirport" 
            viewKey="name"
            selectKey="code"
            [clearButton]="true"
            [options]="arrivalAirport$ | async">
          </app-search-select>
          <app-field-error controlName="arrivalAirport"></app-field-error>
        </app-label>
      </div>

      <div class="field_pair">
        <app-label [label]="'forms.FLIGHT.LABELS.DATE' | translate" [required]="true">
          <app-date-picker id="edit-flight-arrival-date" formControlName="arrivalDate">
          </app-date-picker>
          <app-field-error controlName="arrivalDate"></app-field-error>
        </app-label>
        <app-label [label]="'forms.FLIGHT.LABELS.TIME' | translate" [required]="true">
          <app-time-picker id="edit-flight-arrival-time" formControlName="arrivalTime">
          </app-time-picker>
          <app-field-error controlName="arrivalTime"></app-field-error>
        </app-label>
      </div>
    </div>
    <div class="add-flight_form">
      <div class="headline-5 add-flight_form-title">
        {{ 'forms.FLIGHT.FORM.FLIGHT' | translate }}
        <app-icon icon="plane" theme="inherit" size="x-large"></app-icon>
      </div>
      <div class="field_pair">
        <app-label [label]="'forms.FLIGHT.LABELS.FLIGHT_NUMBER' | translate" [required]="true">
          <app-input id="edit-flight-number" [placeholder]="'forms.FLIGHT.PLACEHOLDERS.FLIGHT_NUMBER' | translate" formControlName="flightNumber">
          </app-input>
          <app-field-error controlName="flightNumber"></app-field-error>
        </app-label>
        <app-label [label]="'forms.FLIGHT.LABELS.BOOKING_NUMBER' | translate">
          <app-input id="edit-flight-booking-number" [placeholder]="'forms.FLIGHT.PLACEHOLDERS.BOOKING_NUMBER' | translate" formControlName="bookingNumber" [maxLength]="128">
          </app-input>
          <app-field-error controlName="bookingNumber"></app-field-error>
        </app-label>
      </div>
      <app-field-error [control]="flightForm"></app-field-error>

      <div class="add-flight__action">
        <button id="action-flight-edit" class="button" (click)="onSave()">
          {{ 'forms.FLIGHT.ACTIONS.EDIT_MY_FLIGHT' | translate }}
        </button>
        <button id="action-flight-delete" class="add-flight__action-button button button_lighten" (click)="onDelete()">
            <app-icon icon="thrash" theme="inherit" size="large"></app-icon>
        </button>
      </div>
    </div>
  </div>
</app-trip-hero-block>