import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemContentAndImageComponent } from './content-and-image.component'

@NgModule({
  declarations: [AemContentAndImageComponent],
  exports: [AemContentAndImageComponent],
  imports: [
    CommonModule,
    AemButtonModule,
    SafeHtmlModule,
    ImgAltDirectiveModule,
  ]
})
export class AemContentAndImageModule { }
