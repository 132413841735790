import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { BackButtonModule } from '@kit/back-button/back-button.module';
import { BreadcrumbsModule } from '@kit/breadcrumbs/breadcrumbs.module';
import { ButtonModule } from '@kit/button/button.module';
import { SliderModule } from '@kit/carousel/slider.module';
import { CheckboxModule } from '@kit/checkbox/checkbox.module';
import { ContentAndShapedImageModule } from '@kit/content-and-shaped-image/content-and-shaped-image.module';
import { DateModule } from '@kit/date/date.module';
import { DialogModule } from '@kit/dialog/dialog.module';
import { FieldErrorModule } from '@kit/field-error/field-error.module';
import { FooterModule } from '@kit/footer/footer.module';
import { IconModule } from '@kit/icon/icon.module';
import { IllustrationModule } from '@kit/illustration/illustration.module';
import { InputModule } from '@kit/input/input.module';
import { LabelModule } from '@kit/label/label.module';
import { MarkdownPipeModule } from '@kit/markdown-pipe/markdown.module';
import { RadioButtonModule } from '@kit/radio-button/radio-button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { SearchSelectModule } from '@kit/search-select/search-select.module';
import { SelectRandomImageModule } from '@kit/select-random-image/select-random-image.module';
import { SelectModule } from '@kit/select/select.module';
import { StatusButtonModule } from '@kit/status-button/status-button.module';
import { TimePickerModule } from '@kit/time-picker/time-picker.module';
import { ToolbarModule } from '@kit/toolbar/toolbar.module';
import { TooltipModule } from '@kit/tooltip/tooltip.module';
import { TripCardModule } from '@kit/trip-card/trip-card.module';
import { UpdatedFieldValueModule } from '@kit/updated-field-value/updated-field-value.module';
import { TranslateModule } from '@ngx-translate/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { AemRouterModule } from '@common/routing/aem-router.module';
import {
  AddActivityElementComponent
} from './components/activity-element/add-activity-element/add-activity-element.component';
import {
  EditActivityElementComponent
} from './components/activity-element/edit-activity-element/edit-activity-element.component';
import { AddTripElementComponent } from './components/add-trip-element/add-trip-element.component';
import { AddCoTravelerComponent } from './components/aem-co-traveler/add-co-traveler/add-co-traveler.component';
import { CoTravelerListComponent } from './components/aem-co-traveler/co-traveler-list/co-traveler-list.component';
import { CoTravelerComponent } from './components/aem-co-traveler/co-traveler.component';
import {
  CoTravelersListDialogComponent
} from './components/aem-co-traveler/co-travelers-list-dialog/co-travelers-list-dialog.component';
import {
  AemPastFlightCompensationComponent
} from './components/aem-past-flight-compensation/aem-past-flight-compensation.component';
import { AlertInfoDialogComponent } from './components/alert-info-dialog/alert-info-dialog.component';
import { EnumerationComponent } from './components/enumeration/enumeration.component';
import { FlightDelayComponent } from './components/flight-delay/flight-delay.component';
import { AddFlightManuallyComponent } from './components/flight/add-flight-manually/add-flight-manually.component';
import { AddFlightComponent } from './components/flight/add-flight/add-flight.component';
import { FlightAddComponent } from './components/flight/flight-add.component';
import { FlightEditComponent } from './components/flight/flight-edit/flight-edit.component';
import { FlightPreviewComponent } from './components/flight/flight-preview/flight-preview.component';
import { FlightSelectorComponent } from './components/flight/flight-selector/flight-selector.component';
import { AddHotelComponent } from './components/hotel/add-hotel/add-hotel.component';
import { EditHotelComponent } from './components/hotel/edit-hotel/edit-hotel.component';
import { MyTripsComponent } from './components/my-trips/my-trips.component';
import {
  NotificationsInfoDialogComponent
} from './components/notifications-info-dialog/notifications-info-dialog.component';
import { StartJourneyComponent } from './components/start-journey/start-journey.component';
import { TripCardDetailedComponent } from './components/trip-card-detailed/trip-card-detailed.component';
import { TripComponent } from './components/trip/trip.component';
import { FlightIconByStatus } from './pipe/flight-icon-by-status.pipe';
import { FlightAddedComponent } from './components/flight-added/flight-added.component';
import { AccordionModule } from "@kit/accordion/accordtion.module";
import { FlightCardDetailedComponent } from './components/flight-card-detailed/flight-card-detailed.component';
import { FlightsExistingComponent } from './components/flights-existing/flights-existing.component';
import { SearchSelectMultipleModule } from "@kit/search-select-multiple/search-select-multiple.module";
import { TripWalletModule } from './components/trip-wallet/trip-wallet.module';
import {
  MergeTripSuccessfullyComponent
} from './components/merge-trip/merge-trip-successfully/merge-trip-successfully.component';
import { MergeTripComponent } from "@pages/trip/components/merge-trip/merge-trip.component";
import {
  TripDetailsAccordionComponent
} from './components/merge-trip/trip-details-accordion/trip-details-accordion.component';
import {
  TripDetailsAccordionFlightComponent
} from './components/merge-trip/components/trip-details-accordion-flight/trip-details-accordion-flight.component';
import {
  TripDetailsAccordionActivityComponent
} from './components/merge-trip/components/trip-details-accordion-activity/trip-details-accordion-activity.component';
import {
  TripDetailsAccordionHotelComponent
} from './components/merge-trip/components/trip-details-accordion-hotel/trip-details-accordion-hotel.component';
import { FlightDelayPipeModule } from './pipe/flight-delay-time.module';
import { WoosmapSearchModule } from "@kit/woosmap-search/woosmap-search.module";
import { CreateTripModule } from "@pages/trip/components/create-trip/create-trip.module";
import { TripHeroBlockModule } from "@pages/trip/components/trip-hero-block/trip-hero-block.module";
import { EditTripFromModule } from "@pages/trip/components/edit-trip-from/edit-trip-from.module";
import { EditTripModule } from "@pages/trip/components/edit-trip/edit-trip.module";

@NgModule({
  declarations: [
    TripComponent,
    MyTripsComponent,
    TripCardDetailedComponent,
    AddFlightComponent,
    AddFlightManuallyComponent,
    FlightPreviewComponent,
    CoTravelerComponent,
    CoTravelerListComponent,
    AddCoTravelerComponent,
    AddHotelComponent,
    EditHotelComponent,
    AddTripElementComponent,
    FlightSelectorComponent,
    AddActivityElementComponent,
    EditActivityElementComponent,
    FlightDelayComponent,
    EnumerationComponent,
    StartJourneyComponent,
    FlightAddComponent,
    FlightEditComponent,
    NotificationsInfoDialogComponent,
    AlertInfoDialogComponent,
    FlightIconByStatus,
    CoTravelersListDialogComponent,
    AemPastFlightCompensationComponent,
    FlightAddedComponent,
    FlightCardDetailedComponent,
    FlightsExistingComponent,
    MergeTripSuccessfullyComponent,
    MergeTripComponent,
    TripDetailsAccordionComponent,
    TripDetailsAccordionFlightComponent,
    TripDetailsAccordionActivityComponent,
    TripDetailsAccordionHotelComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    SelectModule,
    LabelModule,
    InputModule,
    DateModule,
    CheckboxModule,
    MarkdownPipeModule,
    ToolbarModule,
    FooterModule,
    HttpClientModule,
    TuiLetModule,
    StatusButtonModule,
    TimePickerModule,
    BreadcrumbsModule,
    IconModule,
    FieldErrorModule,
    IllustrationModule,
    SliderModule,
    RadioButtonModule,
    SearchSelectModule,
    TooltipModule,
    DialogModule,
    AemRouterModule,
    ButtonModule,
    ContentAndShapedImageModule,
    SelectRandomImageModule,
    SafeHtmlModule,
    UpdatedFieldValueModule,
    BackButtonModule,
    AemButtonModule,
    TripCardModule,
    ImgAltDirectiveModule,
    AccordionModule,
    SearchSelectMultipleModule,
    TripWalletModule,
    FlightDelayPipeModule,
    WoosmapSearchModule,
    CreateTripModule,
    EditTripModule,
    TripHeroBlockModule,
    EditTripFromModule,
  ]
})
export class TripModule {}
