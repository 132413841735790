import { Hotel, HotelDto, HotelSource } from "./hotel.interface";

export function mapHotelSourceToDto(source: HotelSource): HotelDto {
  return {
    hotelName: source.hotelName?.trim(),
    hotelAddress: source.hotelAddress?.trim(),
    checkinDate: source.checkInDate?.toISOString(),
    checkinTime: source.checkInHour,
    checkoutDate: source.checkOutDate?.toISOString(),
    checkoutTime: source.checkOutHour,
    bookingReference: source.bookingReference?.trim(),
  }
}

export function mapHotelToSource(hotel: Hotel): HotelSource {
  return {
    hotelName: hotel.hotelName,
    bookingReference: hotel.bookingReference,
    hotelAddress: hotel.hotelAddress,
    checkInDate: new Date(hotel.checkinDate),
    checkInHour: hotel.checkinTime,
    checkOutDate: new Date(hotel.checkoutDate),
    checkOutHour: hotel.checkoutTime,
  }
}
