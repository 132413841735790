import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { PasswordState } from './password-state.interface';

@Component({
  selector: 'app-password-security',
  templateUrl: './password-security.component.html',
  styleUrls: ['./password-security.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordSecurityComponent implements OnInit {
  @Input() public controlName: string;
  @Input() public control: AbstractControl;

  public state$: Observable<PasswordState>;
  public stateStr$ = new BehaviorSubject<string>(null);
  public strong = PasswordState.strong;
  public passwordValue$ = new BehaviorSubject<string>('');

  private get formControl(): AbstractControl {
    return this.control || this.parentControl?.control?.get(this.controlName);
  }

  private get criteria(): Array<boolean> {
    return [
      this.lengthCriteria,
      this.lowerCaseCriteria,
      this.upperCaseCriteria,
      this.specialCharacterCriteria,
      this.numberCriteria,
    ];
  }

  public get controlValue(): string {
    return this.formControl?.value || '';
  }

  public get lengthCriteria(): boolean {
    return this.controlValue?.length > 7;
  }

  public get lowerCaseCriteria(): boolean {
    return /[a-z]/.test(this.controlValue);
  }

  public get upperCaseCriteria(): boolean {
    return /[A-Z]/.test(this.controlValue);
  }

  public get specialCharacterCriteria(): boolean {
    return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.controlValue);
  }

  public get numberCriteria(): boolean {
    return /[\d]/.test(this.controlValue);
  }

  constructor(
    @Optional()
    @SkipSelf()
    private readonly parentControl: FormGroupDirective,
    private readonly translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.state$ = this.formControl.valueChanges.pipe(
      tap((password: string) => this.passwordValue$.next(password)),
      map(() => this.criteria.filter(Boolean)),
      map(criteria => this.mapCriteriaLengthToState(criteria.length)),
      tap(state => this.stateStr$.next(this.translateService.instant(`forms.PASSWORD_SECURITY.STATUS.${state.toUpperCase()}`)))
    );
  }

  private mapCriteriaLengthToState(length: number): PasswordState {
    switch (true) {
      case length === 5:
        return PasswordState.strong;
      case length === 4:
        return PasswordState.good;
      case length === 3:
        return PasswordState.fine;
      case length < 3:
        return PasswordState.weak;
      default:
        return PasswordState.weak;
    }
  }
}
