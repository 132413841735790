<div class="shell trip-hero_container">
  <app-back-button (goBack)="onBack()"></app-back-button>

  <div class="trip-hero_content">
    <div class="trip-hero_description">
      <h1 class="trip-hero_description-title">{{ aemData.title }}</h1>
      <app-safe-html *ngIf="aemData.text"
                     [content]="aemData.text"
                     class="t-normal m-t-normal">
      </app-safe-html>
      <div *ngIf="aemData.bottomText || aemData.ctas?.length" class="trip-hero_action">
        <app-aem-button *ngFor="let cta of aemData.ctas"
                        [cta]="cta"
                        class="trip-hero_action_button"
                        type="big-width">
        </app-aem-button>
        <app-safe-html *ngIf="aemData.bottomText"
                       [content]="aemData.bottomText"
                       class="trip-hero_bottom-text">
        </app-safe-html>
      </div>
    </div>

    <div class="trip-hero_content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
