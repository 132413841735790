import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { LanguageService } from 'src/app/common/language/language.service';
import { WindowRef } from 'src/app/common/window-service/window.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teleconsultation',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeleconsultationComponent {
  constructor(
    private readonly windowRef: WindowRef,
    private readonly langService: LanguageService,
  ) {}

  @HostListener('click')
  login(): void {
    this.windowRef.nativeWindow.open(
      'tel:' + (environment.teleconsultation as any)[this.langService.countryCode.toLowerCase()],
      '_self'
    );
  }
}
