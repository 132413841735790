import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateModule } from 'src/app/kit/date/date.module';
import { TranslateMembershipPipeModule } from 'src/app/kit/translate/translate-membership-pipe/translate-membership-pipe.module';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { BackButtonModule } from '@kit/back-button/back-button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemAccountHeroComponent } from './aem-account-hero.component';

@NgModule({
  declarations: [AemAccountHeroComponent],
  exports: [AemAccountHeroComponent],
    imports: [
        CommonModule,
        AemRouterModule,
        BackButtonModule,
        SafeHtmlModule,
        DateModule,
        TranslateModule,
        TranslateMembershipPipeModule,
    ]
})
export class AemAccountHeroBlockModule { }
