import { Market } from "src/app/common/language/language.const";

export enum PhoneCode {
  France = '+33',
  Germany = '+49',
  Spain = '+34',
  Italy = '+39',
  Netherlands = '+31',
  Austria = '+43',
  Poland = '+48',
}

export function codeToPattern(code: string): RegExp {
  switch (code) {
    case Market.FR:
      return /^((00|\+)33)[1-9]\d{8}$/;
    case Market.DE:
      return /^((00|\+)49)[1-9]\d{4,12}$/;
    case Market.ES:
      return /^((00|\+)34)[1-9]\d{8}$/;
    case Market.IT:
      return /^((00|\+)39)[1-9]\d{9}$/;
    case Market.NL:
      return /^((00|\+)31)[1-9]\d{8}$/;
    case Market.AT:
      return /^((00|\+)43)[1-9]\d{4,12}$/;
    case Market.PL:
      return /^((00|\+)48)[1-9]\d{8}$/;
    default:
      return /^((00|\+)33)[1-9]\d{8}$/;
  }
}
