import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CounterComponent),
    multi: true
  }],
})
export class CounterComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public min: number;
  @Input() public max: number;
  @Input() public step = 1;

  public innerControl = new UntypedFormControl(null);
  public disabled = false;
  private destroy$ = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.innerControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => this.onChange(value));

    if (this.min >= this.max) {
      throw Error('Min value must be less than Max value');
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onChange = (value: string) => { };
  public onTouched = () => { };

  private getAccurateValue(value: number): number {
    if (this.min && value <= this.min) return this.min;
    if (this.max && value >= this.max) return this.max;

    return value;
  }

  public writeValue(value: number): void {
    this.innerControl.setValue(this.getAccurateValue(value) || this.min || 0, { emitEvent: false });
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public increase(): void {
    this.innerControl.setValue(this.innerControl.value + this.step);
  }

  public decrease(): void {
    this.innerControl.setValue(this.innerControl.value - this.step);
  }
}
