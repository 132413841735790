import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { catchError, forkJoin, map, Observable, switchMap, takeUntil } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { getAllRouterParams, setRouterData } from 'src/app/kit/utils/router.utils';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { FormTripAdd } from '@common/model/formTripAdd';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { EditTripHelperService } from "@pages/trip/services/edit-trip-helper/edit-trip-helper.service";
import { CreateTripSource } from "@pages/trip/components/create-trip/create-trip.interface";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-trip',
  templateUrl: './create-trip.component.html',
  styleUrls: ['./create-trip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService, ServerErrorsValidationService],
})
export class CreateTripComponent extends AemFragmentComponent implements OnInit {
  public showNotificationCheckbox$: Observable<boolean>;
  public showNotificationContainer = this.editTripHelperService.isNotUSMarket();

  constructor(
    public readonly validationService: ServerErrorsValidationService,
    private readonly bootstrapService: BootstrapService,
    private readonly destroy$: TuiDestroyService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly analyticsService: AnalyticsService,
    private readonly editTripHelperService: EditTripHelperService,
    @Inject(AEM_DATA) data: FormTripAdd
  ) {
    super(data);
  }

  ngOnInit(): void {
    this.initNotificationSettings();
  }

  public goBack(): void {
    this.router.navigateByUrl(
      setRouterData(this.bootstrapService.link.trips, getAllRouterParams(this.route.snapshot.root)),
    );
  }

  public createTrip(source: CreateTripSource): void {
    forkJoin([
      this.editTripHelperService.createTrip(source),
      this.showNotificationCheckbox$,
    ]).pipe(
      switchMap(([{ tripId }, showNotificationCheckbox]) =>
        this.editTripHelperService.describeOnNotification$(showNotificationCheckbox, source?.subscribedOnNotifications)
          .pipe(map(() => tripId))
      ),
      catchError(err => this.handleServerError(err)),
      takeUntil(this.destroy$),
    ).subscribe((tripId: number) => {
      this.editTripHelperService.sendCreateTripAnalyticsAction(source);
      this.navigateToAddFlightPage(tripId);
    });
  }

  private initNotificationSettings(): void {
    this.showNotificationCheckbox$ = this.editTripHelperService.isMarketingConsentDisabled$()
      .pipe(takeUntil(this.destroy$));
  }

  private handleServerError(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    return this.validationService.handleServerError(err?.error);
  }

  private navigateToAddFlightPage(tripId: number): void {
    this.router.navigate(
      [setRouterData(this.bootstrapService.link.flightAdd, { tripId })],
      { queryParams: { newTrip: true } }
    );
  }
}
