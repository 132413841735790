import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, makeStateKey, TransferState } from '@angular/core';
import { Observable, of, shareReplay, tap } from 'rxjs';
import { LanguageService } from '../language/language.service';
import { BlogIndex } from '../model/blogIndex';
import { getAEMContentPath } from '../routing/routing.const';
import { IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  data$: Observable<BlogIndex> = this.loadBlogData();

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private transferState: TransferState,
    @Inject(IS_SERVER_PLATFORM) private isServer: boolean,
  ) { }

  private loadBlogData(): Observable<BlogIndex> {
    const BLOGS_KEY = makeStateKey<BlogIndex>('SERVER_BLOGS_DATA');

    if (this.transferState.hasKey(BLOGS_KEY)) {
      const blogs = this.transferState.get<BlogIndex>(BLOGS_KEY, null);
      this.transferState.remove(BLOGS_KEY);

      return of(blogs);
    }

    return this.http.get<BlogIndex>(`${getAEMContentPath(this.languageService.countryCode.toLocaleLowerCase(), this.languageService.languageCode.toLocaleLowerCase())}/_blog.json`)
      .pipe(
        tap(blogs => {
          if(this.isServer) {
            this.transferState.set(BLOGS_KEY, blogs)}
          }),
        shareReplay(1),
      );
  }
}
