import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { CTAAction } from 'src/app/common/model/cTAAction';
import { CTALink } from 'src/app/common/model/cTALink';
import { Router } from '@angular/router';
import { isAbsoluteUrl } from '@kit/utils/router.utils';
import {TagName} from "@kit/carousel/slider-item/slider-item.const";

@Component({
  selector: 'app-slider-item',
  templateUrl: './slider-item.component.html',
  styleUrls: ['./slider-item.component.scss']
})
export class SliderItemComponent implements OnInit {
  @Input() navigateTo: CTALink | CTAAction;

  @HostBinding('style.cursor') cursor = 'auto';

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.stopPropagation();

    if (this.isCTALink()) {
      this.handleCTALinkClick(event);

      return;
    }

    if (this.isCTAAction()) {
      this.handleCTAActionClick(event);
    }
  }

  get ctaLink(): CTALink {
    if (this.navigateTo?._type !== 'CTAAction') {
      return this.navigateTo as CTALink;
    }

    return null;
  }

  get ctaAction(): CTAAction {
    return this.navigateTo?._type === 'CTAAction' ? this.navigateTo as CTAAction : null;
  }

  constructor(public router: Router) { }

  public ngOnInit(): void {
    this.cursor = !!this.navigateTo ? 'pointer' : 'auto';
  }

  private isCTALink(): boolean {
    return this.navigateTo?._type !== 'CTAAction';
  }

  private isCTAAction(): boolean {
    return this.navigateTo?._type === 'CTAAction';
  }

  private handleCTALinkClick(event: Event): void {
    const targetTagName: string = (event.target as HTMLElement).tagName.toLowerCase();
    if (targetTagName !== TagName.button && targetTagName !== TagName.svg && targetTagName !== TagName.use) {
      if (isAbsoluteUrl(this.ctaLink.link)) {
        window.open(this.ctaLink.link);

        return;
      }

      this.router.navigate([this.ctaLink.link]);
    }
  }

  private handleCTAActionClick(event: any): void {
    if (event.target instanceof HTMLAnchorElement || event.target instanceof HTMLButtonElement || event.target instanceof SVGElement) {
      return;
    }

    const selector = `app-${this.ctaAction.action}`;
    const aemButtonWithActionLink = document.querySelector(selector)?.firstElementChild;

    if (aemButtonWithActionLink && event.target !== aemButtonWithActionLink) {
      const clickEvent = new MouseEvent('click', { bubbles: true });
      aemButtonWithActionLink.dispatchEvent(clickEvent);
    }
  }
}
