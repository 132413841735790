import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-thematic',
  template: '<ng-content></ng-content>',
  styleUrls: ['./thematic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThematicComponent {
  @HostBinding('class.thematic-selected')
  @Input() selected: boolean;
  @Input() size: 'normal' | 'small' = 'normal';

  @HostBinding('class') get class() {
    return `thematic__${this.size}`;
  }
}
