import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemQuoteComponent } from './quote.component';

@NgModule({
  declarations: [AemQuoteComponent],
  exports: [AemQuoteComponent],
  imports: [
    CommonModule,
  ]
})
export class AemQuoteModule { }
