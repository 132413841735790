import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  Renderer2,
  StaticProvider,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { DISABLE_GLOBAL_SCROLL_CLASS } from '../../../app.const';
import { DIALOG_DATA, DIALOG_REF } from '../dialog.const';
import { DialogRef } from '../dialog.interfaces';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-dialog-portal',
  templateUrl: './dialog-portal.component.html',
  styleUrls: ['./dialog-portal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogPortalComponent {
  @ViewChild('portal', { read: ViewContainerRef, static: true })
  public container: ViewContainerRef;

  @ViewChild('modalOverlay', { read: ElementRef, static: true })
  modalOverlay: ElementRef<HTMLElement>

  public dialogsCount = 0;
  private activeDialog: DialogRef<any>;

  constructor(
    private readonly dialogService: DialogService,
    private readonly cdr: ChangeDetectorRef,
    private readonly injector: Injector,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.dialogService.registerCreateHandler(this.createDialog);
  }

  @HostListener('click', ['$event'])
  closeDialog(event: Event) {
    if (event.target === this.modalOverlay.nativeElement && this.activeDialog) {
      this.activeDialog.close();
      this.activeDialog = null;
    }
  }

  public createDialog = <C>(component: Type<C>, data?: any, parentInjector?: Injector): DialogRef<C> => {
    const afterClosed$ = new Subject();
    const dialogRef: DialogRef<C> = { afterClosed$ } as any;
    const injector = this.createInjector(data, dialogRef, parentInjector);
    const componentRef = this.container.createComponent(component, { injector });

    this.renderer.addClass(this.document.body, DISABLE_GLOBAL_SCROLL_CLASS);

    const close = (res?: any) => {
      componentRef.destroy();

      afterClosed$.next(res);
      afterClosed$.complete();

      this.activeDialog = null;
      this.renderer.removeClass(this.document.body, DISABLE_GLOBAL_SCROLL_CLASS);
      this.dialogsCount--;
      this.cdr.markForCheck();
    }

    dialogRef.componentRef = componentRef;
    dialogRef.close = close;

    this.dialogsCount++;
    this.cdr.markForCheck();

    this.activeDialog = dialogRef;
    return dialogRef;
  }

  private createInjector<C>(data: any, dialogRef: DialogRef<C>, parentInjector?: Injector): Injector {
    const providers: StaticProvider[] = [
      { provide: DIALOG_DATA, useValue: data },
      { provide: DIALOG_REF, useValue: dialogRef },
    ];

    return Injector.create({
      providers,
      parent: parentInjector || this.injector,
    });
  }
}
