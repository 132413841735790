import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { TripCardComponent } from './trip-card.component';
import { AemRouterModule } from 'src/app/common/routing/aem-router.module';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateModule } from '../date/date.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';

@NgModule({
  declarations: [
    TripCardComponent
  ],
  exports: [
    TripCardComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    ButtonModule,
    TooltipModule,
    TranslateModule,
    DateModule,
    AemRouterModule,
    ImgAltDirectiveModule,
  ]
})
export class TripCardModule { }
