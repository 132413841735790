import { environment } from 'src/environments/environment';

export const ROOT_PATH = '/';
export const VISA_ROOT_PATH = '/en-fr';

export function getAEMContentPath(domainLanguage: string, selectedLanguage: string): string {
  return `${environment.api.aemPath}/api/allyz-com/${domainLanguage}/${selectedLanguage}`;
}

export function getAEMStaticPath(domainLanguage: string): string {
  return `${environment.api.aemPath}/static/allyz-com/${domainLanguage}`;
}

export function getAEMAlternatesPath(): string {
  return `${environment.api.aemPath}/api/alternates`;
}
