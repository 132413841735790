<header class="header">
  <div class="header__main">
    <div class="header__shell shell">
      <div class="hero">
        <ng-container *ngIf="data.componentAlignment === ContentAlignment.Right; else inverted">
          <div class="hero__group">
            <h1 *ngIf="data.title" class="hero__title">{{ data.title }}</h1>
            <app-safe-html *ngIf="data.text" class="hero__text" [content]="data.text"></app-safe-html>

            <div class="hero__action">
              <div class="hero__action-list">
                <app-aem-button 
                  class="hero__action_button"
                  *ngFor="let cta of data.ctas"
                  [cta]="cta"
                  size="l"
                  type="big-width"
                ></app-aem-button>
              </div>
            </div>
          </div>
  
          <div class="hero-component" *ngIf="extra?.component">
            <ng-container *ngComponentOutlet="extra.component; injector: extra.injector"></ng-container>
          </div>
        </ng-container>

        <ng-template #inverted>
          <div class="hero-component" *ngIf="extra?.component">
            <ng-container *ngComponentOutlet="extra.component; injector: extra.injector"></ng-container>
          </div>

          <div class="hero__group">
            <h1 class="hero__title">{{ data.title }}</h1>
            <app-safe-html class="hero__text" [content]="data.text"></app-safe-html>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</header>
