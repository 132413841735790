import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { TuiDestroyService } from '@taiga-ui/cdk';
import { takeUntil } from 'rxjs';
import { BookingPath, QuotationResponseContractContractList } from '@common/booking-path/booking-path.interfaces';
import { mapPlanToDTO } from '../../booking-path.mapper';
import { BookingService } from '../booking.service';
import { BookingPathStep } from '../booking-path-step.enum';
import { DigitalDataService } from '@common/analytics/digital-data.service';

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class ChoosePlanComponent implements OnInit {
  @Output() public done = new EventEmitter<void>();

  public get bookingPathData(): BookingPath {
    return this.bookingService.bookingPathData;
  };

  public get plans(): QuotationResponseContractContractList[] {
    return this.bookingService.plans;
  };


  public initialSlide: number = 0;

  constructor(
    private readonly bookingService: BookingService,
    private readonly destroy$: TuiDestroyService,
    private readonly digitalDataService: DigitalDataService,
  ) {}

  public ngOnInit(): void {
    if (this.plans?.length) {
      this.initialSlide = this.plans.findIndex((plan) =>
        plan.productOffering.highlightedOffer
      );
    }

    this.digitalDataService.updatePageName(BookingPathStep.YOUR_PRICE);
  }

  public selectPlan(plan: QuotationResponseContractContractList): void {
    const bookingData: BookingPath = {
      tripDetails: this.bookingPathData.tripDetails,
      selectedProduct: mapPlanToDTO(plan),
    };

    this.bookingService.completeStep(bookingData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.done.emit(null));
  }

  public goBack(): void {
    this.bookingService.selectStep(1);
  }
}
