import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';
import { Market } from 'src/app/common/language/language.const';
import { UserService } from 'src/app/common/user/user.service';
import { PhoneCode } from './phone.utils';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PhoneComponent),
    multi: true
  }],
})
export class PhoneComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() public placeholder = '';
  @Input() public code: PhoneCode;
  @Input() public autocomplete = 'tel-national';
  @Input() public inputName = 'phone';
  @Input() public disclaimer = '';
  @Input() public formatDisclaimerNeeded = true;

  public innerControl = new UntypedFormControl(null);
  public focused = false;
  public disabled = false;
  private destroy$ = new Subject<void>();

  constructor(private readonly userService: UserService) { }

  public ngOnInit(): void {
    this.innerControl.valueChanges.pipe(
      map(value => value ? this.code + value.replace(/\s/g, '') : null),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(value => this.onChange(value));
  }

  public ngOnChanges(changes: SimpleChanges) {
    changes?.code?.currentValue ? this.code = changes.code.currentValue : this.setCode();
  }

  private setCode(): void {
    switch (this.userService.location) {
      case Market.FR:
        this.code = PhoneCode.France;
        break;
      case Market.IT:
        this.code = PhoneCode.Italy;
        break;
      case Market.DE:
        this.code = PhoneCode.Germany;
        break;
      case Market.ES:
        this.code = PhoneCode.Spain;
        break;
      case Market.NL:
        this.code = PhoneCode.Netherlands;
        break;
      case Market.AT:
        this.code = PhoneCode.Austria;
        break;
      case Market.PL:
        this.code = PhoneCode.Poland;
        break;
      default:
        this.code = PhoneCode.France;
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onChange = (value: string) => { };
  public onTouched = () => { };

  public writeValue(value: string): void {
    this.innerControl.setValue(
      value?.startsWith('00')
        ? value?.replace('00', '+')?.replace(this.code, '')
        : value?.replace(this.code, ''),
      { emitEvent: false }
    );
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if(isDisabled) {
      this.innerControl.disable();
    } else {
      this.innerControl.enable();
    }
  }

  public onFocus(event: Event): void {
    (event.target as HTMLElement).removeAttribute('readonly');

    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.onTouched();
  }
}
