<section class="content-shaped-image">
  <div class="shell">
    <div class="content-shaped-image__grid" [ngClass]="'content-shaped-image__grid_position-' + aemData.shapeAlignment">
      <div class="content-shaped-image__column">
        <div class="content-shaped-image__header">
          <h4 class="content-shaped-image__title">{{ aemData.title }}</h4>
        </div>
        <app-safe-html class="content-shaped-image__text" [content]="aemData.text"></app-safe-html>

        <div class="content-shaped-image__button" *ngIf="aemData.ctas">
          <app-aem-button
            *ngFor="let cta of aemData.ctas"
            [cta]="cta"
            size="m"
            type="full-width"
          ></app-aem-button>
        </div>

        <app-safe-html *ngIf="aemData.additionalText" class="content-shaped-image__text" [content]="aemData.additionalText"></app-safe-html>

        <div *ngIf="aemData.additionalCtas" class="content-shaped-image__button">
          <app-aem-button
            *ngFor="let cta of aemData.additionalCtas"
            [cta]="cta"
            size="m"
            type="full-width"
          ></app-aem-button>
        </div>

        <app-safe-html class="content-shaped-image__bottom-text" *ngIf="aemData.bottomText" [content]="aemData.bottomText"></app-safe-html>
      </div>
      <div class="content-shaped-image__illustration" *ngIf="aemData.image.imagePath">
        <app-illustration
          [src]="aemData.image.imagePath"
          [alt]="aemData.image.altText"
          [colourShapeArc]="aemData.colourShapeArc"
          [colourShapeCircle]="aemData.colourShapeCircle"
          [position]="aemData.imageAlignment"
        ></app-illustration>
      </div>
    </div>
  </div>
</section>
