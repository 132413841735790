import { Injectable } from '@angular/core';
import { Observable, Subject, filter, forkJoin, switchMap, take } from "rxjs";
import { ConfirmationDialogComponent } from "@kit/dialog/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogData } from "@kit/dialog/confirmation-dialog/confirmation-dialog.interface";
import { DialogService } from "@kit/dialog/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { ApiDocumentsService } from '@common/documents-vault/api-documents.service';
import { Document, DownloadLink, File, Folder, VaultData } from '@common/documents-vault/api-documents.interface';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentVaultService {

  private documents$ = new Subject<Document>;
  private folders$ = new Subject<Folder>;
  private vaultData$ = new Subject<VaultData>;

  constructor(
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly apiDocumentsService: ApiDocumentsService
  ) { }

  public get userDocuments$(): Observable<Document> {
    return this.documents$.asObservable();
  }

  public get userFolders$(): Observable<Folder> {
    return this.folders$.asObservable();
  }

  public get userVaultData$(): Observable<VaultData> {
    return this.vaultData$.asObservable();
  }

  public getUserFolders(): void {
    this.apiDocumentsService.getFolders().subscribe((folders: Folder) => {
      this.folders$.next(folders);
    });
  }

  public getUserVaultData(pageSize: number, pageNumber: number, folderId: number | null = null): void {
    forkJoin([
      this.apiDocumentsService.getDocuments(pageSize, pageNumber, folderId),
      this.apiDocumentsService.getFolders()
    ]).subscribe(([documents, folders]) => {
      const userVaultData: VaultData = {
        folder: folders,
        document: documents
      };
      this.vaultData$.next(userVaultData);
    });
  }

  public deleteFile(file: File): Observable<void> {
    return this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData> {
      message: this.translateService.instant('pages.DOCUMENT_VAULT.DELETE_DIALOG.MESSAGE'),
      info: this.translateService.instant('pages.DOCUMENT_VAULT.DELETE_DIALOG.INFO'),
      confirmTitle: this.translateService.instant('pages.DOCUMENT_VAULT.DELETE_DIALOG.CONFIRM_TITLE'),
      cancelTitle: this.translateService.instant('pages.DOCUMENT_VAULT.DELETE_DIALOG.CANCEL_TITLE'),
      reverseButtonPosition: true,
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.apiDocumentsService.deleteDocument(file.id)),
    )
  }

  public downloadFile(file: File): void {
    this.apiDocumentsService.getDocumentDownloadLink(file.id)
      .pipe(
        take(1),
        switchMap((downloadLink: DownloadLink) => this.apiDocumentsService.downloadDocument(downloadLink.downloadLink))
      )
      .subscribe((response: HttpResponse<Blob>) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob([response.body], { type: this.resolveFileType(file.extension) }));
        downloadLink.setAttribute('download', 'file');
        downloadLink.download = `${file.name}.${file.extension}`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  }

  private resolveFileType(fileExtension: string): string {
    if (fileExtension === 'pdf') {
      return `application/${fileExtension}`;
    }

    if (fileExtension === 'jpg') {
      return 'application/jpeg';
    }

    return `image/${fileExtension}`;
  }

  private showConfirmationDialog(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.DOCUMENT_VAULT.DOWNLOAD_DIALOG.MESSAGE'),
      info: this.translateService.instant('pages.DOCUMENT_VAULT.DOWNLOAD_DIALOG.INFO'),
      confirmTitle: this.translateService.instant('pages.DOCUMENT_VAULT.DOWNLOAD_DIALOG.CONFIRM_TITLE'),
    });
  }
}
