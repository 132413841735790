<div class="insurance-content">
  <div class="info-container">
    <h5 class="h5 m-h3 zero-margin">{{ 'forms.BOOKING_PATH.PAYMENT.TITLE' | translate }}</h5>
    <div class="insurance-info">
      <h6 class="h6 m-h4 zero-margin">{{ 'forms.BOOKING_PATH.PAYMENT.SUBTITLE' | translate }}</h6>
      <div class="insurance-info_price">
        <h5 class="h5 m-t-small zero-margin">{{ currentPlan?.productOfferingName }}</h5>
        <h5 class="h5 m-h3 zero-margin nowrap">{{ currentPlan.recommendedPremium?.grossPremium?.amount | appCurrency: currentPlan.recommendedPremium?.grossPremium?.currency }}</h5>
      </div>
      <div class="info-container insurance-info_sub insurance-info_group">
        <div class="weight-bold h6 m-h4">{{ 'forms.BOOKING_PATH.PAYMENT.TRIP_INFO.DESTINATION' | translate }}</div>
        <div class="t-large m-t-normal insurance-info_value">{{ destinations }}</div>
        <div class="weight-bold h6 m-h4">{{ 'forms.BOOKING_PATH.PAYMENT.TRIP_INFO.START_DATE' | translate }}</div>
        <div class="t-large m-t-normal insurance-info_value">{{ bookingPathData?.tripDetails?.startDate | dateFormat }}</div>
        <div class="weight-bold h6 m-h4">{{ 'forms.BOOKING_PATH.PAYMENT.TRIP_INFO.END_DATE' | translate }}</div>
        <div class="t-large m-t-normal insurance-info_value">{{ bookingPathData?.tripDetails?.endDate | dateFormat }}</div>
        <a class="edit-button" (click)="selectStep(1)">{{ 'forms.BOOKING_PATH.PAYMENT.TRIP_INFO.EDIT_BUTTON' | translate }}</a>
      </div>

      <div class="info-container insurance-info_sub">
        <div class="flex-column">
          <h5 class="h5 m-h3 zero-margin">{{ 'forms.BOOKING_PATH.PAYMENT.INSURANCE_INFO.TRAVELERS_TITLE' | translate }}</h5>
          <div class="insurance-info_group">
            <ng-container *ngFor="let traveler of bookingPathData.travelersDetails?.travellers; let index = index">
              <div class="weight-bold h6 m-h4 nowrap">{{ 'forms.BOOKING_PATH.PAYMENT.INSURANCE_INFO.TRAVELER' | translate }} {{ index + 1 }}:</div>
              <div class="t-large m-t-normal insurance-info_value">
                {{ traveler.firstName }} {{ traveler.lastName }} {{ traveler.dateOfBirth | dateFormat }}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="flex-column">
          <h5 class="h5 m-h3 zero-margin">{{ 'forms.BOOKING_PATH.PAYMENT.INSURANCE_INFO.HOLDER_TITLE' | translate }}</h5>
          <div class="insurance-info_group">
            <div class="weight-bold h6 m-h4">{{ 'forms.BOOKING_PATH.PAYMENT.INSURANCE_INFO.NAME' | translate }}</div>
            <div class="t-large m-t-normal insurance-info_value">{{ bookingPathData?.travelersDetails?.policyHolder?.firstName }} {{ bookingPathData?.travelersDetails?.policyHolder?.lastName }}</div>
            <div class="weight-bold h6 m-h4">{{ 'forms.BOOKING_PATH.PAYMENT.INSURANCE_INFO.ADDRESS' | translate }}</div>
            <div class="t-large m-t-normal insurance-info_value">{{ bookingPathData?.travelersDetails?.policyHolder?.address }}</div>
            <div class="weight-bold h6 m-h4">{{ 'forms.BOOKING_PATH.PAYMENT.INSURANCE_INFO.PHONE' | translate }}</div>
            <div class="t-large m-t-normal insurance-info_value">{{ bookingPathData?.travelersDetails?.policyHolder?.phoneNumber }}</div>
            <div class="weight-bold h6 m-h4">{{ 'forms.BOOKING_PATH.PAYMENT.INSURANCE_INFO.EMAIL' | translate }}</div>
            <div class="t-large m-t-normal insurance-info_value">{{ bookingPathData?.travelersDetails?.policyHolder?.email }}</div>
            <a class="edit-button" (click)="selectStep(3)">{{ 'forms.BOOKING_PATH.PAYMENT.TRIP_INFO.EDIT_BUTTON' | translate }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="info-container zero-padding">
      <iframe id="insurance-payment" class="onepay-iframe" [src]="paymentUrl" frameborder=0></iframe>
    </div>
  </div>
</div>
