import { Attribute, Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DataParams, getAllRouterParams, setRouterData } from 'src/app/kit/utils/router.utils';

@Directive({
  selector: ':not(a):not(area)[appAemRouterLink]'
})
export class AemRouterLinkDirective extends RouterLink implements OnInit, OnChanges {
  @Input() aemRouterData: DataParams;
  @Input() aemRouterQueryParams: DataParams;
  @Input() appAemRouterLink: any[]|string|null|undefined;

  private activatedRoute: ActivatedRoute;

  constructor(
    router: Router,
    route: ActivatedRoute,
    @Attribute('tabindex') tabIndexAttribute: string|null|undefined,
    renderer: Renderer2,
    el: ElementRef,
  ) {
    super(router, route, tabIndexAttribute, renderer, el);
    this.activatedRoute = route;
  }

  ngOnInit(): void {
    if (this.appAemRouterLink !== null) super.routerLink = this.fillData();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.aemRouterData?.firstChange === false
      || changes.aemRouterQueryParams?.firstChange === false
      || changes.appAemRouterLink?.firstChange === false
    ) {
      super.routerLink = this.fillData();
    }
  }

  private fillData() {
    const params: DataParams = Object.assign(getAllRouterParams(this.activatedRoute.snapshot.root), this.aemRouterData);
    const queryParams: DataParams = this.aemRouterQueryParams;
    const commands: string | any[] = this.appAemRouterLink;

    super.queryParams = queryParams;

    return (Array.isArray(commands) ? commands : [commands])
      .map(command => setRouterData(command, params));
  }
}
