<div class="faq-block__helpful shell">
  <ng-container *ngIf="showHelpfulBlock; else thankYouMessage">
    <p class="zero-margin t-small m-t-normal weight-bold">{{ 'pages.FAQ.HELPFUL.TITLE' | translate }}</p>
    <app-button class="faq-block__helpful-action" size="inherit" theme="bordered" (click)="onClick(true)">
      {{ 'pages.FAQ.HELPFUL.YES' | translate }}
    </app-button>
    <app-button class="faq-block__helpful-action" size="inherit" theme="bordered" (click)="onClick(false)">
      {{ 'pages.FAQ.HELPFUL.NO' | translate }}
    </app-button>
  </ng-container>
  <ng-template #thankYouMessage>
    <p class="faq-block__helpful-message  zero-margin t-small m-t-normal weight-bold">
      {{ 'pages.FAQ.HELPFUL.MESSAGE' | translate }}
    </p>
  </ng-template>
</div>
