<div class="documents__container">
  <div *ngIf="isUserFreemium" class="documents__freemium documents__freemium-mobile">
    <app-icon icon="file-warning" theme="amped" size="large"></app-icon>
    <app-safe-html [content]="'pages.DOCUMENT_VAULT.DESCRIPTIONS.FREEMIUM_USER_DISCLAIMER' | translate"></app-safe-html>
  </div>
  <div class="documents__section" *ngIf="folderFiles | async as files">
    <div *ngIf="isUserFreemium" class="documents__freemium">
      <app-icon icon="file-warning" theme="amped" size="large"></app-icon>
      <app-safe-html [content]="'pages.DOCUMENT_VAULT.DESCRIPTIONS.FREEMIUM_USER_DISCLAIMER' | translate"></app-safe-html>
    </div>
    <div *ngIf="files.totalElements; else noFiles" class="documents__section-files">
      <div class="weight-bold text-large documents__files-title">
        <div class="weight-bold text-large documents__section-title">{{ 'pages.DOCUMENT_VAULT.LABELS.TITLE_FILES' | translate }}
        </div>
        <div></div>
        <div class="weight-bold text-tiny documents__section-relative">{{ 'pages.DOCUMENT_VAULT.LABELS.TITLE_ADDED' | translate }}</div>
        <div></div>
      </div>
      <div class="documents__files">
        <ng-container *ngFor="let file of files?.content | paginate: {
                                  itemsPerPage: entitiesOnPage,
                                  currentPage: currentPage,
                                  totalItems: files.totalElements
                                  }">
          <app-document-vault-file (deleteFile)="deleteFile($event)"
                                    (changeCurrentPage)="changeCurrentPage($event)"
                                    [file]="file"
                                    [currentPage]="currentPage"
                                    [totalElements]="files.totalElements"
                                    [pageSize]="entitiesOnPage">
          </app-document-vault-file>
        </ng-container>
        <app-pagination [entities]="files?.content"
                        [entitiesOnPage]="entitiesOnPage"
                        [entitiesTotalElements]="files?.totalElements"
                        (changePage)="getPage($event)"
                        [theme]="'grey'"
                        class="documents__pagination">
        </app-pagination>
      </div>
    </div>
  </div>
</div>

<ng-template #noFiles>
  <div class="weight-bold text-large documents__section-title">
    {{ 'pages.DOCUMENT_VAULT.LABELS.TITLE_FILES' | translate }}
  </div>
  <div class="documents__section-text">
    {{ 'pages.DOCUMENT_VAULT.LABELS.NO_DOCUMENTS_TEXT' | translate }}
  </div>
</ng-template>
