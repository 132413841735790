<ng-container *ngIf="ctaLink">
  <a *ngIf="isRoute; else hrefLink"
     [class.disabled]="isDisabled"
     [attr.rel]="ctaLink.nofollow ? 'nofollow' : null"
     [appAemRouterLink]="link"
     [aemRouterQueryData]="queryParams">
    <ng-template [ngTemplateOutlet]="button"></ng-template>
  </a>
</ng-container>

<ng-container *ngIf="ctaAction">
  <ng-container [ngSwitch]="ctaAction.action">

    <app-healthcare-professional *ngSwitchCase="ActionEnum.HealthcareProfessional">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-healthcare-professional>

    <app-log-out *ngSwitchCase="ActionEnum.LogOut">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-log-out>

    <app-login *ngSwitchCase="ActionEnum.Login">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-login>

    <app-chat-with-doctor *ngSwitchCase="ActionEnum.ChatWithDoctor">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-chat-with-doctor>

    <app-send-invite-email *ngSwitchCase="ActionEnum.SendInviteEmail">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-send-invite-email>

    <app-survay *ngSwitchCase="ActionEnum.Survay">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-survay>

    <app-upgrade-insurance-policy *ngSwitchCase="ActionEnum.UpgradeInsurancePolicy">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-upgrade-insurance-policy>

    <app-upgrade-referral-code *ngSwitchCase="ActionEnum.UpgradeReferralCode">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-upgrade-referral-code>

    <app-onetrust-cookies-settings *ngSwitchCase="ActionEnum.OnetrustCookiesSettings">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-onetrust-cookies-settings>

    <app-open-insurance-request *ngSwitchCase="ActionEnum.OpenInsuranceRequest">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-open-insurance-request>

    <app-track-insurance-request *ngSwitchCase="ActionEnum.TrackInsuranceRequest">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-track-insurance-request>

    <app-teleconsultation *ngSwitchCase="ActionEnum.Teleconsultation">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-teleconsultation>

    <app-faq-back-button *ngSwitchCase="ActionEnum.FaqBackButton">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-faq-back-button>

    <app-air-doctor *ngSwitchCase="ActionEnum.AirDoctorButton">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-air-doctor>

    <app-buy-travel-insurance-action *ngSwitchCase="ActionEnum.BuyTravelInsurance">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-buy-travel-insurance-action>

    <app-open-policy-details *ngSwitchCase="ActionEnum.OpenPolicyDetails">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </app-open-policy-details>

    <div *ngSwitchDefault>UnknownAction: <b>{{ ctaAction.action }}</b></div>
  </ng-container>
</ng-container>

<ng-container *ngIf="ctaHideableExtendedLink">
  <app-aem-hideable-extended-link [item]="ctaHideableExtendedLink"></app-aem-hideable-extended-link>
</ng-container>

<ng-container *ngIf="ctaCopyIdAction">
  <app-user-id-button></app-user-id-button>
</ng-container>

<ng-template #hrefLink>
  <a [class.disabled]="isDisabled"
     [attr.href]="ctaLink.link"
     [attr.rel]="ctaLink.nofollow ? 'nofollow' : null"
     target="_blank">
    <ng-template [ngTemplateOutlet]="button"></ng-template>
  </a>
</ng-template>

<ng-template #button>
  <app-tag *ngIf="type === 'tag'; else defaultButton"
           [icon]="cta.icon"
           [content]="cta.label"
           [theme]="cta.colour || 'black'"
           [disabled]="isDisabled">
  </app-tag>

  <ng-template #defaultButton>
    <app-button [size]="size"
                [type]="$any(type)"
                [icon]="cta.icon"
                [theme]="cta.colour || 'black'"
                [disabled]="isDisabled">
      {{ cta.label }}
    </app-button>
  </ng-template>
</ng-template>
