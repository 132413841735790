import { Inject, Pipe, PipeTransform } from '@angular/core';
import { FlightInfo } from '@common/flight/flight.interfaces';
import { DateFormat, FormatDate } from '@kit/date/date-formats/date-formats.factory';
import { DAY, HOUR, MINUTE } from '@kit/date/date.const';

@Pipe({
  name: 'flightDelay'
})
export class FlightDelayPipe implements PipeTransform {
  constructor(
    @Inject(FormatDate) private readonly dateFormat: DateFormat
  ) {}

  transform(info: FlightInfo): string {
    const departureNew = new Date(`${info.departure.date}T${info.departure.time}`);
    const departureOld = new Date(info.flightFieldsOld.departure);

    const difference = Number(departureNew) - Number(departureOld);

    const dayDiff = Math.floor(difference / DAY);
    const hourDiff = Math.floor((difference % DAY) / HOUR);
    const minuteDiff = Math.floor((difference % HOUR) / MINUTE);

    let result = [];

    if (dayDiff) result.push(`${dayDiff} ${this.dateFormat.day}`);
    if (hourDiff) result.push(`${hourDiff} ${this.dateFormat.hour}`);
    if (minuteDiff) result.push(`${minuteDiff} ${this.dateFormat.minute}`);

    return result.join(' ');
  }
}
