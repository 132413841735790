import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@kit/button/button.module';
import { TranslateModule } from '@kit/translate/translate.module';
import { AemShareBlockComponent } from './share-block.component';
import { ShareComponent } from './share/share.component';

@NgModule({
  declarations: [AemShareBlockComponent, ShareComponent],
  exports: [AemShareBlockComponent, ShareComponent],
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
  ]
})
export class AemShareBlockModule { }
