import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DialogCloseDirective } from './dialog-close.directive';
import { DialogPortalComponent } from './dialog-portal/dialog-portal.component';
import { DialogWrapperComponent } from './dialog-wrapper/dialog-wrapper.component';
import { MembershipDialogNotificationModule } from '../../pages/membership/components/membership-dialog/membership-dialog-notification/membership-dialog-notification.module';
import { MembershipDialogUpgradeModule } from '../../pages/membership/components/membership-dialog/membership-dialog-upgrade/membership-dialog-upgrade.module';
import { ResultDialogComponent } from './result-dialog/result-dialog.component';
import { SurveyDialogComponent } from './survey-dialog/survey-dialog.component';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { MarketingDialogComponent } from './marketing-dialog/marketing-dialog.component';
import { CheckboxModule } from "@kit/checkbox/checkbox.module";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    IconModule,
    SafeHtmlModule,
    ImgAltDirectiveModule,
    CheckboxModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [
    DialogPortalComponent,
    DialogCloseDirective,
    DialogWrapperComponent,
    ConfirmationDialogComponent,
    ResultDialogComponent,
    SurveyDialogComponent,
    MarketingDialogComponent,
  ],
  exports: [
    DialogPortalComponent,
    DialogCloseDirective,
    DialogWrapperComponent,
    ConfirmationDialogComponent,
    ResultDialogComponent,
    SurveyDialogComponent,
  ]
})
export class DialogModule { }
