<div class="time-picker form-field"
     [class.form-field_focused]="focused"
     [class.form-field_disabled]="disabled"
     (click)="picker.focus()">
  <input #picker
         [class.flatpickr-mobile]="!innerControl.value"
         [formControl]="innerControl"
         (focus)="onFocus()"
         (blur)="onBlur()"
         type="time"
         placeholder="--:--"
         class="time-picker_field text-small">
</div>
