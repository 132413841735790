import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, switchMap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SspWidgetService {
  constructor(private readonly http: HttpClient) { }

  public auth(policyNumber: string, userEmail: string): Observable<Record<string, string>> {
    return this.oneClaimLogOut$().pipe(
      switchMap(() => this.getAuthToken$(policyNumber, userEmail)),
      switchMap((token: string) => this.oneClaimLogIn$(token, policyNumber, userEmail)),
    );
  }

  private oneClaimLogOut$(): Observable<Record<string, string>> {
    return this.http.post<any>(`${environment.oneClaimUrl}/api/logout`, {})
      .pipe(catchError((error) => throwError(error)));
  }

  private oneClaimLogIn$(token: string, contractNumber: string, userEmail: string): Observable<any> {
    const body: Record<string, string> = {
      contractNumber,
      userEmail
    }

    return this.http.post<any>(`${environment.oneClaimUrl}/api/sso?dataToken=${token}`, body, { withCredentials: true });
  }

  private getAuthToken$(policyNumber: string, userEmail: string): Observable<string> {
    const body: Record<string, any> = {
      scope: 'travel-claims',
      claims: { policyNumber: policyNumber, userEmail: userEmail },
      ttl: 1800,
    }
    const path: string = `${environment.api.baseUrl}/jwtauth/idtoken`;

    return this.http.post<any>(path, body)
      .pipe(map((response: any) => response.token));
  }
}
