import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef, HostBinding,
  Inject,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AEM_DATA } from "@pages/dynamic/dynamic-render/dynamic-render.const";
import { VideoPlayer } from "@common/model/videoPlayer";
import { AemFragmentComponent } from "@kit/aem-base-block/aem-fragment";

@Component({
  selector: 'app-aem-video-player',
  templateUrl: './aem-video-player.component.html',
  styleUrls: ['./aem-video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemVideoPlayerComponent extends AemFragmentComponent implements AfterViewInit {
  @ViewChild('video', { static: false }) videoElementRef: ElementRef;
  @HostBinding('attr.id')
  get id() { return this.data.id || 'video' };

  constructor(
    @Inject(AEM_DATA) public override data: VideoPlayer,
    private readonly renderer: Renderer2,
  ) {
    super(data);
  }

  ngAfterViewInit(): void {
    if (this.data?.description) {
      this.renderer.setAttribute(this.videoElementRef.nativeElement, 'value', this.data.description);
    }
  }
}
