import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DIALOG_DATA, DIALOG_REF } from '../dialog.const';
import { DialogRef } from '../dialog.interfaces';
import { ResultDialogData } from './result-dialog.interface';

@Component({
  selector: 'app-result-dialog',
  templateUrl: './result-dialog.component.html',
  styleUrls: ['./result-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultDialogComponent {

  constructor(
    @Inject(DIALOG_DATA) public data: ResultDialogData,
    @Inject(DIALOG_REF) private dialogRef: DialogRef<ResultDialogComponent>,
  ) { }


  close(): void {
    this.dialogRef.close();
  }
}
