import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageService } from '@common/language/language.service';

@Injectable({ providedIn: 'root' })
export class LangGuard  {
  constructor(
    private readonly router: Router,
    private readonly languageService: LanguageService,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const lang = route.paramMap.get('lang');

    if (lang) {
      this.languageService.setLanguageCode(lang);
    }

    return Boolean(lang) || this.router.createUrlTree([this.languageService.languageCode.toLowerCase()], { queryParams: route.queryParams });
  }
}
