import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Enumeration } from 'src/app/common/model/models';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-enumeration',
  templateUrl: './enumeration.component.html',
  styleUrls: ['./enumeration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnumerationComponent extends AemBaseBlockComponent {

  constructor(
    @Inject(AEM_DATA) public aemData: Enumeration,
  ) {
    super(aemData);
  }
}
