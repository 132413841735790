import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { CTALink } from '@common/model/cTALink';
import { TripHero } from '@common/model/tripHero';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { Activity } from "@pages/trip/trip.interfaces";
import { ActivityType } from "@pages/trip/trip.const";

@Component({
  selector: 'app-add-trip-element',
  templateUrl: './add-trip-element.component.html',
  styleUrls: ['./add-trip-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTripElementComponent extends AemFragmentComponent implements OnInit{
  public tripId: string;
  public isNewTrip: boolean;

  constructor(
    @Inject(AEM_DATA) public aemData: TripHero & { component: { ctas: CTALink[] }},
    private route: ActivatedRoute,
    private router: Router,
    private bootstrapService: BootstrapService,
  ) {
    super(aemData);
  }

  ngOnInit(): void {
    this.tripId = this.route.firstChild.snapshot.params.tripId;
    this.isNewTrip = !!this.route.snapshot.queryParamMap.get('newTrip');
  }

  public goBack(): void {
    this.router.navigate([setRouterData(this.bootstrapService.link.tripWallet, { tripId: this.tripId })]);
  }

  public getLink(cta: CTALink): string {
    if (cta.id !== 'action-add-flight') {
      return cta.link
    }

    const tripActivities: Activity[] = this.route.firstChild.snapshot.data?.currentTrip?.actions;
    const flightsCount: number = tripActivities?.filter((activity: Activity) => activity.type === ActivityType.FLIGHT)?.length;

    return flightsCount
      ? this.bootstrapService.link.existingFlightsPage
      : this.bootstrapService.link.flightAdd;
  }
 }
