import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AemRouterLinkWithHrefDirective } from './aem-router-link-with-href.directive';
import { AemRouterLinkDirective } from './aem-router-link.directive';
import { AuthorizedContentPlaceholderComponent } from './authorized-content-placeholder/authorized-content-placeholder.component';

@NgModule({
  declarations: [AemRouterLinkDirective, AemRouterLinkWithHrefDirective, AuthorizedContentPlaceholderComponent],
  exports: [AemRouterLinkDirective, AemRouterLinkWithHrefDirective, RouterModule, AuthorizedContentPlaceholderComponent],
  imports: [RouterModule]
})
export class AemRouterModule { }
