import { ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { StepperItemDirective } from './stepper-item.directive';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperComponent {
  private _selectedStep = 1;

  @Output() public selectedStepChange = new EventEmitter<number>();

  @Input() public lastAvailableStep = 1;
  @Input() public set selectedStep(value: number) {
    this._selectedStep = value;
  };

  public get selectedStep(): number {
    return this._selectedStep;
  }

  @ContentChildren(StepperItemDirective)
  public items: QueryList<StepperItemDirective>;

  public selectStep(index: number): void {
    if (index !== this.selectedStep && index <= this.lastAvailableStep) {
      this.selectedStep = index;
      this.selectedStepChange.emit(index);
    }
  }
}
