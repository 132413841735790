<app-trip-hero-block (goBack)="onCancel()">
  <div class="wallet-overview_form" [formGroup]="userForm">
    <app-label [label]="'forms.CO_TRAVELER.LABELS.FIRST_NAME' | translate">
      <app-input id="co-travalers-first-name"
                 [maxLength]="50"
                 [placeholder]="'forms.CO_TRAVELER.PLACEHOLDERS.FIRST_NAME' | translate"
                 formControlName="firstName">
      </app-input>
      <app-field-error controlName="firstName"></app-field-error>
    </app-label>

    <app-label [label]="'forms.CO_TRAVELER.LABELS.LAST_NAME' | translate">
      <app-input id="co-travelers-last-name"
                 [maxLength]="50"
                 [placeholder]="'forms.CO_TRAVELER.PLACEHOLDERS.LAST_NAME' | translate"
                 formControlName="lastName">
      </app-input>
      <app-field-error controlName="lastName"></app-field-error>
      <app-field-error [control]="userForm"></app-field-error>
    </app-label>

    <div class="wallet-overview_form-actions">
      <app-button id="action-cotraveler-save"
                  (click)="onSave()"
                  type="full-width"
                  size="inherit">
        {{ 'forms.CO_TRAVELER.ACTIONS.SAVE_INFORMATION' | translate }}
      </app-button>

      <app-button id="action-cotraveler-cancel"
                  (click)="onCancel()"
                  theme="bordered"
                  type="full-width"
                  size="inherit">
        {{ 'forms.CO_TRAVELER.ACTIONS.CANCEL' | translate }}
      </app-button>
    </div>
  </div>
</app-trip-hero-block>
