<section class="survey-banner">
  <div class="shell survey-banner__container">
    <img class="survey-banner__avatar"
         *ngIf="data.image.imagePath"
         [src]="data.image.imagePath"
         [appImgAlt]="data.image.altText"
         [appImgAltData]="data">
    <div class="survey-banner__content">
      <p class="zero-margin t-large weight-medium">{{ data.title }}</p>
      <p class="zero-margin t-tiny">{{ data.text }}</p>
    </div>
    <app-aem-button class="survey-banner__action" [cta]="data.cta" size="inherit"></app-aem-button>
  </div>
</section>
