import { DatePipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TIME_STRING_REGEX } from '../date.const';
import { DateFormat, FormatDate } from './date-formats.factory';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  constructor(
    private readonly dateFormatter: DatePipe,
    @Inject(FormatDate) private readonly dateFormat: DateFormat,
  ) { }

  transform(value: number | string | Date, type: 'time' | 'numeric' | 'fullSpelled' | 'fullSpelledWithFullMonth' = 'numeric'): string {
    let date = value;

    if (type === 'time' && typeof value === 'string' && TIME_STRING_REGEX.test(value)) {
      date = new Date();
      const [hours, minutes] = value.split(':');
      date.setHours(+hours, +minutes);
    }

    return this.dateFormatter.transform(date, this.dateFormat[type]);
  }

}
