<ng-container *ngIf="breadcrumbs.length > 0">
  <ol class="breadcrumbs">
    <li class="breadcrumbs__item">
      <a class="breadcrumbs__link breadcrumbs__link_home" [routerLink]="homeRoute">
        <app-icon class="breadcrumbs__icon" theme="invert" icon="allyz"></app-icon>
      </a>
    </li>
    <li class="breadcrumbs__item" *ngFor="let breadcrumb of breadcrumbs; let last = last;">
      <a *ngIf="!last; else withoutLink" class="breadcrumbs__link" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
      <ng-template #withoutLink>{{ breadcrumb.label }}</ng-template>
    </li>
  </ol>

  <ol class="breadcrumbs breadcrumbs_mobile">
    <li class="breadcrumbs__item">
      <app-icon class="breadcrumbs__icon" theme="invert" icon="chevron-left"></app-icon>
      <a class="breadcrumbs__link" [routerLink]="previousPage.url">{{ previousPage.label }}</a>
    </li>
  </ol>
</ng-container>
