import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, EMPTY, Observable } from 'rxjs';
import { RootPaths } from 'src/app/app.const';
import { Hotel } from './hotel.interface';
import { HotelService } from './hotel.service';

@Injectable({
  providedIn: 'root',
})
export class HotelResolver  {
  constructor(
    private hotelService: HotelService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<Hotel> {
    const { tripId, accommodationId } = route.params;
    return this.hotelService.get(tripId, accommodationId).pipe(
      catchError((err) => {
        this.router.navigate([RootPaths.NotFound], { skipLocationChange: true });

        console.error(err);
        return EMPTY;
      })
    );
  }
}
