import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NotFoundService } from './not-found.service';
import { LanguageService } from '@common/language/language.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {
  public routerLink = '/'

  constructor(
    private notFoundService: NotFoundService,
    private languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    this.notFoundService.setStatus();

    if (this.languageService.isVisaEnUrl()) {
      this.routerLink = '/en-fr/';
    }
  }
}
