<app-trip-hero-block (goBack)="goBack()">
  <div class="flight-preview">
    <div class="flight-preview_header">
      <app-icon class="flight-preview_header-icon" icon="plane-fill" theme="inherit" size="x-large"></app-icon>
      <div class="flight-preview_header-info">
        <div class="h6 m-h4">{{selectedFlight.departure.city}} - {{selectedFlight.arrival.city}}</div>
        <div class="t-small m-t-normal">
          <b>{{selectedFlight.flightNumber}}</b>
          {{'pages.ADDED_FLIGHTS.DEPARTING' | translate}} {{selectedFlight.departure.date | dateFormat:'fullSpelled'}}
          {{'pages.ADDED_FLIGHTS.AT' | translate}} {{selectedFlight.departure.date + ' ' + selectedFlight.departure.time | dateFormat:'time'}}
        </div>
      </div>
    </div>
    <div class="flight-preview_body">
      <hr/>
      <div class="flight-preview_body-flight-info">
        <div>
          <div class="t-small"><b>{{selectedFlight.departure.airportCode}}</b></div>
          <div class="t-tiny">{{selectedFlight.departure.city}}</div>
          <div class="t-tiny margin-bottom">{{selectedFlight.departure.country.name}}</div>
        </div>
        <div class="t-small flight-number">
          <div><b>{{selectedFlight.flightNumber}}</b></div>
          <app-icon class="flight-number_icon" icon="plane-fill" size="large" theme="inherit"></app-icon>
        </div>
        <div>
          <div class="t-small text-align-right"><b>{{selectedFlight.arrival.airportCode}}</b></div>
          <div class="t-tiny text-align-right">{{selectedFlight.arrival.city}}</div>
          <div class="t-tiny text-align-right margin-bottom">{{selectedFlight.arrival.country.name}}</div>
        </div>
      </div>
      <div class="flight-preview_body-flight-time">
        <div>
          <div class="t-small">
            <b>{{selectedFlight.departure.date + ' ' + selectedFlight.departure.time | dateFormat:'time'}}</b>
          </div>
          <div class="t-tiny">{{selectedFlight.departure.date | dateFormat:'fullSpelled'}}</div>
        </div>
        <div>
          <div class="t-small text-align-right">
            <b>{{selectedFlight.arrival.date + ' ' + selectedFlight.arrival.time | dateFormat:'time'}}</b>
          </div>
          <div class="t-tiny text-align-right">{{selectedFlight.arrival.date | dateFormat:'fullSpelled'}}</div>
        </div>
      </div>
      <div *ngIf="selectedFlight.reservationNumber" class="flight-preview_body-flight-addinfo">
        <div>{{'pages.ADDED_FLIGHTS.RESERVATION_NUMBER' | translate}} {{selectedFlight.reservationNumber}}</div>
      </div>
      <hr/>
      <div class="flight-preview_body-footer">
        <app-field-error [control]="fakeControl"></app-field-error>
        <app-button (click)="addFlight()"
                    id="action-flight-confirm"
                    size="l"
                    type="full-width"
                    theme="black">
          {{ 'forms.FLIGHT.ACTIONS.CONFIRM_MY_FLIGHT' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</app-trip-hero-block>
