import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from '../button/button/button.component';
import { IconList } from '../icon/icon.interface';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input() icon: IconList;
  @Input() content: string;
  @Input() theme: ButtonComponent['theme'] = 'black';
  @Input() disabled: boolean = false;
  @Input() counter?: number;
}
