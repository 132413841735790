import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightInfo } from '@common/flight/flight.interfaces';
import { ActivityType } from '@pages/trip/trip.const';
import { Activity, TripDetails } from '@pages/trip/trip.interfaces';
import { Hotel } from '../../hotel/hotel.interface';
import { Activity as ActivityInterface } from '../../activity-element/activity-element.interface';

@Component({
  selector: 'app-trip-details-accordion',
  templateUrl: './trip-details-accordion.component.html',
  styleUrls: ['./trip-details-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripDetailsAccordionComponent {
  @Input() trip: TripDetails;

  public getDestinationCountries(trip: TripDetails): string {
    return trip.destinationPoints.map(( { description }) => description).join(' | ');
  }

  public getFlights(actions: Activity[]): FlightInfo[] {
    return actions?.filter(action => action?.type === ActivityType.FLIGHT) as FlightInfo[];
  }

  public getActivities(actions: Activity[]): ActivityInterface[] {
    return actions?.filter(action => action?.type === ActivityType.ACTIVITY) as ActivityInterface[];
  }

  public getHotels(actions: Activity[]): Hotel[] {
    return actions?.filter(action => action?.type === ActivityType.HOTEL) as Hotel[];
  }

}
