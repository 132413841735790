import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateTripComponent } from "@pages/trip/components/create-trip/create-trip.component";
import { EditTripFromModule } from "@pages/trip/components/edit-trip-from/edit-trip-from.module";
import { TripHeroBlockModule } from "@pages/trip/components/trip-hero-block/trip-hero-block.module";

@NgModule({
  declarations: [CreateTripComponent],
  imports: [
    CommonModule,
    EditTripFromModule,
    TripHeroBlockModule,
  ],
})
export class CreateTripModule { }
