import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembershipPlansComponent } from './membership-plans.component';
import { SliderModule } from '../carousel/slider.module';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { AemButtonModule } from '../aem-button/aem-button.module';

@NgModule({
  declarations: [MembershipPlansComponent],
  exports: [MembershipPlansComponent],
  imports: [
    CommonModule,
    SliderModule,
    SafeHtmlModule,
    AemButtonModule,
  ],
})
export class MembershipPlansModule {}
