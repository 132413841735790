import { ChangeDetectorRef, Component, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss'],
  providers: [
    TuiDestroyService,
  ]
})
export class FieldErrorComponent implements OnInit{
  @Input() public controlName: string;
  @Input() public control: AbstractControl;
  @Input() public isInBlock: boolean = false;

  private get formControl(): AbstractControl {
    return this.control || this.parentControl?.control?.get(this.controlName);
  }

  public get showMessage(): boolean {
    return this.formControl?.invalid && this.formControl?.touched;
  }

  public get errorMessage(): string {
    return this.formControl?.errors?.err;
  }

  constructor(
    @Optional()
    @SkipSelf()
    private parentControl: FormGroupDirective,
    private cdr: ChangeDetectorRef,
    private onDestroy$: TuiDestroyService,
  ) {}

  ngOnInit(): void {
    if (this.formControl) {
      this.formControl.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.cdr.markForCheck();
      })
    }
  }
}
