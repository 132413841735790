<app-dialog-wrapper>
  <div class="dialog-header" dialogTitle>
    <h6 class="h6 m-h5 zero-margin">{{ 'global.MEMBER_ALLIANZ_TRAVEL_DIALOG.TITLE' | translate }}</h6>
  </div>
  <div dialogContent>
    <h5 class="h5 m-h3 headline">{{ 'global.MEMBER_ALLIANZ_TRAVEL_DIALOG.EXPIRY_DATE_TITLE' | translate }}</h5>
    <app-safe-html class="t-large m-t-large text" [content]="'global.MEMBER_ALLIANZ_TRAVEL_DIALOG.EXPIRY_DATE_DESCRIPTION' | translate"></app-safe-html>

    <h5 class="h5 m-h3 headline">{{ 'global.MEMBER_ALLIANZ_TRAVEL_DIALOG.DOCUMENT_TITLE' | translate }}</h5>
    <app-safe-html class="t-large m-t-large text" [content]="'global.MEMBER_ALLIANZ_TRAVEL_DIALOG.DOCUMENT_DESCRIPTION' | translate"></app-safe-html>

    <div class="dialog-footer">
      <p *ngIf="'global.MEMBER_ALLIANZ_TRAVEL_DIALOG.ACTION_DESCRIPTION' | translate as description"> {{ description }} </p>
      <div class="buttons">
        <a [href]="url" class="link">
          <app-button type="full-width" size="l">{{ 'global.MEMBER_ALLIANZ_TRAVEL_DIALOG.SEND_MAIL' | translate }}</app-button>
        </a>
      </div>
      <a [appAemRouterLink]="bootstrapService.link.privacyPolicy"  class="t-tiny m-t-small addition-text" (click)="dialogRef.close()">
        {{ 'global.MEMBER_ALLIANZ_TRAVEL_DIALOG.DISCLAIMER' | translate }}
      </a>
    </div>
  </div>
</app-dialog-wrapper>
