<ng-container *tuiLet="(policies$ | async) as policies">
  <section *ngIf="policies?.length">
    <div class="shell">
      <div class="h4 m-h3">
        {{ 'pages.MY_INSURANCE.TITLES.PAST_POLICIES' | translate }}
      </div>
      <ng-container *ngFor="let policy of policies; let index = index">
        <div *ngIf="index < shownPolicyCount"
             (click)="onViewDetails(policy.number, policy?.origin, policy?.openPolicyNumber)"
             class="card">
          <div class="card_icon">
            <app-icon class="icon"
                      icon="form-edit"
                      size="normal"
                      theme="lighten">
            </app-icon>
          </div>
          <div class="card_body">
            <div class="card_field t-large m-t-large weight-medium">
              {{policy.productName}}
            </div>
            <div class="card_field card_date t-small m-t-small">
              <span class="block-title weight-bold">
                {{ 'pages.MY_INSURANCE.LABELS.DATES' | translate }}
              </span>
              {{policy.startDate | dateFormat : 'fullSpelled'}} - {{policy.endDate | dateFormat : 'fullSpelled'}}
            </div>
            <div class="card_field t-small m-t-small">
              <span class="block-title weight-bold">
              {{ 'pages.MY_INSURANCE.LABELS.POLICY_NUMBER' | translate }} </span>
              {{policy.openPolicyNumber || policy.number}}
            </div>
          </div>
          <div class="card_button">
            <app-button size="s"
                        type="icon-button"
                        icon="chevron-right"
                        theme="black">
            </app-button>
          </div>
        </div>
      </ng-container>
      <div class="buttons" *ngIf="policies.length > displayStep">
        <app-button icon="plus"
                    theme="bordered"
                    type="big-width"
                    (click)="showMorePolicies()"
                    *ngIf="policies.length > shownPolicyCount">
          {{'pages.MY_INSURANCE.ACTIONS.SHOW_MORE_PAST_POLICIES' | translate }}
        </app-button>
        <app-button icon="minus"
                    theme="bordered"
                    type="big-width"
                    (click)="showLessPolicies()"
                    *ngIf="shownPolicyCount > displayStep">
          {{ 'pages.MY_INSURANCE.ACTIONS.HIDE_PAST_POLICIES' | translate }}
        </app-button>
      </div>
    </div>
  </section>
</ng-container>
