import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@common/user/user.service';
import { LanguageService } from '@common/language/language.service';


@Component({
  selector: 'app-log-out',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogOutComponent {
  constructor(
    private userService: UserService,
    private router: Router,
    private languageService: LanguageService,
  ) {}

  @HostListener('click')
  logOut() {
    const path = this.languageService.isVisaEnUrl() ? '/en-fr' : '/';

    this.userService.logOut();
    this.router.navigate([path]);
  }
}
