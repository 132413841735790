import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { ButtonModule } from '../button/button.module';
import { BackButtonComponent } from './back-button.component';

@NgModule({
  declarations: [BackButtonComponent],
  exports: [BackButtonComponent],
  imports: [CommonModule, ButtonModule, AemRouterModule]
})
export class BackButtonModule {}
