import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-updated-field-value',
  templateUrl: './updated-field-value.component.html',
  styleUrls: ['./updated-field-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdatedFieldValueComponent {
  @Input() public oldValue: unknown;
  @Input() public newValue: unknown;
}
