<ng-container *ngIf="(data$ | async) as data">
  <div class="toolbar-container">
    <div class="toolbar shell" [ngClass]="data.burgerMenu?.links?.length ? 'toolbar-with-burger' : ''">
      <div class="toolbar_logo" [routerLink]="[data.logoLink.link]">
        <img class="toolbar_logo-img"
             [src]="data.logo.imagePath"
             (click)="closeMobileMenu()"
             [alt]="data.logo.altText"/>
      </div>

      <nav class="toolbar_nav">
        <ng-container *ngFor="let link of data.links">
          <ng-container *ngTemplateOutlet="isRoute(link.link) ? route : hrefLink; context: {$implicit: link}"></ng-container>
        </ng-container>
      </nav>

      <ng-template let-link #route>
        <a class="toolbar_nav_link"
           routerLinkActive="toolbar_nav_link-active"
           [attr.rel.nofollow]="link?.nofollow"
           [routerLink]="link?.link"
           [attr.id]="link?.id">
           {{ link?.label }}
        </a>
      </ng-template>

      <ng-template let-link #hrefLink>
        <a [attr.href]="link.link"
           [attr.rel]="link.nofollow ? 'nofollow' : null"
           [attr.id]="link?.id"
           class="toolbar_nav_link"
           target="_blank"
           (click)="focusOut($event)">
           {{ link.label }}
        </a>
      </ng-template>

      <div class="toolbar_right-action" [ngClass]="isFrenchMarket ? 'fixed-width' : ''">
        <ng-container *ngIf="isShownLanguageSwitcher$ | async">
          <app-language-switcher *ngIf="isFrenchMarket"></app-language-switcher>
        </ng-container>
        <app-button size="s"
                    [attr.id]="data.cta.id"
                    [icon]="data.cta.icon"
                    [routerLink]="data.cta.link">
          <span class="toolbar_right-action-text">{{ data.cta.label }}</span>
        </app-button>
        <app-icon class="toolbar_mobile-menu"
                  icon="menu"
                  theme="invert"
                  size="large"
                  (click)="openMobileMenu()"></app-icon>
      </div>
      <app-icon class="toolbar_mobile-close"
                icon="close"
                theme="invert"
                size="large"
                (click)="closeMobileMenu()"></app-icon>
      <app-burger-menu *ngIf="data.burgerMenu?.links?.length"
                       class="toolbar_burger"
                       [links]="data.burgerMenu.links">
      </app-burger-menu>
    </div>
  </div>
  <div class="shell mobile-content" [class.mobile-content_scrolled]="isScrolled">
    <nav class="mobile_nav">
      <ng-container *ngFor="let link of data.links">
        <ng-container *ngTemplateOutlet="isRoute(link.link) ? mobileRoute : mobileHrefLink; context: {$implicit: link}"></ng-container>
      </ng-container>
        <div *ngIf="data.burgerMenu?.links?.length" class="verticals_container">
          <div class="verticals_title text-tiny ">{{ 'global.ALLYZ_SERVICES' | translate }}</div>
          <a *ngFor="let item of data.burgerMenu.links"
            class="mobile_nav_link verticals_link"
            [ngClass]="item.label.toLocaleLowerCase().includes('travel') ? 'mobile_nav_link-active' : ''"
            [attr.rel.nofollow]="item.nofollow"
            [attr.href]="item.link"
            [attr.id]="item.id"
            (click)="closeMobileMenu()">
            <span class="mobile_nav_link-text">{{ item.label }}</span>
          </a>
        </div>

      <ng-template let-link #mobileRoute>
        <a class="mobile_nav_link"
           routerLinkActive="mobile_nav_link-active"
           [attr.rel.nofollow]="link?.nofollow"
           [routerLink]="link?.link"
           [attr.id]="link?.id"
           (click)="closeMobileMenu()">
          <span class="mobile_nav_link-text">{{ link.label }}</span>
        </a>
      </ng-template>

      <ng-template let-link #mobileHrefLink>
        <a [attr.href]="link.link"
           [attr.rel]="link.nofollow ? 'nofollow' : null" [attr.id]="link?.id"
           class="mobile_nav_link"
           target="_blank"
           (click)="closeMobileMenu()">
          <span class="mobile_nav_link-text">{{ link.label }}</span>
        </a>
      </ng-template>

    </nav>

    <ng-container *ngIf="isShownLanguageSwitcher$ | async">
      <app-language-switcher *ngIf="isFrenchMarket"
                             [isMobileView]="true"
                             class="mobile-switcher">
      </app-language-switcher>
    </ng-container>

    <div class="info-block" *ngIf="data.bubble" (click)="goToRegister()">
      <app-icon [icon]="data.bubble.icon" size="x-large" type="bubble" theme="green"></app-icon>
      <div class="info-block_content">
        <div class="info-block_title">{{ data.bubble.title }}</div>
        <p class="info-block_text text-small">{{ data.bubble.text }}</p>
      </div>
      <div class="info-block_info">{{ data.bubble.description }}</div>
    </div>
    <div class="figures"></div>
  </div>
</ng-container>
