import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../button/button.module';
import { BurgerMenuComponent } from './burger-menu.component';
import { IconModule } from '@kit/icon/icon.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    BurgerMenuComponent,
  ],
  exports: [
    BurgerMenuComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    IconModule,
    RouterModule
  ]
})
export class BurgerMenuModule { }
