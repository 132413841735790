import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Inject, Renderer2 } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { LumiWidgetService } from '@kit/lumi-widget/service/lumi-widget.service';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, EMPTY, interval, map, takeWhile } from 'rxjs';
import { DialogService } from '@kit/dialog/dialog.service';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { TranslateService } from '@ngx-translate/core';
import { WindowRef } from '@common/window-service/window.service';
import { Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';

@Component({
  selector: 'app-lumi-symptom-checker',
  templateUrl: './lumi-symptom-checker.component.html',
  styleUrls: ['./lumi-symptom-checker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LumiSymptomCheckerComponent {
  private widgetPath: string = environment.lumi.widgetPath;

  constructor(
    private readonly lumiWidgetService: LumiWidgetService,
    private readonly renderer: Renderer2,
    private readonly destroyRef: DestroyRef,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly bootstrap: BootstrapService,
    private readonly windowRef: WindowRef,
    private readonly elementRef: ElementRef,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  ngAfterViewInit(): void {
    this.lumiWidgetService.getLumiToken$()
      .pipe(
        catchError(() => {
          this.showErrorPopup();

          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((token: any) => {
        const scriptLink = this.document.getElementById('lumiScript');

        this.setIframeStyle();

        if (scriptLink) {
          this.lumiWidgetService.initWidget('symptom-checker', 'symptom-checker', token);

          return;
        }

        const scriptLinkElement: HTMLScriptElement = this.loadScript(`${this.widgetPath}/assets/js/lumi_widget.js`);

        scriptLinkElement.onload = (() => {
          this.lumiWidgetService.initWidget('symptom-checker', 'symptom-checker', token);
          // @ts-ignore
          this.windowRef.nativeWindow.window.lumi.onError = () => this.showErrorPopup();
        });
        scriptLinkElement.onerror = () => this.showErrorPopup();
      });
  }

  private loadScript(path: string): HTMLScriptElement {
    const script = this.renderer.createElement('script');

    script.type = 'text/javascript';
    script.src = path;
    script.id = 'lumiScript';
    this.renderer.appendChild(this.document.body, script);

    return script;
  }

  private showErrorPopup(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      info: this.translateService.instant('pages.LUMI_WIDGET.ERROR.SERVICE_UNAVAILABLE'),
      confirmTitle: 'OK',
    }).afterClosed$.subscribe( () => this.router.navigateByUrl(this.bootstrap.link.symptomChecker));
  }

  private setIframeStyle(): void {
    interval(300)
      .pipe(
        map(() => {
          const iFameLink = this.elementRef.nativeElement.getElementsByTagName('iFrame')[0];

          if (!iFameLink) return true;

          this.renderer.setStyle(iFameLink, 'border', '0px');
          this.renderer.setStyle(iFameLink, 'border-radius', '15px');

          return false;
        }),
        takeWhile(Boolean),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe()
  }
}
