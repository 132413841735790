<div *ngIf="cotravelersList?.length; else emptyList" class="filled-list">
  <header class="header">
    <div class="header__main">
      <div class="shell">
        <div class="header__brief">
          <h1 class="brief__title h1 m-h2">
            {{ 'pages.CO_TRAVELERS.FAMILY_AND_FRIENDS' | translate }}
          </h1>
          <app-button id="action-cotraveler-add" [appAemRouterLink]="bootstrapService.link.addCotraveler">
            {{ 'pages.CO_TRAVELERS.ACTIONS.ADD' | translate }}
          </app-button>
        </div>
      </div>
    </div>
  </header>
  <main class="main">
    <section class="section">
      <div class="section__shell shell">
        <div class="cards">
          <div class="cards_item" *ngFor="let cotraveler of cotravelersList">
            <app-icon type="bubble"
                      icon="person-add"
                      size="large"
                      theme="electric">
            </app-icon>
            <p class="section__text t-small">
              {{cotraveler.name}} {{cotraveler.surname}}
            </p>
            <app-context-menu class="context-menu">
              <div [appAemRouterLink]="bootstrapService.link.editCotraveler" [aemRouterData]="{ cotravelerId: cotraveler.id }" class="context-menu_item t-btn-normal m-t-btn-normal">
                {{ 'pages.CO_TRAVELERS.ACTIONS.EDIT' | translate }}
              </div>
              <div (click)="delete(cotraveler)" class="context-menu_item warning-text t-btn-normal m-t-btn-normal">
                {{ 'pages.CO_TRAVELERS.ACTIONS.DELETE' | translate }}
              </div>
            </app-context-menu>
          </div>
        </div>
        <div class="section_info">
          <h5 class="section__title h5 m-h2">
            {{ 'pages.CO_TRAVELERS.HEADER_INFORMATION' | translate }}
          </h5>
          <p class="section__text t-large m-t-normal">
            {{ 'pages.CO_TRAVELERS.INFORMATION' | translate }}
          </p>
        </div>
      </div>
    </section>
  </main>
</div>

<ng-template #emptyList>
  <div class="empty-list">
    <header class="header">
      <div class="header__main">
        <div class="shell">
          <h1 class="hero-title header__brief">
            {{ 'pages.CO_TRAVELERS.FAMILY_AND_FRIENDS' | translate }}
          </h1>
        </div>
      </div>
    </header>
    <main class="main">
      <section>
        <div class="section__shell shell">
          <div class="cards">
            <div class="cards_item premium-block">
              <div class="cards_header">
                <h5 class="section__title h5 m-h3">
                  {{ 'pages.CO_TRAVELERS.ADD_CO_TRAVELER.TITLE' | translate }}
                </h5>
              </div>
              <app-safe-html class="section__text t-large m-t-normal" [content]="'pages.CO_TRAVELERS.ADD_CO_TRAVELER.DESCRIPTION' | translate">
              </app-safe-html>
              <app-button id="action-cotraveler-add" class="cards_item_action" [appAemRouterLink]="bootstrapService.link.addCotraveler">
                {{ 'pages.CO_TRAVELERS.ACTIONS.ADD' | translate }}
              </app-button>
            </div>
            <div class="cards_item">
              <div class="cards_header">
                <h5 class="section__title h5 m-h2">
                  {{ 'pages.CO_TRAVELERS.HEADER_INFORMATION' | translate }}
                </h5>
              </div>
              <p class="section__text t-large m-t-normal">
                {{ 'pages.CO_TRAVELERS.EMPTY_INFORMATION' | translate }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</ng-template>
