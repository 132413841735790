<div class="input form-field"
     [class.form-field_focused]="focused"
     [class.form-field_disabled]="disable">
  <app-icon *ngIf="icon"
            class="input_icon"
            size="large"
            [icon]="icon"
            theme="lighten">
  </app-icon>
  <input class="input_field text-small"
         [placeholder]="placeholder"
         [formControl]="innerControl"
         [attr.maxlength]="maxLength"
         [attr.autocomplete]="autocomplete"
         [attr.name]="inputName"
         (focus)="onFocus()"
         (blur)="onBlur()">
  <app-icon *ngIf="!disable && clearButton && innerControl.value"
            class="input_icon input_clear"
            size="large"
            icon="close"
            (click)="clear()"
            theme="lighten">
  </app-icon>
</div>
