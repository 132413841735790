import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { Breadcrumb } from './breadcrumbs.interface';
import { LinkingDataService } from "@common/seo/linking-data.service";
import { LanguageService } from '@common/language/language.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit {
  public breadcrumbs: Breadcrumb[];
  public homeRoute = this.languageService.isVisaEnUrl() ? '/en-fr' : '/';

  public get previousPage(): Breadcrumb {
    return this.breadcrumbs[this.breadcrumbs.length - 2] || { label: this.translateService.instant('global.BREADCRUMB_HOME'), url: this.homeRoute };
  }

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private bootstrapService: BootstrapService,
    private linkingDataService: LinkingDataService,
    private languageService: LanguageService,
  ) { }

  ngOnInit(): void {
    this.breadcrumbs = this.initBreadcrumbs(this.route.firstChild?.routeConfig?.data?.uuid);
    this.linkingDataService.updateBreadcrumbsStructureData(this.breadcrumbs)
  }

  private initBreadcrumbs(uuid: string, breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    if (uuid) {
      const data = this.bootstrapService.breadcrumbs[uuid];
      if (data && data.parent) {
        const breadcrumb = {
          label: data.label,
          url: data.link
        };
        breadcrumbs.unshift(breadcrumb);
      }

      return this.initBreadcrumbs(data?.parent, breadcrumbs);
    }

    return breadcrumbs;
  }
}
