<app-trip-hero-block (goBack)="goBack()">
  <div class="edit-hotel_form" [formGroup]="form">
    <app-radio-button *ngFor="let item of flights" [value]="item" formControlName="flight">
      <h6 class="edit-hotel_form-title">
        {{ 'forms.FLIGHT.SELECTOR.FLIGHT_TO' | translate: { arrivalName: item.arrival.country.name, arrivalCity: item.arrival.city, departureName: item.departure.country.name, departureCity: item.departure.city } }}
      </h6>
      <p class="edit-hotel_form-text text-small">
        {{ 'forms.FLIGHT.SELECTOR.DEPARTURE_DATE' | translate }}
        <b>{{ item.departure.date | dateFormat }} {{ $any(item.departure.time) | dateFormat : 'time' }}</b>
      </p>
      <p class="edit-hotel_form-text text-small">
        {{ 'forms.FLIGHT.SELECTOR.ARRIVAL_DATE' | translate }}
        <b>{{ item.arrival.date | dateFormat }} {{ $any(item.arrival.time) | dateFormat : 'time' }}</b>
      </p>
    </app-radio-button>

    <button [disabled]="isButtonDisable$ | async" (click)="onSave()" id="action-flight-selected" class="button">
      {{ 'forms.FLIGHT.ACTIONS.SELECT_THIS_FLIGHT' | translate }}
    </button>
    <app-safe-html *ngIf="'forms.FLIGHT.ACTIONS.ADD_FLIGHT_MANUALLY_HREF' | translate: { tripId: tripId } as content"
                   [content]="content"
                   class="m-t-normal add-manually">
    </app-safe-html>
  </div>
</app-trip-hero-block>
