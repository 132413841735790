import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '../aem-button/aem-button.module';
import { AemAdditionalServicesComponent } from './aem-additional-services.component';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';

@NgModule({
  declarations: [AemAdditionalServicesComponent],
  exports: [AemAdditionalServicesComponent],
    imports: [
        CommonModule,
        AemButtonModule,
        SafeHtmlModule,
        ImgAltDirectiveModule,
    ]
})
export class AemAdditionalServicesModule { }
