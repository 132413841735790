<div class="shell">
  <div class="mobile-app-cards__content">

    <div class="mobile-app-cards__title mobile-app-cards__title__mobile">
      <div *ngIf="data.title"
           class="h4">
        {{ data.title }}
      </div>
      <div *ngIf="data.description" class="t-normal t-m-normal">
        <app-safe-html [content]="data.description"></app-safe-html>
      </div>
    </div>

    <img *ngIf="data.image.imagePath"
         [src]="data.image.imagePath"
         [appImgAlt]="data.image?.altText"
         [appImgAltData]="data"
         class="mobile-app-cards__image">
    <div *ngIf="data.cards"
         class="mobile-app-content__container">
      <div class="mobile-app-cards__title">
        <div *ngIf="data.title"
             class="h4 ">
          {{ data.title }}
        </div>
        <div *ngIf="data.description" class="t-normal t-m-normal">
          <app-safe-html [content]="data.description"></app-safe-html>
        </div>
      </div>
      <div class="mobile-app-cards__container">
        <div *ngFor="let card of data.cards"
             class="mobile-app-card">
          <app-icon [icon]="card.icon"
                    theme="inherit"
                    size="x-large">
          </app-icon>
          <app-safe-html [content]="card.text"
                         class="mobile-app-card__text">
          </app-safe-html>
        </div>
      </div>
      <div class="mobile-app-content__buttons">
        <div class="mobile-app-content__button">
          <a *ngIf="data.appleApplicationLink"
             [href]="data.appleApplicationLink"
             target="_blank"
             id="apple-app-link">
            <img [src]="appleStoreImagePath"
                 class="mobile-app-content__button-apple">
          </a>
        </div>
        <div class="mobile-app-content__button">
          <a *ngIf="data.androidApplicationLink"
             [href]="data.androidApplicationLink"
             target="_blank"
             id="google-app-link">
            <img [src]="googlePlayImagePath"
                 class="mobile-app-content__button-google">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
