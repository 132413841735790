import { CoTraveler } from "../profile/profile.interfaces";
import { Country } from "@pages/trip/trip.interfaces";

export interface FlightInfo {
  departure: DestinationFlightInfo;
  arrival: DestinationFlightInfo;
  flightId: number;
  flightNumber: string;
  reservationNumber?: string;
  coTravelers?: CoTraveler[];
  monitoredByCollinson: boolean;
  lessThan24hBeforeDeparture: boolean;
  manual?: boolean;
  flightStatus?: FlightStatus;
  baggageCarouselNumber?: string;
  checkInNumber?: string;
  flightFieldsOld?: {
    flightId?: number;
    departure?: string;
    arrival?: string;
    departureAirportCode?: string;
    arrivalAirportCode?: string;
    departureTerminal?: string;
    arrivalTerminal?: string;
    departureGate?: string;
    arrivalGate?: string;
    departureCity?: string;
    destinationCity?: string;
    baggageCarouselNumber?: string;
    checkInNumber?: string;
    departureCountry?: {
      countryId: number;
      name: string;
      code: string;
    };
    arrivalCountry?: {
      countryId: number;
      name: string;
      code: string;
    };
  };
  created?: string;
  lastChange?: string;
}

export interface DestinationFlightInfo {
  country: Country;
  city: string;
  date: string;
  time: string;
  airportCode: string;
  terminal?: string;
  gate?: string
}

export interface FlightByNumberOptions {
  flightNumber: string;
  departure: string;
}

export interface Airport {
  id?: number;
  code: string;
  city: string;
  name?: string;
  countryName?: string;
  countryCode?: string;
}

export enum FlightStatus {
  untracked = 'IS_UNTRACKED',
  delayed = 'IS_DELAYED',
  early = 'IS_EARLY',
  canceled = 'IS_CANCELLED',
  scheduled = 'IS_SCHEDULED',
  rescheduled = 'IS_RESCHEDULED',
}
