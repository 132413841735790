import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { getCountryCode } from "@common/language/language.const";
import { DOCUMENT } from "@angular/common";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ConfirmationDialogComponent } from "@kit/dialog/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogData } from "@kit/dialog/confirmation-dialog/confirmation-dialog.interface";
import { DialogService } from "@kit/dialog/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { DIALOG_REF } from "@kit/dialog/dialog.const";
import { DialogRef } from "@kit/dialog/dialog.interfaces";
import { TuiDestroyService } from "@taiga-ui/cdk";
import { switchMap } from "rxjs";
import { NotificationService } from "@pages/account/components/notification/notification.service";
import { MarketingPartner } from "@common/profile/profile.interfaces";
import { UserService } from "@common/user/user.service";

@Component({
  selector: 'app-marketing-dialog',
  templateUrl: './marketing-dialog.component.html',
  styleUrls: ['./marketing-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class MarketingDialogComponent implements OnInit, AfterViewInit {
  public isMarketingConsentGlobal = false;
  public form: UntypedFormGroup;

  private isSubmitAction = false;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly destroy$: TuiDestroyService,
    private readonly notificationService: NotificationService,
    private readonly userService: UserService,
    @Inject(DIALOG_REF) private readonly dialogRef: DialogRef<MarketingDialogComponent>,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  public ngOnInit(): void {
    this.initMarketingConsentType();
    this.initForm();
    this.dialogCloseActionSubscription();
  }

  public ngAfterViewInit(): void {
    this.configMarketingConsentPopInLinks();
  }

  public close(): void {
    this.isSubmitAction = true;
    this.dialogRef.close();
  }

  private initMarketingConsentType(): void {
    const market = getCountryCode(this.document.location.host);

    this.isMarketingConsentGlobal = ['DE', 'NL', 'AT'].includes(market);
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      marketingManagementGlobal: [false],
      marketingManagementInternal: [false],
      marketingManagementExternal: [false],
    });
  }

  private configMarketingConsentPopInLinks(): void {
    const oneTrustLink = this.document?.getElementById('mark-description');

    oneTrustLink?.setAttribute('style', 'text-decoration: underline');
    oneTrustLink?.addEventListener('click', (event: MouseEvent) => this.openMarketingPopIn(event));
  }

  private openMarketingPopIn(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      info: this.translateService.instant('pages.REGISTRATION.MARKETING_MANAGEMENT_POP_IN'),
      confirmTitle: 'OK',
    });
  }

  private dialogCloseActionSubscription(): void {
    this.destroy$.pipe(
      switchMap(() => {
        return this.notificationService.saveNotificationSettings$(
          this.notificationService.getMarketingSettings(this.form, this.isMarketingConsentGlobal, !this.isSubmitAction),
          MarketingPartner.ALLYZ,
        );
      }),
    ).subscribe();
  }
}
