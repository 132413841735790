import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { LanguageService } from '@common/language/language.service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForbiddenComponent implements OnInit {
  public routerLink = '/'

  constructor(
    private languageService: LanguageService,
  ) {}

  public ngOnInit(): void {
    if (this.languageService.isVisaEnUrl()) {
      this.routerLink = '/en-fr/';
    }
  }
}
