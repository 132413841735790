import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { MobileAppContentCardsComponent } from './mobile-app-content-cards.component';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { IconModule } from '@kit/icon/icon.module';

@NgModule({
  declarations: [MobileAppContentCardsComponent],
  exports: [MobileAppContentCardsComponent],
  imports: [CommonModule, AemButtonModule, SafeHtmlModule, ImgAltDirectiveModule, IconModule]
})
export class MobileAppContentCardsModule {}
