import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageMappingURLService } from '@common/language-mapping-url/language-mapping-url.service';
import { LanguageService } from '@common/language/language.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LanguageSwitcherService {

  public currentLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.languageService.getCurrentLanguage().toUpperCase()
  );

  constructor(
    private readonly languageService: LanguageService,
    private readonly languageMappingURLService: LanguageMappingURLService,
    private readonly activatedRoute: ActivatedRoute,
  ) { }

  public updateLanguage(lang: string): void {
    this.currentLanguage$.next(lang);
  }

  public getNewRoute(newLanguage: string): string | undefined {
    return this.languageMappingURLService.getMappedRoute(
      newLanguage.toLowerCase(),
      this.activatedRoute.firstChild.snapshot
    );
  }

  public navigateToNewRoute(newLanguage: string): void {
    const location: Location = window.location;
    const baseUrl: string = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}`;

    if(this.getNewRoute(newLanguage)) {
      const url: string = baseUrl + this.getNewRoute(newLanguage) || baseUrl;

      history.pushState(history.state, '', url);
      window.open(url, '_self');
    }
  }
}
