import { ChangeDetectorRef, EventEmitter, Host, Inject, Optional, Output, QueryList } from '@angular/core';
import { Directive } from '@angular/core';
import { AccordionComponent } from './accordion.component';

@Directive({ selector: '[appAccordionContainer]' })
export class AccordionDirective {
  @Output() extendedChange = new EventEmitter<boolean>();

  constructor(
    @Host() @Optional() @Inject(AccordionComponent) private items: QueryList<AccordionComponent>,
    private cdr: ChangeDetectorRef
  ) { }

  closeAll(isOpen: boolean) {
    this.extendedChange.emit(isOpen);

    if (this.items?.length) this.items?.forEach(panel => panel.close());

    this.cdr.markForCheck();
  }
}
