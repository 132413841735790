<div class="insurance-content">
  <div class="info-container">
    <h1 class="h5 m-h4 zero-margin">
      {{ 'forms.BOOKING_PATH.TRAVELERS_DETAILS.INSURANCE.TITLE' | translate }}
    </h1>

    <div class="info-group">
      <app-accordion size="child" theme="insurance" class="full-width">
        <ng-container accordionTitle>
          <h6 class="zero-margin h6">{{ currentPlan?.productOfferingName }}</h6>
        </ng-container>
        <ng-container accordionTitleRight>
          <div class="price h6">
            {{ currentPlan.recommendedPremium?.grossPremium?.amount | appCurrency: currentPlan.recommendedPremium?.grossPremium?.currency }}
          </div>
        </ng-container>

        <div class="insurance-info">
          <span class="weight-bold h6 m-h4">{{ 'forms.BOOKING_PATH.TRAVELERS_DETAILS.INSURANCE.DESTINATION' | translate }}</span>
          <span class="t-large m-t-normal insurance-info_value"> {{ destinations }} </span>
          <span class="weight-bold h6 m-h4">{{ 'forms.BOOKING_PATH.TRAVELERS_DETAILS.INSURANCE.START_DATE' | translate }}</span>
          <span class="t-large m-t-normal insurance-info_value"> {{ bookingPathData?.tripDetails?.startDate | dateFormat }} </span>
          <span class="weight-bold h6 m-h4">{{ 'forms.BOOKING_PATH.TRAVELERS_DETAILS.INSURANCE.END_DATE' | translate }}</span>
          <span class="t-large m-t-normal insurance-info_value"> {{ bookingPathData?.tripDetails?.endDate | dateFormat }} </span>
          <a class="edit-button" (click)="onEditClick()">{{ 'forms.BOOKING_PATH.TRAVELERS_DETAILS.INSURANCE.EDIT_BUTTON' | translate }}</a>
        </div>
      </app-accordion>
    </div>
  </div>

  <div class="info-container"
       *ngFor="let travelerForm of travelersArray.controls; let index = index">
    <div class="traveler-header">
      <app-icon icon="person" theme="white" size="inherit"></app-icon>
      <div class="h5 m-h5">
        {{ 'forms.BOOKING_PATH.TRAVELERS_DETAILS.TRAVELERS.INFO' | translate: {number: index + 1, age: travelerForm.value?.dateOfBirth | age} }}
      </div>
    </div>
    <div class="info-group_header">
      {{ 'forms.BOOKING_PATH.TRAVELERS_DETAILS.TRAVELERS.DATE_OF_BIRTH' | translate }} {{ travelerForm.value?.dateOfBirth | dateFormat: 'fullSpelled' }}
    </div>
    <div class="info-group" [formGroup]="$any(travelerForm)">
      <app-label [label]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.TRAVELERS.LABELS.FIRST_NAME' | translate" [required]="true" class="input-field-half">
        <app-input [placeholder]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.TRAVELERS.PLACEHOLDERS.FIRST_NAME' | translate" [maxLength]="79" formControlName="firstName"></app-input>
        <app-field-error controlName="firstName"></app-field-error>
      </app-label>
      <app-label [label]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.TRAVELERS.LABELS.LAST_NAME' | translate" [required]="true" class="input-field-half">
        <app-input [placeholder]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.TRAVELERS.PLACEHOLDERS.LAST_NAME' | translate" [maxLength]="79" formControlName="lastName"></app-input>
        <app-field-error controlName="lastName"></app-field-error>
      </app-label>
    </div>
  </div>

  <div class="info-container" [formGroup]="policyForm">
    <div class="traveler-header">
      <app-icon icon="shield" theme="white" size="inherit"></app-icon>
      <div class="h5 m-h5">{{ 'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.TITLE' | translate }}</div>
    </div>

    <div class="info-group">
      <app-label [label]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.LABELS.FIRST_NAME' | translate" [required]="true" class="input-field-half">
        <app-input [placeholder]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.PLACEHOLDERS.FIRST_NAME' | translate" [maxLength]="79" formControlName="firstName"></app-input>
        <app-field-error controlName="firstName"></app-field-error>
      </app-label>
      <app-label [label]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.LABELS.LAST_NAME' | translate" [required]="true" class="input-field-half">
        <app-input [placeholder]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.PLACEHOLDERS.LAST_NAME' | translate" [maxLength]="79" formControlName="lastName"></app-input>
        <app-field-error controlName="lastName"></app-field-error>
      </app-label>
    </div>

    <div class="info-group">
      <app-label [label]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.LABELS.DATE_OF_BIRTH' | translate" [required]="true"
        class="input-field-half">
        <app-date-picker formControlName="dateOfBirth" [min]="birthDateLimits.from" [max]="birthDateLimits.to"></app-date-picker>
        <app-field-error controlName="dateOfBirth"></app-field-error>
      </app-label>
      <app-label [label]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.LABELS.PHONE_NUMBER' | translate" [required]="true" class="input-field-half">
        <app-phone [placeholder]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.PLACEHOLDERS.PHONE_NUMBER' | translate" formControlName="phoneNumber"></app-phone>
        <app-field-error controlName="phoneNumber"></app-field-error>
      </app-label>
    </div>

    <div class="info-group">
      <app-label [label]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.LABELS.STREET_ADDRESS' | translate" [required]="true">
        <app-input [placeholder]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.PLACEHOLDERS.STREET_ADDRESS' | translate" [maxLength]="128" formControlName="address"></app-input>
        <app-field-error controlName="address"></app-field-error>
      </app-label>
      <app-label [label]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.LABELS.CITY' | translate" [required]="true">
        <app-input [placeholder]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.PLACEHOLDERS.CITY' | translate" [maxLength]="128" formControlName="city"></app-input>
        <app-field-error controlName="city"></app-field-error>
      </app-label>
    </div>

    <div class="info-group">
      <app-label [label]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.LABELS.POSTCODE' | translate" [required]="true" class="input-field-half">
        <app-input [placeholder]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.PLACEHOLDERS.POSTCODE' | translate" [maxLength]="10" formControlName="postCode"></app-input>
        <app-field-error controlName="postCode"></app-field-error>
      </app-label>
      <app-label [label]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.LABELS.COUNTRY' | translate" [required]="true" class="input-field-half">
        <app-search-select formControlName="residence"
                           viewKey="name"
                           valueKey="code"
                           [clearButton]="true"
                           [options]="residenceCountries$ | async">
        </app-search-select>
        <app-field-error controlName="residence"></app-field-error>
      </app-label>
    </div>

    <div class="info-group">
      <app-label [label]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.LABELS.EMAIL' | translate" [required]="true" class="full-width">
        <app-input [placeholder]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.PLACEHOLDERS.EMAIL' | translate" formControlName="email"></app-input>
        <app-field-error controlName="email"></app-field-error>
      </app-label>
    </div>

    <div class="info-group">
      <app-checkbox id="register-privacy-policy" formControlName="termsAccept" class="full-width">
        <app-safe-html [content]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.LABELS.TERMS_AND_CONDITIONS' | translate: {
          termsLink: termsDocument?.documentInfo?.url,
          iddLink: distributionDocument?.documentInfo?.url
        }"></app-safe-html>
      </app-checkbox>
      <app-field-error controlName="termsAccept" class="full-width"></app-field-error>
    </div>

    <div class="info-group">
      <app-checkbox id="register-privacy-policy" formControlName="marketingAccept" class="full-width">
        <app-safe-html [content]="'forms.BOOKING_PATH.TRAVELERS_DETAILS.POLICY_HOLDER.LABELS.MARKETING_ACCEPT' | translate"></app-safe-html>
      </app-checkbox>
      <app-field-error controlName="marketingAccept" class="full-width"></app-field-error>
    </div>
    
    <app-button type="full-width" (click)="onProceed()">{{ 'forms.BOOKING_PATH.TRAVELERS_DETAILS.PROCEED_BUTTON' | translate }}</app-button>
  </div>
</div>