import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { BootstrapService } from "@common/bootstrap/bootstrap.service";
import { ActivityCategoryConfiguration } from "@common/model/activityCategoryConfiguration";
import { Activity } from '../../../../activity-element/activity-element.interface';
import { TripDetails } from '@pages/trip/trip.interfaces';
import { ActivatedRoute } from "@angular/router";
import { DialogService } from "@kit/dialog/dialog.service";
import { TuiDestroyService } from "@taiga-ui/cdk";
import { ActivityElementService } from "@pages/trip/components/activity-element/activity-element.service";
import { TranslateService } from "@ngx-translate/core";
import { filter, switchMap, takeUntil } from "rxjs";
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';

@Component({
  selector: 'app-trip-planner-activity',
  templateUrl: './trip-planner-activity.component.html',
  styleUrls: ['./trip-planner-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})

export class TripPlannerActivityComponent {
  @Input() currentTrip: TripDetails;
  @Input() activity: Activity;
  @Input() boundUpdateCurrentTrip: Function;

  public activityConfiguration = this.bootstrapService.activityConfiguration;
  public readonly defaultActivityConfiguration: ActivityCategoryConfiguration = {
    code: null,
    icon: 'activities',
    images: [{ imagePath: '/assets/img/default/activity.webp', altText: '', _type: '' }]
  }

  constructor (
    public readonly bootstrapService: BootstrapService,
    private readonly route: ActivatedRoute,
    private readonly dialogService: DialogService,
    private readonly onDestroy$: TuiDestroyService,
    private readonly activityElementService: ActivityElementService,
    private readonly translateService: TranslateService,
  ) {}

  public deleteActivity(activity: Activity): void {
    const { tripId } = this.route.firstChild.snapshot.params;

    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      title: this.translateService.instant('global.CONFIRMATION_DIALOG.TITLES.CONFIRMATION'),
      message: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DELETE_ACTIVITY'),
      info: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DISCLAIMER_DELETE'),
      cancelTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.CANCEL'),
      confirmTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.DELETE'),
      reverseButtonPosition: true
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.activityElementService.delete(tripId, activity.activityId)),
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.boundUpdateCurrentTrip());
  }
}
