import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { catchError, takeUntil } from 'rxjs/operators';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { TripHero } from 'src/app/common/model/tripHero';
import { wrapValidator } from 'src/app/kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { notBlankValidator } from '@kit/utils/validators';
import { ActivityElementService } from '../activity-element.service';
import { mapActivitySourceToDto } from './add-activity-element.mapper';
import { getDate } from '@kit/utils/date.utils';
import { ActivityDto } from "@pages/trip/components/activity-element/activity-element.interface";
import { Observable } from "rxjs";
import { getAllFormErrors } from "@kit/utils/form";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-activity-element',
  templateUrl: './add-activity-element.component.html',
  styleUrls: ['./add-activity-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TuiDestroyService,
    ServerErrorsValidationService,
  ],
})
export class AddActivityElementComponent extends AemFragmentComponent implements OnInit {
  form = this.formBuilder.group({
    name: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
        wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
        wrapValidator(Validators.maxLength(35), 'errors.CEAZ000_Size'),
      ],
      this.validationService.createValidator('name'),
    ],
    location: [
      null,
      wrapValidator(Validators.maxLength(35), 'errors.CEAZ000_Size'),
      this.validationService.createValidator('location'),
    ],
    start: [
      null,
      wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      this.validationService.createValidator('startDate'),

    ],
    hour: [null, null, this.validationService.createValidator('startTime')],
    activityCategory: [
      null,
      wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      this.validationService.createValidator('activitySubcategory')
    ],
    bookingReference: [
      null,
      wrapValidator(Validators.maxLength(15), 'errors.CEAZ000_Size'),
      this.validationService.createValidator('bookingReference')
    ],
  });

  optionsCategory$ = this.activityElementService.getActivityCategory();
  tripId = this.route.firstChild.snapshot.params.tripId;

  constructor(
    private bootstrapService: BootstrapService,
    private formBuilder: UntypedFormBuilder,
    private activityElementService: ActivityElementService,
    private validationService: ServerErrorsValidationService,
    private onDestroy$: TuiDestroyService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly analyticsService: AnalyticsService,
    @Inject(AEM_DATA) public aemData: TripHero,
  ) {
    super(aemData);
  }

  ngOnInit(): void {
    this.form.addAsyncValidators(this.validationService.createValidator());
  }

  goBack() {
    this.router.navigate(
      [setRouterData(this.bootstrapService.link.element, { tripId: this.tripId })],
      { queryParamsHandling: 'preserve' },
    );
  }

  onCreate() {
    this.form.markAllAsTouched();

    if (getAllFormErrors(this.form)?.length) {
      this.analyticsService.validationError(this.form, this.constructor.name);
      return;
    }

    const activity: ActivityDto = mapActivitySourceToDto(this.form.value);

    this.activityElementService.create(this.tripId, activity).pipe(
      catchError(err => this.handleServerError(err)),
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      this.sentAnalytics(activity);
      this.goBack();
    });
  }

  private handleServerError(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    return this.validationService.handleServerError(err?.error);
  }

  private sentAnalytics(activity: ActivityDto): void {
    this.analyticsService.triggerAction({
      category: 'travel_wallet',
      action: 'activityAdded',
      label: `${getDate(new Date(activity.startDate))}_${activity.name}`,
      value: 1,
    });
  }
}
