import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SEOAlternateHREFLANG } from '@common/model/sEOAlternateHREFLANG';
import { IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';
import { catchError, map, Observable, of } from 'rxjs';
import { RouterDataResolver } from './router.data.resolver';
import { getAEMAlternatesPath } from './routing.const';

@Injectable({
  providedIn: 'root',
})
export class RouterAlternatesResolver  {
  constructor(
    private http: HttpClient,
    @Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<SEOAlternateHREFLANG[]> {
    const routeAemConfig = RouterDataResolver.dataUrls.get(route.routeConfig.path);
    if (!this.isServer || !routeAemConfig.dataPath) {
      return of([]);
    }

    return this.http.get<{ data: SEOAlternateHREFLANG[] }>(`${getAEMAlternatesPath()}/${routeAemConfig.uuid}`).pipe(
      map(alternates => alternates?.data),
      catchError(() => of([])),
    );
  }
}
