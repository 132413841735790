export enum EmergencyNumberType {
  FIRE_PHONE = 'fire_phone',
  AMBULANCE_PHONE = 'ambulance_phone',
  POLICE_PHONE = 'police_phone',
  EMERGENCY_PHONE = 'emergency_phone',
}

export interface EmergencyNumberDto {
  number: string;
  type: EmergencyNumberType;
}

export type EmergencyNumber = {[key in EmergencyNumberType]: string}