import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiBookingPathService } from './api-booking-path.service';
import { BookingPath, CompleteInsuranceInfo, QuotationResponse } from './booking-path.interfaces';

@Injectable({ providedIn: 'root' })
export class BookingPathService implements ApiBookingPathService {
  private readonly baseUrl = environment.api.baseUrl;
  public readonly localStorageKey = 'booking-path';

  constructor(
    private readonly http: HttpClient
  ) { }

  public getBookingPathForm(): Observable<BookingPath> {
    return this.http.get<BookingPath>(`${this.baseUrl}/booking-path`);
  }

  public getQuote(bookingForm: BookingPath): Observable<QuotationResponse> {
    return this.http.post<QuotationResponse>(`${this.baseUrl}/booking-path/insurance-quotation`, bookingForm);
  }
  
  public updateTravelersDetails(bookingForm: BookingPath): Observable<string> {
    return this.http.post<{ paymentPageUrl: string }>(`${this.baseUrl}/booking-path/travelers-details`, bookingForm).pipe(
      map(({ paymentPageUrl }) => paymentPageUrl)
    );
  }

  public selectPlan(bookingForm: BookingPath): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/booking-path/trip-plan`, bookingForm);
  }

  public commitContract(bookingForm: BookingPath): Observable<CompleteInsuranceInfo> {
    return this.http.post<CompleteInsuranceInfo>(`${this.baseUrl}/booking-path/committing-contract`, bookingForm);
  }

  public clearQuoteCache(): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/booking-path`);
  }
}
