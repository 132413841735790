import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AemRichTextComponent } from './aem-rich-text.component';
import { SafeHtmlModule } from "@kit/safe-html/safe-html.module";

@NgModule({
  declarations: [AemRichTextComponent],
  imports: [CommonModule, SafeHtmlModule],
})
export class AemRichTextModule {}
