import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { GradientCardsContentComponent } from './gradient-cards-content.component';

@NgModule({
  declarations: [GradientCardsContentComponent],
  exports: [GradientCardsContentComponent],
  imports: [CommonModule, AemButtonModule, SafeHtmlModule]
})
export class GradientCardsContentModule {}
