import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { map, Observable, pluck, takeUntil } from 'rxjs';
import { TripContentAndShapedImage } from '@common/model/tripContentAndShapedImage';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { endDatePassed } from '@kit/utils/date.utils';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { ActivityType } from '../../trip.const';
import { Activity, TripDetails } from '../../trip.interfaces';

@Component({
  selector: 'app-start-journey',
  templateUrl: './start-journey.component.html',
  styleUrls: ['./start-journey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class StartJourneyComponent extends AemBaseBlockComponent implements AfterViewInit {
  showJourneyBlock$: Observable<boolean> = this.route.firstChild.data.pipe(
    pluck('currentTrip'),
    map((trip: TripDetails) => !endDatePassed(trip.endDate) && !trip.actions?.some((activity: Activity) => activity.type === ActivityType.FLIGHT)),
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly elRef: ElementRef<HTMLElement>,
    private readonly destroy$: TuiDestroyService,
    @Inject(AEM_DATA) data: TripContentAndShapedImage,
  ) {
    super(data);
  }

  ngAfterViewInit(): void {
    this.showJourneyBlock$.pipe(takeUntil(this.destroy$))
      .subscribe((showBlock: boolean) => {
        if (!showBlock) {
          this.elRef.nativeElement.parentNode.removeChild(this.elRef.nativeElement);
        }
      });
  }
}
