import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CTALink } from '@common/model/cTALink';

const NOT_ROUTE_LINK_REGEXP = /^(mailto|tel|http|https):/;

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BurgerMenuComponent {
  @Input() public links: CTALink[];
  public displayMenu: boolean = false;
  public clickPositionX: 'left' | 'right' | '' = '';
  public clickPositionY: 'top' | 'middle' | 'bottom' | '' = '';

  constructor(
    private readonly elRef: ElementRef,
    private readonly router: Router
  ) { }

  @HostListener('document:click', ['$event'])
  clickHandler(event: MouseEvent): void {
    if(!this.elRef.nativeElement.contains(event.target)) {
      this.displayMenu = false;
    }
  }

  public toggleMenu(): void {
    this.displayMenu = !this.displayMenu;
  }

  public navigate(link: string): void {
    this.resetClickPositions();
    this.toggleMenu();

    NOT_ROUTE_LINK_REGEXP.test(link)
      ? window.open(link, '_self')
      : this.router.navigate([link]);
  }

  public toggleMenuAndAnimation(event: MouseEvent | TouchEvent, index: number): void {
    this.resetClickPositions();
    this.calculateClickPosition(event, index);
  }

  private calculateClickPosition(event: MouseEvent | TouchEvent, index: number): void {
    const buttonRect = this.elRef.nativeElement.querySelectorAll('.burger__item')[index].getBoundingClientRect();
    let relX = 0;
    let relY = 0;
    const pixelsForMiddleClick = 8;

    const buttonCenterX = buttonRect.width / 2;

    if (event.type === 'touchstart') {
      relX = (event as TouchEvent).touches[0].clientX - buttonRect.left;
      relY = (event as TouchEvent).touches[0].clientY - buttonRect.top;
    }

    if (event.type === 'mousedown') {
      relX = (event as MouseEvent).clientX - buttonRect.left;
      relY = (event as MouseEvent).clientY - buttonRect.top;
    }

    this.clickPositionX = (relX < buttonCenterX) ? 'left' : 'right';

    const buttonCenterY = buttonRect.height / 2;

    this.clickPositionY = 'middle';
    if (relY < buttonCenterY - pixelsForMiddleClick) this.clickPositionY = 'top';
    if (relY > buttonCenterY + pixelsForMiddleClick) this.clickPositionY = 'bottom';
  }

  private resetClickPositions(): void {
    this.clickPositionX = '';
    this.clickPositionY = '';
  }
}
