<a 
  *ngIf="!goBack.observed; else button"
  [appAemRouterLink]="link"
  >
  <ng-template [ngTemplateOutlet]="button"></ng-template>
</a>

<ng-template #button>
  <app-button 
      id="action-go-back"
      icon="arrow-left"
      type="icon-button"
      size="s"
      theme="white"
      (click)="onClick()"
  ></app-button>
</ng-template>