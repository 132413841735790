import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { DIALOG_DATA, DIALOG_REF } from '@kit/dialog/dialog.const';
import { DialogRef } from '@kit/dialog/dialog.interfaces';
import { CoTravelersListDialogData } from './co-travelers-list-dialog-data';

@Component({
  selector: 'app-co-travelers-list-dialog',
  templateUrl: './co-travelers-list-dialog.component.html',
  styleUrls: ['./co-travelers-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoTravelersListDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) public readonly data: CoTravelersListDialogData,
    @Inject(DIALOG_REF) private readonly dialog: DialogRef<CoTravelersListDialogComponent>,
    private readonly router: Router,
    private readonly bootstrapService: BootstrapService,
  ) { }

  public close(): void {
    this.dialog.close();
  }

  public editList(): void {
    this.router.navigate([setRouterData(
      this.bootstrapService.link.flightSelectCotravelers,
      {
        tripId: this.data.tripId,
        flightId: this.data.flightId,
      }
    )], { queryParams: { returnToTripWallet: true } });

    this.dialog.close();
  }
}
