import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { LinkingDataService } from 'src/app/common/seo/linking-data.service';
import { FAQCollapsible } from '@common/model/fAQCollapsible';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';

@Component({
  selector: 'app-faq-collapsible',
  templateUrl: './faq-collapsible.component.html',
  styleUrls: ['../faq-collapsible-group/faq-collapsible-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemFaqCollapsibleComponent extends AemFragmentComponent {
  constructor(
    private readonly structuredService: LinkingDataService,
    @Inject(AEM_DATA) public override data: FAQCollapsible,
  ) {
    super(data);
    this.structuredService.updateFAQStructuredData(data.items);
  }
}
