import { LocationStrategy } from '@angular/common';
import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DataParams, getAllRouterParams, setRouterData } from 'src/app/kit/utils/router.utils';

@Directive({
  selector: 'a[appAemRouterLink],area[appAemRouterLink]'
})
export class AemRouterLinkWithHrefDirective extends RouterLink implements OnInit, OnChanges {
  @Input() aemRouterData: DataParams;
  @Input() aemRouterQueryData: DataParams;
  @Input() appAemRouterLink: any[]|string|null|undefined;

  private activatedRoute: ActivatedRoute;
  private locationStrategyRef: LocationStrategy;
  private routerRef: Router;

  constructor(
    router: Router,
    route: ActivatedRoute,
    locationStrategy: LocationStrategy,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    super(router, route,undefined, renderer, elementRef, locationStrategy);
    this.routerRef = router;
    this.activatedRoute = route;
    this.locationStrategyRef = locationStrategy;
  }

  ngOnInit(): void {
    super.routerLink = this.fillData(this.appAemRouterLink);
    this.refreshHref();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.aemRouterData?.firstChange === false || changes.appAemRouterLink?.firstChange === false) {
      super.routerLink = this.fillData(this.appAemRouterLink);
      this.refreshHref();
    }
  }

  private fillData(commands: any[]|string|null|undefined) {
    const params = Object.assign(getAllRouterParams(this.activatedRoute.snapshot.root), this.aemRouterData);

    super.queryParams = this.aemRouterQueryData;

    return (Array.isArray(commands) ? commands : [commands])
      .map(command => setRouterData(command, params));
  }

  private refreshHref(): void {
    this.href = this.urlTree !== null ?
        this.locationStrategyRef.prepareExternalUrl(this.routerRef.serializeUrl(this.urlTree)) :
        null;
  }
}
