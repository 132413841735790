import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  LOCALE_ID,
  NgZone,
  OnInit,
  Renderer2,
  RendererStyleFlags2
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { debounceTime, filter, takeUntil } from 'rxjs';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    TuiDestroyService,
  ],
})
export class AppComponent implements OnInit {
  private currentScroll: number;

  @HostBinding('attr.id')
  id = 'app';

  @HostBinding('class.disable_scrolling')
  public disableScrolling: boolean;

  public viewInitialized = false;

  constructor(
    private readonly bootstrapService: BootstrapService,
    private readonly destroy$: TuiDestroyService,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    @Inject(IS_SERVER_PLATFORM) public readonly isServer: boolean,
    private readonly router: Router,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
  ) {}

  public setScroll(isOpen: boolean): void {
    if (this.isServer) {
      return;
    }

    if (isOpen) {
      this.currentScroll = document.scrollingElement.scrollTop;
      this.disableScrolling = isOpen;
    } else {
      this.disableScrolling = isOpen;

      this.zone.runOutsideAngular(() => {
        requestAnimationFrame(() => {
          document.scrollingElement.scrollTop = this.currentScroll;
        });
      });
    }
  }

  public ngOnInit(): void {
    this.document.documentElement.lang = this.localeId;
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.viewInitialized = true;
      });

    this.bootstrapService.theme$.pipe(
      debounceTime(0),
      takeUntil(this.destroy$),
    ).subscribe(theme => {
      const mainBackground = theme?.mainBackground || this.bootstrapService.defaultTheme?.mainBackground;
      const headerBackground = theme?.headerBackground || this.bootstrapService.defaultTheme?.headerBackground;
      const headerActiveCtaText = theme?.headerActiveCtaText || this.bootstrapService.defaultTheme?.headerActiveCtaText;
      const headerActiveCtaBackground = theme?.headerActiveCtaBackground || this.bootstrapService.defaultTheme?.headerActiveCtaBackground;

      this.renderer.setStyle(this.elementRef.nativeElement, '--main-background', mainBackground, RendererStyleFlags2.DashCase);
      this.renderer.setStyle(this.elementRef.nativeElement, '--header-primary', headerBackground, RendererStyleFlags2.DashCase);
      this.renderer.setStyle(this.elementRef.nativeElement, '--header-secondary-active', headerActiveCtaText, RendererStyleFlags2.DashCase);
      this.renderer.setStyle(this.elementRef.nativeElement, '--header-secondary-active-background', headerActiveCtaBackground, RendererStyleFlags2.DashCase);
    });
  }
}
