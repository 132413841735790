import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Step } from '@common/model/step';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepComponent implements OnInit {
  @Input() step: Step;
  @Input() showCounter: boolean;

  public backgroundColor: string;

  public ngOnInit(): void {
    this.backgroundColor = this.step.colourBackground || '#fff'
  }
}
