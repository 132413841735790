import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Airport, FlightByNumberOptions, FlightInfo } from './flight.interfaces';

@Injectable({ providedIn: 'root' })
export class ApiFlightService {
  private readonly baseUrl = environment.api.baseUrl;
  public readonly maxCotravelersCount = 5;

  constructor(
    private readonly http: HttpClient
  ) { }

  public getFlight(tripId: string, flightId: string): Observable<FlightInfo> {
    return this.http.get<FlightInfo>(`${this.baseUrl}/trips/${tripId}/flights/${flightId}`);
  }

  public updateFlight(tripId: number | string, flightId: string, flight: FlightInfo): Observable<FlightInfo> {
    return this.http.put<FlightInfo>(`${this.baseUrl}/trips/${tripId}/flights/${flightId}`, flight);
  }

  public addFlightToTrip(tripId: number | string, flight: FlightInfo): Observable<{ flightId: number }> {
    return this.http.post<{ flightId: number }>(`${this.baseUrl}/trips/${tripId}/flights`, flight);
  }

  public deleteFlight(tripId: number | string, flightId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/trips/${tripId}/flights/${flightId}`);
  }

  public checkValidFlight(tripId: number | string, options: FlightByNumberOptions): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/trips/${tripId}/flights/valid`, {
      params: {
        flightNumber: options.flightNumber,
        departure: new Date(options.departure).toISOString().slice(0, 10),
      },
    });
  }

  public checkFlightsLimit(): Observable<void> {
    return this.http.get<void>(`${this.baseUrl}/flights-limit-check`);
  }

  public getFlightByNumber(options: FlightByNumberOptions): Observable<FlightInfo[]> {
    return this.http.get<FlightInfo[]>(`${this.baseUrl}/gds-flights`, {
      params: {
        flightNumber: options.flightNumber,
        departure: new Date(options.departure).toISOString().slice(0, 10),
      },
    });
  }

  public getAirports(countryCode: string): Observable<Airport[]> {
    return this.http.get<Airport[]>(`${this.baseUrl}/airports/${countryCode.toUpperCase()}`);
  }

  public updateCotravelersList(tripId: number | string, flightId: string, ids: number[]): Observable<{ flightId: number }> {
    return this.http.put<{ flightId: number }>(`${this.baseUrl}/trips/${tripId}/flights/${flightId}/cotravelers`, ids);
  }

  public getFlightCompensation(flightNumber: string, departure: string): Observable<FlightInfo[]> {
    return this.http.get<FlightInfo[]>(`${this.baseUrl}/claim-check`, {
      params: {
        flightNumber,
        departure,
      }
    }).pipe(
      catchError((err) => {
        if (err.status === 404) {
          return throwError(() => ({
            error: {
              fault: {
                errors: [{
                  errorCode: 'CEAZ006',
                  fieldName: null,
                }]
              }
            }
          }));
        }

        return throwError(() => err);
      })
    );
  }
}
