<app-dialog-wrapper>
  <ng-container dialogContent>
    <div class="content">
      <div class="title h4 m-h0">{{ 'global.MARKETING_CAMPAIGN_DIALOG.TITLE' | translate }}</div>
      <div class="description t-small m-t-small">{{ 'global.MARKETING_CAMPAIGN_DIALOG.DESCRIPTION' | translate }}</div>
      <div class="separator"></div>
      <div class="t-small m-t-small">
        <ng-container *ngIf="isMarketingConsentGlobal; else marketingConsents">
          <div [formGroup]="form" class="body">
            <app-checkbox id="register-marketing-management-global"
                          formControlName="marketingManagementGlobal">
              <app-safe-html [content]="'pages.REGISTRATION.MARKETING_MANAGEMENT_GLOBAL' | translate"
                             class="t-small m-t-small">
              </app-safe-html>
            </app-checkbox>
          </div>
        </ng-container>
      </div>
      <app-button (click)="close()" type="full-width">
        {{ 'global.MARKETING_CAMPAIGN_DIALOG.BUTTON_TEXT' | translate }}
      </app-button>
    </div>
  </ng-container>
</app-dialog-wrapper>

<ng-template #marketingConsents>
  <div [formGroup]="form" class="body">
    <app-checkbox id="register-marketing-management-internal"
                  formControlName="marketingManagementInternal">
      <app-safe-html [content]="'pages.REGISTRATION.MARKETING_MANAGEMENT_INTERNAL' | translate"
                     class="t-small m-t-small">
      </app-safe-html>
    </app-checkbox>
    <app-checkbox id="register-marketing-management-external"
                  formControlName="marketingManagementExternal">
      <app-safe-html [content]="'pages.REGISTRATION.MARKETING_MANAGEMENT_EXTERNAL' | translate"
                     class="t-small m-t-small">
      </app-safe-html>
    </app-checkbox>
  </div>
</ng-template>
