import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AemVideoPlayerComponent } from './aem-video-player.component';

@NgModule({
  declarations: [AemVideoPlayerComponent],
  exports: [AemVideoPlayerComponent],
  imports: [CommonModule]
})
export class AemVideoPlayerModule {
}
