import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '../aem-button/aem-button.module';
import { TranslateModule } from '../translate/translate.module';
import { ThematicModule } from '../thematic/thematic.module';
import { ArticlesComponent } from './article.component';
import { AemRouterModule } from '../../common/routing/aem-router.module';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';

@NgModule({
  declarations: [ArticlesComponent],
  exports: [ArticlesComponent],
  imports: [
    CommonModule,
    AemButtonModule,
    TranslateModule,
    ThematicModule,
    AemRouterModule,
    ImgAltDirectiveModule,
  ]
})
export class ArticleModule { }
