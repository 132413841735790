/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Directive({
  selector: '[appImgAlt]',
})
export class ImgAltDirective implements OnInit {
  @Input('appImgAlt') public altText: any;
  @Input('appImgAltData') public altData: any;

  @HostBinding('attr.alt')
  public alternate: string;

  constructor(
    private readonly titleService: Title,
  ) {}

  public ngOnInit(): void {
    this.alternate = this.altText || this.altData?.title || this.titleService.getTitle();
  }
}
