import { NgModule } from '@angular/core';
import { MobileApplicationLinksComponent } from './mobile-application-links.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [MobileApplicationLinksComponent],
  exports: [MobileApplicationLinksComponent],
  imports: [CommonModule]
})
export class MobileApplicationLinksModule { }
