<a *ngIf="isRoute(item.link); else hrefLink"
   [appAemRouterLink]="item.link"
   [attr.rel]="item.nofollow ? 'nofollow' : null"
   class="action-card">
  <app-icon [icon]="item.icon"
            [style.color]="item.colourIcon"
            theme="inherit"
            size="x-large">
  </app-icon>
  <div class="action-card_content">
    <div class="action-card_content-label t-tiny m-t-normal">{{item.label}}</div>
    <div class="action-card_content-description t-tiny m-t-normal">{{item.description}}</div>
  </div>
  <app-icon icon="arrow-right"
            theme="inherit"
            size="x-large">
  </app-icon>
</a>

<ng-template #hrefLink>
  <a [attr.href]="getLink()"
     [attr.rel]="item.nofollow ? 'nofollow' : null"
     target="_blank"
     class="action-card">
    <app-icon [icon]="item.icon"
              [style.color]="item.colourIcon"
              theme="inherit"
              size="x-large">
    </app-icon>
    <div class="action-card_content">
      <div class="action-card_content-label t-tiny m-t-normal">{{item.label}}</div>
      <div class="action-card_content-description t-tiny m-t-normal">{{item.description}}</div>
    </div>
    <app-icon icon="arrow-right" theme="inherit" size="x-large"></app-icon>
  </a>
</ng-template>
