<div class="shell">
  <div class="not-found">
    <div class="not-found_block">
      <img class="not-found_image" src="assets/img/404.svg">
      <div class="not-found_title h3 m-h3">{{ 'pages.NOT_FOUND.TITLES.NOT_FOUND' | translate }}</div>
      <div class="not-found_text t-normal m-t-normal">{{ 'pages.NOT_FOUND.DESCRIPTIONS.NOT_FOUND' | translate }}</div>
      <app-button [routerLink]="routerLink" type="big-width">{{ 'pages.NOT_FOUND.ACTIONS.GO_TO_HOMEPAGE' | translate }}</app-button>
    </div>
    <div class="not-found_block">
      <img class="not-found_image" src="assets/img/not-found-img.svg">
    </div>
  </div>
</div>
