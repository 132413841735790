import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { ContactUsForm } from '@common/model/contactUsForm';

@Component({
  selector: 'app-aem-contact-form',
  templateUrl: './aem-contact-form.component.html',
  styleUrls: ['./aem-contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemContactFormComponent extends AemFragmentComponent {

  constructor(
    @Inject(AEM_DATA) public override data: ContactUsForm,
  ) {
    super(data);
  }
}
