import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountyImageService {

  constructor() { }

  public getCountyImagePath(countryCode: string): any {
    return `${environment.api.aemPath}/static/allyz-com/root/media/countries/${countryCode?.toLowerCase()}.webp`;
  }

  public getDefaultImagePath(): string {
    return `${environment.api.aemPath}/static/allyz-com/root/media/countries/default.webp`;
  }
}
