<header class="header">
  <div class="header__main">
    <div class="header__shell shell">
      <div class="brief anonimus-header">
        <h1 class="hero-title brief__title">{{ data.stateDefault.title }}</h1>

        <div class="brief__group">
          <app-safe-html class="hero__text" [content]="data.stateDefault.text"></app-safe-html>
          <div class="hero__action">
            <app-aem-button 
              class="hero__action_button"
              *ngFor="let cta of data.stateDefault.ctas"
              [cta]="cta"
              size="m"
              type="big-width"
            ></app-aem-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header__bottom" *ngIf="data.stateDefault.image">
    <div class="header__shell shell">
      <img class="header__img"
           [src]="data.stateDefault.image.imagePath"
           [alt]="data.stateDefault.image.altText"
           [appImgAlt]="data.stateDefault.image.altText"
           [appImgAltData]="data"/>
    </div>
  </div>
</header>