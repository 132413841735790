<div class="date-picker form-field"
     [class.form-field_focused]="focused"
     [class.form-field_disabled]="disabled"
     (click)="picker.focus()">
  <input #picker
         [max]="max"
         [min]="min"
         [class.flatpickr-mobile]="!innerControl.value"
         [formControl]="innerControl"
         [placeholder]="placeholder"
         (focus)="onFocus()"
         (blur)="onBlur()"
         type="date"
         class="date-picker_field text-small">
</div>
