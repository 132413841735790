import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SideBySideWithCTA } from '@common/model/sideBySideWithCTA';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { UserService } from '@common/user/user.service';

@Component({
  selector: 'app-side-by-side-with-cta',
  templateUrl: './side-by-side-with-cta.component.html',
  styleUrls: ['./side-by-side-with-cta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemSideBySideWithCtaComponent extends AemBaseBlockComponent {
  public userSubscription$ = this.userService.userSubscription$;

  constructor(
    private userService: UserService,
    @Inject(AEM_DATA) public aemData: SideBySideWithCTA,
  ) {
    super(aemData);
  }

}
