<div class="section__shell shell">
  <div class="h4">{{aemData.title}}</div>
  <app-safe-html [content]="aemData.text" class="t-normal list"></app-safe-html>
  <div class="actions">
    <app-aem-button *ngFor="let cta of aemData.ctas"
                    [cta]="cta"
                    class="action"
                    size="inherit">
    </app-aem-button>
  </div> 
</div>