import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, Input, ViewChild } from '@angular/core';
import { IconList } from '@kit/icon/icon.interface';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() public type: 'normal' | 'icon-button' | 'normal-width'  | 'big-width' | 'full-width' = 'normal';
  @Input() public theme: 'black' | 'green' | 'purple' | 'white' | 'bordered' | 'inherit' | 'pure' | 'pure-bordered' | 'grey' | 'blue' | 'darkseagreen' | 'royalrose' | 'bordered-black-icon' = 'black';
  @Input() public size: 'l' | 'm' | 's' | 'xs' | 'inherit' = 'l';
  @HostBinding('class.disabled') @Input() public disabled = false;
  @Input() public icon: IconList = null;

  @ViewChild('button')
  public button: ElementRef<HTMLElement>;

  @HostListener('focus')
  public focus(): void {
    this.button.nativeElement.focus();
  }
}
