<app-dialog-wrapper class="dialog__wrapper">
  <div dialogContent>
    <div class="h5 m-h3 dialog__title">{{ 'pages.AIR_DOCTOR.DIALOG.MESSAGE' | translate }}</div>
    <div class="t-normal m-t-normal">
      <app-safe-html [content]="'pages.AIR_DOCTOR.DIALOG.INFO' | translate : { service: data.service }"></app-safe-html>
    </div>
      <div class="dialog__buttons">
          <app-button type="big-width" (click)="goToSite()" id="action-dialog-go-to-air-doctor">{{ 'pages.AIR_DOCTOR.DIALOG.CONFIRM_TITLE' | translate}}</app-button>
          <app-button type="big-width" (click)="close()" id="action-dialog-close" theme="pure-bordered">{{ 'pages.AIR_DOCTOR.DIALOG.CANCEL_TITLE' | translate}}</app-button>
      </div>
      <div class="dialog__footer">
        <div class="t-normal m-t-normal weight-bold">
          <app-safe-html [content]="'pages.AIR_DOCTOR.DIALOG.NOTE_TITLE' | translate"></app-safe-html></div>
        <div class="t-normal m-t-normal dialog__href">
          <app-safe-html class="dialog__href" [content]="'pages.AIR_DOCTOR.DIALOG.NOTE' | translate : { link: data.link }"></app-safe-html></div>
      </div>

  </div>
</app-dialog-wrapper>
