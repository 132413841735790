import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditTripFormComponent } from './edit-trip-form.component';
import { ReactiveFormsModule } from "@angular/forms";
import { LabelModule } from "@kit/label/label.module";
import { SearchSelectModule } from "@kit/search-select/search-select.module";
import { FieldErrorModule } from "@kit/field-error/field-error.module";
import { WoosmapSearchModule } from "@kit/woosmap-search/woosmap-search.module";
import { DateModule } from "@kit/date/date.module";
import { InputModule } from "@kit/input/input.module";
import { CheckboxModule } from "@kit/checkbox/checkbox.module";
import { SafeHtmlModule } from "@kit/safe-html/safe-html.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    EditTripFormComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    LabelModule,
    SearchSelectModule,
    FieldErrorModule,
    WoosmapSearchModule,
    DateModule,
    InputModule,
    CheckboxModule,
    SafeHtmlModule,
  ],
  exports: [
    EditTripFormComponent
  ]
})
export class EditTripFromModule { }
