import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TranslateModule } from '@ngx-translate/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';

@NgModule({
  declarations: [
    FooterComponent
  ],
  exports: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TuiLetModule,
    TranslateModule,
    ImgAltDirectiveModule,
  ]
})
export class FooterModule {}
