import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { debounceTime, switchMap, takeUntil } from "rxjs/operators";
import { filter, ReplaySubject, timer } from "rxjs";
import { TuiDestroyService } from "@taiga-ui/cdk/services";
import {
  WoosmapSearchService
} from "@kit/woosmap-search/woosmap-search.service";
import { Country, DestinationPoint } from "@pages/trip/trip.interfaces";
import {
  AddressComponent,
  WoosmapAutocompleteData,
  WoosmapDetails
} from "@kit/woosmap-search/woosmap-search.interface";

@Component({
  selector: 'app-woosmap-search',
  templateUrl: './woosmap-search.component.html',
  styleUrls: ['./woosmap-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WoosmapSearchComponent),
      multi: true
    },
    TuiDestroyService,
  ],
})
export class WoosmapSearchComponent implements ControlValueAccessor, OnInit {
  @Input() public theme: 'normal' | 'small' = 'normal';
  @Input() public clearButton: boolean;
  @Input() public placeholder = 'Start typing a destination';
  @Input() public countries: Country[] = [];
  @Input() public warningText = 'You have reached the maximum entities limit';
  @Input() public entitiesLimit = 3;

  public searchControl = new FormControl('');
  public expanded = false;
  public disabled = false;
  public focused = false;
  public dirtyInput = false;
  public selectedOptions: DestinationPoint[] = [];
  public options$: ReplaySubject<WoosmapAutocompleteData[]> = new ReplaySubject<WoosmapAutocompleteData[]>();
  public isButtonDisabled = false;

  public onChange = (value: unknown) => {};
  public onTouched = () => {};

  constructor(
    private cdr: ChangeDetectorRef,
    private onDestroy$: TuiDestroyService,
    private woosmapService: WoosmapSearchService,
  ) {
    this.clearButton = true;
  }

  public ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe((inputValue: string) => {
      if (inputValue === null || inputValue === '') {
        this.expanded = false;
        this.dirtyInput = false;

        return;
      }

      this.expanded = true;
      this.dirtyInput = true;
    });

    this.searchControl.valueChanges.pipe(
      debounceTime(300),
      filter((inputValue: string) => !(inputValue === null) && !(inputValue === '')),
      switchMap((value: string) => this.woosmapService.autocomplete(value)),
      takeUntil(this.onDestroy$),
    ).subscribe((localities: WoosmapAutocompleteData[]) => {
      this.options$.next(localities);
    });
  }

  public registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public select(option: WoosmapAutocompleteData): void {
    const description = option.description;

    this.focused = false;
    this.expanded = false;
    this.dirtyInput = false;

    if (this.selectedOptions.find(selectedOption => {
      return selectedOption.lat === option.location.lat && selectedOption.lng === option.location.lng;
    })) return;

    this.selectedOptions = [...this.selectedOptions, { description } as DestinationPoint];
    this.updateControlValue();
    this.getWoosmapDetails(option);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.isButtonDisabled = isDisabled;
    this.cdr.markForCheck();
  }

  public writeValue(value: any): void {
    this.selectedOptions = [...value];
    this.cdr.markForCheck();
  }

  public clear(event: Event): void {
    event.stopPropagation();
    this.onTouched();
    this.searchControl.setValue(null);
    this.options$.next(null);
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    timer(300).pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      this.searchControl.setValue(null , { emitEvent: false });
      this.focused = false;
      this.expanded = false;
      this.dirtyInput = false;
      this.onTouched();
      this.cdr.markForCheck();
    });
  }

  public deleteElement(description: string): void {
    const selectedOptions = [...this.selectedOptions];

    this.selectedOptions = selectedOptions.filter((entity: DestinationPoint) => entity.description !== description);
    this.onChange(this.selectedOptions);
    this.onTouched();
  }

  private getSelectedDestinationPoint(details: WoosmapDetails, name: string, description: string): DestinationPoint {
    return  {
      country: {
        countryId: this.getCountryId(details?.address_components?.find((component: AddressComponent) => component?.types?.includes("country")).short_name)
      },
      description: description,
      name: name,
      lat: details?.geometry?.location?.lat,
      lng: details?.geometry?.location?.lng,
      type: details?.types.includes('country') ? 'COUNTRY' : 'CITY'
    }
  }

  private getCountryId(countryCode: string): number {
    return this.countries.find((country: Country) => country.code === countryCode)?.countryId;
  }

  private updateControlValue(): void {
    this.onChange(this.selectedOptions);
    this.searchControl.setValue(null, {emitEvent: false});
    this.cdr.markForCheck();
  }

  private getWoosmapDetails(option: WoosmapAutocompleteData): void {
    const description = option.description;
    const name = option.name;

    this.woosmapService.getDetails(option.public_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (details: WoosmapDetails) => {
          const destinationPoint: DestinationPoint = this.getSelectedDestinationPoint(details, name, description);

          this.selectedOptions.pop();
          this.selectedOptions = [...this.selectedOptions, destinationPoint];
          this.updateControlValue();
        },
        error: () => {
          this.selectedOptions.pop();
          this.updateControlValue();
        }
      });
  }
}
