<section class="image-block">
  <div class="shell">
    <picture>
      <source [srcset]="data.imageMobile.imagePath" media="(max-width: 480px)">
      <source [srcset]="data.imageTablet.imagePath" media="(max-width: 992px)">
      <img class="image-block__img"
           [src]="data.imageDesktop.imagePath"
           [appImgAlt]="data.imageDesktop.altText"
           [appImgAltData]="data">
    </picture>
  </div>
</section>
