import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordSecurityComponent } from './password-security.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PasswordSecurityComponent],
  exports: [PasswordSecurityComponent],
  imports: [CommonModule, TuiLetModule, TranslateModule]
})
export class PasswordSecurityModule { }
