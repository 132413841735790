import { RegisterDTO } from 'src/app/common/auth/auth.interfaces';
import { RegisterSource } from './register-form.interfaces';

export function mapRegisterSourceToDto(source: RegisterSource): RegisterDTO {
  return {
    email: source.email,
    firstName: source.firstName?.trim(),
    lastName: source.lastName?.trim(),
    promocode: source.promoCode?.trim(),
    phoneNumber: source.phone,
    password: source.password,
    marketingConsent: getMarketingConsentSettings(source),
  }
}

function getMarketingConsentSettings(source: RegisterSource): string {
  const consents = ['tc'];

  if (source.marketingManagementGlobal) consents.push('mark');
  if (source.marketingManagementInternal) consents.push('mark-int');
  if (source.marketingManagementExternal) consents.push('mark-ext');

  return consents.join(', ');
}
