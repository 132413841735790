<section class="bubble-shaped-image">
  <div class="shell">
    <div class="bubble-shaped-image__grid" [ngClass]="'bubble-shaped-image__grid_position-' + aemData.shapeAlignment">
      <div class="bubble-shaped-image__column bubble-shaped-image__column_bubble">
        <div class="bubble-shaped-image__header">
          <h4 class="bubble-shaped-image__title">{{ aemData.title }}</h4>
        </div>
        <app-safe-html class="bubble-shaped-image__text" [content]="aemData.text"></app-safe-html>

        <div class="bubble-shaped-image__button" *ngIf="aemData.ctas">
          <app-aem-button
            *ngFor="let cta of aemData.ctas"
            [cta]="cta"
            size="m"
            type="normal"
          ></app-aem-button>
        </div>
      </div>
      <app-illustration
        class="bubble-shaped-image__illustration" 
        *ngIf="aemData.image.imagePath"
        [src]="aemData.image.imagePath"
        [alt]="aemData.image.altText"
        [colourShapeArc]="aemData.colourShapeArc"
        [colourShapeCircle]="aemData.colourShapeCircle"
        [position]="aemData.imageAlignment"
      ></app-illustration>
    </div>
  </div>
</section>
