import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit, QueryList,
  SimpleChanges,
  ViewChild, ViewChildren
} from '@angular/core';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { takeUntil } from 'rxjs';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { DistinctionStatus } from '@common/information/destination-status.const';
import { DestinationResponse } from '@common/information/destination.interface';
import { DestinationExplorerDetailsBubble } from '@common/model/destinationExplorerDetailsBubble';
import { DestinationExplorerHero } from '@common/model/destinationExplorerHero';
import { UserService } from '@common/user/user.service';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { filterByEntitlements } from '@kit/utils/router.utils';
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';
import { AccordionComponent } from '@kit/accordion/accordion.component';

const TOOLBAR_MARGIN = -115;

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TuiDestroyService,
  ]
})
export class DestinationsComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() countryName: string;
  @Input() destination: DestinationResponse;
  @ViewChild('destinationsContainer', { read: ElementRef }) destinationsContainer: ElementRef<HTMLElement>;
  @ViewChildren(AccordionComponent) accordionList: QueryList<AccordionComponent>;

  public isAuth$ = this.userService.isAuth$;
  public bubbles: DestinationExplorerDetailsBubble[];
  public isAccordionExtended: boolean;
  public distinctionStatus = DistinctionStatus;

  private countryChanged = false;

  constructor(
    @Inject(AEM_DATA) public data: DestinationExplorerHero,
    @Inject(IS_BROWSER_PLATFORM) public isBrowser: boolean,
    private userService: UserService,
    private onDestroy$: TuiDestroyService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isBrowser && changes.destination?.currentValue) {
      this.countryChanged = true;
      this.analyticsService.triggerAction({
        category: 'destination_explorer',
        action: 'countrySearched',
        label: this.countryName,
        value: 1,
      });

      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.destinationsContainer?.nativeElement?.scrollIntoView();
          window.scrollBy(0, TOOLBAR_MARGIN);
        }, 0);
      });
    }
  }

  ngOnInit(): void {
    this.userService.userSubscription$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(userType => {
      this.bubbles = filterByEntitlements(this.data.component.bubbles, userType);
    })
  }

  ngAfterViewChecked(): void {
    if (this.countryChanged && this.accordionList.first) {
      this.accordionList.first.toggle(true);
      this.cdr.detectChanges();
      this.countryChanged = false;
    }
  }

  public extendedChange(isOpen: boolean) {
    this.isAccordionExtended = isOpen;
    this.cdr.detectChanges();
  }
}
