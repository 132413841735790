<section class="faq-block">
  <div class="shell">
    <h2 *ngIf="data.title" class="faq-block__title h2 m-h2">{{ data.title }}</h2>
    <div appAccordionContainer class="faq-block__container">
      <app-accordion size="child" class="faq-block__item" *ngFor="let item of data.items">
        <ng-container accordionTitle>
          <h6 class="zero-margin h6 m-h4">{{ item.question }}</h6>
        </ng-container>

        <app-safe-html class="faq-block__answer t-small m-t-normal" [content]="item.answer"></app-safe-html>

        <app-aem-button *ngIf="item.cta" [cta]="item.cta" size="m"></app-aem-button>
        <app-helpful *ngIf="!item.hideFeedback" [faqId]="item.uuid"></app-helpful>
      </app-accordion>
    </div>
  </div>
</section>
