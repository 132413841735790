import { Component, Inject, Injectable, Injector } from '@angular/core';
import { Fragment } from '@common/model/fragment';
import { TripHero } from '@common/model/tripHero';
import { UserType } from '@common/profile/profile.interfaces';
import { filterByEntitlements } from '@kit/utils/router.utils';
import { AEM_COMPONENTS_MAP, AEM_DATA } from './dynamic-render.const';
import { DynamicContent } from './dynamic-render.interface';

// TODO tmp component
@Component({
  selector: 'app-foo',
  template: '<div>Not Implemented: <b>{{data._type}}</b></div>',
})
export abstract class NotImplementedComponent {
  protected constructor(
    @Inject(AEM_DATA) public data: any,
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class DynamicRenderService {
  constructor(
    private injector: Injector,
    @Inject(AEM_COMPONENTS_MAP)
    private readonly componentsMap: Record<string, any>,
  ) { }

  public prepareContent(fragments: Fragment[], userSubscription: UserType, injector?: Injector): DynamicContent[] {
    return filterByEntitlements(fragments, userSubscription).map(container => this.getComponent(container, injector));
  }

  public getComponent(data: Fragment, injector?: Injector): DynamicContent {
    if (!data?._type) {
      return null;
    }

    return {
      component: this.bindAEMWithComponent(data),
      injector: Injector.create({
        providers: [{ provide: AEM_DATA, useValue: data }],
        parent: injector || this.injector,
      })
    };
  }

  private bindAEMWithComponent(data: Fragment) {
    const type = data?._type === 'TripHero' ? (data as TripHero).component?._type :  data?._type;

    return this.componentsMap[type as keyof typeof this.componentsMap]
        || NotImplementedComponent;
  }
}
