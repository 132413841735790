import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { HomeHero } from '@common/model/homeHero';
import { UserService } from '@common/user/user.service';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';

@Component({
  selector: 'app-home-hero',
  templateUrl: './home-hero.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemHomeHeroComponent extends AemFragmentComponent {
  isAuth$ = this.userService.isAuth$;

  constructor(
    @Inject(AEM_DATA) public override data: HomeHero,
    private userService: UserService,
  ) {
    super(data);
  }
}
