<div class="create-trip_form" [formGroup]="tripForm">
  <div class="create-trip_form-block">
    <app-label [label]="'forms.CREATE_TRIP.LABELS.DEPARTURE_COUNTRY' | translate" [required]="true">
      <app-search-select [clearButton]="true"
                         [options]="countries$ | async"
                         [placeholder]="'forms.CREATE_TRIP.PLACEHOLDERS.DEPARTURE_COUNTRY' | translate"
                         id="create-trip-departure-country edit-trip-departure-country"
                         formControlName="departureCountry"
                         viewKey="name"
                         selectKey="countryId">
      </app-search-select>
      <app-field-error controlName="departureCountry"></app-field-error>
    </app-label>
    <app-label [label]="'forms.CREATE_TRIP.LABELS.DESTINATION_COUNTRY' | translate"
               [clarification]="'forms.CREATE_TRIP.LABELS.DESTINATION_COUNTRY_CLARIFICATION' | translate"
               [required]="true">
      <app-woosmap-search [countries]="countries$ | async"
                          [placeholder]="'forms.CREATE_TRIP.PLACEHOLDERS.DESTINATION_COUNTRY' | translate"
                          [warningText]="'forms.CREATE_TRIP.PLACEHOLDERS.DESTINATIONS_LIMIT' | translate"
                          [entitiesLimit]="30"
                          formControlName="destinationPoints"
                          id="create-trip-destination-point edit-trip-destination-point">
      </app-woosmap-search>
      <app-field-error controlName="destinationPoints"></app-field-error>
    </app-label>
  </div>

  <div class="create-trip_form-block">
    <app-label [label]="'forms.CREATE_TRIP.LABELS.START_DATE' | translate" [required]="true">
      <app-date-picker id="create-trip-start-date edit-trip-start-date" formControlName="from"></app-date-picker>
      <app-field-error controlName="from"></app-field-error>
    </app-label>
    <app-label [label]="'forms.CREATE_TRIP.LABELS.END_DATE' | translate" [required]="true">
      <app-date-picker id="create-trip-end-date edit-trip-end-date" formControlName="to"></app-date-picker>
      <app-field-error controlName="to"></app-field-error>
    </app-label>
    <app-label [label]="'forms.CREATE_TRIP.LABELS.NAME' | translate"
               [clarification]="'forms.CREATE_TRIP.LABELS.NAME_CLARIFICATION' | translate">
      <app-input [placeholder]="'forms.CREATE_TRIP.PLACEHOLDERS.NAME' | translate"
                 [maxLength]="80"
                 id="create-trip-name edit-trip-name"
                 formControlName="name">
      </app-input>
      <app-field-error controlName="name"></app-field-error>
      <app-field-error [control]="tripForm"></app-field-error>
    </app-label>
    <ng-container *ngIf="showNotificationContainer">
      <ng-container *ngIf="showNotificationCheckbox; else notificationDisclaimer">
        <app-checkbox id="create-trip-notification edit-trip-notification"
                      formControlName="subscribedOnNotifications">
          <div class="t-tiny m-t-small">{{ 'forms.CREATE_TRIP.NOTIFICATIONS_OFFER' | translate }}</div>
        </app-checkbox>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!tripData; else editButtons">
      <button (click)="createTripHandler()" id="action-create-trip" class="button">
        {{ 'forms.CREATE_TRIP.SEND_BUTTON' | translate }}
      </button>
    </ng-container>
  </div>
</div>

<ng-template #notificationDisclaimer>
  <div class="t-tiny m-t-small">
    <app-safe-html [content]="'forms.CREATE_TRIP.NOTIFICATIONS_DISCLAIMER' | translate"></app-safe-html>
  </div>
</ng-template>

<ng-template #editButtons>
  <button (click)="editTripHandler()" id="action-trip-save" class="button">
    {{ 'forms.EDIT_TRIP.ACTIONS.SAVE_MY_TRIP' | translate }}
  </button>
  <button (click)="deleteTripHandler()" id="action-trip-delete" class="button button_lighten">
    {{ 'forms.EDIT_TRIP.ACTIONS.DELETE_MY_TRIP' | translate }}
  </button>
</ng-template>
