<section class="content-image">
  <div class="shell">
    <div class="content-image__grid" [ngClass]="'content-image__grid_position-' + data.imageAlignment">
      <div class="content-image__column">
        <div class="content-image__header">
          <h4 class="content-image__title h4 m-h2">{{ data.title }}</h4>
        </div>
        <app-safe-html class="t-small m-t-normal" [content]="data.text"></app-safe-html>

        <div class="content-image__button" *ngIf="data.ctas">
          <app-aem-button
            *ngFor="let cta of data.ctas"
            [cta]="cta"
            size="m"
            type="big-width"
          ></app-aem-button>
        </div>
        <app-safe-html class="t-tiny m-t-small" *ngIf="data.bottomText" [content]="data.bottomText"></app-safe-html>
      </div>
      <img class="content-image__illustration"
           *ngIf="data.image.imagePath"
           [src]="data.image.imagePath"
           [appImgAlt]="data.image?.altText"
           [appImgAltData]="data">
    </div>
  </div>
</section>
