import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { SliderModule } from '@kit/carousel/slider.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemStepByStepComponent } from './step-by-step.component';
import { StepComponent } from './step/step.component';

@NgModule({
  declarations: [AemStepByStepComponent, StepComponent],
  exports: [AemStepByStepComponent],
  imports: [
    CommonModule,
    AemButtonModule,
    SafeHtmlModule,
    SliderModule,
    ImgAltDirectiveModule,
  ]
})
export class AemStepByStepWithCtaModule { }
