<section class="shell">
  <div class="titled-image">
    <div *ngIf="data.title" class="titled-image__title">{{ data.title }}</div>
    <app-safe-html *ngIf="data.text" class="titled-image__subtitle" [content]="data.text"></app-safe-html>
    <div class="titled-image__img">
      <img [src]="data.image.imagePath"
           [appImgAlt]="data.image.altText"
           [appImgAltData]="data"/>
    </div>
  </div>
</section>
  