import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ApiProfileService } from '@common/profile/api-profile.service';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from '@kit/dialog/dialog.service';
import { filter, switchMap, takeUntil } from 'rxjs';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { LoungeVouchersService } from '../lounge-vouchers.service';
import { LoungeVoucher } from '../lounge-vouchers.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-voucher-accordion',
  templateUrl: './voucher-accordion.component.html',
  styleUrls: ['./voucher-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class VoucherAccordionComponent {
  @Input() public vouchers: LoungeVoucher[];
  @Input() public id: string;

  public currentPage = 0;
  public entitiesOnPage = 1;

  constructor(
    private readonly dialogService: DialogService,
    private readonly apiProfileService: ApiProfileService,
    private readonly onDestroy$: TuiDestroyService,
    private readonly loungeVouchersService: LoungeVouchersService,
    private readonly translateService: TranslateService
    ) { }

  public removeVoucher(id: number): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.LOUNGE_VOUCHERS.DIALOG.MESSAGE'),
      info: this.translateService.instant('pages.LOUNGE_VOUCHERS.DIALOG.INFO'),
      confirmTitle: this.translateService.instant('pages.LOUNGE_VOUCHERS.DIALOG.CONFIRM_TITLE'),
      cancelTitle: this.translateService.instant('pages.LOUNGE_VOUCHERS.DIALOG.CANCEL_TITLE'),
      reverseButtonPosition: true,
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.apiProfileService.deleteLoungeAccessVoucher(id)),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.loungeVouchersService.getUserLoungeVouchers();

      this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 0;
    })
  }

  public setCurrentPage(page: number): void {
    this.currentPage = page;
  }

  public trackBy(index: number, item: LoungeVoucher): string {
    return item.voucherId.toString();
  }

}
