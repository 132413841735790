<div class="dialog-container">
  <div class="dialog-header">
    <div class="dialog-header_title">
      <ng-content select="[dialogTitle]"></ng-content>
    </div>
    <app-button id="action-dialog-close"
      *ngIf="closeButton"
      class="dialog-header_close"
      icon="close"
      type="icon-button"
      theme="bordered"
      size="xs"
      (click)="close()"
    ></app-button>
  </div>
  <div class="dialog-content">
    <ng-content select="[dialogContent]"></ng-content>
  </div>
</div>
