import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { FAQHero } from '@common/model/fAQHero';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';


@Component({
  selector: 'app-aem-hero-only-text',
  templateUrl: './aem-hero-only-text.component.html',
  styleUrls: [
    '../../../aem-hero-block/aem-hero-block.component.scss',
    './aem-hero-only-text.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemHeroOnlyTextComponent extends AemFragmentComponent {
  constructor(
    @Inject(AEM_DATA) public override data: FAQHero,
  ) {
    super(data);
  }
}
