<div class="shell">
  <div class="player">
    <iframe [src]="link"
            [width]="width"
            [height]="height"
            class="responsive-iframe"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen>
    </iframe>
  </div>
</div>
