import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountNotification } from '@common/profile/profile.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ApiNotificationService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
  ) {}

  public getNotifications(): Observable<AccountNotification[]> {
    return this.http.get<AccountNotification[]>(`${this.baseUrl}/profile/notification-settings`)
  }

  public updateNotifications(options: AccountNotification[]): Observable<AccountNotification[]> {
    return this.http.patch<AccountNotification[]>(`${this.baseUrl}/profile/notification-settings`, options)
  }

  public acceptDoubleOptIn(profileId: string): Observable<void> {
    return this.http.put<void>(`${environment.api.publicProfile}/profile/notification-settings/double-opt-in/${profileId}`, null);
  }

  public getUserNotificationSettings(id: number): Observable<AccountNotification[]> {
    return this.http.get<AccountNotification[]>(`${this.baseUrl}/profiles/${id}/notification-settings`);
  }
}
