import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { InsuranceCarrousel, InsuranceCategory } from 'src/app/common/model/models';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-insurance-carrousel',
  templateUrl: './insurance-carrousel.component.html',
  styleUrls: ['./insurance-carrousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemInsuranceCarrouselComponent extends AemBaseBlockComponent {
  selected: InsuranceCategory = this.data.items?.[0];

  constructor(
    @Inject(AEM_DATA) public override data: InsuranceCarrousel,
  ) {
    super(data);
  }

  selectCategory(category: InsuranceCategory): void {
    this.selected = category;
  }
}
