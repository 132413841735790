import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { IS_BROWSER_PLATFORM } from 'src/app/kit/utils/ssr.utils';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { ServerErrorsValidationService } from '@kit/server-errors/server-errors-validation.service';
import { BookingService } from '../booking.service';
import { BookingTripDetails, CoverageType, DateOfBirth } from "@common/booking-path/booking-path.interfaces";

@Component({
  selector: 'app-booking-path',
  templateUrl: './booking-path.component.html',
  styleUrls: ['./booking-path.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    BookingService,
    ServerErrorsValidationService,
  ]
})
export class AemBookingPathComponent implements OnInit{
  public logo$: Observable<string> = this.bootstrapService.footer$.pipe(
    map(data => data.logo.imagePath)
  );

  constructor(
    public bookingService: BookingService,
    private validation: ServerErrorsValidationService,
    private bootstrapService: BootstrapService,
    private router: Router,
    private route: ActivatedRoute,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    @Inject(IS_BROWSER_PLATFORM) public isBrowser: boolean,
  ) {}

  ngOnInit(): void {
    if (
      this.isBrowser &&
      this.route.snapshot.queryParamMap.get('deeplink') === '1' &&
      this.route.snapshot.queryParamMap.get('travel_type') === 'one_way_trip'
    ) {

      this.bookingService.deepLinkData = this.getDeepLinkData();
      this.router.navigate([], { relativeTo: this.route, queryParams: {} });

      return;
    }

    if (this.isBrowser) {
      this.bookingService.initData(this.validation);
    }
  }

  public goBack(): void {
    this.router.navigateByUrl(this.bootstrapService.link.insuranceLanding);
  }

  public resetScroll(): void {
    this.zone.runOutsideAngular(() => requestAnimationFrame(
      () => this.document.scrollingElement.scrollTop = 0
    ));
  }

  private getDeepLinkData(): BookingTripDetails {
    const queryParamMap = this.route.snapshot.queryParamMap;

    const destinationCountries = queryParamMap.keys
      .filter((key: string) => key.includes('destination_countries'))
      .map((key: string) => queryParamMap.get(key));

    const travelersBirthDates: DateOfBirth[] = queryParamMap.keys
      .filter((key: string) => key.includes('traveler_birthdate'))
      .map((key: string) => ({ dateOfBirth: queryParamMap.get(key) }))

    return {
      marketScope: null,
      startDate: queryParamMap.get('begin_date'),
      endDate: queryParamMap.get('end_date'),
      totalTripPrice: Number(queryParamMap.get('total_amount')),
      promoCode: queryParamMap.get('promo'),
      tripBookDate: queryParamMap.get('purchase_date'),
      residenceCountryCode: queryParamMap.get('residence_country'),
      destinationLocation: { names: destinationCountries },
      coverage: { coverageType: queryParamMap.get('coverage_type') === 'cancellation' ? CoverageType.CANCELLATION : CoverageType.NOT_SURE },
      travellers: travelersBirthDates,
    };
  }
}
