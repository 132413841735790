<app-dialog-wrapper>
    <div class="zero-margin" dialogTitle>
      <h6 class="h6 m-h5 zero-margin">{{ 'global.MEMBER_DIALOG.TITLE' | translate }}</h6>
    </div>
    <div dialogContent>
      <h5 class="h5 m-h3 headline" *ngIf="'global.MEMBER_DIALOG.DURATION_UPDATE_TITLE' | translate as DURATION_UPDATE_TITLE">
        {{ DURATION_UPDATE_TITLE }}
      </h5>
      <app-safe-html class="t-large m-t-large text" [content]="'global.MEMBER_DIALOG.DURATION_UPDATE_DESCRIPTION' | translate"></app-safe-html>
  
      <h5 class="h5 m-h3 headline">{{ 'global.MEMBER_DIALOG.INSURANCE_CONTRACT_TITLE' | translate }}</h5>
      <app-safe-html class="t-large m-t-large text" [content]="'global.MEMBER_DIALOG.INSURANCE_CONTRACT_DESCRIPTION' | translate"></app-safe-html>
   
      <div class="dialog-footer">
        <div class="buttons">
          <a [href]="url" class="link">
            <app-button type="full-width" size="l">{{ 'global.MEMBER_DIALOG.SEND_MAIL' | translate }}</app-button>
          </a>
        </div>
        <a [appAemRouterLink]="bootstrapService.link.privacyPolicy" class="t-tiny m-t-small addition-text" (click)="dialogRef.close()">
          {{ 'global.MEMBER_DIALOG.DISCLAIMER' | translate }}
        </a>
      </div>
    </div>
  </app-dialog-wrapper>
  