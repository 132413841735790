import { Inject, Injectable, Optional, Renderer2, RendererFactory2 } from '@angular/core';
import { Screen } from '../model/screen';
import { DOCUMENT } from '@angular/common';
import { FAQItem } from '../model/fAQItem';
import { Breadcrumb } from "@kit/breadcrumbs/breadcrumbs.interface";
import { BlogHero } from "@common/model/blogHero";
import { getDate } from "@kit/utils/date.utils";
import { BootstrapService } from "@common/bootstrap/bootstrap.service";
import { SEOBootstrapConfiguration } from "@common/model/sEOBootstrapConfiguration";
import { TranslateService } from "@ngx-translate/core";
import { BlogArticleScreen } from "@common/model/blogArticleScreen";
import { ContentBlock } from "@common/model/contentBlock";
import { CURRENT_LOCATION } from "@common/seo/seo.const";
import { IS_SERVER_PLATFORM } from "@kit/utils/ssr.utils";
import { filter, map, Observable, take } from "rxjs";

const LD_SCRIP_ID = 'ld';
const FAQ_LD_SCRIPT_ID = 'faq_ld';
const BREADCRUMB_LD_SCRIPT_ID = 'breadcrumb_ld';
const BLOG_ARTICLE_LD_SCRIPT_ID = 'blog_ld';
const LD_SCRIPT_TYPE = 'application/ld+json';
const TAG_TEMPLATE_SEPARATOR = ' | ';

@Injectable({ providedIn: 'root' })
export class LinkingDataService {
  private renderer: Renderer2;

  private get seoBootstrapConfig$(): Observable<SEOBootstrapConfiguration> {
    return this.bootstrapService.seo$.asObservable();
  }

  private get host(): string {
    return this.isServer
      ? new URL(this.currentLocation).host
      : window.location.host;
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean,
    private readonly rendererFactory: RendererFactory2,
    private readonly bootstrapService: BootstrapService,
    private readonly translateService: TranslateService,
    @Optional() @Inject(CURRENT_LOCATION) private readonly currentLocation: string,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public updateJsonLD(data: Screen): void {
    this.removeOldLDScript(LD_SCRIP_ID);

    if (data?.seo?.jsonLd) {
      this.createLdScript(data.seo.jsonLd, LD_SCRIPT_TYPE);
    }
  }

  public updateFAQStructuredData(faqItems: FAQItem[]): void {
    this.removeOldLDScript(FAQ_LD_SCRIPT_ID);

    if (faqItems.length) {
      this.createLdScript(this.generateFAQJsonLd(faqItems), FAQ_LD_SCRIPT_ID);
    }
  }

  public updateBreadcrumbsStructureData(breadcrumbs: Breadcrumb[]): void {
    this.removeOldLDScript(BREADCRUMB_LD_SCRIPT_ID);

    if (breadcrumbs.length) {
      this.createLdScript(this.generateBreadcrumbsJsonLd(breadcrumbs), BREADCRUMB_LD_SCRIPT_ID);
    }
  }

  public updateBlogArticleJsonLD(data: Screen): void {
    this.removeOldLDScript(BLOG_ARTICLE_LD_SCRIPT_ID);

    if (data && data._type === 'BlogArticleScreen') {
      this.generateBlogArticleJsonLd$(data)
        .pipe((take(1)))
        .subscribe((jsonLD: string) => this.createLdScript(jsonLD, BLOG_ARTICLE_LD_SCRIPT_ID));
    }
  }

  private createLdScript(data: string, scriptId: string) {
    const LDScript = this.renderer.createElement('script');
    const text = this.renderer.createText(data);

    this.renderer.setAttribute(LDScript, 'type', LD_SCRIPT_TYPE);
    this.renderer.setAttribute(LDScript, 'id', scriptId);
    this.renderer.appendChild(LDScript, text);
    this.renderer.appendChild(this.document.body, LDScript);
  }

  private generateFAQJsonLd(faqItems: FAQItem[]): string {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": faqItems.map((item) => ({
        "@type": "Question",
        "name": item.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": this.sanitizeHtml(item.answer),
        }
      })),
    };

    return JSON.stringify(structuredData);
  }

  private generateBreadcrumbsJsonLd(breadcrumbs: Breadcrumb[]): string {
    let position = 1;

    const itemList = breadcrumbs.map((breadcrumb: Breadcrumb) => {
      return {
        "@type": "ListItem",
        "position": position++,
        "name": breadcrumb.label,
        "item": `${this.host}${breadcrumb.url}`,
      };
    });

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": itemList,
    };

    return JSON.stringify(structuredData);
  }

  private generateBlogArticleJsonLd$(screen: BlogArticleScreen): Observable<string> {
    const hero = screen?.top.find(element => element._type === 'BlogHero') as BlogHero;
    const container = screen?.container.find(element => element._type === 'ContentBlock') as ContentBlock;
    const synopsis = container?.text.match(/^<p>(.*?)<\/p>/)[0] || '';
    const publishDate = hero.date;

    const data = {
      blogArticleUrl: this.isServer ? this.currentLocation : document.location,
      blogArticleTitle: hero?.title,
      blogArticleSynopsis: this.sanitizeHtml(synopsis),
      blogArticleMainImage: hero?.image.imagePath,
      blogArticlePublishDate: getDate(new Date(publishDate)),
      blogArticleUpdateDate: getDate(new Date(publishDate)),
    }

    return this.seoBootstrapConfig$
      .pipe(
        filter(Boolean),
        map(({templateBlogArticleJsonLd}: SEOBootstrapConfiguration) => {
          const contentString: string = this.translateService.instant(templateBlogArticleJsonLd, data);

          return contentString
            .split(TAG_TEMPLATE_SEPARATOR)
            .filter(Boolean)
            .join(TAG_TEMPLATE_SEPARATOR);
        })
      )

  }

  private sanitizeHtml(html: string): string {
    const div: HTMLDivElement = this.renderer.createElement('div');
    div.innerHTML = html;

    return div.textContent || div.innerText || "";
  }

  private removeOldLDScript(scriptId: string): void {
    const oldLDScript = this.document.getElementById(scriptId);

    if (oldLDScript) {
      this.renderer.removeChild(this.document.body, oldLDScript);
    }
  }
}
