import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Hotel } from '@pages/trip/components/hotel/hotel.interface';

@Component({
  selector: 'app-trip-details-accordion-hotel',
  templateUrl: './trip-details-accordion-hotel.component.html',
  styleUrls: ['./trip-details-accordion-hotel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripDetailsAccordionHotelComponent {
  @Input() hotel: Hotel;

  constructor() {
  }

}
