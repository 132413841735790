import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SideBySide } from 'src/app/common/model/sideBySide';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '../aem-base-block/aem-base-block';

@Component({
  selector: 'app-side-by-side',
  templateUrl: './side-by-side.component.html',
  styleUrls: ['./side-by-side.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBySideComponent extends AemBaseBlockComponent {
  constructor(
    @Inject(AEM_DATA) public override data: SideBySide,
  ) {
    super(data);
  }
}
