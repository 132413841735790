import { Inject, Injectable, Optional, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { TranslateService } from '@ngx-translate/core';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { Hero, HomeHero, Screen, Fragment, SEOBootstrapConfiguration, FAQArticleScreen, BlogArticleScreen } from '../model/models';
import { CURRENT_LOCATION } from "@common/seo/seo.const";
import { IS_SERVER_PLATFORM } from "@kit/utils/ssr.utils";
import { Subscription } from 'rxjs';


type HeroBlock = Partial<Hero & HomeHero>;

const TAG_TEMPLATE_SEPARATOR = ' | ';

@Injectable({ providedIn: 'root' })
export class MetaTagsService {
  private renderer: Renderer2;
  private descriptionTag: HTMLMetaElement;
  private subscription: Subscription;

  private get seoBootstrapConfig(): SEOBootstrapConfiguration {
    return this.bootstrapService.seo$.getValue();
  }

  private get originUrl(): string {
    return this.isServer
      ? new URL(this.currentLocation).origin
      : window.location.origin;
  }

  private get siteLogo(): string {
    return this.originUrl + '/assets/img/allianz-logo-twitter.webp';
  }

  constructor(
    private readonly metaService: Meta,
    private readonly titleService: Title,
    private readonly bootstrapService: BootstrapService,
    private readonly translateService: TranslateService,
    private readonly rendererFactory: RendererFactory2,
    @Optional() @Inject(CURRENT_LOCATION) private currentLocation: string,
    @Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public updateMetaTag(data: Screen): void {
    this.subscription?.unsubscribe();

    this.subscription = this.translateService.get('global.COUNTRY')
      .subscribe(() => {
        this.addMetaDescription(data);
        this.addTwitterMetaTag(data);
        this.addOpenGraphMetaTag(data);
        this.addTitleTag(data);
      });
  }

  private addTitleTag(data: Screen): void {
    const sanitizedTagContent = this.generateTitle(data);

    this.titleService.setTitle(sanitizedTagContent);
  }

  private addMetaDescription(data: Screen): void {
    if (this.descriptionTag) {
      this.metaService.removeTagElement(this.descriptionTag);
    }

    const sanitizedTagContent = this.generateDescription(data);

    this.descriptionTag = this.metaService.addTag({
      name: 'description',
      content: this.normalizeContentLength(sanitizedTagContent),
    });
  }

  private generateDescription(data: Screen) {
    const tagContent = data?.seo?.description
      ? data.seo.description
      : this.getTagContent(this.seoBootstrapConfig?.templateDescription || '', data);

    return this.sanitizeHtml(tagContent);
  }

  private generateTitle(data: Screen): string {
    const tagContent: string = data?.seo?.title
      ? data.seo.title
      : this.getTagContent(this.seoBootstrapConfig?.templateTitle || '', data);

    return this.sanitizeHtml(tagContent);
  }

  private getTagContent(template: string, screen: Screen | FAQArticleScreen | BlogArticleScreen): string {
    const heroBlock: HeroBlock = this.getHeroFragment(screen);

    const data: Record<string, string> = {
      h1: heroBlock?.title || heroBlock?.stateDefault?.title || '',
      country: this.getTranslation('global.COUNTRY'),
      synopsys: heroBlock?.text || heroBlock?.stateDefault?.text || '',
      sitename: this.getTranslation('global.SITE_NAME'),
    };

    let contentString: string = template;

    Object.keys(data).forEach((key: string) => {
      contentString = contentString.replace(`{{${key}}}`, data[key]);
    })

    return contentString
      .split(TAG_TEMPLATE_SEPARATOR)
      .filter(Boolean)
      .join(TAG_TEMPLATE_SEPARATOR);
  }

  private getHeroFragment(data: Screen | FAQArticleScreen | BlogArticleScreen): HeroBlock {
    const topContent = (data as any).top || [];
    const containerContent = data.container || [];

    return [...topContent, ...containerContent].find((fragment: Fragment) => /Hero/.test(fragment._type));
  }

  private addTwitterMetaTag(data: Screen): void {
    this.deleteMetaTagsByName('name^="twitter:"');

    const twitterTags = data?.seo?.twitterTags;

    this.metaService.addTag({
      name: 'twitter:card',
      content: twitterTags?.card || 'summary_large_image',
    });

    this.metaService.addTag({
      name: 'twitter:title',
      content: twitterTags?.title || this.generateTitle(data),
    });

    this.metaService.addTag({
      name: 'twitter:description',
      content: twitterTags?.description || this.generateDescription(data),
    });

    this.metaService.addTag({
      name: 'twitter:image',
      content: twitterTags?.image || this.siteLogo,
    });
  }

  private addOpenGraphMetaTag(data: Screen): void {
    const ogTags = data?.seo?.ogTags;

    this.deleteMetaTagsByName('property^="og:"');


      this.metaService.addTag({
        property: 'og:title',
        content: ogTags?.title || this.generateTitle(data),
      });


      this.metaService.addTag({
        property: 'og:description',
        content: ogTags?.description || this.generateDescription(data),
      });


      this.metaService.addTag({
        property: 'og:URL',
        content: ogTags?.url || this.currentLocation || window?.location.href,
      });


      this.metaService.addTag({
        property: 'og:image',
        content: ogTags?.image || this.siteLogo,
      });


      this.metaService.addTag({
        property: 'og:site_name',
        content: ogTags?.siteName || this.translateService.instant('global.SITE_NAME'),
      });
  }

  private deleteMetaTagsByName(selector: string): void {
    const tags = this.metaService.getTags(selector);

    tags.forEach((tag: HTMLMetaElement) =>
      this.metaService.removeTagElement(tag)
    );
  }

  private sanitizeHtml(html: string): string {
    const div: HTMLDivElement = this.renderer.createElement('div');
    div.innerHTML = html;

    return div.textContent || div.innerText || '';
  }

  private normalizeContentLength(content: string): string {
    if (content.length <= 150) {
      return content;
    }

    const endOfContent = content.substring(0, 150);
    const spaceIndex = endOfContent.lastIndexOf(' ');
    const lastIncludedSpaceIndex = spaceIndex === -1
      ? content.length
      : spaceIndex;

    return content.substring(0, lastIncludedSpaceIndex) + '...';
  }

  private getTranslation(key: string): string {
    const translation = this.translateService.instant(key);

    return translation === key ? '' : translation;
  }
}
