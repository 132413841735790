import { ActivityDto, ActivitySource } from "../activity-element.interface";

export function mapActivitySourceToDto(source: ActivitySource): ActivityDto {
  return {
    name: source.name?.trim(),
    location: source.location?.trim(),
    startDate: source.start?.toISOString(),
    startTime: source.hour,
    bookingReference: source.bookingReference?.trim(),
    activitySubcategory: source.activityCategory,
  }
}
