import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { getAllFormErrors } from 'src/app/kit/utils/form';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { FormActivityEdit } from '@common/model/formActivityEdit';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from '@kit/dialog/dialog.service';
import { wrapValidator } from '@kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from '@kit/server-errors/server-errors-validation.service';
import { setRouterData } from '@kit/utils/router.utils';
import { notBlankValidator } from '@kit/utils/validators';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { ActivityElementService } from '../activity-element.service';
import { mapActivitySourceToDto } from '../add-activity-element/add-activity-element.mapper';
import { mapActivityToSource } from './edit-activity-element.mapper';
import { Observable } from "rxjs";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-activity-element',
  templateUrl: './edit-activity-element.component.html',
  styleUrls: ['./edit-activity-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TuiDestroyService,
    ServerErrorsValidationService,
  ],
})
export class EditActivityElementComponent extends AemFragmentComponent implements OnInit {
  form = this.formBuilder.group({
    name: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
        wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
        wrapValidator(Validators.maxLength(35), 'errors.CEAZ000_Size'),
      ],
      this.validationService.createValidator('name'),
    ],
    location: [
      null,
      wrapValidator(Validators.maxLength(35), 'errors.CEAZ000_Size'),
      this.validationService.createValidator('location'),
    ],
    start: [
      null,
      wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      this.validationService.createValidator('startDate'),

    ],
    hour: [null, null, this.validationService.createValidator('startTime')],
    activityCategory: [
      null,
      wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      this.validationService.createValidator('activitySubcategory')
    ],
    bookingReference: [
      null,
      wrapValidator(Validators.maxLength(15), 'errors.CEAZ000_Size'),
      this.validationService.createValidator('bookingReference')
    ],
  });

  optionsCategory$ = this.activityElementService.getActivityCategory();
  tripId = this.route.firstChild.snapshot.params.tripId;

  constructor(
    private bootstrapService: BootstrapService,
    private formBuilder: UntypedFormBuilder,
    private activityElementService: ActivityElementService,
    private validationService: ServerErrorsValidationService,
    private onDestroy$: TuiDestroyService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    @Inject(AEM_DATA) data: FormActivityEdit,
  ) {
    super(data)
  }

  ngOnInit(): void {
    this.form.addAsyncValidators(this.validationService.createValidator());
    this.form.patchValue(mapActivityToSource(this.route.firstChild.snapshot.data.activity));
  }

  public goBack(): void {
    this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId: this.tripId }));
  }

  public onSave(): void {
    this.form.markAllAsTouched();

    if (getAllFormErrors(this.form)?.length) {
      this.analyticsService.validationError(this.form, this.constructor.name);
      return;
    }

    const activity = mapActivitySourceToDto(this.form.value);
    const { tripId, activityId } = this.route.firstChild.snapshot.params;

    this.activityElementService.save(tripId, activityId, activity).pipe(
      catchError(err => this.handleServerError(err)),
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId }));
    });
  }

  public onDelete(): void {
    const { tripId, activityId } = this.route.firstChild.snapshot.params;

    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      title: this.translateService.instant('global.CONFIRMATION_DIALOG.TITLES.CONFIRMATION'),
      message: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DELETE_ACTIVITY'),
      info: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DISCLAIMER_DELETE'),
      cancelTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.CANCEL'),
      confirmTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.DELETE'),
      reverseButtonPosition: true
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.activityElementService.delete(tripId, activityId)),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId }));
    });
  }

  private handleServerError(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    return this.validationService.handleServerError(err?.error);
  }
}
