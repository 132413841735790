import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { LanguageService } from '@common/language/language.service';
import { DocumentVaultService } from './document-vault.service';
import { Observable, map, switchMap, takeUntil } from 'rxjs';
import { File, FolderContent, VaultData } from '@common/documents-vault/api-documents.interface';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { environment } from 'src/environments/environment';
import { UserService } from '@common/user/user.service';
import { UserType } from '@common/profile/profile.interfaces';
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: 'app-document-vault',
  templateUrl: './document-vault.component.html',
  styleUrls: ['./document-vault.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class AemDocumentVaultComponent implements OnInit {
  public vaultData$: Observable<VaultData> = this.documentVaultService.userVaultData$;

  public entitiesOnPage = 10;
  public currentPage = 1;
  public selectedFolder: FolderContent | null = null;
  public pageTitle$: Observable<string>;

  private language = this.languageService.languageCode;
  public appStorePath = `assets/img/app-store/${this.language}/app-store-badge-black.svg`;
  public googlePlayPath = `assets/img/google-play/${this.language}/google-play-badge-black.png`;
  public appStoreLink = (<any>environment.appStore)[this.userService.location?.toLowerCase()] || '#';
  public googlePlayLink = (<any>environment.googlePlay)[this.userService.location?.toLowerCase()] || '#';
  public isUserFreemium = this.userService.userData.membershipStatus === UserType.Freemium;
  public isInsideFolder = this.route.snapshot.queryParams.folderId;

  private vaultData: VaultData;

  constructor(
    private readonly languageService: LanguageService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly bootstrapService: BootstrapService,
    private readonly documentVaultService: DocumentVaultService,
    private readonly destroy$: TuiDestroyService,
    private readonly userService: UserService,
    private readonly translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.resolveViews();
    this.fetchDocuments();
  }

  public trackByFile(index: number, item: File): string {
    return item.id;
  }

  public trackByFolder(index: number, item: FolderContent): string {
    return item.id.toString();
  }

  public getPage(page: number): void {
    this.currentPage = page;
    this.fetchDocuments();
  }

  public changeCurrentPage(event: number): void {
    this.currentPage = event;
  }

  public selectFolder(folder: FolderContent): void {
    this.selectedFolder = folder;
    this.isInsideFolder = true;
    const folderId = folder.id;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { folderId }
    });
  }

  public goBack(): void {
    if (this.selectedFolder) {
      this.router.navigate([]);
      this.currentPage = 1;
      this.fetchDocuments();
      this.isInsideFolder = false;

      return;
    }
    this.router.navigate([this.bootstrapService.link.tripHealth]);
  }

  private fetchDocuments(): void {
    this.documentVaultService.getUserVaultData(this.entitiesOnPage, this.currentPage - 1)
  }

  private resolveViews(): void {
    this.vaultData$.pipe(
      map(vaultData => this.vaultData = vaultData),
      switchMap((_params) => this.route.queryParams),
      takeUntil(this.destroy$)
    ).subscribe(params => {
      this.selectedFolder = this.vaultData.folder.content.find((folder: any) => folder.id === +params['folderId']) || null;
      this.pageTitle$ = this.getPageTitle$();
    })
  }

  private getPageTitle$(): Observable<string> {
    return this.selectedFolder
      ? this.translateService.get(
        `pages.DOCUMENT_VAULT.FOLDER_NAME.${this.selectedFolder.name
          .replace(' ',  '_').toUpperCase()}`
      )
      : this.translateService.get('pages.DOCUMENT_VAULT.DEFAULT_PAGE_TITLE');
  }
}
