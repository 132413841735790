import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, Observable } from 'rxjs';
import { RootPaths } from 'src/app/app.const';
import { ApiProfileService } from 'src/app/common/profile/api-profile.service';
import { CoTraveler } from 'src/app/common/profile/profile.interfaces';

@Injectable({
  providedIn: 'root',
})
export class CoTravelerResolver  {
  constructor(
    private profileService: ApiProfileService,
    private router: Router
  ) {}

  resolve(): Observable<CoTraveler[]> {
    return this.profileService.getCotravelers().pipe(
      catchError((err) => {
        this.router.navigate([RootPaths.NotFound], { skipLocationChange: true });

        console.error(err);
        return EMPTY;
      })
    );
  }
}
