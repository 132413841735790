import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AemContactFormComponent } from './aem-contact-form.component';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { BackButtonModule } from '@kit/back-button/back-button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { ContactUsFormComponent } from '@kit/aem/common/aem-contact-form/contact-us-form/contact-us-form.component';
import { LabelModule } from '@kit/label/label.module';
import { InputModule } from '@kit/input/input.module';
import { FieldErrorModule } from '@kit/field-error/field-error.module';
import { TranslateModule } from '@kit/translate/translate.module';
import { SelectModule } from '@kit/select/select.module';
import { TextareaModule } from '@kit/textarea/textarea.module';
import { CheckboxModule } from '@kit/checkbox/checkbox.module';
import { ButtonModule } from '@kit/button/button.module';
import { MarkdownPipeModule } from '@kit/markdown-pipe/markdown.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AemContactFormComponent,
    ContactUsFormComponent,
  ],
  exports: [
    AemContactFormComponent,
    ContactUsFormComponent,
  ],
  imports: [
    CommonModule,
    AemButtonModule,
    BackButtonModule,
    SafeHtmlModule,
    LabelModule,
    InputModule,
    FieldErrorModule,
    TranslateModule,
    SelectModule,
    TextareaModule,
    CheckboxModule,
    ButtonModule,
    MarkdownPipeModule,
    ReactiveFormsModule,
  ]
})
export class AemContactFormModule { }
