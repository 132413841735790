import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemQuoteAndImageComponent } from './quote-and-image.component';

@NgModule({
  declarations: [AemQuoteAndImageComponent],
  exports: [AemQuoteAndImageComponent],
  imports: [
    CommonModule,
    SafeHtmlModule,
    ImgAltDirectiveModule,
  ]
})
export class AemQuoteAndImageModule { }
