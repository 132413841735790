import { InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface DateFormat {
  time: string,
  numeric: string,
  fullSpelled: string,
  fullSpelledWithFullMonth: string,
  range: string[],
  day: string,
  hour: string,
  minute: string,
}

export const dateFormatFactory = (translateService: TranslateService) => (<DateFormat>{
  time: translateService.instant('dateFormat.time'),
  numeric: translateService.instant('dateFormat.numeric'),
  fullSpelled: translateService.instant('dateFormat.fullSpelled'),
  fullSpelledWithFullMonth: translateService.instant('dateFormat.fullSpelledWithFullMonth'),
  range: translateService.instant('dateFormat.range'),
  day: translateService.instant('dateFormat.day'),
  hour: translateService.instant('dateFormat.hour'),
  minute: translateService.instant('dateFormat.minute')
});

export const FormatDate = new InjectionToken<DateFormat>('FormatDate');