import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';
import { ButtonComponent } from '@kit/button/button/button.component';
import { map, Observable, takeUntil, takeWhile, timer } from 'rxjs';
import { IconTheme } from '@kit/icon/icon.interface';
import { TuiDestroyService } from '@taiga-ui/cdk';

@Component({
  selector: 'app-button-with-timer',
  templateUrl: './button-with-timer.component.html',
  styleUrls: ['./button-with-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class ButtonWithTimerComponent implements OnInit {
  @Input() public timer: number = 60;
  @Input() public type: ButtonComponent['type'] = 'normal';
  @Input() public size: ButtonComponent['size'] = 'l';
  @Input() public theme: ButtonComponent['theme'] = 'black';
  @Input() public iconTheme: IconTheme = 'amped';
  @Input() public preposition: string;
  @Input() public autoStarted = false;

  public remainTimer$: Observable<string>;
  public disabled = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private destroy$: TuiDestroyService,
  ) { }

  @HostListener('click')
  public onCLick(): void {
    if (this.disabled) return;

    this.startTimer();
  }

  public ngOnInit(): void {
    if (this.autoStarted) {
      this.startTimer();
    }
  }

  private startTimer(): void {
    this.disabled = true;
    this.renderer.addClass(this.elementRef.nativeElement, 'disabled');

    this.remainTimer$ = timer(1, 1000)
      .pipe(
        takeWhile((counter: number) => {
          if (counter >= this.timer) {
            this.disabled = false;
            this.renderer.removeClass(this.elementRef.nativeElement, 'disabled');
            this.cdr.detectChanges();

            return false;
          }

          return true;
        }),
        map((counter: number) => this.getTimerValue(this.timer - counter)),
        takeUntil(this.destroy$),
      )
  }

  private getTimerValue(timerValue: number): string {
    if (timerValue <= 0) return '0:00';

    const minutes = Math.floor(timerValue / 60);
    const seconds = timerValue - (minutes*60);

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }
}
