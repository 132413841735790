import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, EMPTY, Observable } from 'rxjs';
import { RootPaths } from 'src/app/app.const';
import { Activity } from './activity-element.interface';
import { ActivityElementService } from './activity-element.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityResolver  {
  constructor(
    private activityElementService: ActivityElementService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<Activity> {
    const { tripId, activityId } = route.params;
    return this.activityElementService.get(tripId, activityId).pipe(
      catchError((err) => {
        this.router.navigate([RootPaths.NotFound], { skipLocationChange: true });
        console.error(err);
        return EMPTY;
      })
    );
  }
}
