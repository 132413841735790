import { InjectionToken, makeStateKey } from '@angular/core';
import { Bootstrap, Breadcrumbs, RouterConfigurations } from '../model/models';
import { AuthServerConfig } from "@common/auth/auth.interfaces";

export const SERVER_BOOTSTRAP_DATA = new InjectionToken('SERVER_BOOTSTRAP_DATA');
export const SERVER_BREADCRUMBS_DATA = new InjectionToken('SERVER_BREADCRUMBS_DATA');
export const SERVER_ROUTING_DATA = new InjectionToken('SERVER_ROUTING_DATA');
export const SERVER_AUTH_DATA = new InjectionToken('SERVER_AUTH_DATA');
export const BUILD_TIME_STAMP = new InjectionToken('BUILD_TIME_STAMP');

export const SERVER_BOOTSTRAP_DATA_STATE = makeStateKey<Bootstrap>('SERVER_BOOTSTRAP_DATA');
export const SERVER_BREADCRUMBS_DATA_STATE = makeStateKey<Breadcrumbs>('SERVER_BREADCRUMBS_DATA');
export const SERVER_ROUTING_DATA_STATE = makeStateKey<RouterConfigurations>('SERVER_ROUTING_DATA');
export const SERVER_AUTH_DATA_STATE = makeStateKey<AuthServerConfig>('SERVER_AUTH_DATA');
export const BUILD_TIME_STAMP_STATE = makeStateKey<number>('BUILD_TIME_STAMP');
