import { ChangeDetectionStrategy, Component, HostListener, Injector } from '@angular/core';
import { MembershipDialogUpgradeComponent } from '@pages/membership/components/membership-dialog/membership-dialog-upgrade/membership-dialog-upgrade.component';
import { DialogService } from '@kit/dialog/dialog.service';

@Component({
  selector: 'app-upgrade-insurance-policy',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeInsurancePolicyComponent {
  constructor(
    private readonly dialogService: DialogService,
    private readonly injector: Injector,
  ) {}

  @HostListener('click')
  onClick() {
    this.dialogService.open(MembershipDialogUpgradeComponent, null, this.injector);
  }
}
