import { ChangeDetectionStrategy, Component, HostListener, Inject, Injector } from '@angular/core';
import { SurveyBanner } from '@common/model/surveyBanner';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { DialogService } from '@kit/dialog/dialog.service';
import { SurveyDialogComponent } from '@kit/dialog/survey-dialog/survey-dialog.component';

@Component({
  selector: 'app-survay',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurvayComponent {
  constructor(
    private dialogService: DialogService,
    private injector: Injector,
    @Inject(AEM_DATA) private data: SurveyBanner,
  ) {}

  @HostListener('click')
  openDialog() {
    if (!this.data.modal){
      return;
    }

    this.dialogService.open(SurveyDialogComponent, this.data.modal, this.injector);
  }
}
