import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(@Inject(IS_BROWSER_PLATFORM) private isBrowser: boolean) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isBrowser || req.url.startsWith('http') || req.url.startsWith('https')) {
      return next.handle(req);
    } else {
      const separator = req.url.startsWith('/') ? '' : '/';

      return next.handle(req.clone({
        url: `${environment.origin}${separator}${req.url.replace('./', '/')}`
      }));
    }
  }
}
