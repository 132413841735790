import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { SearchSelectComponent } from './search-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from "@kit/icon/icon.module";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SearchSelectComponent],
  exports: [SearchSelectComponent],
  imports: [CommonModule, ReactiveFormsModule, TuiActiveZoneModule, IconModule, TranslateModule],
})
export class SearchSelectModule { }
