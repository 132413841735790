import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { AemButtonModule } from '../aem-button/aem-button.module';
import { ButtonModule } from '../button/button.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { FieldErrorModule } from '../field-error/field-error.module';
import { IconModule } from '../icon/icon.module';
import { InputModule } from '../input/input.module';
import { LabelModule } from '../label/label.module';
import { PasswordSecurityModule } from '../password-security/password-security.module';
import { PasswordModule } from '../password/password.module';
import { PhoneModule } from '../phone/phone.module';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { RegisterFormComponent } from './register-form.component';
import { TuiLetModule } from "@taiga-ui/cdk";

@NgModule({
  declarations: [RegisterFormComponent],
  exports: [RegisterFormComponent],
  imports: [
    CommonModule,
    AemRouterModule,
    ReactiveFormsModule,
    InputModule,
    LabelModule,
    FieldErrorModule,
    CheckboxModule,
    IconModule,
    AemButtonModule,
    ButtonModule,
    TranslateModule,
    SafeHtmlModule,
    PhoneModule,
    PasswordModule,
    PasswordSecurityModule,
    TuiLetModule,
  ],
})
export class RegisterFormModule {
}
