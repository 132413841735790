import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { AemTitledImageComponent } from './aem-titled-image.component';

@NgModule({
  declarations: [AemTitledImageComponent],
  exports: [AemTitledImageComponent],
  imports: [
    CommonModule,
    SafeHtmlModule,
    ImgAltDirectiveModule,
  ]
})
export class AemTitledImageModule { }
