import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemRowArticleComponent } from './row-article.component';

@NgModule({
  declarations: [AemRowArticleComponent],
  exports: [AemRowArticleComponent],
  imports: [
    CommonModule,
  ]
})
export class AemRowArticleModule { }
