<div class="tag tag-theme_{{theme}}" [class.tag_disabled]="disabled">
    <app-icon class="tag_icon"
              *ngIf="icon"
              [icon]="icon"
              theme="inherit"
              size="x-large">
    </app-icon>
    <div class="tag_body">
      <span class="tag_content t-btn-normal">
          {{content}}
      </span>
      <div *ngIf="counter" class="tag_counter weight-medium">{{counter}}</div>
    </div>
    <app-icon class="tag_arrow"
              icon="arrow-right"
              theme="inherit"
              size="x-large">
    </app-icon>
</div>
