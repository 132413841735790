import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AEM_DATA } from "@pages/dynamic/dynamic-render/dynamic-render.const";
import { UserService } from "@common/user/user.service";
import { UserType } from "@common/profile/profile.interfaces";
import { RoleBasedContentWrapper } from "@common/model/roleBasedContentWrapper";
import { distinctUntilChanged, Observable } from "rxjs";
import { DynamicContent } from "@pages/dynamic/dynamic-render/dynamic-render.interface";
import { map } from "rxjs/operators";
import { DynamicRenderService } from "@pages/dynamic/dynamic-render/dynamic-render.service";

@Component({
  selector: 'app-role-based-content-wrapper',
  templateUrl: './role-based-content-wrapper.component.html',
  styleUrls: ['./role-based-content-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleBasedContentWrapperComponent implements OnInit {
  public dynamicContent$: Observable<DynamicContent[]>;

  constructor(
    @Inject(AEM_DATA) public aemData: RoleBasedContentWrapper,
    private dynamicRenderService: DynamicRenderService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.dynamicContent$ = this.userService.userSubscription$.pipe(
      distinctUntilChanged(),
      map((userSubscription: UserType) =>
        this.dynamicRenderService.prepareContent([this.aemData[userSubscription]], userSubscription)
      ),
    )
  }
}
