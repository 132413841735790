import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { map, Observable } from 'rxjs';
import { FieldValidationError } from './field-error.interfaces';

export function wrapValidator(validator: ValidatorFn, errorMessage: string): ValidatorFn {
  return (control: AbstractControl): FieldValidationError | null => validator(control)
    ? { err: errorMessage }
    : null;
}

export function wrapAsyncValidator(
  validator: (control: AbstractControl) => Observable<ValidationErrors | null>,
  errorMessage: string
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<FieldValidationError | null> => validator(control).pipe(
    map(error => error
      ? { err: errorMessage }
      : null
    )
  );
}
