<section class="faq-back-button">
  <div class="shell">
    <app-button [icon]="data.cta.icon"
                (click)="goBack()"
                size="s"
                theme="bordered">
      {{data.cta.label}}
    </app-button>
  </div>
</section>
