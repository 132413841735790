<app-accordion class="flight-item" theme="trip">
  <ng-container accordionTitle>
    <div class="flight-item_header">
      <app-icon class="flight-item_header-icon" icon="plane-fill" theme="inherit" size="x-large"></app-icon>
      <div class="flight-item_header-info">
        <div class="h6 m-h4">{{flight.departure.city}} - {{flight.arrival.city}}</div>
        <div class="t-small m-t-normal">
          <b>{{flight.flightNumber}}</b>
          {{'pages.ADDED_FLIGHTS.DEPARTING' | translate}} {{flight.departure.date | dateFormat:'fullSpelled'}}
          {{'pages.ADDED_FLIGHTS.AT' | translate}} {{flight.departure.date + ' ' + flight.departure.time | dateFormat:'time'}}
        </div>
      </div>
    </div>
  </ng-container>
  <div class="flight-item_body">
    <hr/>
    <div class="flight-item_body-flight-info">
      <div>
        <div class="t-small"><b>{{flight.departure.airportCode}}</b></div>
        <div class="t-tiny">{{flight.departure.city}}</div>
        <div class="t-tiny margin-bottom">{{flight.departure.country.name}}</div>
      </div>
      <div class="t-small flight-number">
        <div><b>{{flight.flightNumber}}</b></div>
        <app-icon class="flight-number_icon" icon="plane-fill" size="large" theme="inherit"></app-icon>
      </div>
      <div>
        <div class="t-small text-align-right"><b>{{flight.arrival.airportCode}}</b></div>
        <div class="t-tiny text-align-right">{{flight.arrival.city}}</div>
        <div class="t-tiny text-align-right margin-bottom">{{flight.arrival.country.name}}</div>
      </div>
    </div>
    <div class="flight-item_body-flight-time">
      <div>
        <div class="t-small">
          <b>{{flight.departure.date + ' ' + flight.departure.time | dateFormat:'time'}}</b>
        </div>
        <div class="t-tiny">{{flight.departure.date | dateFormat:'fullSpelled'}}</div>
      </div>
      <div>
        <div class="t-small text-align-right">
          <b>{{flight.arrival.date + ' ' + flight.arrival.time | dateFormat:'time'}}</b>
        </div>
        <div class="t-tiny text-align-right">{{flight.arrival.date | dateFormat:'fullSpelled'}}</div>
      </div>
    </div>
    <div *ngIf="flight.reservationNumber" class="flight-item_body-flight-addinfo">
      <div>{{'pages.ADDED_FLIGHTS.RESERVATION_NUMBER' | translate}} {{flight.reservationNumber}}</div>
    </div>
    <hr/>
    <div class="flight-item_body-footer">
      <app-icon (click)="delete.emit(flight.flightId)"
                id="flight-card-detailed-action-delete"
                class="delete-button"
                icon="thrash"
                size="x-large"
                theme="inherit">
      </app-icon>
    </div>
  </div>
</app-accordion>
