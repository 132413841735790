<hr />
<div class="accordion" *ngIf="trip">
  <div class="accordion__header">
    <div class="accordion__header-detail">
      <app-icon icon="calendar"
                theme="invert"
                size="large"
                class="accordion__icon">
      </app-icon>
      <div class="t-small m-h5">
        {{ trip.startDate | date: 'MMM dd' }} - {{ trip.endDate | date: 'MMM dd, yyyy' }}
      </div>
    </div>
    <div class="accordion__header-detail">
      <app-icon icon="location"
                theme="invert"
                size="large"
                class="accordion__icon">
      </app-icon>
      <div class="t-small m-h5">{{ getDestinationCountries(trip) }}</div>
    </div>
  </div>
  <div class="accordion__body" *ngIf="trip.actions">
    <hr />
    <div class="accordion__detail" *ngIf="getFlights(trip.actions) as flights">
      <div class="accordion__card" *ngFor="let flight of flights">
        <app-trip-details-accordion-flight [flight]="flight"></app-trip-details-accordion-flight>
      </div>
    </div>
    <div class="accordion__detail" *ngIf="getActivities(trip.actions) as activities">
      <div class="accordion__card" *ngFor="let activity of activities">
        <app-trip-details-accordion-activity [activity]="activity"></app-trip-details-accordion-activity>
      </div>
    </div>
    <div class="accordion__detail" *ngIf="getHotels(trip.actions) as hotels">
      <div class="accordion__card" *ngFor="let hotel of hotels">
        <app-trip-details-accordion-hotel [hotel]="hotel"></app-trip-details-accordion-hotel>
      </div>
    </div>

  </div>
</div>
