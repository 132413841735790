import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, fromEvent, map, Observable, takeUntil } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { Header, Theme } from 'src/app/common/model/models';
import { UserService } from 'src/app/common/user/user.service';
import { WindowRef } from '@common/window-service/window.service';
import { Market, getCountryCode } from '@common/language/language.const';
import { LanguageMappingURLService } from '@common/language-mapping-url/language-mapping-url.service';
import { LanguageService } from '@common/language/language.service';
import { LanguageSwitcherService } from '@kit/language-switcher/language-switcher.service';

const NOT_ROUTE_LINK_REGEXP = /^(mailto|tel|http|https):/;

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class ToolbarComponent implements OnInit {
  @HostBinding('class.mobile-menu-open')
  mobileMenuOpen: boolean = false;

  @HostBinding('class.scrolled')
  isScrolled: boolean = false;

  @HostBinding('style.display')
  isHideToolbar: 'none' | null = null;

  @Output()
  menuStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isFrenchMarket: boolean = getCountryCode(this.document.location.host) === Market.FR;
  public isShownLanguageSwitcher$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private readonly userService: UserService,
    private readonly bootstrapService: BootstrapService,
    private readonly windowRef: WindowRef,
    private readonly onDestroy$: TuiDestroyService,
    private readonly router: Router,
    private readonly renderer: Renderer2,
    private readonly languageMappingURLService: LanguageMappingURLService,
    private readonly languageService: LanguageService,
    private readonly languageSwitcherService: LanguageSwitcherService,
    private readonly activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: Document,
  ) { }

  public data$: Observable<Header> = combineLatest([
    this.bootstrapService.header$,
    this.userService.isAuth$,
  ]).pipe(
    map(([headers, isAuth]) =>
      headers.find(({ entitlements }) =>
        entitlements.anonymous !== isAuth
      )
    )
  );

  ngOnInit(): void {
    fromEvent(this.document, 'scroll').pipe(
      map(() => !!this.windowRef.nativeWindow?.pageYOffset),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$),
    ).subscribe(isScrolled => {
      this.isScrolled = isScrolled;
    });

    this.bootstrapService.theme$.pipe(
      map((theme: Theme) => !!theme?.hasNoHeader),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$),
    ).subscribe((hasNoHeader: boolean) => {
      this.isHideToolbar = hasNoHeader ? 'none' : null;
    });

    this.manageLanguageSwitcherVisibility();
    this.manageBrowserButtons();
  }

  public openMobileMenu(): void {
    this.mobileMenuOpen = true;
    this.renderer.setStyle(this.windowRef.nativeWindow.document.body, 'overflow', 'hidden');
    this.menuStateChange.emit(true);
  }

  public closeMobileMenu(): void {
    if (this.mobileMenuOpen) {
      this.mobileMenuOpen = false;
      this.renderer.setStyle(this.windowRef.nativeWindow.document.body, 'overflow', 'auto');
      this.menuStateChange.emit(false);
    }
  }

  public goToRegister(): void {
    this.closeMobileMenu()
    this.router.navigateByUrl(this.bootstrapService.link.register);
  }

  public isRoute(link: string): boolean {
    return !NOT_ROUTE_LINK_REGEXP.test(link);
  }

  public focusOut(event: Event): void {
    (event.target as HTMLElement).blur();
  }

  private manageLanguageSwitcherVisibility(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntil(this.onDestroy$)
    ).subscribe(event => {
      const navigationEndEvent: NavigationEnd = event as NavigationEnd;
      const currentLanguage: string = this.languageService.getCurrentLanguage().toUpperCase();
      const possibleLanguages: string = this.languageMappingURLService.getPossibleLanguages(this.router.url).find(lang => lang !== currentLanguage)?.toLowerCase();
      const mappedRoute: string = this.languageMappingURLService.getMappedRoute(
        possibleLanguages,
        this.activatedRoute.firstChild.snapshot);

      if (navigationEndEvent.url.includes('admin') || !mappedRoute) {
        this.isShownLanguageSwitcher$.next(false);

        return;
      }

      this.isShownLanguageSwitcher$.next(true);
    });
  }

  private manageBrowserButtons(): void {
    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', () => {
        const currentLanguage: string = this.languageService.getCurrentLanguage();
        const prevUrl: string[] = this.document.referrer.split('/').filter(Boolean);
        const currentUrl: string[] = window.location.href.split('/').filter(Boolean);
        const uniqueElements = [...prevUrl, ...currentUrl].reduce((acc: any, curr) => {
          acc[curr] = (acc[curr] || 0) + 1;
          return acc;
        }, {});
        const regex: RegExp = /[a-z]{2}-[a-z]{2}/;
        const singleElements: string[] = Object.keys(uniqueElements).filter(key => uniqueElements[key] === 1);

        if (singleElements.some((el: string) => regex.test(el))) {
          this.languageSwitcherService.navigateToNewRoute(currentLanguage);
        }
      });
    }
  }
}
