<header class="header">
  <div class="header__main">
    <div class="header__shell shell">
      <app-breadcrumbs *ngIf="data.hasBreadcrumbs"></app-breadcrumbs>

      <div *ngIf="!data.bigVideo; else: bigVideo" class="hero">
        <div class="hero__group">
          <h1 class="hero__title">{{ data.title }}</h1>
          <img *ngIf="data.image?.imagePath"
               class="hero__img-mobile"
               [src]="data.image.imagePath"
               [appImgAlt]="data.image.altText"
               [appImgAltData]="data"/>
          <div class="hero__video-mobile" *ngIf="videoComponent">
            <ng-container *ngComponentOutlet="videoComponent.component; injector: videoComponent.injector"></ng-container>
          </div>

          <div class="hero__price" *ngIf="data.pricePart1">
            <h4 class="zero-margin h4 m-h2">{{ data.pricePart1 }}</h4>
            <p class="zero-margin t-normal m-t-normal" *ngIf="data.pricePart2">{{ data.pricePart2 }}</p>
          </div>

          <app-safe-html class="hero__text" [content]="data.text"></app-safe-html>

          <div class="hero__action">
            <div class="hero__action-list">
              <app-aem-button
                class="hero__action_button"
                *ngFor="let cta of data.ctas"
                [cta]="cta"
                size="l"
                type="big-width"
              ></app-aem-button>
            </div>

            <app-safe-html class="hero__button-text" *ngIf="data.bottomText" [content]="data.bottomText"></app-safe-html>
          </div>
          <ng-content></ng-content>
        </div>
        <img *ngIf="data.image?.imagePath"
             class="hero__img"
             [src]="data.image.imagePath"
             [appImgAlt]="data.image.altText"
             [appImgAltData]="data"/>
        <div class="hero__video" *ngIf="videoComponent">
          <ng-container *ngComponentOutlet="videoComponent.component; injector: videoComponent.injector"></ng-container>
        </div>
        <div class="hero-component" *ngIf="showExtra && extra?.component">
          <ng-container *ngComponentOutlet="extra.component; injector: extra.injector"></ng-container>
        </div>
      </div>
    </div>
  </div>
</header>

<ng-template #bigVideo>
  <h1 class="hero__title hero__title_big-video">{{ data.title }}</h1>
  <app-safe-html class="hero__text hero__text_big-video" [content]="data.text"></app-safe-html>
  <div class="hero__big-video" *ngIf="videoComponent">
    <div class="fullwidth-video">
      <ng-container *ngComponentOutlet="videoComponent.component; injector: videoComponent.injector"></ng-container>
    </div>
  </div>
</ng-template>
