import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, take, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  MaskedEmails,
  Policy,
  PolicyDetails,
  PolicyRequestData,
} from './policy.interface';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from '@kit/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { Router } from '@angular/router';
import { ServerValidationError } from '@kit/server-errors/server-errors.interface';

@Injectable({ providedIn: 'root' })
export class ApiInsurancePolicyService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    public readonly bootstrapService: BootstrapService,
    private readonly router: Router,
  ) {
  }

  public getPolicies(): Observable<Policy[]> {
    return this.http.get<Policy[]>(`${ this.baseUrl }/contracts`)
  }

  public getPolicyDetails(contractNumber: string, origin?: string): Observable<PolicyDetails> {
    const queryParams = origin ? `?origin=${origin}` : '';

    return this.http.get<PolicyDetails>(
      `${ this.baseUrl }/contracts/${ contractNumber }/details${queryParams}`
    ).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err?.status === 404) {
          this.showNotFoundErrorPopup();

          return throwError(err);
        }

        this.showErrorPopUp();

        return throwError(err);
      })
    );
  }

  public verifyContract(policyRequestData: PolicyRequestData, forcedValidation: boolean = true): Observable<MaskedEmails> {
    const body: PolicyRequestData & { forcedValidation: boolean }  = {} as any;

    body.forcedValidation = forcedValidation;
    if (policyRequestData.contractNumber) body.contractNumber = policyRequestData.contractNumber;
    if (policyRequestData.pinCode) body.pinCode = policyRequestData.pinCode;
    if (policyRequestData.firstName) body.firstName = policyRequestData.firstName;
    if (policyRequestData.lastName) body.lastName = policyRequestData.lastName;
    if (policyRequestData.bookingReference) body.bookingReference = policyRequestData.bookingReference;
    if (policyRequestData.dateOfBirth) body.dateOfBirth = policyRequestData.dateOfBirth;

    return this.http.post<MaskedEmails>(`${this.baseUrl}/contracts`, body).pipe(
      catchError((err: HttpErrorResponse) => throwError(this.updateErrorFieldName(err))),
    );
  }

  public sendPinToEmail(contractNumber: string, target: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/contracts/${contractNumber}/pin-validation`, {
      target,
    });
  }

  public getPinCode(policyNumber: string, alreadyAdded: boolean): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/insurance-policy/register`, {
      policyNumber,
      alreadyAdded
    });
  }

  public showOpenPolicyPopUp(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.MY_INSURANCE.TITLES.POLICY_UNAVAILABLE'),
      info: this.translateService.instant('pages.MY_INSURANCE.DESCRIPTIONS.POLICY_UNAVAILABLE'),
      confirmTitle: this.translateService.instant('OK'),
    }).afterClosed$.pipe(
      tap(() => this.router.navigate([this.bootstrapService.link.myInsurancePolicies])),
    ).subscribe();
  }

  private showErrorPopUp(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.MY_INSURANCE.TITLES.SERVICE_UNAVAILABLE'),
      info: this.translateService.instant('pages.MY_INSURANCE.DESCRIPTIONS.SERVICE_UNAVAILABLE'),
      confirmTitle: this.translateService.instant('OK'),
    }).afterClosed$.pipe(
      tap(() => this.router.navigate([this.bootstrapService.link.myInsurancePolicies])),
    ).subscribe();
  }

  private showNotFoundErrorPopup(): void {
    const dialogRef = this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.MY_INSURANCE.TITLES.POLICY_NOT_FOUND'),
      info: this.translateService.instant('pages.MY_INSURANCE.DESCRIPTIONS.POLICY_NOT_FOUND'),
      confirmTitle: this.translateService.instant('OK'),
    });

    const routerSubscription = this.router.events.pipe(
      take(1),
      tap(() => dialogRef.close('linkNavigation')),
    ).subscribe();

    dialogRef.afterClosed$.subscribe((arg: string) => {
      routerSubscription?.unsubscribe();

      if (arg !== 'linkNavigation' ) {
        this.router.navigate([this.bootstrapService.link.myInsurancePolicies])
      }
    });
  }

  private updateErrorFieldName(err: HttpErrorResponse): HttpErrorResponse {
    const errorWithUpdatedFieldName = err?.error?.fault?.errors.map((err: ServerValidationError) => {
      if (err.errorCode === 'CEAZ131' || err.errorCode === 'CEAZ121') {
        const editedError = {...err};
        editedError.fieldName = null;

        return editedError;
      }

      return err;
    })

    return {
      ...err,
      error: {
        ...err.error,
        fault: {
          ...err.error.fault,
          errors: errorWithUpdatedFieldName
        }
      }
    }
  }
}
