import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, EMPTY, map, Observable } from 'rxjs';
import { RootPaths } from '../../../../../app.const';
import { ApiFlightService } from '@common/flight/api-flight.service';
import { FlightInfo } from '@common/flight/flight.interfaces';

@Injectable({
  providedIn: 'root',
})
export class FlightEditResolver  {
  constructor(
    private router: Router,
    private flightService: ApiFlightService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<FlightInfo> {
    const { flightId, tripId } = route.params;
    return this.flightService.getFlight(tripId, flightId).pipe(
      map((flight) => {
        if (!flight.manual) {
          this.router.navigate([RootPaths.NotFound], { skipLocationChange: true });
          return null;
        }

        return flight;
      }),
      catchError((err) => {
        this.router.navigate([RootPaths.NotFound], { skipLocationChange: true });

        console.error(err);
        return EMPTY;
      })
    );
  }
}
