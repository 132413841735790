<div class="label t-small m-t-large" *ngIf="label">
  {{ label }} <span class="label-clarification">{{clarification}}</span>
  <span *ngIf="required" class="required-mark">*</span>
  <app-icon *ngIf="hint"
            icon="info-fill"
            theme="white"
            size="inherit"
            [appTooltip]="hint">
  </app-icon>
</div>
<div class="content">
  <ng-content></ng-content>
</div>
