import { ChangeDetectionStrategy, Component, forwardRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { IconList } from '../icon/icon.interface';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputComponent),
    multi: true
  }],
})
export class InputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public placeholder = '';
  @Input() public icon: IconList = null;
  @Input() public clearButton = false;
  @Input() public maxLength: number = null;
  @Input() public autocomplete = 'on';
  @Input() public inputName: string;
  @Input() public disable: boolean = false;

  @HostListener('selectstart', ['$event']) onSelect(event: PointerEvent): void {
    if (this.disable) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public innerControl = new UntypedFormControl(null);
  public focused = false;
  private destroy$ = new Subject<null>();

  constructor() {}

  public ngOnInit(): void {
    this.innerControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => this.onChange(value));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public onChange = (value: string) => {};
  public onTouched = () => {};

  public writeValue(value: string): void {
    this.innerControl.setValue(value, { emitEvent: false });
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;

    if (isDisabled) {
      this.innerControl.disable();
    } else {
      this.innerControl.enable();
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.onTouched();
  }

  public clear(): void {
    this.innerControl.setValue(null);
  }
}
