import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, Renderer2, RendererStyleFlags2, SimpleChanges } from '@angular/core';
import { IllustrationPosition } from './illustration.interface';

@Component({
  selector: 'app-illustration',
  templateUrl: './illustration.component.html',
  styleUrls: ['./illustration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IllustrationComponent implements OnChanges {
  @Input() src: string;
  @Input() alt: string;
  @Input() colourShapeCircle: string;
  @Input() colourShapeArc: string;
  @Input() position: IllustrationPosition = 'right';

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.colourShapeCircle || changes.colourShapeArc) {
      if (this.colourShapeCircle) {
        this.renderer.setStyle(this.elementRef.nativeElement, '--colourShapeCircle', this.colourShapeCircle, RendererStyleFlags2.DashCase);
      }

      if (this.colourShapeArc) {
        this.renderer.setStyle(this.elementRef.nativeElement, '--colourShapeArc', this.colourShapeArc, RendererStyleFlags2.DashCase);
      }
    }
  }
}