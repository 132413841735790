import { Market } from '../language/language.const';
import { environment } from "../../../environments/environment";

export enum AuthActions {
  activate = 'activate',
  delete = 'delete',
  registration = 'registration',
  login = 'login',
  notificationSettings = 'notification-settings',
  acceptMarketing = 'accept-marketing',
}

export const ResidenceMap = new Map([
  [Market.DE, 0],
  [Market.FR, 1],
  [Market.ES, 2],
  [Market.IT, 3],
  [Market.AT, 4],
  [Market.NL, 8],
]);

export const allowedAuthUrls = [
  environment.api.baseUrl,
];

export const excludeAuthUrls = [
  environment.api.publicInformation,
  environment.api.publicCustomer,
  environment.api.publicBookingPath,
  environment.api.publicProfile
];

export const anonymousAllowedUrls = [
  environment.api.publicInformation,
  environment.api.publicCustomer,
  environment.api.publicBookingPath,
  environment.api.publicProfile,
  environment.freshWorksAdapter,
];

export const ANONYMOUS_TOKEN_KEY = 'anonymous_token';
export const ANONYMOUS_TOKEN_EXPIRES_KEY = 'anonymous_token_expire_at';
