import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-send-invite-email',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendInviteEmailComponent {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly translateService: TranslateService,
  ){}

  @HostListener('click')
  openEmailApp(): void {
    const title = this.translateService.instant('email.INVITE_MAIL.TITLE');
    const body = this.document.location.origin;
    const pathNamePart = this.document.location.pathname.split('/')[1];
    const multiLangRegex = /[a-z]{2}-[a-z]{2}/;

    if (multiLangRegex.test(pathNamePart)) {
      window.open(`mailto:?subject=${title}&body=${body}/${pathNamePart}`);

      return;
    }

    window.open(`mailto:?subject=${title}&body=${body}`);
  }
}
