import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FAQ } from 'src/app/common/model/models';
import { LinkingDataService } from 'src/app/common/seo/linking-data.service';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-faq-block',
  templateUrl: './faq-block.component.html',
  styleUrls: ['./faq-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemFaqComponent extends AemBaseBlockComponent {
  constructor(
    private readonly structuredService: LinkingDataService,
    @Inject(AEM_DATA) public override data: FAQ,
  ) {
    super(data);
    this.structuredService.updateFAQStructuredData(data.items);
  }
}
