import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../button/button.module';
import { ForbiddenComponent } from './forbidden.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [ForbiddenComponent],
  exports: [ForbiddenComponent],
  imports: [CommonModule, ButtonModule, TranslateModule, RouterModule],
})
export class ForbiddenModule {}
