<section class="section">
  <div class="shell">
    <div class="code-input">
      <div class="code-input_title h5 m-h3">{{ 'pages.MY_INSURANCE.TITLES.ENTER_POLICY_CODE' | translate }}</div>
      <div class="code-input_text t-small m-t-normal">
        <app-safe-html [content]="'pages.MY_INSURANCE.DESCRIPTIONS.PIN_CODE' | translate : {email: email.email}"></app-safe-html>
      </div>
      <app-label [label]="'pages.MY_INSURANCE.LABELS.PIN_CODE' | translate" [required]="true">
        <app-input [placeholder]="'pages.MY_INSURANCE.PLACEHOLDERS.PIN_CODE' | translate"
                   [maxLength]="6"
                   [formControl]="pinControl">
        </app-input>
        <app-field-error [control]="pinControl"></app-field-error>
      </app-label>
      <div class="code-input_buttons">
        <app-button class="code-input_button"
                    type="full-width"
                    [disabled]="pinControl.touched && pinControl.invalid"
                    (click)="onSubmit()">
          {{ 'pages.MY_INSURANCE.ACTIONS.SUBMIT' | translate }}
        </app-button>
        <app-button-with-timer class="code-input_button"
                    [autoStarted]="true"
                    [preposition]="preposition"
                    type="full-width"
                    theme="bordered"
                    (click)="resendCode()">
          {{'pages.MY_INSURANCE.ACTIONS.RESEND' | translate }}
        </app-button-with-timer>
      </div>
    </div>
  </div>
</section>
