<ng-container *ngIf="(tripList$ | async) as tripList">
  <header class="header" *ngIf="tripList.length; else createTripBlock">
    <div class="header__main">
      <div class="header__shell shell">
        <div class="brief">
          <h1 class="h1 m-h1 hero-title">{{ data.stateHasTrips.title }}</h1>
          <div class="hero-title_group">
            <div class="t-large note" *ngIf="data.stateHasTrips.text">
              <app-safe-html [content]="data.stateHasTrips.text"></app-safe-html>
            </div>
            <app-aem-button
              *ngIf="data.stateHasTrips.cta"
              class="action"
              [cta]="data.stateHasTrips.cta"
              size="m"
              type="full-width"
            ></app-aem-button>
          </div>
        </div>
      </div>
    </div>
    <div class="header__bottom">
      <div class="shell">
        <div class="slider_container">
          <app-slider [pagination]="false">
            <ng-template *ngFor="let trip of tripList" #swiperSlide>
              <app-slider-item>
                <app-trip-card
                  class="slider-item_card"
                  [data]="trip"
                  [allowMedia]="false">
                </app-trip-card>
              </app-slider-item>
            </ng-template>
          </app-slider>
        </div>
      </div>
    </div>
  </header>
</ng-container>

<ng-template #createTripBlock>
  <header class="header">
    <div class="header__main">
      <div class="header__shell shell create-trip">
        <div class="brief no-trips-header">
          <h1 class="h1 m-h2 hero-title">{{ data.stateHasNoTrips.title }}</h1>
          <div class="t-large m-t-large no-trips-header-text" *ngIf="data.stateHasNoTrips.title">
            <app-safe-html [content]="data.stateHasNoTrips.text"></app-safe-html>
          </div>
          <div class="m-placeholder">
            <img [src]="data.stateHasNoTrips.image.imagePath"
                 [appImgAlt]="data.stateHasNoTrips.image.altText"
                 [appImgAltData]="data">
            <div class="h5 placeholders_item-title">{{ data.stateHasNoTrips.imageCaption }}</div>
          </div>
          <div class="t-placeholders-container">
            <div class="t-placeholders">
              <div *ngFor="let opacity of [1, 0.5, 0.3]" class="t-placeholders_item" [style.opacity]="opacity">
                <img [src]="data.stateHasNoTrips.image.imagePath"
                     [appImgAlt]="data.stateHasNoTrips.image.altText"
                     [appImgAltData]="data">
                <div class="h5 t-placeholders_item-title">{{ data.stateHasNoTrips.imageCaption }}</div>
              </div>
            </div>
          </div>
          <div class="no-trips-header-actions">
            <app-aem-button
              class="hero__action_button"
              *ngFor="let cta of data.stateHasNoTrips.ctas"
              [cta]="cta"
              size="m"
              type="full-width"
            ></app-aem-button>
          </div>
        </div>
        <div class="placeholders-container">
          <div class="placeholders">
            <div *ngFor="let opacity of [1, 0.5, 0.3]" class="placeholders_item" [style.opacity]="opacity">
              <img [src]="data.stateHasNoTrips.image.imagePath"
                   [appImgAlt]="data.stateHasNoTrips.image.altText"
                   [appImgAltData]="data">
              <div class="h5 placeholders_item-title">{{ data.stateHasNoTrips.imageCaption }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</ng-template>
