import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { catchError, takeUntil, tap, Observable, map, switchMap } from 'rxjs';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { Policy, PolicyDetails, PolicyDocument } from '../../policy.interface';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@common/user/user.service';

@Component({
  selector: 'app-insurance-policy-details',
  templateUrl: './insurance-policy-details.component.html',
  styleUrls: ['./insurance-policy-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class InsurancePolicyDetailsComponent implements OnInit {
  public policyDetails$: Observable<PolicyDetails>;

  private readonly policyCode = atob(this.route.firstChild.snapshot.params.policyId);
  private readonly userEmail = this.userService.userData.email;

  constructor(
    private readonly destroy$: TuiDestroyService,
    private readonly policyService: ApiInsurancePolicyService,
    public readonly bootstrapService: BootstrapService,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.initPolicyDetails();
  }

  public getDocument(doc: PolicyDocument): void {
    //TODO

    // this.policyService.getDocument(doc, this.policyDetails.idToken).pipe(
    //   catchError((err: HttpErrorResponse) => {
    //     if (err.status === 404) {
    //       this.timer?.unsubscribe();
    //
    //       return this.getDialogFlow();
    //     }
    //
    //     return of(null);
    //   }),
    //   takeUntil(this.destroy$)
    // ).subscribe(data => data?.content && this.downloadFile(data.content));
  }

  private downloadFile(data: string): void {
    const arrayBuffer = this.base64ToArrayBuffer(data);
    const blob = new Blob([arrayBuffer], {type: "application/pdf"});
    const link = window.URL.createObjectURL(blob);

    window.open(link, '_blank');
  }

  private base64ToArrayBuffer(base64: string): Uint8Array {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);

    binaryString.split('').forEach((char, i) => {
      bytes[i] = char.charCodeAt(0);
    });

    return bytes;
  }

  private initPolicyDetails(): void {
    this.policyDetails$ = this.getPolicyOrigin()
      .pipe(
        switchMap((origin: string) => this.policyService.getPolicyDetails(this.policyCode, origin)),
      )
  }

  private getPolicyOrigin(): Observable<string> {
    return this.policyService.getPolicies()
      .pipe(
        map((policies: Policy[]) => {
          return policies.find((policy: Policy) => policy.number === this.policyCode).origin || 'TRAVEL';
        }),
        takeUntil(this.destroy$),
      )
  }
}

