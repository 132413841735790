import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { wrapValidator } from '@kit/field-error/field-error.utils';
import { maxDateValidator, notBlankValidator } from '@kit/utils/validators';
import { UserService } from '@common/user/user.service';
import { ApiInsurancePolicyService } from '@pages/account/components/my-insurance/insurance-policy.service';
import { PolicyRequestData, PolicyUserDataForm, PolicyValidationInputs } from '@pages/account/components/my-insurance/policy.interface';
import { catchError, takeUntil, throwError } from 'rxjs';
import { getAllFormErrors } from '@kit/utils/form';
import { applyDaysOffset, dateToEndDay } from '@kit/utils/date.utils';

@Component({
  selector: 'app-insurance-user-data',
  templateUrl: './insurance-user-data.component.html',
  styleUrls: ['./insurance-user-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService, ServerErrorsValidationService]
})
export class InsuranceUserDataComponent implements OnInit {
  @Input() public policyValidationParameter: keyof PolicyRequestData;
  @Input() public contractNumber: string = null;

  @Output() complete = new EventEmitter<string>();

  public userDataForm: UntypedFormGroup;
  public today = applyDaysOffset(dateToEndDay(new Date()), 0);

  constructor(
    public readonly bootstrapService: BootstrapService,
    private readonly fb: UntypedFormBuilder,
    private readonly validationService: ServerErrorsValidationService,
    private readonly userService: UserService,
    private readonly policyService: ApiInsurancePolicyService,
    private readonly destroy$: TuiDestroyService,
  ) { }

  public ngOnInit(): void {
    this.initForm();
  }

  public disableButton(): boolean {
    return this.userDataForm.touched && !!getAllFormErrors(this.userDataForm)?.length;
  }

  public onSubmit(): void {
    this.userDataForm.markAllAsTouched();

    if (!!getAllFormErrors(this.userDataForm)?.length) {
      return;
    }

    const policyRequestData: PolicyRequestData = {
      contractNumber: this.contractNumber,
      firstName: this.userDataForm.get('firstName').value,
      lastName: this.userDataForm.get('lastName').value,
      [this.policyValidationParameter]: this.userDataForm.get(this.policyValidationParameter).value
    }

    this.policyService.verifyContract(policyRequestData, true)
      .pipe(
        catchError((err) => {
          this.validationService.handleServerError(err?.error);
          return throwError(() => err);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.complete.emit(this.contractNumber));
  }

  private initForm(): void {
    const formControlsConfig: PolicyUserDataForm = {
      firstName: [
        this.userService.userData.firstName,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
        ],
        this.validationService.createValidator('userName')
      ],
      lastName: [
        this.userService.userData.lastName,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
        ],
        this.validationService.createValidator('userLastName')
      ],
    }

    if (this.policyValidationParameter === PolicyValidationInputs.BookingReference) {
      formControlsConfig.bookingReference = [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
        ],
        this.validationService.createValidator('bookingReference')
      ];
    }

    if (this.policyValidationParameter === PolicyValidationInputs.DateOfBirth) {
      formControlsConfig.dateOfBirth = [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(maxDateValidator(this.today), 'errors.CEAZ000_Past')
        ],
        this.validationService.createValidator('dateOfBirth')
      ];
    }

    this.userDataForm = this.fb.group(formControlsConfig);

    this.userDataForm.addAsyncValidators(this.validationService.createValidator());
  }

}
