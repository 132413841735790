import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[stepperItem]'
})
export class StepperItemDirective {
    @Input('stepperItem') title: string;

    constructor(public template: TemplateRef<any>) { }

}
