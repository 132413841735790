import { Injectable } from '@angular/core';
import { dateToEndDay } from '@kit/utils/date.utils';
import { PremiumDetail, UpgradeReasonEnum } from '@pages/admin/components/user-edit/premium-details.interface';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  constructor(
  ) { }

  public joinMemberships(memberships: PremiumDetail[]): PremiumDetail[] {
    const joinedMemberships: PremiumDetail[] = [];

    const filteredAnSortedMemberships = memberships
      .filter((membership: PremiumDetail) => membership.upgradeReason === UpgradeReasonEnum.INSURANCE_POLICY)
      .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

    if (filteredAnSortedMemberships.length === 0) {
      return [];
    }

    let currentMembership = filteredAnSortedMemberships[0];

    filteredAnSortedMemberships.forEach((nextMembership) => {
      const currentEndDate = new Date(currentMembership.endDate);
      const nextStartDate = new Date(nextMembership.startDate);
      const nextEndDate = new Date(nextMembership.endDate);

      if ((currentEndDate >= nextStartDate || this.isNextDay(currentEndDate, nextStartDate)) && currentEndDate < nextEndDate) {
        currentMembership.endDate = nextMembership.endDate;
        return;
      }

      if (nextEndDate > currentEndDate) {
        joinedMemberships.push(currentMembership);
        currentMembership = nextMembership;
      }
    });

    joinedMemberships.push(currentMembership);

    return joinedMemberships;
  }

  public hasCurrentPolicy(memberships: PremiumDetail[]): boolean {
    return this.getAllCurrentPolicies(memberships).length > 0;
  }

  public hasCurrentPolicyWithPolicyNumber(memberships: PremiumDetail[]): boolean {
    return this.getCurrentPoliciesWithPolicyNumber(memberships).length > 0;
  }

  public hasFuturePolicy(memberships: PremiumDetail[]): boolean {
    return this.getFuturePolicies(memberships).length > 0;
  }

  public getAllCurrentPolicies(memberships: PremiumDetail[]): PremiumDetail[] {
    const currentDateTime = new Date().getTime();

    return memberships
    .filter(this.isInsurance)
    .filter((membership: PremiumDetail) => (this.getMembershipStartDateTime(membership.startDate) <= currentDateTime)
      && (this.getMembershipEndDateTime(membership.endDate) >= currentDateTime));
  }

  public getCurrentPoliciesWithPolicyNumber(memberships: PremiumDetail[]): PremiumDetail[] {
    const currentDateTime = new Date().getTime();

    return memberships
    .filter(this.isInsurance)
    .filter((membership: PremiumDetail) => membership.insurance)
    .filter((membership: PremiumDetail) => (this.getMembershipStartDateTime(membership.startDate) <= currentDateTime)
      && (this.getMembershipEndDateTime(membership.endDate) >= currentDateTime));
  }

  public getFuturePolicies(memberships: PremiumDetail[]): PremiumDetail[] {
    const currentDateTime = new Date().getTime();

    return memberships
    .filter(this.isInsurance)
    .filter((membership: PremiumDetail) => (this.getMembershipStartDateTime(membership.startDate) > currentDateTime)
      && (this.getMembershipEndDateTime(membership.endDate) > currentDateTime));
  }

  private isInsurance(membership: PremiumDetail): boolean {
    return membership.upgradeReason === UpgradeReasonEnum.INSURANCE_POLICY;
  }

  private getMembershipStartDateTime(startDate: string): number {
    return new Date(startDate).getTime();
  }

  private getMembershipEndDateTime(endDate: string): number {
    return dateToEndDay(endDate).getTime();
  }

  private isNextDay(currentEndDate: Date, nextStartDate: Date): boolean {
    const nextDay = new Date(currentEndDate.getTime());
    nextDay.setDate(currentEndDate.getDate() + 1);

    return nextDay.toDateString() === nextStartDate.toDateString();
  }

}
