<app-trip-hero-block (goBack)="back()">
  <div class="trips">
    <div class="h4 m-h2">{{ 'pages.MERGE_TRIPS.YOUR_TRIPS' | translate }}</div>
    <div *ngFor="let trip of trips$ | async" class="trips_items">
      <app-accordion theme="trip">
        <ng-container accordionTitle>
          <div class="trip-details">
            <app-checkbox (click)="checkBoxClick($event, trip.tripId)"></app-checkbox>
            <div>
              <div class="h6 m-h4">{{trip.name}}</div>
              <div class="t-small m-h5">
                {{trip.destinationPoints.length}} {{ 'pages.MERGE_TRIPS.DESTINATIONS' | translate }} - {{trip.startDate | date: 'MMM dd'}} - {{trip.endDate | date: 'MMM dd, yyyy'}}
              </div>
            </div>
          </div>
        </ng-container>
        <app-trip-details-accordion [trip]="trip"></app-trip-details-accordion>
      </app-accordion>
    </div>
    <app-button (click)="mergeTrips()"
                id="merge-trip-action-merge"
                class="trips_button"
                type="full-width"
                size="l"
                theme="black">
      {{ 'pages.MERGE_TRIPS.JOIN_TRIPS' | translate }}
    </app-button>
    <app-button (click)="back()"
                id="merge-trip-action-back"
                class="trips_button"
                type="full-width"
                size="l"
                theme="white">
      {{ 'pages.MERGE_TRIPS.CANCEL' | translate }}
    </app-button>
  </div>
</app-trip-hero-block>
