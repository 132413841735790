import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { Observable, of, shareReplay, take, takeUntil } from 'rxjs';
import { LanguageService } from 'src/app/common/language/language.service';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { ApiInformationService } from '@common/information/api-information.service';
import { DestinationResponse } from '@common/information/destination.interface';
import { DestinationExplorerHero } from '@common/model/destinationExplorerHero';
import { Country } from '@pages/trip/trip.interfaces';
import { AemFragmentComponent } from '../aem-base-block/aem-fragment';
import { wrapValidator } from '../field-error/field-error.utils';
import { IS_BROWSER_PLATFORM } from '../utils/ssr.utils';

@Component({
  selector: 'app-destination-hero',
  templateUrl: './destination-hero.component.html',
  styleUrls: ['./destination-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class AemDestinationHeroComponent extends AemFragmentComponent implements OnInit {
  selectedCountry = new FormControl(null, wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'));
  departureCountry = new FormControl(null, wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'));
  countryName: string;
  destination: DestinationResponse;
  countries$: Observable<Country[]>;

  constructor(
    @Inject(AEM_DATA) public override data: DestinationExplorerHero,
    private informationService: ApiInformationService,
    private langService: LanguageService,
    private onDestroy$: TuiDestroyService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    @Inject(IS_BROWSER_PLATFORM) private isBrowser: boolean,
  ) {
    super(data);

    if (this.isBrowser) {
      this.countries$ = this.informationService.getCountries().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
    } else {
      this.countries$ = of([]);
    }
   }

  ngOnInit(): void {
    const { destinationCountryId, departureCountryId } = this.route.snapshot.queryParams;

    this.countries$.pipe(
      take(1),
      takeUntil(this.onDestroy$)
    ).subscribe((countries) => {
      if (destinationCountryId) {
        const destination = countries.find((country) => country.countryId.toString() === destinationCountryId?.toString());
        if (destination) this.selectedCountry.setValue(destination);
      }

      const departure = departureCountryId
        ? countries.find((country) => country.countryId.toString() === departureCountryId?.toString())
        : countries.find((country) => country.code.toLowerCase() === this.langService.countryCode.toLowerCase());

      if (departure) this.departureCountry.setValue(departure);

      this.loadData(false);
    });
  }

  public loadData(markTouched = true): void {
    if (markTouched) {
      this.selectedCountry.markAsTouched();
      this.departureCountry.markAsTouched();
    }

    const selectedCountry: Country = this.selectedCountry.value;
    const departureCountry: Country = this.departureCountry.value;

    if (this.selectedCountry.valid && this.departureCountry.valid) {
      this.destination = null;
      this.countryName = selectedCountry?.name;

      this.informationService.getDestination(selectedCountry.code, departureCountry.code)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(destination => {
          this.destination = destination;
          this.cdr.markForCheck();
        });
    }
  }
}
