import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { wrapValidator } from '@kit/field-error/field-error.utils';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemResetPasswordComponent extends AemFragmentComponent {
  public emailControl = new UntypedFormControl(null, wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'));

  send(): void {
    // To DO
  }
}
