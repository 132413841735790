import { CreateTripDto } from '../../trip.interfaces';
import { CreateTripSource } from './create-trip.interface';
import { TranslateService } from '@ngx-translate/core';

export function getTripName(name: string, translateService: TranslateService, userResidence: string): string {
  if (name?.trim()) return name?.trim();

  const currentDate = new Date();
  const [month, day, year] = [
    (currentDate.getMonth() + 1).toString().padStart(2, '0'),
    currentDate.getDate().toString().padStart(2, '0'),
    currentDate.getFullYear(),
  ];
  const tripDate = userResidence === 'US'
    ? `${month}/${day}/${year}`
    : `${day}/${month}/${year}`;

  return `${translateService.instant('forms.CREATE_TRIP.TRIP_ADDED')} ${tripDate}`;
}

export function mapTripSourceToDto(
  source: CreateTripSource,
  translateService: TranslateService,
  userResidence: string
): CreateTripDto {
  return {
    departureCountry: source.departureCountry,
    destinationPoints: source.destinationPoints,
    name: getTripName(source.name, translateService, userResidence),
    startDate: source.from?.toISOString(),
    endDate: source.to?.toISOString(),
  };
}
