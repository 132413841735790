<app-dialog-wrapper>
  <ng-container dialogContent>
    <div class="phone-dialog">
      <div class="h5 m-h3">
        {{ 'pages.NOTIFICATION.PHONE_DIALOG.TITLE' | translate }}
      </div>
      <div class="t-normal m-t-normal">
        {{ 'pages.NOTIFICATION.PHONE_DIALOG.TEXT' | translate }}
      </div>
      <app-label [label]="'pages.NOTIFICATION.PHONE_DIALOG.LABEL' | translate" [required]="true">
        <app-phone [placeholder]="'pages.NOTIFICATION.PHONE_DIALOG.PLACEHOLDER' | translate"
                   [formControl]="phoneControl">
        </app-phone>
        <app-field-error [control]="phoneControl"></app-field-error>
      </app-label>
      <div class="phone-dialog_buttons">
        <app-button type="big-width"
                    class="phone-dialog_button"
                    (click)="save()">
          {{ 'pages.NOTIFICATION.PHONE_DIALOG.CONFIRM' | translate }}
        </app-button>
        <app-button type="big-width"
                    theme="bordered"
                    class="phone-dialog_button"
                    (click)="cancel()">
          {{ 'pages.NOTIFICATION.PHONE_DIALOG.CANCEL' | translate }}
        </app-button>
      </div>
    </div>
  </ng-container>
</app-dialog-wrapper>
