/**
 * Content API
 * The API which will provide the content for Angular application to render Allyz websites 
 *
 * OpenAPI spec version: v0.1
 * Contact: tbd@allyz.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Fragment } from './fragment';

export interface Block extends Fragment { 
    marginTop?: Block.MarginTopEnum;
    marginBottom?: Block.MarginBottomEnum;
    /**
     * Background colour
     */
    colourBackground?: string;
}
export namespace Block {
    export type MarginTopEnum = 'none' | 'small' | 'medium' | 'high';
    export const MarginTopEnum = {
        None: 'none' as MarginTopEnum,
        Small: 'small' as MarginTopEnum,
        Medium: 'medium' as MarginTopEnum,
        High: 'high' as MarginTopEnum
    };
    export type MarginBottomEnum = 'none' | 'small' | 'medium' | 'high';
    export const MarginBottomEnum = {
        None: 'none' as MarginBottomEnum,
        Small: 'small' as MarginBottomEnum,
        Medium: 'medium' as MarginBottomEnum,
        High: 'high' as MarginBottomEnum
    };
}