<div class="travel-planner__column">
  <div class="travel-planner__row">
    <div class="travel-planner__detail">
      <div class="travel-planner__cart">
        <div class="travel-planner__detail-info">
          <h5 class="travel-planner__title">{{ 'forms.TRIP_PLANNER.LABELS.FLIGHT' | translate }}</h5>
          <div class="travel-planner__text text-large">
            {{ flight.departure.city }}
            <app-icon icon="plane-flies" theme="inherit" size="large" class="travel-planner__icon">
            </app-icon>
            {{ flight.arrival.city }}
          </div>
          <div class="travel-planner__text text-large">{{ 'forms.TRIP_PLANNER.LABELS.FLIGHT_NUMBER' | translate }}
            <b>{{ flight.flightNumber }}</b>
          </div>
          <div class="travel-planner__text text-large" *ngIf="flight.reservationNumber">
            {{ 'forms.TRIP_PLANNER.LABELS.BOOKING_NUMBER' | translate }} <b class="travel-planner__text_break-all">{{
              flight.reservationNumber }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="travel-planner__row travel-planner__row-center travel-planner__row-details">
    <ng-container [ngSwitch]="flight.flightStatus">
      <ng-container *ngSwitchCase="FlightStatus.scheduled">
        <div class="travel-planner__verified">
          <app-icon theme="inherit" size="inherit" icon="done"
            class="travel-planner__info-icon travel-planner__info-icon_success">
          </app-icon>
          <span class="travel-planner__text-green travel-planner__text text-large">
            {{ 'forms.TRIP_PLANNER.LABELS.STATUS_SCHEDULED' | translate }}
          </span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="FlightStatus.untracked">
        <div class="travel-planner__verified">
          <app-icon class="travel-planner__info-icon" icon="plane-flies" theme="inherit" size="inherit">
          </app-icon>
          <span class="travel-planner__text text-large">{{ 'forms.TRIP_PLANNER.LABELS.STATUS_UNTRACKED_FLIGHT' |
            translate }}</span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="FlightStatus.canceled">
        <div class="travel-planner__verified">
          <app-icon theme="inherit" size="inherit" icon="plane-cancelled"
            class="travel-planner__info-icon travel-planner__info-icon_failed">
          </app-icon>
          <span class="travel-planner__text text-large">
            {{ 'forms.TRIP_PLANNER.LABELS.STATUS_CANCELED' | translate }}
          </span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="FlightStatus.delayed">
        <div class="travel-planner__verified">
          <app-icon icon="clock" theme="inherit" size="inherit" class="travel-planner__info-icon">
          </app-icon>
          <span class="travel-planner__text text-large">{{ 'forms.TRIP_PLANNER.LABELS.STATUS_DELAYED' | translate :
            {time: flight | flightDelay } }}</span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="FlightStatus.early">
        <div class="travel-planner__verified">
          <app-icon icon="delay" theme="inherit" size="inherit" class="travel-planner__info-icon">
          </app-icon>
          <span class="travel-planner__text text-large">{{ 'forms.TRIP_PLANNER.LABELS.STATUS_EARLY' | translate
            }}</span>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="travel-planner__verified">
          <app-icon theme="inherit" size="inherit" icon="done"
            class="travel-planner__info-icon travel-planner__info-icon_success">
          </app-icon>
          <span class="travel-planner__text-green travel-planner__text text-large">
            {{ 'forms.TRIP_PLANNER.LABELS.STATUS_SCHEDULED' | translate }}
          </span>
        </div>
      </ng-container>
    </ng-container>

    <p class="travel-planner__text text-normal"><b>{{ 'forms.TRIP_PLANNER.LABELS.AIRPORT' | translate }} {{
        flight.departure.city }} ({{ flight.departure.airportCode }})</b></p>
    <p class="travel-planner__text text-normal">
      {{ 'forms.TRIP_PLANNER.LABELS.DEPARTURE_DATE' | translate }}
      <app-updated-field-value [oldValue]="flight.flightFieldsOld?.departure | dateFormat: 'fullSpelled'"
        [newValue]="flight.departure.date | dateFormat: 'fullSpelled'">
      </app-updated-field-value>
    </p>
    <p class="travel-planner__text text-normal">
      {{ 'forms.TRIP_PLANNER.LABELS.DEPARTURE_TIME' | translate }}
      <app-updated-field-value [oldValue]="flight.flightFieldsOld?.departure?.slice(11, 19) | dateFormat: 'time'"
        [newValue]="flight.departure.time | dateFormat: 'time'">
      </app-updated-field-value>
    </p>
    <p class="travel-planner__text text-normal" *ngIf="flight.departure.terminal || flight.departure.gate">
      <ng-container *ngIf="flight.departure.terminal">
        {{ 'forms.TRIP_PLANNER.LABELS.TERMINAL' | translate }}
        <app-updated-field-value [oldValue]="flight.flightFieldsOld?.departureTerminal"
          [newValue]="flight.departure.terminal">
        </app-updated-field-value>
      </ng-container>
      <ng-container *ngIf="flight.departure.terminal && flight.departure.gate"> - </ng-container>
      <ng-container *ngIf="flight.departure.gate">
        {{ 'forms.TRIP_PLANNER.LABELS.GATE' | translate }}
        <app-updated-field-value [oldValue]="flight.flightFieldsOld?.departureGate" [newValue]="flight.departure.gate">
        </app-updated-field-value>
      </ng-container>
    </p>
    <p class="travel-planner__text text-normal" *ngIf="flight.checkInNumber">
      {{ 'forms.TRIP_PLANNER.LABELS.CHECK_IN_DESK' | translate }}
      <app-updated-field-value [oldValue]="flight.flightFieldsOld?.checkInNumber" [newValue]="flight.checkInNumber">
      </app-updated-field-value>
    </p>

    <ul class="travel-planner-list" *ngIf="flight.manual">
      <li class="travel-planner-list__item">{{ 'forms.TRIP_PLANNER.LABELS.INFO_MORE_AIRLINE_WEBSITE' | translate }}
      </li>
    </ul>

    <p class="travel-planner__text-red t-tiny m-t-normal" *ngIf="flight.flightStatus === FlightStatus.canceled">
      {{ 'forms.TRIP_PLANNER.LABELS.INFO_MORE_AIRLINE_WEBSITE' | translate }}
    </p>
  </div>

  <div class="travel-planner__row travel-planner__row-center travel-planner__row-details">
    <p class="travel-planner__text text-normal"><b>{{ 'forms.TRIP_PLANNER.LABELS.AIRPORT' | translate }} {{
        flight.arrival.city }} ({{ flight.arrival.airportCode }})</b></p>
    <p class="travel-planner__text text-normal">
      {{ 'forms.TRIP_PLANNER.LABELS.ARRIVAL_DATE' | translate }}
      <app-updated-field-value [oldValue]="flight.flightFieldsOld?.arrival | dateFormat: 'fullSpelled'"
        [newValue]="flight.arrival.date | dateFormat: 'fullSpelled'">
      </app-updated-field-value>
    </p>
    <p class="travel-planner__text text-normal">
      {{ 'forms.TRIP_PLANNER.LABELS.ARRIVAL_TIME' | translate }}
      <app-updated-field-value [oldValue]="flight.flightFieldsOld?.arrival?.slice(11, 19) | dateFormat: 'time'"
        [newValue]="flight.arrival.time | dateFormat: 'time'">
      </app-updated-field-value>
    </p>
    <p class="travel-planner__text text-normal" *ngIf="flight.arrival.terminal || flight.arrival.gate">
      <ng-container *ngIf="flight.arrival.terminal">
        {{ 'forms.TRIP_PLANNER.LABELS.TERMINAL' | translate }}
        <app-updated-field-value [oldValue]="flight.flightFieldsOld?.arrivalTerminal"
          [newValue]="flight.arrival.terminal">
        </app-updated-field-value>
      </ng-container>
      <ng-container *ngIf="flight.arrival.terminal && flight.arrival.gate"> - </ng-container>
      <ng-container *ngIf="flight.arrival.gate">
        {{ 'forms.TRIP_PLANNER.LABELS.GATE' | translate }}
        <app-updated-field-value [oldValue]="flight.flightFieldsOld?.arrivalGate" [newValue]="flight.arrival.gate">
        </app-updated-field-value>
      </ng-container>
    </p>
    <p class="travel-planner__text text-normal" *ngIf="flight.baggageCarouselNumber">
      {{ 'forms.TRIP_PLANNER.LABELS.BAGGAGE_CAROUSEL' | translate }}
      <app-updated-field-value [oldValue]="flight.flightFieldsOld?.baggageCarouselNumber"
        [newValue]="flight.baggageCarouselNumber">
      </app-updated-field-value>
    </p>
  </div>
</div>
