<div class="shell">
  <div class="reset_form">
    <h2 class="reset_form_title">{{ 'forms.RESET_PASSWORD.TITLE' | translate }}</h2>
    <app-label [label]="'forms.RESET_PASSWORD.LABELS.EMAIL' | translate" [required]="true">
      <app-input id="reset-password" [placeholder]="'forms.RESET_PASSWORD.PLACEHOLDERS.EMAIL' | translate" [clearButton]="true" [formControl]="emailControl">
      </app-input>
      <app-field-error [control]="emailControl"></app-field-error>
    </app-label>

    <app-button id="action-send-recovery-link" theme="black" type="full-width" size="m" (click)="send()">
      {{ 'forms.RESET_PASSWORD.ACTIONS.SEND_RECOVERY_LINK' | translate }}
    </app-button>
  </div>
</div>
