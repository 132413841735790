import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { GradientCardsContent } from '@common/model/gradientCardsContent';

@Component({
  selector: 'app-gradient-cards-content',
  templateUrl: './gradient-cards-content.component.html',
  styleUrls: ['./gradient-cards-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GradientCardsContentComponent extends AemBaseBlockComponent {
  constructor(
    @Inject(AEM_DATA) public override data: GradientCardsContent,
  ) {
    super(data);
  }
}
