import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { SliderModule } from '@kit/carousel/slider.module';
import { IconModule } from '@kit/icon/icon.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { TagModule } from '@kit/tag/tag.module';
import { TooltipModule } from '@kit/tooltip/tooltip.module';
import { AemInsuranceCarrouselComponent } from './insurance-carrousel.component';

@NgModule({
  declarations: [AemInsuranceCarrouselComponent],
  exports: [AemInsuranceCarrouselComponent],
  imports: [
    CommonModule,
    AemButtonModule,
    IconModule,
    SliderModule,
    TooltipModule,
    SafeHtmlModule,
    TagModule,
  ]
})
export class AemInsuranceCarrouselModule { }
