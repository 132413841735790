import { AbstractControl, UntypedFormArray, UntypedFormGroup } from "@angular/forms";

export function getAllFormErrors(control: AbstractControl): string[] | null {
  let controls: AbstractControl[];

  if (control instanceof UntypedFormArray) {
    controls = control.controls;
  } else if (control instanceof UntypedFormGroup) {
    controls = Object.values(control.controls);
  }

  if (controls) {
    const result = [];

    if (control.errors?.err) {
      result.push(control.errors?.err);
    }

    return controls.reduce(
      (acc, childControl) => {
        const childErrors = getAllFormErrors(childControl);
        if (childErrors) {
          return acc.concat(childErrors);
        }

        return acc;
      },
      result
    );
  }



  return control.errors?.err;
}