import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CurrencySymbolPipe } from './currency.pipe';



@NgModule({
  declarations: [CurrencySymbolPipe],
  exports: [CurrencySymbolPipe],
  imports: [CommonModule],
  providers: [CurrencyPipe]
})
export class CurrencyModule { }
