import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ManageAccountModule } from '@pages/account/components/manage-account/manage-account.module';
import { AemAccountEditFormComponent } from './account-edit-form.component';

@NgModule({
  declarations: [AemAccountEditFormComponent],
  exports: [AemAccountEditFormComponent],
  imports: [
    CommonModule,
    ManageAccountModule,
  ]
})
export class AemAccountEditFormModule { }
