<label class="check">
  <input
    class="check__input"
    type="checkbox"
    [checked]="checked"
    [disabled]="disabled"
    (change)="onChangeValue($event)"
    (blur)="onBlur()"/>
  <span class="check__box">
    <app-icon class="check__icon"
              [icon]="icon"
              size="large"
              theme="normal">
    </app-icon>
  </span>
  <span class="check__backdrop"></span>
</label>
