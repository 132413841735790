<div class="insurance-content" [formGroup]="firstStepForm">
    <h1 class="h5 m-h4 zero-margin">
      {{ 'forms.BOOKING_PATH.TRIP_DETAILS.TITLE' | translate }}
    </h1>

    <div class="info-container">
      <div class="info-group">
        <div class="t-large full-width weight-medium">
          {{ 'forms.BOOKING_PATH.TRIP_DETAILS.TRIP_DATE.TITLE' | translate }}
        </div>
        <app-label [label]="'forms.BOOKING_PATH.TRIP_DETAILS.TRIP_DATE.FROM' | translate"
                   [required]="true"
                   class="input-field-half">
          <app-date-picker formControlName="from" [min]="originDateLimits.from" [max]="originDateLimits.to"></app-date-picker>
          <app-field-error controlName="from"></app-field-error>
        </app-label>
        <app-label [label]="'forms.BOOKING_PATH.TRIP_DETAILS.TRIP_DATE.TO' | translate"
                   [required]="true"
                   class="input-field-half">
          <app-date-picker formControlName="to" [min]="originDateLimits.from"></app-date-picker>
          <app-field-error controlName="to"></app-field-error>
          <app-field-error [control]="firstStepForm" *ngIf="!tripEndControl.errors"></app-field-error>
        </app-label>
      </div>

      <div class="info-group">
        <div class="full-width">
          <div class="t-large weight-bold">
            {{'forms.BOOKING_PATH.TRIP_DETAILS.PURCHASE_DATE.TITLE' | translate}}
          </div>
          <div class="hint t-small">
            <app-safe-html [content]="'forms.BOOKING_PATH.TRIP_DETAILS.PURCHASE_DATE.HINT' | translate"></app-safe-html>
          </div>
        </div>
        <app-label [label]="'forms.BOOKING_PATH.TRIP_DETAILS.PURCHASE_DATE.LABEL' | translate"
                   [required]="true"
                   class="input-field-half">
          <app-date-picker formControlName="purchaseDate" [min]="purchaseDateLimits.from" [max]="purchaseDateLimits.to"></app-date-picker>
          <app-field-error controlName="purchaseDate"></app-field-error>
        </app-label>
      </div>
    </div>

    <div class="info-container">
      <div class="t-large full-width weight-medium">
        {{'forms.BOOKING_PATH.TRIP_DETAILS.DESTINATIONS.TITLE' | translate}}
      </div>
      <div class="info-group" *ngIf="destinationsControl.value?.length < maxCountOfDestinations">
        <app-label [label]="'forms.BOOKING_PATH.TRIP_DETAILS.DESTINATIONS.LABEL' | translate"
                   [required]="true"
                   class="full-width">
          <app-search-select [placeholder]="'forms.BOOKING_PATH.TRIP_DETAILS.DESTINATIONS.PLACEHOLDER' | translate"
                             [formControl]="destinationsSearchControl"
                             id="get-quote-input-country"
                             viewKey="name"
                             selectKey="countryId"
                             [clearButton]="true"
                             (touched)="destinationsTouch()"
                             [options]="countries$ | async">
          </app-search-select>
          <app-field-error [control]="destinationsControl"></app-field-error>
          <app-field-error controlName="tripDetails.destinationLocation.names"></app-field-error>
        </app-label>
      </div>
      <div class="info-group destinations" *ngIf="destinationsControl.value">
        <div class="countries_item" *ngFor="let country of destinationsControl.value">
          <app-icon icon="error"
                    size="large"
                    theme="inherit"
                    *ngIf="getTooltip(country?.code) as tooltip"
                    [appTooltip]="tooltip"
                    tooltipPosition="left"
                    class="warning">
          </app-icon>
          <div class="name h6">{{ country.name }}</div>
          <app-button icon="close"
                      id="get-quote-action-delete-country"
                      class="delete"
                      (click)="deleteCountry(country)"
                      type="icon-button"
                      size="inherit"
                      theme="inherit">
          </app-button>
        </div>
      </div>
    </div>

    <div class="info-container">
      <div class="t-large full-width weight-medium">
        {{'forms.BOOKING_PATH.TRIP_DETAILS.TRAVELERS.TITLE' | translate}}
      </div>
      <app-counter [formControl]="travelersCounterControl"
                   [min]="1"
                   [max]="50">
      </app-counter>
      <div class="info-group birth-date" formArrayName="travelers">
        <div class="birth-date_item" *ngFor="let control of travelers.controls;let index=index">
          <div class="birth-date_item_header">
            <app-icon icon="person"
                      theme="white"
                      size="large"></app-icon>
            <div class="t-small">
              {{'forms.BOOKING_PATH.TRIP_DETAILS.TRAVELERS.LABEL' | translate : {count: index + 1} }}
            </div>
          </div>
          <app-date-picker id="get-quote-input-birth-date"
                           [formControlName]="index"
                           [min]="birthDateLimits.from"
                           [max]="birthDateLimits.to">
          </app-date-picker>
          <app-field-error [control]="control"></app-field-error>
        </div>
      </div>
    </div>

    <div class="info-container" *ngIf="showCoverOptions">
      <div class="t-large full-width weight-medium">
        {{'forms.BOOKING_PATH.TRIP_DETAILS.OPTIONS.TITLE' | translate}}
      </div>
      <div class="info-group options">
        <div class="options_item"
             (click)="selectCoverOption(CoverageType.CANCELLATION)"
             [class.active]="tripCancellation">
          <div class="options_item_content">
            <app-icon icon="plane-fill"
                      theme="inherit"
                      size="x-large"></app-icon>
            <div class="t-btn-normal">
              {{'forms.BOOKING_PATH.TRIP_DETAILS.OPTIONS.TRIP_CANCELLATION' | translate}}
            </div>
          </div>
          <app-icon icon="info-fill"
                    theme="white"
                    size="large"
                    class="options_item_hint"
                    [appTooltip]="'forms.BOOKING_PATH.TRIP_DETAILS.OPTIONS.TOOLTIP.TRIP_CANCELLATION' | translate">
          </app-icon>
        </div>
        <div class="options_item"
             (click)="selectCoverOption(CoverageType.NOT_SURE)"
             [class.active]="notSure">
          <div class="options_item_content">
            <app-icon icon="message-fill"
                      theme="inherit"
                      size="x-large">
            </app-icon>
            <div class="t-btn-normal">
              {{'forms.BOOKING_PATH.TRIP_DETAILS.OPTIONS.NOT_SURE' | translate}}
            </div>
          </div>
          <app-icon icon="info-fill"
                    theme="white"
                    size="large"
                    class="options_item_hint"
                    [appTooltip]="'forms.BOOKING_PATH.TRIP_DETAILS.OPTIONS.TOOLTIP.NOT_SURE' | translate">
          </app-icon>
        </div>
      </div>
      <app-field-error controlName="coverOptions"></app-field-error>
    </div>

    <div class="info-container">
      <div class="info-group">
        <app-label [label]="'forms.BOOKING_PATH.TRIP_DETAILS.TOTAL.TITLE' | translate"
                   class="input-field-half"
                   *ngIf="priceControl"
                   [hint]="'forms.BOOKING_PATH.TRIP_DETAILS.TOTAL.TOOLTIP' | translate">
          <app-input [placeholder]="'forms.BOOKING_PATH.TRIP_DETAILS.TOTAL.PLACEHOLDER' | translate" formControlName="price"></app-input>
          <app-field-error controlName="price"></app-field-error>
        </app-label>
        <app-label [label]="'forms.BOOKING_PATH.TRIP_DETAILS.RESIDENCE.TITLE' | translate"
                   [required]="true"
                   class="input-field-half">
          <app-search-select [placeholder]="'forms.BOOKING_PATH.TRIP_DETAILS.RESIDENCE.PLACEHOLDER' | translate"
                             formControlName="residence"
                             viewKey="name"
                             selectKey="code"
                             [clearButton]="true"
                             [options]="residenceCountries$ | async">
          </app-search-select>
          <app-field-error controlName="residence"></app-field-error>
        </app-label>
      </div>
    </div>
    <div class="info-container promo">
      <app-label [label]="'forms.BOOKING_PATH.TRIP_DETAILS.PROMO.TITLE' | translate">
        <app-input [maxLength]="128"
                   [placeholder]="'forms.BOOKING_PATH.TRIP_DETAILS.PROMO.PLACEHOLDER' | translate"
                   formControlName="promo"></app-input>
        <app-field-error controlName="promo"></app-field-error>
      </app-label>
      <app-button class="promo_action"
                  id="get-quote-action-get-quote"
                  size="inherit"
                  (click)="onGetQuote()">
                  {{'forms.BOOKING_PATH.TRIP_DETAILS.GET_QUOTE' | translate}}
      </app-button>
    </div>
</div>
