import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreatePromoCodeDTO, PromoCode, PromoCodeSearchParams, PromoCodeSearchResult } from './promocode.interface';

@Injectable({
  providedIn: 'root'
})
export abstract class ApiPromoCodeService {
  abstract getPromoCodes(search?: PromoCodeSearchParams): Observable<PromoCodeSearchResult>;
  abstract getPromoCode(id: string): Observable<PromoCode>;
  abstract createPromoCode(body: CreatePromoCodeDTO): Observable<{id: string}>;
  abstract editPromoCode(id: string, body: CreatePromoCodeDTO): Observable<PromoCode>;
  abstract deletePromoCode(id: string): Observable<void>;
  abstract getPromoCodeList(): Observable<Blob>;
  abstract validatePromoCode(promoCode: string, countryCode: string): Observable<void>;
}
