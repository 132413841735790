import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  expand,
  Observable,
  of,
  shareReplay,
  Subject,
  take,
  takeUntil,
  tap,
  throwError
} from 'rxjs';
import { AuthService } from '@common/auth/auth.service';
import {
  BookingPath,
  BookingTripDetails,
  CompleteInsuranceInfo,
  DateOfBirth,
  QuotationResponseContractContractList,
} from '@common/booking-path/booking-path.interfaces';
import { ApiBookingPathService } from '@common/booking-path/api-booking-path.service';
import { DAY } from '@kit/date/date.const';
import { ServerErrorsValidationService } from '@kit/server-errors/server-errors-validation.service';
import { AppStorage } from '@kit/utils/ssr.utils';
import { DigitalDataService } from '@common/analytics/digital-data.service';
import { DigitalData } from '@common/analytics/digital-data.interface';
import { getAge, getRangeInDays } from '@kit/utils/date.utils';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { TradedoublerService } from "@common/tradedoubler/tradedoubler.service";
import { TradedoublerEvent } from "@common/tradedoubler/tradedoublerRouteData";

export const BOOKING_PATH_STORAGE_KEY = 'booking_path';
export const BOOKING_PATH_TIMESTAMP_STORAGE_KEY = 'booking_path_timestamp';

@Injectable()
export class BookingService implements OnDestroy {
  private _currentStep = 1;
  private _currentStepChange$ = new BehaviorSubject(1);
  private _lastAvailableStep = 1;
  private _lastAvailableStepChange$ = new BehaviorSubject(1);
  private _bookingPathData: BookingPath;
  private _bookingPathDataChange$ = new BehaviorSubject<BookingPath>(null);
  private _completeInsuranceInfo: CompleteInsuranceInfo;
  private _completeInsuranceInfoChange$ = new BehaviorSubject<CompleteInsuranceInfo>(null);
  private _selectedPlanDetails: QuotationResponseContractContractList;
  private _selectedPlanDetailsChange$ = new BehaviorSubject<QuotationResponseContractContractList>(null);
  private _paymentUrl: string = null;
  private _paymentUrlChange$ = new BehaviorSubject<string>(null);
  private _plans: QuotationResponseContractContractList[];
  private _showConfirmationStep: boolean;
  private validationService: ServerErrorsValidationService;
  private destroy$ = new Subject<void>();

  public currentStepChange$ = this._currentStepChange$.asObservable();
  public bookingPathDataChange$ = this._bookingPathDataChange$.asObservable();
  public selectedPlanDetailsChange$ = this._selectedPlanDetailsChange$.asObservable();
  public paymentUrlChange$ = this._paymentUrlChange$.asObservable();
  public lastAvailableStepChange$ = this._lastAvailableStepChange$.asObservable();
  public completeInsuranceInfoChange$ = this._completeInsuranceInfoChange$.asObservable();
  public deepLinkData: BookingTripDetails;

  public get bookingPathData(): BookingPath {
    return this._bookingPathData;
  }

  public get currentStep(): number {
    return this._currentStep;
  }

  public get lastAvailableStep(): number {
    return this._lastAvailableStep;
  }

  public get plans(): QuotationResponseContractContractList[] {
    return this._plans;
  }

  public get selectedPlanDetails(): QuotationResponseContractContractList {
    return this._selectedPlanDetails;
  }

  public get paymentUrl(): string {
    return this._paymentUrl;
  }

  public get showConfirmationStep(): boolean {
    return this._showConfirmationStep;
  }

  public get completeInsuranceInfo(): CompleteInsuranceInfo {
    return this._completeInsuranceInfo;
  }

  constructor(
    private readonly storage: AppStorage,
    private readonly authService: AuthService,
    private readonly bookingPathService: ApiBookingPathService,
    private readonly digitalDataService: DigitalDataService,
    private readonly analyticsService: AnalyticsService,
    private readonly tradedoublerService: TradedoublerService,
  ) {
  }

  public initData(validationService: ServerErrorsValidationService): void {
    this.validationService = validationService;

    if (this.authService.authorized) {
      this.loadBookingDataFromServer()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: BookingPath) => {
            this.updateBookingPathData(data);
            this.recalculateStepByData();
            this.retryFlow(this._currentStep - 1);
          },
          error: () => {
            const data = this.loadBookingDataFromStorage();

            if (data) {
              this.updateBookingPathData(data);
              this.recalculateStepByData();
              this.retryFlow(this._currentStep - 1);
            }
          }
        });
    } else {
      const data = this.loadBookingDataFromStorage();

      if (data) {
        this.updateBookingPathData(data);
        this.recalculateStepByData();
        this.retryFlow(this._currentStep - 1);
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public selectStep(stepNumber: number): void {
    this._currentStep = stepNumber;
    this._currentStepChange$.next(stepNumber);
  }

  public changeLastAvailableStep(stepNumber: number): void {
    this._lastAvailableStep = stepNumber;
    this._lastAvailableStepChange$.next(stepNumber);
  }

  public completeStep(bookingData: BookingPath): Observable<void> {
    this.updateBookingPathData(bookingData);

    switch (this.currentStep) {
      case 1:
        return this.firstStep(bookingData);

      case 2:
        return this.secondStep(bookingData);

      case 3:
        return this.thirdStep(bookingData);

      case 4:
        return this.fourthStep(bookingData);

      default:
        return of(null);
    }
  }

  public newQuote(): void {
    this.updateBookingPathData(null);
    this.selectStep(1);
    this.changeLastAvailableStep(1);

    this._plans = null;
    this._selectedPlanDetails = null;
    this._showConfirmationStep = null;
    this._selectedPlanDetailsChange$.next(null);
    this._completeInsuranceInfo = null;
    this._completeInsuranceInfoChange$.next(null);

    if (this.authService.authorized) {
      this.bookingPathService.clearQuoteCache()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => window.location.reload());
    } else {
      window.location.reload();
    }
  }

  private retryFlow(retryStepsCount: number): void {
    if (!retryStepsCount) {
      return;
    }

    this.selectStep(1);

    this.completeStep(this._bookingPathData).pipe(
      expand(() => this.completeStep(this._bookingPathData)),
      take(retryStepsCount - 1),
    ).subscribe();
  }

  private firstStep(bookingData: BookingPath): Observable<void> {
    const query$: Observable<any> = this.bookingPathService.getQuote(bookingData)
      .pipe(
        tap((quote) => {
          this._plans = this.sortPlans(quote.contract?.contractList || []);
          this.selectStep(this._currentStep + 1);
          this.changeLastAvailableStep(this._currentStep);
          this.analyticsService.bookingPathStepEvent();
        }),
        catchError(err => {
          this.changeLastAvailableStep(this._currentStep);
          return throwError(() => err);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

    query$
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.firstStepUpdateDigitalData(bookingData);

    return query$;
  }

  private secondStep(bookingData: BookingPath): Observable<void> {
    const query$: Observable<any> = this.bookingPathService.selectPlan(bookingData)
      .pipe(
        tap(() => {
          const plan = this._plans.find((planData) => planData.productOfferingSign === bookingData.selectedProduct.productOfferingSign);

          this._selectedPlanDetails = plan;
          this._selectedPlanDetailsChange$.next(plan);
          this.selectStep(this._currentStep + 1);
          this.changeLastAvailableStep(this._currentStep);
          this.analyticsService.bookingPathStepEvent();
        }),
        catchError(err => {
          this.validationService.handleServerError(err?.error);
          this.changeLastAvailableStep(this._currentStep);
          return throwError(() => err);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

    query$
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.secondStepUpdateDigitalData(bookingData);

    return query$;
  }

  private thirdStep(bookingData: BookingPath): Observable<void> {
    const query$: Observable<any> = this.bookingPathService.updateTravelersDetails(bookingData)
      .pipe(
        tap((paymentUrl) => {
          this.selectStep(this._currentStep + 1);
          this.changeLastAvailableStep(this._currentStep);
          this._paymentUrl = paymentUrl;
          this.analyticsService.bookingPathStepEvent();
        }),
        catchError(err => {
          this.validationService.handleServerError(err?.error);
          this.changeLastAvailableStep(this._currentStep);
          return throwError(() => err);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

    query$
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.thirdStepUpdateDigitalData(bookingData);

    return query$;
  }

  private fourthStep(bookingData: BookingPath): Observable<void> {
    const query$: Observable<any> = this.bookingPathService.commitContract(bookingData)
      .pipe(
        tap((insuranceInfo) => {
          this.changeLastAvailableStep(this._currentStep);
          this.updateInLocalStorage(null);
          this._completeInsuranceInfo = insuranceInfo;
          this._completeInsuranceInfoChange$.next(insuranceInfo);
          this._showConfirmationStep = true;
          bookingData.contractNumber = insuranceInfo?.contractNumber || null;
          this.fourthStepUpdateDigitalData(bookingData);
          this.analyticsService.bookingPathStepEvent();
          this.tradedoublerService.sendTradedoublerAction(
            TradedoublerEvent.insurancePurchase,
            bookingData.contractNumber,
            bookingData.selectedProduct.grossPremium.amount,
          );
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

    query$
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    return query$;
  }

  private firstStepUpdateDigitalData(bookingData: BookingPath): void {
    try {
      const digitalData: DigitalData = { ...this.digitalDataService.getDigitalData() };
      const updatedDigitalData: DigitalData = {
        ...digitalData,
        quote: {
          ...digitalData.quote || {},
          quoteDetails: {
            ...digitalData?.quote?.quoteDetails || {},
            primaryCategory: 'short-trip',
            subCategory: bookingData.tripDetails.coverage.coverageType,
            date: new Date(bookingData.tripDetails.tripBookDate).toISOString().split('T')[0],
          },
          coverage: {
            ...digitalData?.quote?.coverage || {},
            objects: {
              ...digitalData?.quote?.coverage?.objects || {},
              coverageRegion: bookingData.tripDetails.destinationLocation.names.join('|'),
            },
            startDate: new Date(bookingData.tripDetails.startDate).toISOString().split('T')[0],
            endDate: new Date(bookingData.tripDetails.endDate).toISOString().split('T')[0],
            persons: {
              ...digitalData?.quote?.coverage?.persons || {},
              numberOfPersons: bookingData.tripDetails.travellers.length,
              insuredPersons: this.getInsuredPerson(bookingData.tripDetails.travellers)
            },
            duration: getRangeInDays(new Date(bookingData.tripDetails.startDate), new Date(bookingData.tripDetails.endDate)),
            daysBeforeCoverageStart: getRangeInDays(new Date(bookingData.tripDetails.tripBookDate), new Date(bookingData.tripDetails.startDate))
          },
          policyHolder: {
            ...digitalData?.quote?.policyHolder,
            residenceCountry: bookingData.tripDetails.residenceCountryCode,
          },
        },
        transaction: {
          ...digitalData.transaction,
          promoCode: bookingData.tripDetails.promoCode,
        }
      };

      this.digitalDataService.putDigitalData(updatedDigitalData);
    } catch {
    }
  }

  private secondStepUpdateDigitalData(bookingData: BookingPath): void {
    try {
      const digitalData: DigitalData = { ...this.digitalDataService.getDigitalData() };
      const updatedDigitalData: DigitalData = {
        ...digitalData,
        quote: {
          ...digitalData?.quote || {},
          quoteDetails: {
            ...digitalData?.quote?.quoteDetails || {},
            insuredCost: bookingData.selectedProduct.grossPremium.amount,
          }
        },
        product: {
          ...digitalData?.product || {},
          id: bookingData.selectedProduct.productOfferingSign,
          name: bookingData.selectedProduct.productOfferingName,
          price: bookingData.selectedProduct.originalGrossPremium.amount,
        }
      }

      this.digitalDataService.putDigitalData(updatedDigitalData);
    } catch {
    }
  }

  private thirdStepUpdateDigitalData(bookingData: BookingPath): void {
    try {
      const digitalData: DigitalData = { ...this.digitalDataService.getDigitalData() };
      const updatedDigitalData: DigitalData = {
        ...digitalData,
        quote: {
          ...digitalData?.quote || {},
          policyHolder: {
            age: getAge(bookingData.travelersDetails.policyHolder.dateOfBirth).toString(),
            residenceCountry: bookingData.travelersDetails.policyHolder.country,
            residenceCity: bookingData.travelersDetails.policyHolder.city,
            residencePostalCode: bookingData.travelersDetails.policyHolder.postCode,
          }
        },
        process: {
          ...digitalData.process || {},
          legalAcceptance: {
            isElectronicCommunicationChecked: bookingData.travelersDetails.marketingAccept ? 'true' : 'false',
            isPreContractualInformationChecked: bookingData.travelersDetails.marketingAccept ? 'true' : 'false',
            isTermsAndConditionsChecked: bookingData.travelersDetails.termsAccept ? 'true' : 'false',
          }
        }
      }

      this.digitalDataService.putDigitalData(updatedDigitalData);
    } catch {
    }
  }

  private fourthStepUpdateDigitalData(bookingData: BookingPath): void {
    try {
      const digitalData: DigitalData = { ...this.digitalDataService.getDigitalData() };
      const updatedDigitalData: DigitalData = {
        ...digitalData,
        transaction: {
          ...digitalData?.transaction || {},
          uniqueTransactionId: bookingData.iframeResponse.paymentResponse.transactionID,
          purchaseId: bookingData.contractNumber,
          totalPrice: bookingData.selectedProduct.grossPremium.amount,
          currency: bookingData.selectedProduct.grossPremium.currency,
          totalQuantity: 1,
          transactionDate: new Date().toISOString().split('T')[0],
          paymentStatus: bookingData.iframeResponse.paymentResponse.status,
        }
      }

      this.digitalDataService.putDigitalData(updatedDigitalData);
    } catch {
    }
  }

  private getInsuredPerson(travelers: DateOfBirth[]): string {
    const result = { infant: 0, child: 0, youth: 0, adult: 0, senior: 0, oldSenior: 0 };

    travelers.forEach((traveler: DateOfBirth) => {
      const age = getAge(traveler.dateOfBirth);

      if (age < 1) {
        result.infant = result.infant + 1;
        return;
      }

      if (age < 20) {
        result.child = result.child + 1;
        return;
      }

      if (age < 30) {
        result.youth = result.youth + 1;
        return;
      }

      if (age < 65) {
        result.adult = result.adult + 1;
        return;
      }

      if (age < 80) {
        result.senior = result.senior + 1;
        return;
      }

      result.oldSenior = result.oldSenior + 1;
      return;
    });

    return Object.entries(result).map(([key, value]: [string, number]) => `${key}:${value}`).join('|');
  }

  private updateBookingPathData(bookingData: BookingPath): void {
    this._bookingPathData = bookingData;
    this._bookingPathDataChange$.next(bookingData);
    this.updateInLocalStorage(bookingData);
  }

  private updateInLocalStorage(bookingData: BookingPath): void {
    this.storage.setItem(BOOKING_PATH_STORAGE_KEY, JSON.stringify(bookingData));
    this.storage.setItem(BOOKING_PATH_TIMESTAMP_STORAGE_KEY, Date.now().toString());
  }

  private loadBookingDataFromStorage(): BookingPath {
    const jsonData = this.storage.getItem(BOOKING_PATH_STORAGE_KEY);
    const timestamp = this.storage.getItem(BOOKING_PATH_TIMESTAMP_STORAGE_KEY);

    if (jsonData && jsonData !== 'null' && timestamp && (Number(timestamp) + DAY > Date.now())) {
      return JSON.parse(jsonData);
    }

    this.storage.setItem(BOOKING_PATH_STORAGE_KEY, null);
    this.storage.setItem(BOOKING_PATH_TIMESTAMP_STORAGE_KEY, null);

    return null;
  }

  private loadBookingDataFromServer(): Observable<BookingPath> {
    return this.bookingPathService.getBookingPathForm();
  }

  private recalculateStepByData(): void {
    let step = 0;

    switch (true) {
      case Boolean(this._bookingPathData?.travelersDetails):
        step = 4;
        break;

      case Boolean(this._bookingPathData?.selectedProduct):
        step = 3;
        break;

      case Boolean(this._bookingPathData?.tripDetails):
        step = 2;
        break;

      default:
        step = 1;
        break;
    }

    this.selectStep(step);
    this.changeLastAvailableStep(step);
  }

  private sortPlans(plans: QuotationResponseContractContractList[]): QuotationResponseContractContractList[] {
    return plans
      .sort((a, b) =>
        Number(a.categorySortingNumber) - Number(b.categorySortingNumber)
      )
      .map(plan => ({
        ...plan,
        coverages: plan.coverages.sort((a, b) =>
          Number(a.coverageSortingNumber) - Number(b.coverageSortingNumber)
        ),
      }));
  }
}
