import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  Bootstrap, Breadcrumb, Breadcrumbs, Footer, Header, Image,
  SEOBootstrapConfiguration, Shadows, Templates, Theme, TripConfigurations,
 } from '../model/models';
import { ActivityConfiguration } from './activity-configuration.interface';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  public readonly header$ = new ReplaySubject<Header[]>(1);
  public readonly footer$ = new ReplaySubject<Footer>(1);
  public readonly link$ = new BehaviorSubject<Shadows>(null);
  public readonly seo$ = new BehaviorSubject<SEOBootstrapConfiguration>(null);
  public defaultTheme: Theme;
  public readonly theme$ = new BehaviorSubject<Theme>(null);
  public tripConfig: TripConfigurations;
  public breadcrumbs: { [key: string]: Breadcrumb; };
  public headScripts: string;
  public templates: Templates;

  get link(): Shadows {
    return this.link$.value;
  }

  get accommodationImage(): Image[] {
    return this.tripConfig.accommodationConfigurations.images;
  }

  get activityConfiguration(): ActivityConfiguration {
    return (this.tripConfig?.activityCategoryConfigurations?.configurations || []).reduce(
      (acc, item) => {
        acc[item.code] = item;
        return acc;
      }, <ActivityConfiguration>{});
  }

  public setBootstrapData(data: Bootstrap): void {
    this.header$.next(data.headers);
    this.footer$.next(data.footers[0]);
    this.link$.next(data.shadows);
    this.seo$.next(data.seo);
    this.defaultTheme = data.theme;
    this.tripConfig = data.tripConfigurations;
    this.headScripts = data.headScripts;
    this.templates = data.templates;
    this.setTheme(data.theme);
  }

  public setBreadCrumbs(breadcrumbs: Breadcrumbs): void {
    this.breadcrumbs = breadcrumbs.data;
  }

  public setTheme(theme: Theme): void {
    this.theme$.next(theme);
  }
}
