import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { LoaderModule } from 'src/app/kit/loader/loader.module';
import { AppStorage } from 'src/app/kit/utils/ssr.utils';
import { AnonymousTokenInterceptor } from './anonymous-token.interceptor';
import { AuthErrorInterceptor } from './auth-error.interceptor';
import { AccessTokenInterceptor } from './auth.interceptor';
import { allowedAuthUrls } from "@common/auth/auth.const";

@NgModule({
  exports: [OAuthModule],
  imports: [
    CommonModule,
    LoaderModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: allowedAuthUrls,
        sendAccessToken: false,
      }
    })
  ],
  providers: [
    {
      provide: OAuthStorage,
      useExisting: AppStorage,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AnonymousTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthErrorInterceptor,
      multi: true,
    }
  ]
})
export class AuthModule {
}
