<div class="shell">
  <div class="forbidden">
    <div class="forbidden_block">
      <img class="forbidden_image" src="assets/img/403.svg">
      <div class="forbidden_title h3 m-h3">{{ 'pages.NOT_FOUND.TITLES.FORBIDDEN' | translate }}</div>
      <div class="forbidden_text t-normal m-t-normal">{{ 'pages.NOT_FOUND.DESCRIPTIONS.FORBIDDEN' | translate }}</div>
      <app-button [routerLink]="routerLink" type="big-width">{{ 'pages.NOT_FOUND.ACTIONS.GO_TO_HOMEPAGE' | translate }}</app-button>
    </div>
    <div class="forbidden_block">
      <img class="forbidden_image" src="assets/img/not-found-img.svg">
    </div>
  </div>
</div>
