import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { forkJoin, Observable, switchMap, takeUntil } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { ApiInformationService } from '@common/information/api-information.service';
import { FormTripEdit } from '@common/model/formTripEdit';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { EditTripHelperService } from "@pages/trip/services/edit-trip-helper/edit-trip-helper.service";
import { CreateTripSource } from "@pages/trip/components/create-trip/create-trip.interface";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-trip',
  templateUrl: './edit-trip.component.html',
  styleUrls: ['./edit-trip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService, ServerErrorsValidationService],
})
export class EditTripComponent extends AemFragmentComponent implements OnInit {
  public showNotificationCheckbox$: Observable<boolean>;
  public showNotificationContainer = this.editTripHelperService.isNotUSMarket();
  public currentTrip = this.route.firstChild.snapshot.data.currentTrip;

  private tripId = this.route.firstChild.snapshot.params.tripId;

  constructor(
    public readonly validationService: ServerErrorsValidationService,
    private readonly informationService: ApiInformationService,
    private readonly destroy$: TuiDestroyService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly bootstrapService: BootstrapService,
    private readonly analyticsService: AnalyticsService,
    private readonly editTripHelperService: EditTripHelperService,
    @Inject(AEM_DATA) data: FormTripEdit,
  ) {
    super(data);
  }

  ngOnInit(): void {
    this.initNotificationSettings();
  }

  public goBack(): void {
    this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId: this.tripId }));
  }

  public editTrip(source: CreateTripSource): void {
    forkJoin([
      this.editTripHelperService.editTrip(source, this.tripId),
      this.showNotificationCheckbox$,
    ]).pipe(
      switchMap(([_tripId, showNotificationCheckbox]) =>
        this.editTripHelperService.describeOnNotification$(showNotificationCheckbox, source?.subscribedOnNotifications)
      ),
      catchError(err => this.handleServerError(err)),
      takeUntil(this.destroy$),
    ).subscribe(() =>
      this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId: this.tripId }))
    );
  }

  public deleteTrip(): void {
    this.editTripHelperService.deleteTrip(this.tripId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.router.navigateByUrl(this.bootstrapService.link.trips));
  }

  private initNotificationSettings(): void {
    this.showNotificationCheckbox$ = this.editTripHelperService.isMarketingConsentDisabled$()
      .pipe(takeUntil(this.destroy$));
  }

  private handleServerError(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    return this.validationService.handleServerError(err?.error);
  }
}
