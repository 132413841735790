<section class="membership">
  <div class="membership__shell shell">
    <div class="membership__grid">
      <div class="membership__column">
        <h4 class="membership__title">{{ data.title }}</h4>
      </div>
      <div class="membership__column membership__description">
        {{ data.text }}
      </div>
    </div>
    <div class="membership__body">
      <app-slider [showAllInMobile]="true" class="membership-cards">
        <ng-template *ngFor="let item of data.items" #swiperSlide>
          <app-slider-item
            [ngClass]="['membership-card_' + item.colour]"
            [navigateTo]="item.cta"
            [attr.id]="item.id"
            class="membership-card">
            <h5 class="membership-card__header h5 m-h2">
              {{ item.title }}
              <div class="membership-card__price" *ngIf="item.pricePart1">
                {{ item.pricePart1 }} / {{ item.pricePart2 }}
              </div>
            </h5>
            <div class="membership-card__body">
              <app-safe-html [content]="item.text" class="membership-card__item t-small m-t-small"></app-safe-html>
            </div>
            <app-aem-button
              *ngIf="item.cta"
              [cta]="item.cta"
              class="membership-card__button"
              size="l"
              type="full-width">
            </app-aem-button>
          </app-slider-item>
        </ng-template>
      </app-slider>
    </div>
  </div>
</section>
