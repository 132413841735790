<app-trip-hero-block (goBack)="goBack()">
  <div class="add-hotel_form" [formGroup]="form">
    <app-label [label]="'forms.HOTEL.LABELS.HOTEL_NAME' | translate" [required]="true">
      <app-input id="add-hotel-name" [placeholder]="'forms.HOTEL.PLACEHOLDERS.HOTEL_NAME' | translate" formControlName="hotelName" [maxLength]="35"></app-input>
      <app-field-error controlName="hotelName"></app-field-error>
    </app-label>

    <app-label [label]="'forms.HOTEL.LABELS.ADDRESS' | translate">
      <app-input id="add-hotel-address" [placeholder]="'forms.HOTEL.PLACEHOLDERS.ADDRESS' | translate" formControlName="hotelAddress" [maxLength]="160"></app-input>
      <app-field-error controlName="hotelAddress"></app-field-error>
    </app-label>

    <app-label [label]="'forms.HOTEL.LABELS.CHECK_IN' | translate" [required]="true">
      <app-date-picker id="add-hotel-checkin-date" formControlName="checkInDate"></app-date-picker>
      <app-field-error controlName="checkInDate"></app-field-error>
    </app-label>
    <app-label [label]="'forms.HOTEL.LABELS.HOUR' | translate" [required]="true">
      <app-time-picker id="add-hotel-ckeckin-time" formControlName="checkInHour"></app-time-picker>
      <app-field-error controlName="checkInHour"></app-field-error>
    </app-label>
    <app-label [label]="'forms.HOTEL.LABELS.CHECK_OUT' | translate" [required]="true">
      <app-date-picker id="add-hotel-checkout-date" formControlName="checkOutDate"></app-date-picker>
      <app-field-error controlName="checkOutDate"></app-field-error>
    </app-label>
    <app-label [label]="'forms.HOTEL.LABELS.HOUR' | translate" [required]="true">
      <app-time-picker id="add-hotel-checkout-time" formControlName="checkOutHour"></app-time-picker>
      <app-field-error controlName="checkOutHour"></app-field-error>
    </app-label>

    <app-label [label]="'forms.HOTEL.LABELS.BOOKING_NUMBER' | translate">
      <app-input id="add-hotel-booking-number" [placeholder]="'forms.HOTEL.PLACEHOLDERS.BOOKING_NUMBER' | translate" formControlName="bookingReference"  [maxLength]="15"></app-input>
      <app-field-error controlName="bookingReference"></app-field-error>
    </app-label>

    <app-field-error [control]="form"></app-field-error>

    <button id="action-hotel-save" class="button" (click)="onSave()">
      {{ 'forms.HOTEL.ACTIONS.SAVE_MY_HOTEL_DETAILS' | translate }}
    </button>
  </div>
</app-trip-hero-block>