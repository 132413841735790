import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { catchError, filter, map, Observable, of, switchMap, takeUntil } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { ApiFlightService } from '@common/flight/api-flight.service';
import { DestinationFlightInfo, FlightByNumberOptions, FlightInfo } from '@common/flight/flight.interfaces';
import { wrapValidator } from 'src/app/kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { FLIGHT_NUMBER_REGEXP } from '../flight.const';
import { getAllFormErrors } from "@kit/utils/form";
import { DialogService } from '@kit/dialog/dialog.service';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { TranslateService } from '@ngx-translate/core';
import { Country } from '@pages/trip/trip.interfaces';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-flight',
  templateUrl: './add-flight.component.html',
  styleUrls: ['./add-flight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ServerErrorsValidationService,
    TuiDestroyService,
  ]
})
export class AddFlightComponent implements OnInit {
  @Output() flight = new EventEmitter<FlightInfo[]>()
  @Output() manually = new EventEmitter<void>()

  public flightForm: UntypedFormGroup;
  public showManuallyButton = false;
  public newTrip = this.route.firstChild.snapshot.queryParams.newTrip;
  public isAddingFlightAvailable$: Observable<string>;

  private tripId = this.route.firstChild.snapshot.params.tripId;

  constructor(
    public readonly bootstrapService: BootstrapService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly flightsService: ApiFlightService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly validationService: ServerErrorsValidationService,
    private readonly onDestroy$: TuiDestroyService,
    private readonly analyticsService: AnalyticsService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {
  }

  public ngOnInit(): void {
    this.initFlightsLimit();
    this.initForm();
  }

  public goBack() {
    this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId: this.tripId }));
  }

  public addFlight(): void {
    this.flightForm.markAllAsTouched();

    if (getAllFormErrors(this.flightForm)?.length) {
      this.analyticsService.validationError(this.flightForm, this.constructor.name);
      return;
    }

    const { tripId } = this.route.firstChild.snapshot.params;
    const data: FlightByNumberOptions = this.flightForm.getRawValue();

    this.flightsService.checkValidFlight(tripId, data).pipe(
      switchMap(() => this.getFlightByNumber(data)),
      catchError(err => this.handleServerError(err)),
      takeUntil(this.onDestroy$),
    ).subscribe((flights: FlightInfo[]) => {
      if (this.checkFlightsFullInformation(flights).length < flights.length) {
        this.showErrorMessage();

        return;
      }

      if (flights.length) {
        this.flight.emit(flights);

        return;
      }

      this.showManuallyButton = true;
      this.cdr.markForCheck();
    });
  }

  public goToAllTrips(): void {
    this.sendAnalyticsForTripLimitReached();
    this.router.navigateByUrl(this.bootstrapService.link.trips);
  }

  public skipStep(): void {
    this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId: this.tripId }));
  }

  private checkFlightsFullInformation(flights: FlightInfo[]): FlightInfo[] {
    return flights.reduce((acc: FlightInfo[], flight: FlightInfo) => {
      if (this.isFlightObjectEmpty(flight) &&
          this.isDestinationFilled(flight.departure) &&
          this.isDestinationFilled(flight.arrival) &&
          this.isFlightInfoFilled(flight)) {
        acc.push(flight);
      }

      return acc;
    }, []);
  }

  private isFlightObjectEmpty(flight: FlightInfo): boolean {
    return !!Object.keys(flight).length;
  }

  private isDestinationFilled(destination: DestinationFlightInfo): boolean {
    if (!destination) {
      return false;
    }

    const requiredFields: (keyof DestinationFlightInfo)[] = ['city', 'date', 'time', 'airportCode'];

    return this.isCountryFilled(destination.country) && requiredFields.every((field) => !!destination[field]);
  }

  private isFlightInfoFilled(flightInfo: { flightNumber: string }): boolean {
    const requiredField: (keyof { flightNumber: string }) = 'flightNumber';

    return !!flightInfo[requiredField];
  }

  private isCountryFilled(country: Country): boolean {
    if (!country) {
      return false;
    }

    const requiredFields: (keyof Country)[] = ['countryId', 'name', 'code'];

    return requiredFields.every((field) => !!country[field]);
  }

  private showErrorMessage(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      title: this.translateService.instant('pages.ADD_FLIGHT.NOT_RECOGNIZED_FLIGHT_DIALOG.DIALOG_TITLE'),
      message: this.translateService.instant('pages.ADD_FLIGHT.NOT_RECOGNIZED_FLIGHT_DIALOG.CONTENT_MESSAGE'),
      info: this.translateService.instant('pages.ADD_FLIGHT.NOT_RECOGNIZED_FLIGHT_DIALOG.DIALOG_INFO'),
      cancelTitle: this.translateService.instant('pages.ADD_FLIGHT.NOT_RECOGNIZED_FLIGHT_DIALOG.CANCEL_TITLE'),
      confirmTitle: this.translateService.instant('pages.ADD_FLIGHT.NOT_RECOGNIZED_FLIGHT_DIALOG.CONFIRMATION_TITLE'),
      reverseButtonPosition: true
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.router.navigate(
        [setRouterData(this.bootstrapService.link.flightAddManually, { tripId: this.tripId })],
        { queryParams: {flightNumber: this.flightForm.controls.flightNumber.value} })),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  private getFlightByNumber(data: FlightByNumberOptions): Observable<FlightInfo[]> {
    return this.flightsService.getFlightByNumber(data)
      .pipe(
        catchError(() => {
          this.senAnalyticsForGentFlightError();

          return of([]);
        })
      );
  }

  private initFlightsLimit(): void {
    this.isAddingFlightAvailable$ = this.flightsService.checkFlightsLimit()
      .pipe(
        map(() => 'success'),
        catchError(() => of('error')),
      );
  }

  private initForm(): void {
    this.flightForm = this.formBuilder.group({
      flightNumber: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.pattern(FLIGHT_NUMBER_REGEXP), 'errors.CEAZ000_Pattern'),
        ],
        this.validationService.createValidator('flightNumber'),
      ],
      departure: [
        null,
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
        this.validationService.createValidator('departure'),
      ],
    });

    this.flightForm.addAsyncValidators(this.validationService.createValidator());
  }

  private senAnalyticsForGentFlightError(): void {
    this.analyticsService.triggerAction({
      category: 'error',
      action: this.constructor.name,
      label: `404 Not Found (No flight or no future been has been found with this flight number)`,
      value: 1,
    });
  }

  private sendAnalyticsForTripLimitReached(): void {
    this.analyticsService.triggerAction({
      category: 'error',
      action: 'tripLimitReached',
      label: this.tripId,
      value: 1,
    });
  }

  private handleServerError(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    return this.validationService.handleServerError(err?.error);
  }
}
