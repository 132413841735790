import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ManageAccountModule } from '@pages/account/components/manage-account/manage-account.module';
import { AemPasswordCreationsFormComponent } from './password-creations-form.component';

@NgModule({
  declarations: [AemPasswordCreationsFormComponent],
  exports: [AemPasswordCreationsFormComponent],
  imports: [
    CommonModule,
    ManageAccountModule,
  ]
})
export class AemPasswordCreationsFormModule { }
