import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AccountHero } from '@common/model/accountHero';
import { UserService } from '@common/user/user.service';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { DynamicRenderService } from '@pages/dynamic/dynamic-render/dynamic-render.service';

@Component({
  selector: 'app-aem-account-hero',
  templateUrl: './aem-account-hero.component.html',
  styleUrls: ['./aem-account-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemAccountHeroComponent extends AemFragmentComponent {
  public readonly extra = this.dynamicRenderService.getComponent(this.data.component);
  public readonly userData$ = this.userService.reloadUserInfo$();
  public readonly loungeVouchersPageTitles = ['lounge vouchers', 'lounge-gutscheine', 'buoni per la lounge', 'bons pour le salon', 'accesos a sala vip'];

  constructor(
    @Inject(AEM_DATA) public override data: AccountHero,
    public readonly userService: UserService,
    private readonly dynamicRenderService: DynamicRenderService
  ) {
    super(data);
  }
}
