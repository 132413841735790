import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FAQBackButton } from '@common/model/fAQBackButton';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { Router } from '@angular/router';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';

@Component({
  selector: 'app-faq-back-button',
  templateUrl: './faq-back-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqBackButtonComponent extends AemBaseBlockComponent {
  constructor(
    @Inject(AEM_DATA) public override data: FAQBackButton,
    private readonly router: Router,
    private readonly bootstrap: BootstrapService,
  ) {
    super(data);
  }

  public goBack(): void {
    this.router.navigateByUrl(this.bootstrap.link.faqLanding);
  }
}
