import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-authorized-content-placeholder',
  template: '',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorizedContentPlaceholderComponent implements OnInit, OnDestroy {
  private noIndexTag: HTMLMetaElement;

  constructor(
    private readonly metaService: Meta,
  ) {}

  public ngOnInit(): void {
    this.noIndexTag = this.metaService.addTag({ name: 'robots', content: 'noindex' });
  }

  public ngOnDestroy(): void {
    this.metaService.removeTagElement(this.noIndexTag);
  }
}
