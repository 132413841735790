<app-dialog-wrapper>
  <div dialogTitle>
    <app-icon icon="person-add" 
              theme="invert" 
              size="large">
    </app-icon>
    <div class="h5 m-h3">{{ 'pages.CO_TRAVELERS.TRAVELERS_LIST_DIALOG.TITLE' | translate }}</div>
  </div>
  <div dialogContent>
    <p *ngFor="let coTraveler of data.coTravelers" class="contravelers-item t-large m-t-large weight-medium">
      {{coTraveler.name}} {{coTraveler.surname}}
    </p>

    <app-button id="action-contravaler-list" 
                class="edit-button"
                type="big-width"
                *ngIf="data.editable"
                (click)="editList()">
      {{ 'pages.CO_TRAVELERS.TRAVELERS_LIST_DIALOG.BUTTON' | translate }}
    </app-button>
  </div>
</app-dialog-wrapper>
