<header class="header">
  <div class="header__main">
    <div class="header__shell shell">
      <app-back-button></app-back-button>

      <div class="hero">
        <div class="hero__group">
          <h1 class="hero__title h1 m-h1">{{ data.title }}</h1>
          <app-safe-html [content]="data.text" class="t-large m-t-small"></app-safe-html>
        </div>

        <div class="hero-component">
          <app-contact-us-form [categories]="data?.categories"></app-contact-us-form>
        </div>

        <div class="hero__action">
          <h6 class="hero__cta-title h6 m-h4">{{ data.ctasTitle }}</h6>
          <app-aem-button *ngFor="let cta of data.ctas"
                          [cta]="cta"
                          class="hero__action_button"
                          size="m"
                          type="big-width">
          </app-aem-button>
        </div>
      </div>
    </div>
  </div>
</header>
