import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  forkJoin,
  map,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from 'src/app/kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from 'src/app/kit/dialog/dialog.service';
import { AppStorage, IS_BROWSER_PLATFORM } from 'src/app/kit/utils/ssr.utils';
import { AuthService } from '../auth/auth.service';
import { getDomainByResidence, Market } from '../language/language.const';
import { LanguageService } from '../language/language.service';
import { ApiProfileService } from '../profile/api-profile.service';
import { AccountNotification, UserProfile, UserType } from '../profile/profile.interfaces';
import { WindowRef } from '../window-service/window.service';
import { AvailableScope } from './user.const';
import { TradedoublerService } from "@common/tradedoubler/tradedoubler.service";
import { DOCUMENT } from "@angular/common";
import { ConsentType } from '@pages/account/components/notification/notification-options.consts';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { Router } from '@angular/router';
import { MarketingDialogComponent } from "@kit/dialog/marketing-dialog/marketing-dialog.component";
import { ApiNotificationService } from '@common/notification/api-notification.service';

const IS_US_MARKET_USER = 'us_user';

@Injectable({ providedIn: 'root' })
export class UserService implements OnDestroy {
  public isAuth$ = new ReplaySubject<boolean>(1);
  public userData$ = new BehaviorSubject<UserProfile>(null);
  public userNotification$ = new BehaviorSubject<AccountNotification[]>(null);
  public userSubscription$ = new BehaviorSubject<UserType>(UserType.Anonymous);
  public adminScopeAvailable$ = new BehaviorSubject<boolean>(false);
  public superAdminScopeAvailable$ = new BehaviorSubject<boolean>(false);
  public userDataInitialized$ = new ReplaySubject<boolean>(1);
  public isContentManager$ = new ReplaySubject<boolean>(1);

  private onDestroy$ = new Subject<void>();

  public get location(): string {
    return this.userData$.value?.residence || this.languageService.countryCode;
  }

  public get userName(): string {
    const user = this.userData$.value;
    return `${user?.firstName} ${user?.lastName}`;
  }

  public get userEmail(): string {
    return this.userData$.value?.email;
  }

  public get authorized(): boolean {
    return this.authService.authorized;
  }

  public get userData(): UserProfile {
    return this.userData$.value;
  }

  constructor(
    private readonly authService: AuthService,
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService,
    private readonly apiProfileService: ApiProfileService,
    private readonly apiNotificationService: ApiNotificationService,
    private readonly dialog: DialogService,
    private readonly windowRef: WindowRef,
    private readonly tradedoublerService: TradedoublerService,
    private readonly router: Router,
    private readonly bootstrap: BootstrapService,
    private readonly storage: AppStorage,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean,
  ) {
    this.authService.loginResult$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(isAuth => this.isAuth$.next(isAuth));

    this.isAuth$.pipe(
      switchMap(isAuth => isAuth
        ? combineLatest([
          this.apiProfileService.getUserProfileAndScope().pipe(catchError((err) => this.onUserProfileError(err))),
          this.apiNotificationService.getNotifications().pipe(catchError(() => of(null))),
        ])
        : of([null, null])
      ),
      takeUntil(this.onDestroy$),
      map(([userData, notification]) => {
        if (
          userData?.profile &&
          !this.isDifferentResidenceAllowed(userData.scope) &&
          userData.profile.residence.toLowerCase() !== this.languageService.countryCode.toLowerCase()
        ) {
            this.handleDifferentResidences(userData);
          }

        return [userData, notification];
      }),
    ).subscribe(([userData, notification]) => {
      this.updateAuthData(userData);
      this.checkDoubleOptIn(userData?.profile);
      this.checkMarketingCampaign(userData?.profile);
      this.userNotification$.next(notification);
      this.userDataInitialized$.next(true);
      this.isContentManager$.next(this.isTranslationKeysAllowed(userData?.scope))
    });

    if (this.isBrowser) {
      this.authService.tokenRefreshed$
        .pipe(
          switchMap(() => this.apiProfileService.getUserProfileAndScope()),
          takeUntil(this.onDestroy$)
        )
        .subscribe(userData => this.updateAuthData(userData));
    }

    this.checkUSMarketResidence();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public logOut(): void {
    this.authService.logOut();
  }

  public isSuperAdmin(): boolean {
    return this.superAdminScopeAvailable$.getValue();
  }

  public updateAuthData(userData: {
    profile: UserProfile,
    scope: string,
  }): void {
    const scopes = userData?.scope;
    this.updateUserInfo(userData?.profile);
    this.adminScopeAvailable$.next(this.checkScope(AvailableScope.Admin, scopes));
    this.superAdminScopeAvailable$.next(this.checkScope(AvailableScope.SuperAdmin, scopes));
  }

  public reloadUserInfo$(): Observable<UserProfile> {
    const query$ = this.apiProfileService.getUserProfile().pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    query$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((profile: UserProfile) => {
        this.updateUserInfo(profile);
      });

    return query$ as Observable<UserProfile>;
  }

  public reloadUserNotificationInfo$(): Observable<AccountNotification[]> {
    const query$ = this.apiNotificationService.getNotifications().pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    query$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((notification: AccountNotification[]) => {
        this.userNotification$.next(notification);
      });

    return query$ as Observable<AccountNotification[]>;
  }

  public showDoubleOptInPopUp(): void {
    const titleTranslation$ = this.translateService.get('global.DOUBLE_OPT_IN_NOTIFICATION.TITLE');
    const contentTranslation$ = this.translateService.get('global.DOUBLE_OPT_IN_NOTIFICATION.CONTENT');
    const confirmTranslation$ = this.translateService.get('global.DOUBLE_OPT_IN_NOTIFICATION.CONFIRM');

    forkJoin([titleTranslation$, contentTranslation$, confirmTranslation$])
      .pipe(
        map(([title, content, confirmTitle]) => {
          this.dialog.open(ConfirmationDialogComponent, <ConfirmDialogData>{
            message: title,
            info: content,
            confirmTitle: confirmTitle,
          });
        })
      ).subscribe();
  }

  public showUSMarketPopUp$(): Observable<any> {
    const titleTranslation$ = this.translateService.get('global.US_DOMAIN_DIALOG.TITLE');
    const contentTranslation$ = this.translateService.get('global.US_DOMAIN_DIALOG.CONTENT');
    const confirmTranslation$ = this.translateService.get('global.US_DOMAIN_DIALOG.ACTION');

    return forkJoin([titleTranslation$, contentTranslation$, confirmTranslation$])
      .pipe(
        map(([title, content, confirmTitle]) => {
          return this.dialog.open(ConfirmationDialogComponent, <ConfirmDialogData>{
            message: title,
            info: content,
            confirmTitle: confirmTitle,
          }).afterClosed$
        }
      ))
  }

  private handleDifferentResidences(userData: { profile: UserProfile, scope: string }): null[] {
    if (userData.profile.residence === Market.US) {
      this.storage.setItem(IS_US_MARKET_USER, 'true');
      this.authService.logOut();

      return [null, null];
    }

    this.onDifferentResidence(userData.profile.residence);

    return [null, null];
  }

  private updateUserInfo(profile: UserProfile) {
    const subscription = (this.authorized && profile)
      ? profile.membershipStatus
      : UserType.Anonymous;

    this.userSubscription$.next(subscription);

    this.userData$.next(profile);
  }

  private isDifferentResidenceAllowed(scope: string): boolean {
    return this.checkScope(AvailableScope.SuperAdmin, scope) ||
      this.checkScope(AvailableScope.ContentGlobalManager, scope) ||
      this.checkScope(AvailableScope.ContentDeveloper, scope);
  }

  private isTranslationKeysAllowed(scope: string): boolean {
    return this.checkScope(AvailableScope.SuperAdmin, scope) ||
      this.checkScope(AvailableScope.ContentManager, scope) ||
      this.checkScope(AvailableScope.ContentGlobalManager, scope) ||
      this.checkScope(AvailableScope.ContentDeveloper, scope);
  }

  private checkScope(scope: AvailableScope, scopes: string) {
    return scopes?.split(' ')?.some(item => scope === item);
  }

  private onDifferentResidence(residence: string): void {
    const residenceName$ = this.translateService.get(`countries.${residence.toUpperCase()}`);
    const correctLink = getDomainByResidence(residence);

    const translations$ = combineLatest([residenceName$, of(correctLink)]).pipe(
      switchMap(([residenceName, link]) =>
        forkJoin({
          title: this.translateService.get('global.INCORRECT_DOMAIN_DIALOG.TITLE', { country: residenceName }),
          confirmTitle: this.translateService.get('global.INCORRECT_DOMAIN_DIALOG.ACTION', { country: residenceName }),
          info: this.translateService.get('global.INCORRECT_DOMAIN_DIALOG.CONTENT', { country: residenceName, link }),
        })
      )
    );

    translations$.pipe(
      take(1),
      takeUntil(this.onDestroy$)
    ).subscribe(({ title, confirmTitle, info }) => {
      this.authService.safeLogout(true);
      this.dialog.open(ConfirmationDialogComponent, <ConfirmDialogData>{
        icon: 'info',
        title,
        confirmTitle,
        info,
      }).afterClosed$.pipe(
        take(1),
        takeUntil(this.onDestroy$)
      ).subscribe((value) => {
        if (value === ConfirmationDialogComponent.CONFIRM) {
          this.windowRef.nativeWindow.open(correctLink, '_self');
        }
      });
    })
  }

  private checkUSMarketResidence(): void {
    if (this.storage.getItem(IS_US_MARKET_USER)) {
      this.storage.removeItem(IS_US_MARKET_USER);
      this.showUSMarketPopUp$().pipe(
        takeUntil(this.onDestroy$)
      ).subscribe();
    }
  }

  private checkDoubleOptIn(profile: UserProfile): void {
    if (
      profile &&
      !profile.firstAccess &&
      (profile.residence.toUpperCase() === Market.DE.toUpperCase() || profile.residence.toUpperCase() === Market.AT.toUpperCase()) &&
      profile.consents.find(consent => consent.consentType === ConsentType.INITIATED_MARKETING).consentValue
    ) {
      this.showDoubleOptInPopUp();
    }
  }

  private checkMarketingCampaign(profile: UserProfile): void {
    const marketingCampaignData = profile?.consents
      .find((consent: AccountNotification) => consent.consentType === ConsentType.MARKETING_CAMPAIGN);

    if (marketingCampaignData?.consentValue === false) {
      this.dialog.open(MarketingDialogComponent);
    }
  }

  private onUserProfileError(err: HttpErrorResponse): Observable<void> {
    if (err.status !== 401 && err.status !== 403) {
      return this.dialog.open(ConfirmationDialogComponent, {
        info: this.translateService.instant('global.CONTACT_SUPPORT_DIALOG.MESSAGE'),
      }).afterClosed$.pipe(
        tap(() => this.authService.logOut())
      );
    }

    return of(null);
  }
}
