export interface PageInfo {
  pageName: string;
  URL: string;
  fullURL: string;
  language: string;
  siteSection: string;
  previousPage?: string;
  title: string;
  referringURL?: string;
}

export interface Page {
  pageInfo: PageInfo;
}

interface Login {
  isLoggedIn: 'true' | 'false';
  identificationMethod?: string;
  lastLoginDate?: string;
  firstLoginDate?: string;
  dateOfAccountCreation?: string;
}

interface Consent {
  preferredCommunication: string;
}

export interface User {
  login: Login;
  profileId?: string;
  consent?: Consent;
  dob?: string;
  age?: string;
  gender?: string;
  country?: string;
  nationality?: string;
  language?: string;
  voucherCode?: string;
  membershipStatus: string;
  membershipDuration?: string;
  noOfCoTravellers?: string;
  email?: string;
  type?: string;
}

interface LegalAcceptance {
  isElectronicCommunicationChecked: 'true' | 'false';
  isTermsAndConditionsChecked: 'true' | 'false';
  isPreContractualInformationChecked: 'true' | 'false';
}

export interface Process {
  legalAcceptance: LegalAcceptance;
}

export enum NotificationMethod {
  EMAIL = 'email',
  SMS = 'sms',
  BOTH = 'both',
  NONE = 'none',
}

export interface Notification {
  serviceInfo: {
    serviceName: string;
    serviceMethod: NotificationMethod;
  }
}

export interface EventDigitalData {
  category: string;
  action: string;
  label: string;
  value: string | number;
  timeStamp?: string;
}

export interface QuoteDigitalData {
  quoteDetails?: QuoteDetailsDigitalData;
  coverage?: CoverageDigitalData;
  policyHolder?: PolicyHolder;
}

export interface QuoteDetailsDigitalData {
  primaryCategory?: string;
  date?: string;
  subCategory?: string;
  insuredCost?: number;
  policyHolder?: {
    residenceCountry?: string;
  }
}

interface CoverageDigitalData {
  objects?: {
    coverageRegion?: string;
  },
  startDate?: string;
  endDate?: string;
  duration?: number;
  daysBeforeCoverageStart?: number;
  persons?: {
    numberOfPersons?: number;
    insuredPersons?: string;
  };
}

interface PolicyHolder {
  residenceCountry?: string;
  age?: string;
  residencePostalCode?: string;
  residenceCity?: string;
}

interface Product {
  id?: string;
  name?: string;
  price?: string;
  isRecommended?: boolean;
}

interface Transaction {
  uniqueTransactionId?: string;
  purchaseId?: string;
  totalPrice?: number;
  currency?: string;
  totalQuantity?: number;
  transactionDate?: string;
  paymentStatus?: string;
  promoCode?: string;
  isPromoCodeValid?: boolean;
  totalPromoCodeDiscount?: string;
  percentagePromoCodeDiscount?: string;
}

export interface Environment {
  applicationId: string;
  applicationName: string;
  applicationVersion: string;
  clientOs: string;
  clientOsVersion: string;
  name: string;
  screenResolution: string;
  type: string;
  viewportResolution: string;
}

export interface DigitalData {
  page: Page;
  user: User;
  process?: Process;
  notifications?: Notification[];
  event?: EventDigitalData;
  quote?: QuoteDigitalData;
  product?: Product;
  transaction?: Transaction;
  environment?: Environment,
}
