<section class="quote-image">
  <div class="shell">
    <div class="quote-image__grid" [ngClass]="'quote-image__grid_position-' + data.imageAlignment">
      <div class="quote-image__column">
        <app-safe-html class="quote-image__quote h5 m-h3" [content]="data.text"></app-safe-html>
      </div>
      <img class="quote-image__illustration"
           *ngIf="data.image.imagePath"
           [src]="data.image.imagePath"
           [appImgAlt]="data.image.altText"
           [appImgAltData]="data">
    </div>
  </div>
</section>
