import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { catchError, Observable } from 'rxjs';
import { checkUrl } from "@common/auth/auth.helper";

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(
    private authStorage: OAuthStorage,
    private errorHandler: OAuthResourceServerErrorHandler,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();

    if (!checkUrl(url)) {
      return next.handle(req);
    }

    const token = this.authStorage.getItem('access_token');
    const headers = req.headers.set('Authorization', 'Bearer ' + token);

    return next.handle(req.clone({ headers })).pipe(
      catchError(err => this.errorHandler.handleError(err))
    );
  }
}
