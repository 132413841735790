import { Activity,  ActivitySource } from "../activity-element.interface";

export function mapActivityToSource(source: Activity): ActivitySource {
  return {
    name: source.name?.trim(),
    location: source.location?.trim(),
    start: new Date(source.startDate),
    hour: source.startTime,
    bookingReference: source.bookingReference?.trim(),
    activityCategory: source.activitySubcategory,
  }
}
