<ng-container *tuiLet="state$ | async as state">
  <ng-container *ngIf="passwordValue$ | async">
    <ng-container *ngIf="state">
      <div class="meter">
        <span class="bar bar_{{state}}"></span>
      </div>
      <div class="text-small">
        {{ 'forms.PASSWORD_SECURITY.STATUS.TITLE' | translate }}
        <span class="text_{{state}} text-normal">
      {{ stateStr$ | async }}
    </span>
      </div>
    </ng-container>
    <div *ngIf="state !== strong" class="text-small requirements">
      {{ 'forms.PASSWORD_SECURITY.RECOMENDATIONS.TITLE' | translate }}
      <ul class="requirements_list">
        <li *ngIf="!lengthCriteria">{{ 'forms.PASSWORD_SECURITY.RECOMENDATIONS.LENGTH' | translate }}</li>
        <li *ngIf="!upperCaseCriteria">{{ 'forms.PASSWORD_SECURITY.RECOMENDATIONS.UPPER_CASE' | translate }}</li>
        <li *ngIf="!lowerCaseCriteria">{{ 'forms.PASSWORD_SECURITY.RECOMENDATIONS.LOWER_CASE' | translate }}</li>
        <li *ngIf="!numberCriteria">{{ 'forms.PASSWORD_SECURITY.RECOMENDATIONS.NUMBER' | translate }}</li>
        <li *ngIf="!specialCharacterCriteria">{{ 'forms.PASSWORD_SECURITY.RECOMENDATIONS.SPECIAL_CHARACTER' | translate }}
        </li>
      </ul>
    </div>
  </ng-container>
</ng-container>
