<div *ngIf="showForm | async; else success" [formGroup]="contactForm" class="contact-us_form">
    <app-label [label]="'forms.CONTACT.LABELS.FIRST_NAME' | translate" [required]="true">
        <app-input [maxLength]="50"
                   [placeholder]="'forms.CONTACT.PLACEHOLDERS.FIRST_NAME' | translate"
                   id="first-name"
                   formControlName="firstName">
        </app-input>
        <app-field-error controlName="firstName"></app-field-error>
    </app-label>

    <app-label [label]="'forms.CONTACT.LABELS.LAST_NAME' | translate" [required]="true">
        <app-input [maxLength]="50"
                   [placeholder]="'forms.CONTACT.PLACEHOLDERS.LAST_NAME' | translate"
                   id="last-name"
                   formControlName="lastName">
        </app-input>
        <app-field-error controlName="lastName"></app-field-error>
    </app-label>

    <app-label [label]="'forms.CONTACT.LABELS.EMAIL' | translate" [required]="true">
        <app-input [maxLength]="320"
                   [placeholder]="'forms.CONTACT.PLACEHOLDERS.EMAIL' | translate"
                   id="email"
                   formControlName="email">
        </app-input>
        <app-field-error controlName="email"></app-field-error>
    </app-label>

    <app-label [label]="'forms.CONTACT.LABELS.CATEGORY' | translate" [required]="true">
        <app-select-with-child [options]="categories"
                               [placeholder]="'forms.CONTACT.PLACEHOLDERS.CATEGORY' | translate"
                               id="edit-activity-category"
                               formControlName="subcategory"
                               viewKey="categoryName"
                               optionsKey="subcategories">
        </app-select-with-child>
        <app-field-error controlName="subcategory"></app-field-error>
    </app-label>

    <app-safe-html *ngIf="selectedSubcategory?.categoryDescription"
                   [content]="selectedSubcategory?.categoryDescription | markdown"
                   class="note t-normal m-t-small">
    </app-safe-html>

    <app-label [label]="'forms.CONTACT.LABELS.MESSAGE' | translate" [required]="true">
        <app-textarea [rows]="3"
                      [maxLength]="1000" [placeholder]="'forms.CONTACT.PLACEHOLDERS.MESSAGE' | translate"
                      id="message"
                      formControlName="message">
        </app-textarea>
        <app-field-error controlName="message"></app-field-error>
    </app-label>
    <app-label>
        <app-checkbox id="create-trip-notification" formControlName="confirm">
          <app-safe-html [content]="'forms.CONTACT.LABELS.PRIVACY' | translate"></app-safe-html>
        </app-checkbox>
        <app-field-error controlName="confirm"></app-field-error>
    </app-label>

    <app-field-error [control]="contactForm"></app-field-error>
    <app-button (click)="submit()" id="action-cotraveler-save" type="full-width" size="l">
        {{ 'forms.CONTACT.ACTIONS.SUBMIT' | translate }}
    </app-button>
</div>

<ng-template #success>
    <div class="contact-us_form success">
        <img class="success-icon" src="../../../../../../assets/img/success.svg">
        <div class="h5 m-h1 success-title">
            {{ 'forms.CONTACT.TITLE_SEND' | translate }}
        </div>
        <div class="t-normal m-t-small">
            <app-safe-html [content]="'forms.CONTACT.DESCRIPTION_SEND' | translate"></app-safe-html>
        </div>
        <app-button (click)="fillNewForm()" type="full-width" class="success-action">
            {{ 'forms.CONTACT.ACTIONS.NEW_REQUEST' | translate }}
        </app-button>
    </div>
</ng-template>
