<ng-container [ngSwitch]="step">
  <app-add-flight
    *ngSwitchCase="FlightSteps.ADD"
    (flight)="selectFlight($event)">
  </app-add-flight>

  <app-flight-selector
    *ngSwitchCase="FlightSteps.SELECT"
    [flights]="flights"
    (flight)="selectFlight($event)"
    (goPreviousStep)="goBack(true)">
  </app-flight-selector>

  <app-flight-preview
    *ngSwitchCase="FlightSteps.PREVIEW"
    [selectedFlight]="selectedFlight"
    (goPreviousStep)="goBack()">
  </app-flight-preview>
</ng-container>
