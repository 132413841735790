<div class="travel-planner__column">
  <div class="travel-planner__row">
    <div class="travel-planner__detail">
      <div class="travel-planner__cart">
        <img class="travel-planner__img"
          [src]="(bootstrapService.accommodationImage | selectRandomImage: hotel.hotelId)?.imagePath"
          [attr.alt]="(bootstrapService.accommodationImage | selectRandomImage: hotel.hotelId)?.altText">
        <div class="travel-planner__detail-info">
          <h5 class="travel-planner__title">
            {{ 'forms.TRIP_PLANNER.LABELS.HOTEL' | translate }}
          </h5>
          <div class="travel-planner__text travel-planner__text_break-all text-large">
            <app-icon icon="bed"
                      theme="inherit"
                      size="large"
                      class="travel-planner__icon">
            </app-icon>
            {{ hotel.hotelName }}
          </div>
          <div class="travel-planner__text travel-planner__text_break-all text-large">
            {{ hotel.hotelAddress }}
          </div>
        </div>
      </div>
      <div class="travel-planner__action">
        <button id="action-hotel-delete" class="travel-planner-delete" (click)="deleteHotel(hotel)">
          <app-icon icon="thrash"
                    theme="inherit"
                    size="inherit"
                    class="travel-planner-delete__icon">
          </app-icon>
          <span class="travel-planner-delete__text">
            {{ 'forms.TRIP_PLANNER.ACTIONS.DELETE' | translate }}
          </span>
        </button>

        <div class="travel-planner__edit">
          <app-button theme="bordered"
                      size="s"
                      [appAemRouterLink]="bootstrapService.link.accommodationEdit"
                      [aemRouterData]="{ tripId: currentTrip.tripId, accommodationId: hotel.hotelId }"
                      id="action-go-edit-hotel">
            {{ 'forms.TRIP_PLANNER.ACTIONS.EDIT_HOTEL' | translate }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
  <div class="travel-planner__row travel-planner__row-center travel-planner__row-details">
    <ng-container *ngIf="hotel.bookingReference">
      <p class="travel-planner__text travel-planner__text-green text-large">
        {{ 'forms.TRIP_PLANNER.LABELS.STATUS_CONFIRMED_BOOKING' | translate }}
      </p>
      <p class="travel-planner__text text-normal">
        {{ 'forms.TRIP_PLANNER.LABELS.RESERVATION_NUMBER' | translate }}
        <b>{{ hotel.bookingReference }}</b>
      </p>
    </ng-container>
    <p class="travel-planner__text text-normal">
      {{ 'forms.TRIP_PLANNER.LABELS.CHECK_IN_DATE' | translate }}
      <b>{{ hotel.checkinDate | dateFormat: 'fullSpelled' }}</b>
    </p>
    <p class="travel-planner__text text-normal">
      {{ 'forms.TRIP_PLANNER.LABELS.CHECK_IN_TIME' | translate }}
      <b>{{ hotel.checkinTime | dateFormat: 'time'}}</b>
    </p>
    <p class="travel-planner__text text-normal">
      {{ 'forms.TRIP_PLANNER.LABELS.CHECK_OUT_DATE' | translate }}
      <b>{{ hotel.checkoutDate | dateFormat: 'fullSpelled' }}</b>
    </p>
    <p class="travel-planner__text text-normal">
      {{ 'forms.TRIP_PLANNER.LABELS.CHECK_OUT_TIME' | translate }}
      <b>{{ hotel.checkoutTime | dateFormat: 'time' }}</b>
    </p>
  </div>
</div>

