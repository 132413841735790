import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Memberships } from '@common/model/memberships';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '../aem-base-block/aem-base-block';

@Component({
  selector: 'app-membership-plans',
  templateUrl: './membership-plans.component.html',
  styleUrls: ['./membership-plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembershipPlansComponent extends AemBaseBlockComponent {
  constructor(
    @Inject(AEM_DATA) public override data: Memberships,
  ) {
    super(data);
  }
}
