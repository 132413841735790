<ng-container *ngIf="authorized$ | async; else unauthorized">
  <div *ngIf="showConfirmationMessage; else form" class="compensation-form">
    <h3 class="compensation-form_title h5 m-h3 zero-margin">
      {{ 'forms.PAST_FLIGHT_COMPENSATION.CONFIRMATION.FORM_TITLE' | translate }}
    </h3>

    <div class="compensation-form_success">
      <img class="compensation-form_success-img" src="/assets/img/success.svg">
      <div class="compensation-form_success-message text-large m-t-large">
        {{ 'forms.PAST_FLIGHT_COMPENSATION.CONFIRMATION.SUCCESS_MESSAGE' | translate }}
      </div>
    </div>

    <div class="text-tiny m-t-tiny">
      {{ 'forms.PAST_FLIGHT_COMPENSATION.CONFIRMATION.FORM_DESCRIPTION' | translate }}
    </div>

    <app-button id="action-clear-flight-compensation-form"
                size="m"
                theme="black"
                type="full-width"
                (click)="toggle()">
      {{ 'forms.PAST_FLIGHT_COMPENSATION.CONFIRMATION.ACTIONS.CLEAR_FORM' | translate }}
    </app-button>

    <app-safe-html class="compensation-form_action-link text-tiny m-t-tiny"
                   [content]="'forms.PAST_FLIGHT_COMPENSATION.CONFIRMATION.PARTNER_DESCRIPTION' | translate">
    </app-safe-html>
  </div>
</ng-container>

<ng-template #form>
  <div class="compensation-form"
       [formGroup]="compensationForm">
    <h3 class="compensation-form_title h5 m-h3 zero-margin">
      {{ 'forms.PAST_FLIGHT_COMPENSATION.FORM.FORM_TITLE' | translate }}
    </h3>

    <app-label [label]="'forms.PAST_FLIGHT_COMPENSATION.FORM.FIELDS.FLIGHT_NUMBER.LABEL' | translate">
      <app-input id="past-flight-desctination-country"
                 [placeholder]="'forms.PAST_FLIGHT_COMPENSATION.FORM.FIELDS.FLIGHT_NUMBER.PLACEHOLDER' | translate"
                 formControlName="flightNumber">
      </app-input>
      <app-field-error controlName="flightNumber"></app-field-error>
    </app-label>
    <app-label [label]="'forms.PAST_FLIGHT_COMPENSATION.FORM.FIELDS.FLIGHT_DATE.LABEL' | translate">
      <app-date-picker id="past-flight-date"
                       formControlName="departureDate"
                       [max]="yesterday">
      </app-date-picker>
      <app-field-error controlName="departureDate"></app-field-error>
      <app-field-error [control]="compensationForm"></app-field-error>
    </app-label>

    <div class="text-tiny m-t-tiny">
      {{ 'forms.PAST_FLIGHT_COMPENSATION.FORM.FORM_DESCRIPTION' | translate }}
    </div>

    <app-button id="action-get-past-flight-compensation"
                size="m"
                theme="black"
                type="full-width"
                (click)="submit()">
      {{ 'forms.PAST_FLIGHT_COMPENSATION.FORM.ACTIONS.GET_COMPENSATION' | translate }}
    </app-button>

    <app-safe-html class="compensation-form_action-link text-tiny m-t-tiny"
                   [content]="'forms.PAST_FLIGHT_COMPENSATION.FORM.PARTNER_DESCRIPTION' | translate">
    </app-safe-html>
  </div>
</ng-template>

<ng-template #unauthorized>
  <div class="compensation-form"
       [formGroup]="compensationForm">
    <h3 class="compensation-form_title h5 m-h3 zero-margin">
      {{ 'forms.PAST_FLIGHT_COMPENSATION.UNAUTHORIZED.FORM_TITLE' | translate }}
    </h3>

    <div class="text-normal m-t-normal">
      {{ 'forms.PAST_FLIGHT_COMPENSATION.UNAUTHORIZED.FORM_DESCRIPTION' | translate }}
    </div>

    <app-button id="action-get-past-flight-login"
                size="m"
                theme="black"
                type="full-width"
                (click)="login()">
      {{ 'forms.PAST_FLIGHT_COMPENSATION.UNAUTHORIZED.ACTIONS.LOGIN' | translate }}
    </app-button>

    <a [attr.href]="registrationLink" class="compensation-form_action-link text-tiny m-t-tiny">
      {{ 'forms.PAST_FLIGHT_COMPENSATION.UNAUTHORIZED.ACTIONS.REGISTER' | translate }}
    </a>
  </div>
</ng-template>
