import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { RowArticle } from '@common/model/rowArticle';
import { DynamicContent } from '@pages/dynamic/dynamic-render/dynamic-render.interface';
import { DynamicRenderService } from '@pages/dynamic/dynamic-render/dynamic-render.service';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-row-article',
  templateUrl: './row-article.component.html',
  styleUrls: ['./row-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemRowArticleComponent extends AemBaseBlockComponent {
  columns: DynamicContent[] = this.data.columns.map(item => this.dynamicRenderService.getComponent(item));

  constructor(
    @Inject(AEM_DATA) public override data: RowArticle,
    private dynamicRenderService: DynamicRenderService,
  ) {
    super(data);
  }
}
