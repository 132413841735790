import { ChangeDetectionStrategy, Component, forwardRef, Input, Renderer2, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { tuiPure } from '@taiga-ui/cdk';
import { flattenWith } from '@kit/utils/flatten-with.utils';
import { SelectComponent } from '../select.component';

const OPEN_OPTIONS_CLASS = 'options-open';

@Component({
  selector: 'app-select-with-child',
  templateUrl: './select-with-child.component.html',
  styleUrls: ['../select.component.scss', './select-with-child.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectWithChildComponent),
      multi: true
    }
  ],
})
export class SelectWithChildComponent<T extends {[key: string]: any}> extends SelectComponent<T> implements ControlValueAccessor, OnChanges {
  @Input() public optionsKey: keyof T = null;

  constructor(
    private renderer: Renderer2,
    cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  public clickOnSelect(option: T, element: HTMLElement): void {
    if (this.optionsKey && option[this.optionsKey]?.length) {
      this.toggleChildOptions(element);
      return;
    }

    this.select(option)
  }

  private toggleChildOptions(element: HTMLElement): void {
    if (element.classList.contains(OPEN_OPTIONS_CLASS)) {
      this.renderer.removeClass(element, OPEN_OPTIONS_CLASS)
    } else {
      this.renderer.addClass(element, OPEN_OPTIONS_CLASS)
    }
  }

  @tuiPure
  protected override findSelected(options: T[], value: unknown): T {
    return flattenWith(option => option[this.optionsKey], options)
      ?.find((option) => option[this.selectKey || this.valueKey] === value) || null;
  }
}
