import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  NgZone,
  OnInit,
  Output
} from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { TuiDestroyService } from "@taiga-ui/cdk";
import {
  catchError,
  finalize,
  map,
  Observable, of,
  shareReplay,
  Subscription,
  switchMap,
  takeUntil,
  tap,
  timer
} from "rxjs";
import { ApiInformationService } from "src/app/common/information/api-information.service";
import { CountryTooltip } from "src/app/common/model/countryTooltip";
import { InsuranceBookingPath } from "src/app/common/model/insuranceBookingPath";
import { UserService } from "src/app/common/user/user.service";
import { AemBaseBlockComponent } from "src/app/kit/aem-base-block/aem-base-block";
import { DateRange } from "src/app/kit/date/date-range.interface";
import { DAY, HOUR } from "src/app/kit/date/date.const";
import { ConfirmationDialogComponent } from "src/app/kit/dialog/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogData } from "src/app/kit/dialog/confirmation-dialog/confirmation-dialog.interface";
import { DialogService } from "src/app/kit/dialog/dialog.service";
import { wrapValidator } from "src/app/kit/field-error/field-error.utils";
import { ServerErrorsValidationService } from "src/app/kit/server-errors/server-errors-validation.service";
import { dateToStartDay } from "src/app/kit/utils/date.utils";
import {
  maxDateValidator,
  minDateValidator,
  rangeMoreThenValidator,
  rangeValidator
} from "src/app/kit/utils/validators";
import { AEM_DATA } from "src/app/pages/dynamic/dynamic-render/dynamic-render.const";
import { Country } from "src/app/pages/trip/trip.interfaces";
import {
  BookingPath,
  BookingTripDetails,
  CoverageType
} from "@common/booking-path/booking-path.interfaces";
import { IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';
import { mapGetQuoteForm, mapTripDetailsToForm } from "../../booking-path.mapper";
import { BookingService } from '../booking.service';
import { BookingPathStep } from '../booking-path-step.enum';
import { BLOCKED_COUNTRIES } from "./get-quote.const";
import { DigitalDataService } from '@common/analytics/digital-data.service';
import { LanguageService } from "@common/language/language.service";

@Component({
  selector: 'app-get-quote',
  templateUrl: './get-quote.component.html',
  styleUrls: ['./get-quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class GetQuoteComponent extends AemBaseBlockComponent implements OnInit, AfterViewInit {
  @Output() public done = new EventEmitter<void>();

  public firstStepForm: UntypedFormGroup;
  public destinationsSearchControl: UntypedFormControl;
  public travelersCounterControl: UntypedFormControl;
  public coverOptionsControl: UntypedFormControl;
  public maxCountOfDestinations = 3;
  public CoverageType = CoverageType;
  public countries$: Observable<Country[]>;
  public residenceCountries$: Observable<Country[]>;
  public originDateLimits: DateRange;
  public purchaseDateLimits: DateRange;
  public birthDateLimits: DateRange;
  public userCountry: Country;
  public showCoverOptions = true;

  private dialogTimer: Subscription;
  private totalPriceControl: UntypedFormControl;
  private cachedTravelers: AbstractControl[] = [];

  constructor(
    private readonly infoService: ApiInformationService,
    private readonly fb: UntypedFormBuilder,
    private readonly destroy$: TuiDestroyService,
    private readonly validationService: ServerErrorsValidationService,
    private readonly dialogService: DialogService,
    private readonly userService: UserService,
    private readonly translateService: TranslateService,
    private readonly bookingService: BookingService,
    private readonly zone: NgZone,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly digitalDataService: DigitalDataService,
    private readonly langService: LanguageService,
    @Inject(AEM_DATA) public aemData: InsuranceBookingPath,
    @Inject(IS_SERVER_PLATFORM) public isServer: boolean,
  ) {
    super(aemData);

    if (this.isServer) {
      this.changeDetector.detach();
    }

    this.initDestinationCountries();
    this.initResidenceCountries();
    this.initTotalPriceControl();

    this.setDateLimits();
    this.initForm();
    this.startEdittingProcess();

    const tripDetails = this.bookingService.deepLinkData;

    if (tripDetails) {
      this.fillFormFromDeepLink(tripDetails);
    } else {
      this.fillFormFromBackEnd();
    }

    this.firstStepForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.bookingService.changeLastAvailableStep(1);
        this.setTimerDialog();
      });
  }

  ngOnInit(): void {
    this.digitalDataService.updatePageName(BookingPathStep.GET_A_QUOTE);
  }

  ngAfterViewInit(): void {
    if (this.isServer) {
      this.changeDetector.reattach();
      this.changeDetector.markForCheck();
    }
  }

  public get travelers(): UntypedFormArray {
    return this.firstStepForm.get('travelers') as UntypedFormArray;
  }

  public get destinationsControl(): AbstractControl {
    return this.firstStepForm.get('destinations');
  }

  public get tripCancellation(): boolean {
    return this.coverOptionsControl.value === CoverageType.CANCELLATION;
  }

  public get notSure(): boolean {
    return this.coverOptionsControl.value === CoverageType.NOT_SURE;
  }

  public get priceControl(): AbstractControl {
    return this.firstStepForm.get('price');
  }

  public get promoCodeControl(): AbstractControl {
    return this.firstStepForm.get('promo')
  }

  public get tripEndControl(): AbstractControl {
    return this.firstStepForm.get('to');
  }

  public destinationsTouch(): void {
    this.destinationsControl.markAsTouched();
  }

  public selectCoverOption(option: CoverageType): void {
    this.coverOptionsControl.setValue(option);
  }

  public deleteCountry(country: Country): void {
    const destinations = this.firstStepForm.get('destinations');
    const target = destinations.value.findIndex((el: Country) => el === country);

    destinations.value.splice(target, 1);
    destinations.updateValueAndValidity();
  }

  public getTooltip(code: string): string {
    return this.aemData.tooltips?.find((tooltip: CountryTooltip) => tooltip.country === code)?.text;
  }

  public onGetQuote(): void {
    this.firstStepForm.markAllAsTouched();

    if (!this.isValidForm(this.firstStepForm)) {
      return;
    }

    const tripDetails = mapGetQuoteForm(this.firstStepForm.value, this.userService.location);

    this.bookingService.completeStep({ tripDetails })
      .pipe(
        catchError(err => this.validationService.handleServerError(err?.error)),
        finalize(() => this.changeDetector.markForCheck()),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.done.emit(null);
      });
  }

  private initForm(): void {
    this.destinationsSearchControl = this.fb.control(null);
    this.destinationsSearchControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((country: Country) => {
      const destinations = [...this.destinationsControl.value];
      const countyNames = destinations.map((country: Country) => country.name);

      if (!countyNames.includes(country.name)) {
        destinations.push(country);
        this.destinationsControl.setValue(destinations);
      }

      this.destinationsSearchControl.setValue(null, { emitEvent: false });
    });

    this.coverOptionsControl = this.fb.control(
      null,
      wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
      this.validationService.createValidator('tripDetails.coverOptions')
    );

    this.firstStepForm = this.fb.group({
      from: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(minDateValidator(this.originDateLimits.from as Date), 'errors.CEAZ015'),
          wrapValidator(maxDateValidator(this.originDateLimits.to as Date), 'errors.CEAZ019'),
        ],
        this.validationService.createValidator('tripDetails.startDate')
      ],
      to: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(minDateValidator(this.originDateLimits.from as Date), 'errors.CEAZ015'),
        ],
        this.validationService.createValidator('tripDetails.endDate')
      ],
      purchaseDate: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(minDateValidator(this.purchaseDateLimits.from as Date), 'errors.CEAZ020'),
          wrapValidator(maxDateValidator(this.purchaseDateLimits.to as Date), 'errors.CEAZ018'),
        ],
        this.validationService.createValidator('tripDetails.bookingDate')
      ],
      destinations: [
        [],
        wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
        [
          this.validationService.createValidator('tripDetails.destinations'),
          this.validationService.createValidator('tripDetails.destinationLocation.names'),
        ],
      ],
      travelers: this.fb.array([[
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(minDateValidator(this.birthDateLimits.from as Date), 'forms.BOOKING_PATH.TRIP_DETAILS.TRAVELERS.DATE_OF_BIRTH_PAST_ERROR'),
          wrapValidator(maxDateValidator(this.birthDateLimits.to as Date), 'forms.BOOKING_PATH.TRIP_DETAILS.TRAVELERS.DATE_OF_BIRTH_FUTURE_ERROR'),
        ],
        this.validationService.createValidator('tripDetails.travelers.dateOfBirth')
      ]]),
      coverOptions: this.coverOptionsControl,
      price: this.totalPriceControl,
      residence: [
        null,
        wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
        this.validationService.createValidator('tripDetails.residence')
      ],
      promo: [
        null,
        null,
        this.validationService.createValidator('tripDetails.promo')
      ]
    });

    this.firstStepForm.addValidators(
      [
        wrapValidator(
          rangeValidator(
            this.firstStepForm.get('from'),
            this.firstStepForm.get('to'),
            true),
          'errors.CEAZ016'),
        wrapValidator(
          rangeMoreThenValidator(
            this.firstStepForm.get('from'),
            this.firstStepForm.get('to'),
            62),
          'forms.BOOKING_PATH.TRIP_DETAILS.TRIP_DATE.TRIP_DURATION_ERROR'),
      ]
    );

    this.travelersCounterControl = this.fb.control(1);

    this.travelersCounterControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((count) => {
      if (count > this.travelers.length) {
        const control = this.cachedTravelers.length
          ? this.cachedTravelers.pop()
          : this.fb.control(
            null,
            [
              wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
              wrapValidator(minDateValidator(this.birthDateLimits.from as Date), 'forms.BOOKING_PATH.TRIP_DETAILS.TRAVELERS.DATE_OF_BIRTH_PAST_ERROR'),
              wrapValidator(maxDateValidator(this.birthDateLimits.to as Date), 'forms.BOOKING_PATH.TRIP_DETAILS.TRAVELERS.DATE_OF_BIRTH_FUTURE_ERROR'),
            ],
            this.validationService.createValidator('dateOfBirth'),
          );

        this.travelers.push(control);
      } else {
        this.cachedTravelers.push(this.travelers.at(this.travelers.length - 1));
        this.travelers.removeAt(this.travelers.length - 1);
      }
    });

    this.firstStepForm.get('purchaseDate').valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((date: Date) => {
      if (+date < Date.now() - 2 * DAY) {
        this.firstStepForm.removeControl('coverOptions');
        this.firstStepForm.removeControl('price');
        this.showCoverOptions = false;
      } else {
        this.firstStepForm.addControl('coverOptions', this.coverOptionsControl);
        this.firstStepForm.addControl('price', this.totalPriceControl);
        this.showCoverOptions = true;
      }
    });

    this.firstStepForm.addAsyncValidators(this.validationService.createValidator('tripDetails'));
  }

  private setTimerDialog(): void {
    if (this.dialogTimer) {
      this.dialogTimer.unsubscribe();
    }

    this.dialogTimer = timer(HOUR).pipe(
      switchMap(() => {
        return this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
          message: this.translateService.instant('forms.BOOKING_PATH.IDLE_DIALOG.TITLE'),
          info: this.translateService.instant('forms.BOOKING_PATH.IDLE_DIALOG.MESSAGE'),
          confirmTitle: this.translateService.instant('forms.BOOKING_PATH.IDLE_DIALOG.CONFIRM'),
          cancelTitle: this.translateService.instant('forms.BOOKING_PATH.IDLE_DIALOG.CANCEL'),
          reverseButtonPosition: true
        }).afterClosed$;
      }),
      takeUntil(this.destroy$),
    ).subscribe((value) => {
      if (value === ConfirmationDialogComponent.CANCEL) {
        this.bookingService.newQuote();
      }
    });
  };

  private setDateLimits(): void {
    const nextFiveYears = new Date(new Date().setFullYear(new Date().getFullYear() + 5)).setUTCHours(0, 0, 0, 0);
    const prevFiveYears = new Date(new Date().setFullYear(new Date().getFullYear() - 5)).setUTCHours(0, 0, 0, 0);
    const prevHundredNFiftyYears = new Date(new Date().setFullYear(new Date().getFullYear() - 150)).setUTCHours(0, 0, 0, 0);

    this.originDateLimits = {
      from: dateToStartDay(new Date().toISOString()),
      to: new Date(nextFiveYears)
    };

    this.purchaseDateLimits = {
      from: new Date(prevFiveYears),
      to: dateToStartDay(new Date().toISOString())
    }

    this.birthDateLimits = {
      from: new Date(prevHundredNFiftyYears),
      to: dateToStartDay(new Date().toISOString())
    }
  }

  public startEdittingProcess(): void {
    if (this.userService.authorized) {
      this.countries$.pipe(
        takeUntil(this.destroy$),
      ).subscribe((countries) => {
        this.userCountry = countries.find(country => this.userService.location === country.code);
        this.firstStepForm.get('residence').setValue(this.userCountry, { emitEvent: false });
      });
    }
  }

  public fillForm(source: BookingTripDetails): Observable<Country[]> {
    const formData = mapTripDetailsToForm(source);

    if (formData.coverOptions && +new Date(formData.purchaseDate) > Date.now() - 2 * DAY) {
      this.coverOptionsControl.setValue(formData?.coverOptions, { emitEvent: false });
    } else {
      this.showCoverOptions = false;
      this.firstStepForm.removeControl('coverOptions', { emitEvent: false });
      this.firstStepForm.removeControl('price', { emitEvent: false });
    }

    this.travelers.controls.forEach((control, i) => {
      control.setValue(new Date(formData?.travelers[i]), { emitEvent: false });
    });

    formData.travelers.forEach((dateStr: Date | string, index: number) => {
      const control = this.travelers.at(index);
      const value = dateStr ? new Date(dateStr) : null;

      control ? control.setValue(value, { emitEvent: false }) : this.travelers.push(this.fb.control(value), { emitEvent: false });
    });

    this.travelersCounterControl.setValue(this.travelers.length, { emitEvent: false });
    this.totalPriceControl.setValue(formData?.price || null, { emitEvent: false });
    this.promoCodeControl.setValue(formData?.promo || null, { emitEvent: false });

    this.firstStepForm.patchValue({
      from: formData?.from ? new Date(formData.from) : null,
      to: formData?.to ? new Date(formData.to) : null,
      purchaseDate: formData?.purchaseDate ? new Date(formData.purchaseDate) : null,
      promo: formData?.promo || null
    }, { emitEvent: false });

    return this.countries$.pipe(
      tap((countries: Country[]) => {
        const residence = countries.find(country => formData.residence === country.code);
        const destinations = formData.destinations.map(countryCode => {
          return countries.find(country => countryCode === country.code);
        });

        this.firstStepForm.get('residence').setValue(
          residence || this.userCountry,
          { emitEvent: false }
        );
        this.firstStepForm.get('destinations').setValue(destinations || [], { emitEvent: false });
      }),
      takeUntil(this.destroy$)
    )
  }

  private isValidForm(form: UntypedFormGroup): boolean {
    return Object.keys(form.controls).every(field => {
      if (form.get(field) instanceof UntypedFormArray) {
        return (form.get(field) as UntypedFormArray).controls.every(control => !control.errors);
      }

      return !form.get(field).errors;
    });
  }

  private initDestinationCountries(): void {
    this.countries$ = this.infoService.getCountries().pipe(
      map(countries => countries.filter(country => !BLOCKED_COUNTRIES.includes(country.code))),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private initResidenceCountries(): void {
    this.residenceCountries$ = this.infoService.getLocalCountries(this.langService.countryCode.toUpperCase()).pipe(
      map(countries => countries.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;

        return 0;
      })),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private initTotalPriceControl(): void {
    this.totalPriceControl = this.fb.control(
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
        wrapValidator(Validators.pattern(/^([0-9]*[.])?[0-9]+$/), 'errors.CEAZ023'),
        wrapValidator(Validators.min(1), 'errors.CEAZ023'),
      ],
      this.validationService.createValidator('tripDetails.price')
    );
  }

  private fillFormFromDeepLink(tripDetails: BookingTripDetails): void {
    this.fillForm(tripDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.bookingService.deepLinkData = null;
        this.onGetQuote();
      });
  }

  private fillFormFromBackEnd(): void {
    this.bookingService.bookingPathDataChange$.pipe(
      switchMap((bookingData: BookingPath) =>
        bookingData?.tripDetails ? this.fillForm(bookingData.tripDetails) : of(null)
      ),
      takeUntil(this.destroy$),
    ).subscribe();
  }
}
