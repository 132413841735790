import { Inject, Injectable } from '@angular/core';
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';

function getWindow(): globalThis.Window & typeof globalThis {
  return window;
}

@Injectable({ providedIn: 'root' })
export class WindowRef {
  constructor(
    @Inject(IS_BROWSER_PLATFORM)
    private readonly isBrowser: boolean
  ) {}

  get nativeWindow(): globalThis.Window & typeof globalThis {
    if (this.isBrowser) {
      return getWindow();
    }

    throw new Error('Don\'t use [window] in server platform');
  }
}
