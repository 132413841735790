import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { map } from 'rxjs';
import { HomeHero } from '@common/model/homeHero';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { TripService } from '@pages/trip/trip.service';
import { TripsList } from '@pages/trip/trip.interfaces';

@Component({
  selector: 'app-authorized-home-hero',
  templateUrl: './authorized-home-hero.component.html',
  styleUrls: ['./authorized-home-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemAuthorizedHomeHeroComponent {
  tripList$ = this.tripService.getTrips().pipe(
    map((trips: TripsList) =>  trips.currentTrips.concat(trips.upcomingTrips).slice(0, 4))
  );

  constructor(
    @Inject(AEM_DATA) public data: HomeHero,
    private tripService: TripService,
  ) { }
}
