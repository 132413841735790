import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Inject, Input, OnInit,
  TemplateRef, ViewChild, ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { IS_BROWSER_PLATFORM } from '../utils/ssr.utils';
import { SliderItemComponent } from './slider-item/slider-item.component';

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent implements OnInit, AfterViewInit {
  @Input() slidesPerView: number | 'auto' = 'auto';
  @Input() slidesPerGroup = 1;
  @Input() spaceBetween = 20;
  @Input() pagination = true;
  @Input() navigation = true;
  @Input() showAllInMobile = false;
  @Input() initialSlide = 0;
  @Input() itemsCentered = false;

  @ContentChildren('swiperSlide') tplList: TemplateRef<SliderItemComponent>[];

  @ViewChild(SwiperComponent, { static: false }) swiper: SwiperComponent;

  public config$ = new BehaviorSubject<SwiperOptions>(null);
  public mobileView$ = new BehaviorSubject(false);

  constructor(
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initConfig();
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.mobileView$.next(this.showAllInMobile && this.swiper?.swiperRef.width < 431);
      this.changeDetector.detectChanges();
    }
  }

  private initConfig(): void {
    const config: SwiperOptions = {
      slidesPerView: this.slidesPerView,
      slidesPerGroup: this.slidesPerGroup,
      spaceBetween: this.spaceBetween,
      pagination: this.pagination ? { clickable: true } : false,
      navigation: this.navigation,
      initialSlide: this.initialSlide,
      centerInsufficientSlides: this.itemsCentered
    }

    this.config$.next(config);
  }
}
