import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
  @Output() goBack = new EventEmitter<void>();

  link: string = this.getLink();

  constructor(
    private bootstrapService: BootstrapService,
    private route: ActivatedRoute,
  ) { }

  onClick() {
    if (this.goBack.observed) {
      this.goBack.emit();
    }
  }

  private getLink(): string {
    const uuid = this.route.firstChild?.routeConfig?.data?.uuid;
    const parentUuid = this.bootstrapService.breadcrumbs[uuid]?.parent;

    return this.bootstrapService.breadcrumbs[parentUuid]?.link || '/';
  }
}
