import { HOUR } from "../date/date.const";

export function setCookie(name: string, value = '', days?: number): void {
  let cookie = `${name}=${value}`;

  if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * HOUR));
      cookie += `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${cookie}; path=/`;
}
