<div *ngFor="let voucher of vouchers | paginate: {
                id: id,
                itemsPerPage: entitiesOnPage,
                currentPage: currentPage,
                totalItems: vouchers?.length
                }, trackBy: trackBy">
  <div class="voucher__container">
    <img *ngIf="voucher?.qrCodeUrl"
         [src]="voucher.qrCodeUrl"
         [alt]="'voucher'"
         class="voucher__image" />
    <div class="text-small weight-medium voucher__text">{{'pages.LOUNGE_VOUCHERS.LABELS.TRAVELER_NAME' | translate}} {{voucher.travelerName}}</div>
    <div class="text-tiny">{{'pages.LOUNGE_VOUCHERS.LABELS.EXPIRING_DATE' | translate}} {{ voucher.endDate | dateFormat: 'fullSpelled' }}</div>
    <div class="text-small weight-medium voucher__footer-text">{{'pages.LOUNGE_VOUCHERS.LABELS.REMOVE_VOUCHER' | translate}}</div>
    <button class="text-tiny voucher__footer-button"
            id="voucher-detailed-action-remove"
            (click)="removeVoucher(voucher.voucherId)">{{'pages.LOUNGE_VOUCHERS.ACTIONS.REMOVE_VOUCHER' | translate}}</button>
    <img src="../../../../../../assets/img/loungekey-logo.svg" class="voucher__logo">
  </div>
</div>

<app-pagination [entities]="vouchers"
                [id]="id"
                [entitiesOnPage]="entitiesOnPage"
                [entitiesTotalElements]="vouchers?.length"
                [visiblePagesCount]="5"
                (changePage)="setCurrentPage($event)">
</app-pagination>
