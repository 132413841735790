import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  PremiumDetail,
  PremiumPeriodRequestData,
  PremiumPeriodResponseData
} from '@pages/admin/components/user-edit/premium-details.interface';
import {
  ActivationCode,
  ActivationCodesData,
  CoTraveler,
  CoTravelerDTO,
  MembershipsValidationResponse,
  ProfileSearchResult,
  ProfileSearchResultDTO,
  UserProfile,
  UserProfileDTO,
  UserProfileForm,
  UserProfilePathOptions,
  UserSearchOptionsDTO
} from './profile.interfaces';
import { mapUserProfileFromDto } from './profile.mapper';
import { Tradedoubler } from "@common/tradedoubler/tradedoublerRouteData";
import { LoungeVoucher } from '@pages/account/components/lounge-vouchers/lounge-vouchers.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiProfileService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
  ) {}

  public getUserProfileAndScope(): Observable<{
    profile: UserProfile,
    scope: string,
  }> {
    return this.http.get<UserProfileDTO>(`${this.baseUrl}/profile`, {observe: 'response'}).pipe(
      map((resp) => ({
        scope: resp.headers.get('x-scope'),
        profile: mapUserProfileFromDto(resp.body),
      }))
    );
  }

  public getUserProfile(): Observable<UserProfile> {
    return this.http.get<UserProfileDTO>(`${this.baseUrl}/profile`).pipe(
      map(mapUserProfileFromDto)
    );
  }

  public getUserProfileById(id: string): Observable<UserProfile> {
    return this.http.get<UserProfileDTO>(`${this.baseUrl}/profiles/${id}`).pipe(
      map(mapUserProfileFromDto)
    );
  }

  public editUserProfile(data: UserProfileForm): Observable<{id: number}> {
    return this.http.put<{id: number}>(`${this.baseUrl}/profile`, data);
  }

  public getProfilesList(searchOptions: UserSearchOptionsDTO, page: number): Observable<ProfileSearchResult> {
    const params: URLSearchParams = new URLSearchParams();

    params.append('page', page.toString());
    if (searchOptions.profileId) params.append('profileId', searchOptions.profileId);
    if (searchOptions.firstName) params.append('firstName', searchOptions.firstName);
    if (searchOptions.lastName) params.append('lastName', searchOptions.lastName);
    if (searchOptions.email) params.append('email', searchOptions.email);
    if (searchOptions.profileStatus) params.append('profileStatus', searchOptions.profileStatus);
    if (searchOptions.membershipStatus) params.append('membershipStatus', searchOptions.membershipStatus);
    if (searchOptions.residence) params.append('residence', searchOptions.residence);
    if (searchOptions.fromCreated) params.append('fromCreated', searchOptions.fromCreated);
    if (searchOptions.toCreated) params.append('toCreated', searchOptions.toCreated);

    return this.http.get<ProfileSearchResultDTO>(`${this.baseUrl}/profiles?${params.toString()}`).pipe(
      map(resp => ({
        ...resp,
        profiles: resp.profiles?.map(mapUserProfileFromDto) || [],
      }))
    );
  }

  public editUserProfileById(id: number, data: Partial<UserProfile>): Observable<{id: number}> {
    return this.http.put<{id: number}>(`${this.baseUrl}/profiles/${id}`, data);
  }

  public deleteUserProfile(): Observable<{id: number}> {
    return this.http.delete<{id: number}>(`${this.baseUrl}/profile`);
  }

  public deleteUserProfileById(id: string): Observable<{id: number}> {
    return this.http.delete<{id: number}>(`${this.baseUrl}/profiles/${id}`);
  }

  public getProfileMemberships(profileId: string): Observable<PremiumDetail[]> {
    return this.http.get<PremiumDetail[]>(`${this.baseUrl}/profiles/${profileId}/memberships`);
  }

  public createProfileMembership(profileId: string, data: Partial<PremiumDetail>): Observable<PremiumDetail> {
    return this.http.post<PremiumDetail>(`${this.baseUrl}/profiles/${profileId}/memberships`, data);
  }

  public editProfileMembership(profileId: string, data: PremiumDetail): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(`${this.baseUrl}/profiles/${profileId}/memberships/${data.id}`, data);
  }

  public deleteProfileMembership(profileId: string, membershipId: number): Observable<{ id: number }> {
    return this.http.delete<{ id: number }>(`${this.baseUrl}/profiles/${profileId}/memberships/${membershipId}`);
  }

  public getPremiumPeriod(requestData: PremiumPeriodRequestData): Observable<PremiumPeriodResponseData> {
    const params: URLSearchParams = new URLSearchParams();

    params.append('upgradeReason', requestData.upgradeReason);
    if (requestData.promoCode) params.append('promoCode', requestData.promoCode);
    if (requestData.startDate) params.append('startDate', requestData.startDate);
    if (requestData.endDate) params.append('endDate', requestData.endDate);

    return this.http.get<PremiumPeriodResponseData>(`${this.baseUrl}/memberships/premium-period?${params.toString()}`);
  }

  public validateMemberships(countryCode: string, file: File): Observable<MembershipsValidationResponse> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<MembershipsValidationResponse>(`${this.baseUrl}/memberships/validate/${countryCode}`, formData);
  }

  public importMemberships(countryCode: string, fileId: string): Observable<{uri: string}> {
    return this.http.post<{uri: string}>(`${this.baseUrl}/memberships/import/${countryCode}/${fileId}`, {});
  }

  public getCotravelers(): Observable<CoTraveler[]> {
    return this.http.get<CoTraveler[]>(`${this.baseUrl}/profile/cotravelers`);
  }

  public getCotravelerById(id: number): Observable<CoTraveler> {
    return this.getCotravelers().pipe(
      map(data => data.find(el => el.id === id))
    );
  }

  public addCotraveler(data: CoTravelerDTO): Observable<number> {
    return this.http.post<number>(
      `${this.baseUrl}/profile/cotravelers`,
      data
    );
  }

  public editCotraveler(data: CoTraveler): Observable<number> {
    return this.http.put<number>(
      `${this.baseUrl}/profile/cotravelers/${data.id}`,
      data
    );
  }

  public deleteCotraveler(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/profile/cotravelers/${id}`);
  }

  public getActivationCode(): Observable<ActivationCode> {
    return this.http.get<ActivationCode>(`${this.baseUrl}/profile/activation-code`);
  }

  public parseActivationCodes(countryCode: string, file: File): Observable<ActivationCodesData> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<ActivationCodesData>(`${this.baseUrl}/activation-codes/parse/${countryCode}`, formData);
  }

  public sendActivationCodes(data: ActivationCodesData): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/activation-codes/import`, data);
  }

  public sendTradedoublerData(body: Tradedoubler, isAuthorized: boolean): Observable<void> {
    const url = isAuthorized ? this.baseUrl : environment.api.publicProfile;

    return this.http.post<void>(`${url}/profile/affiliate-partners-outersource`, body);
  }

  public getUserProfileMemberships(): Observable<PremiumDetail[]> {
    return this.http.get<PremiumDetail[]>(`${this.baseUrl}/profile/memberships`);
  }

  public getLoungeAccessVouchers(): Observable<LoungeVoucher[]> {
    return this.http.get<LoungeVoucher[]>(`${this.baseUrl}/profile/vouchers`);
  }

  public deleteLoungeAccessVoucher(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/profile/vouchers/${id}`);
  }

  public acceptTermsAndConditions(): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/profile/terms-and-conditions`, null);
  }

  public updateUserProfile(options: UserProfilePathOptions[]): Observable<UserProfile> {
    return this.http.patch<UserProfile>(`${this.baseUrl}/profile`, options);
  }
}
