import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { AuthService } from '@common/auth/auth.service';

@Component({
  selector: 'app-login',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TuiDestroyService,
  ]
})
export class LoginComponent {
  constructor(
    private authService: AuthService,
  ) {}

  @HostListener('click')
  login(): void {
    this.authService.authorize();
  }
}
