<button *ngIf="type === 'bubble'; else iconElement"
        [ngClass]="['icon-bubble_' + size, 'icon-bubble-theme_' + theme]"
        class="icon-bubble">
  <ng-container *ngTemplateOutlet="iconElement"></ng-container>
</button>

<ng-template #iconElement>
  <svg [ngClass]="['icon_' + size, 'icon-theme_' + theme]"
       class="icon">
    <use [attr.xlink:href]="iconSpriteLink + icon"></use>
  </svg>
</ng-template>
