import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CustomerCareRequests } from "./customer-care.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiCustomerCareService {
  constructor(private readonly http: HttpClient) {}

  public sendCustomerCare(contactForm: CustomerCareRequests): Observable<void> {
    return this.http.post<void>(`${environment.freshWorksAdapter}/tickets`, contactForm);
  }
}
