import { DatePipe } from "@angular/common";
import { Inject, Pipe, PipeTransform } from "@angular/core";
import { DateFormat, FormatDate } from "../date-formats/date-formats.factory";

@Pipe({ name: 'dateRange' })
export class DateRangePipe implements PipeTransform {
  constructor(
    private readonly dateFormatter: DatePipe,
    @Inject(FormatDate) private readonly dateFormat: DateFormat,
  ) { }

  public transform(value: any, startDateKey = 'startDate', endDateKey = 'endDate'): string {
    const startDate = new Date(value[startDateKey]);
    const endDate = new Date(value[endDateKey]);

    if (!isFinite(+startDate) || !isFinite(+endDate)) {
      throw new Error('Incorrect date input format');
    }
    const { fullSpelled, range } = this.dateFormat;

    if (startDate.getFullYear() === endDate.getFullYear() && startDate.getMonth() === endDate.getMonth()) {
      if (startDate.getDate() === endDate.getDate()) {
        return this.dateFormatter.transform(startDate, fullSpelled);
      }

      return `${this.dateFormatter.transform(startDate, range[0])}${this.dateFormatter.transform(endDate, range[1])}`
    }

    return `${this.dateFormatter.transform(startDate, fullSpelled)} - ${this.dateFormatter.transform(endDate, fullSpelled)}`;
  }
}
