import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiPreviewService {
  constructor(
    private http: HttpClient,
  ) {}

  public refreshPreviewContent(): Observable<void> {
    return this.http.post<void>(`${environment.api.aemAdminPath}/refresh`, null);
  }

  public refreshPreviewContentByCountryCode(countryCode: string): Observable<void> {
    return this.http.post<void>(`${environment.api.aemAdminPath}/refresh/${countryCode}`, null);
  }
}
