import { Component, HostBinding, Inject } from '@angular/core';
import { Block } from '@common/model/block';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemFragmentComponent } from './aem-fragment';

@Component({
  template: '',
})
export class AemBaseBlockComponent extends AemFragmentComponent {
  @HostBinding('class') get class() {
    return `aem-top-${this.data.marginTop || Block.MarginTopEnum.Medium } aem-bottom-${this.data.marginBottom || Block.MarginBottomEnum.Medium }`;
  }

  @HostBinding('style.background-color') get backgroundColor() {
    return this.data.colourBackground;
  }

  constructor(
    @Inject(AEM_DATA) public override data: Block,
  ) {
    super(data);
  }
}
