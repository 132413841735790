import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

const GOOGLE_VERIFICATION_TAG_NAME = 'google-site-verification';

@Injectable({ providedIn: 'root' })
export class GoogleVerificationService {
  constructor(
    private readonly metaService: Meta,
  ) {}

  public updateGoogleVerificationCode(code: string): void {
    if (code) {
      this.metaService.addTag({
        name: GOOGLE_VERIFICATION_TAG_NAME,
        content: code
      });
    }
  }
}
