<app-button *tuiLet="remainTimer$ | async as timer"
            [disabled]="disabled"
            [type]="type"
            [size]="size"
            [theme]="theme">
  <app-icon *ngIf="disabled"
            [theme]="iconTheme"
            icon="clock"
            size="large"></app-icon>
  <ng-content></ng-content>
  <span *ngIf="disabled && preposition">{{ preposition }}</span>
  <span *ngIf="disabled">{{ timer }}</span>
</app-button>
