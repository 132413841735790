import { Pipe, PipeTransform } from '@angular/core';
import { getAge } from '../../utils/date.utils';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {
  transform(value: Date | string | number): number {
    return getAge(value);
  }
}