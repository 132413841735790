<section *ngIf="thematics$ | async as thematics" class="shell">
  <div class="h5 m-h3">{{ 'pages.FAQ.THEMES' | translate }}</div>

  <div class="faq-thematics">
    <a *ngFor="let theme of thematics"
       [appAemRouterLink]="theme.link"
       class="faq-thematics_link">
      <div class="faq-thematics_item">
        <div>
          <div class="h6 m-h5 title">
            {{theme.title}}
          </div>
          <div class="text-tiny info">
            {{'pages.FAQ.DEMANDS' | translate: { count: theme.questionNumber} }}
          </div>
        </div>
        <app-icon icon="arrow-right" theme="lighten" size="x-large"></app-icon>
      </div>
    </a>
  </div>
</section>


