import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirective } from './img-alternate.directive';

@NgModule({
  declarations: [ImgAltDirective],
  exports: [ImgAltDirective],
  imports: [CommonModule],
})
export class ImgAltDirectiveModule { }
