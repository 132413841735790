import { FlightInfo } from "@common/flight/flight.interfaces";
import { FlightDto } from "./components/flight/flight.interface";
import { getDate } from '@kit/utils/date.utils';

export function mapFlightToFormSource(flightInfo: FlightInfo) {
  return {
    departure: {
      countryId: flightInfo.departure.country.countryId,
      city: flightInfo.departure.city,
      airport: null as unknown,
      airportCode: flightInfo.departure.airportCode,
      date: new Date(flightInfo.departure.date),
      time: flightInfo.departure.time,
      terminal: flightInfo.departure.terminal,
      gate: flightInfo.departure.gate,
    },
    arrival: {
      countryId: flightInfo.arrival.country.countryId,
      city: flightInfo.arrival.city,
      airport: null as unknown,
      airportCode: flightInfo.arrival.airportCode,
      date: new Date(flightInfo.arrival.date),
      time: flightInfo.arrival.time,
      terminal: flightInfo.arrival.terminal,
      gate: flightInfo.arrival.gate,
    },
    flightNumber: flightInfo.flightNumber,
    reservationNumber: flightInfo.reservationNumber,
  }
}

export function mapFlightSourceToDto(source: FlightInfo): FlightDto {
  return {
    departureCountry: source.departure.country,
    departureAirport: {
      code: source.departure.airportCode,
      city: source.departure.city,
    },
    departureDate: new Date(source.departure.date),
    departureTime: source.departure.time,

    arrivalCountry: source.arrival.country,
    arrivalAirport: {
      code: source.arrival.airportCode,
      city: source.arrival.city,
    },
    arrivalDate: new Date(source.arrival.date),
    arrivalTime: source.arrival.time,
    coTravelers: source.coTravelers,
    flightId: source.flightId,
    flightNumber: source.flightNumber,
    bookingNumber: source.reservationNumber,
    monitoredByCollinson: source.monitoredByCollinson,
    lessThan24hBeforeDeparture: source.lessThan24hBeforeDeparture
  };
}

export function mapFlightDtoToFlightInfo(source: FlightDto): FlightInfo {
  return {
    departure: {
      airportCode: source.departureAirport.code,
      city: source.departureAirport.city,
      country: source.departureCountry,
      date: getDate(source.departureDate),
      time: source.departureTime,
    },
    arrival: {
      airportCode: source.arrivalAirport.code,
      city: source.arrivalAirport.city,
      country: source.arrivalCountry,
      date: getDate(source.arrivalDate),
      time: source.arrivalTime,
    },
    flightId: source.flightId,
    flightNumber: source.flightNumber,
    reservationNumber: source.bookingNumber,
    manual: true,
    coTravelers: source.coTravelers,
    monitoredByCollinson: source.monitoredByCollinson,
    lessThan24hBeforeDeparture: source.lessThan24hBeforeDeparture,
  };
}
