import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { StepByStep } from '@common/model/stepByStep';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-step-by-step',
  templateUrl: './step-by-step.component.html',
  styleUrls: ['./step-by-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemStepByStepComponent extends AemBaseBlockComponent {
  constructor(
    @Inject(AEM_DATA) public aemData: StepByStep,
  ) {
    super(aemData);
  }
}
