<section class="shell">
  <div class="file-downloads">
    <app-document-button *ngFor="let item of data.items"
                         [label]="item.label"
                         [link]="item.file"
                         [type]="item.type"
                         [size]="item.size">
    </app-document-button>
  </div>
</section>
