<label [attr.for]="inputId" class="radio-label">
  <span class="radio-container">
    <input class="radio-input-origin" type="radio"
        [id]="inputId"
        [checked]="checked"
        [disabled]="disabled"
        [attr.name]="computedName"
        [attr.value]="value"
        [required]="required"
        (change)="onInputChange()"
        (click)="onInputClick()">
    <span class="radio-input"></span>  
  </span>

  <div class="radio-label-content">
    <ng-content></ng-content>
  </div>
</label>