import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { SliderModule } from 'src/app/kit/carousel/slider.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { ButtonModule } from '@kit/button/button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { TripCardModule } from '@kit/trip-card/trip-card.module';
import { AemAnonymousHomeHeroComponent } from './anonymous-home-hero/anonymous-home-hero.component';
import { AemAuthorizedHomeHeroComponent } from './authorized-home-hero/authorized-home-hero.component';
import { AemHomeHeroComponent } from './home-hero.component';

@NgModule({
  declarations: [AemHomeHeroComponent, AemAnonymousHomeHeroComponent, AemAuthorizedHomeHeroComponent],
  exports: [AemHomeHeroComponent],
  imports: [
    CommonModule,
    AemButtonModule,
    SliderModule,
    SafeHtmlModule,
    TripCardModule,
    ButtonModule,
    ImgAltDirectiveModule,
  ]
})
export class AemHomeHeroModule { }
