import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { TripHero } from 'src/app/common/model/tripHero';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';

@Component({
  selector: 'app-trip-hero-block',
  templateUrl: './trip-hero-block.component.html',
  styleUrls: ['./trip-hero-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripHeroBlockComponent {
  @Output() goBack = new EventEmitter<void>();

  constructor(@Inject(AEM_DATA) public aemData: TripHero) { }

  onBack() {
    this.goBack.emit();
  }
}
