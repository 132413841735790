import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '../aem-button/aem-button.module';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { DynamicWrapperBlockComponent } from './aem-dynamic-wrapper.component';

@NgModule({
  declarations: [DynamicWrapperBlockComponent],
  exports: [DynamicWrapperBlockComponent],
  imports: [
    CommonModule,
    SafeHtmlModule,
    AemButtonModule,
  ]
})
export class AemDynamicWrapperBlockModule {}
