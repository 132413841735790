import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { AemButtonModule } from '../aem-button/aem-button.module';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { SideBySideComponent } from './side-by-side.component';

@NgModule({
  declarations: [
    SideBySideComponent,
  ],
  exports: [
    SideBySideComponent,
  ],
  imports: [
    CommonModule,
    SafeHtmlModule,
    AemButtonModule,
    AemRouterModule,
    ImgAltDirectiveModule,
  ]
})
export class SideBySideModule { }
