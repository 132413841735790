import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemRouterModule } from '../../../../common/routing/aem-router.module';
import { AemButtonModule } from '../../../../kit/aem-button/aem-button.module';
import { TagModule } from '../../../../kit/tag/tag.module';
import { AemProfileLinksComponent } from './aem-profile-links.component';
import { AemLoungeVouchersModule } from '../lounge-vouchers/lounge-vouchers.module';

@NgModule({
  declarations: [AemProfileLinksComponent],
  exports: [AemProfileLinksComponent],
  imports: [
    CommonModule,
    AemRouterModule,
    AemButtonModule,
    TagModule,
    AemLoungeVouchersModule
  ]
})
export class AemProfileLinksModule { }
