import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { TuiDestroyService } from "@taiga-ui/cdk";
import { finalize, Observable, pairwise, shareReplay, startWith, takeUntil } from 'rxjs';
import { wrapValidator } from "src/app/kit/field-error/field-error.utils";
import { ServerErrorsValidationService } from "src/app/kit/server-errors/server-errors-validation.service";
import { emailValidator, maxDateValidator, minDateValidator, notBlankValidator } from "src/app/kit/utils/validators";
import {
  BookingPath,
  QuotationResponseContractContractList,
  QuotationResponseContractDocuments,
  QuotationResponseContractDocumentType,
  TravelerInfo
} from '@common/booking-path/booking-path.interfaces';
import { ApiInformationService } from '@common/information/api-information.service';
import { LanguageService } from '@common/language/language.service';
import { UserService } from '@common/user/user.service';
import { DateRange } from '@kit/date/date-range.interface';
import { codeToPattern } from '@kit/phone/phone.utils';
import { addYearsToDate, dateToStartDay } from '@kit/utils/date.utils';
import { Country } from '@pages/trip/trip.interfaces';
import { mapTravelersDetailsDTOToForm, mapTravelersDetailsToDTO } from '../../booking-path.mapper';
import { BookingService } from '../booking.service';
import { POSTCODE_REGEX } from './travelers-details.const';
import { BookingPathStep } from '../booking-path-step.enum';
import { CREDENTIAL_REGEXP } from "src/app/pages/account/components/co-travelers/co-travelers.const";
import { DigitalDataService } from '@common/analytics/digital-data.service';

@Component({
  selector: 'app-travelers-details',
  templateUrl: './travelers-details.component.html',
  styleUrls: ['./travelers-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class TravelersDetailsComponent implements OnInit {
  @Output() public done = new EventEmitter<void>();

  public get bookingPathData(): BookingPath {
    return this.bookingService.bookingPathData;
  };

  public travelersArray: UntypedFormArray;
  public policyForm: UntypedFormGroup;
  public travelersDetailsForm: UntypedFormGroup;
  public destinations: string;

  public termsDocument: QuotationResponseContractDocuments;
  public distributionDocument: QuotationResponseContractDocuments;

  public get currentPlan(): QuotationResponseContractContractList {
    return this.bookingService.selectedPlanDetails;
  }

  public residenceCountries$: Observable<Country[]> = this.infoService.getLocalCountries(this.languageService.countryCode.toUpperCase()).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public birthDateLimits: DateRange = {
    from: addYearsToDate(new Date(), -150),
    to: dateToStartDay(new Date()),
  }

  constructor(
    private readonly bookingService: BookingService,
    private readonly fb: UntypedFormBuilder,
    private readonly validationService: ServerErrorsValidationService,
    private readonly languageService: LanguageService,
    private readonly infoService: ApiInformationService,
    private readonly userService: UserService,
    private readonly destroy$: TuiDestroyService,
    private readonly translateService: TranslateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly digitalDataService: DigitalDataService,
  ) { }

  public ngOnInit(): void {
    this.initMainForm();

    this.destinations = this.getCountryNames(this.bookingPathData?.tripDetails?.destinationLocation?.names);
    this.termsDocument = this.bookingPathData?.selectedProduct?.documents
      ?.find(({ documentInfo: { documentType } }) => documentType === QuotationResponseContractDocumentType.TAndC);
    this.distributionDocument = this.bookingPathData?.selectedProduct?.documents
      ?.find(({ documentInfo: { documentType } }) => documentType === QuotationResponseContractDocumentType.IDD);

    this.digitalDataService.updatePageName(BookingPathStep.TRAVELLER_INFO);
  }

  public onEditClick(): void {
    this.bookingService.selectStep(1);
  }

  private initMainForm(): void {
    this.initPolicyForm(this.bookingPathData.tripDetails.residenceCountryCode);
    this.initTravelersArray(this.bookingPathData.travelersDetails?.travellers);

    this.travelersDetailsForm = this.fb.group({
      travelers: this.travelersArray,
      policy: this.policyForm,
    });

    const firstTravelerControl = this.travelersArray.get([0]);
    const firstTravelerDateOfBirthControl = firstTravelerControl.get('dateOfBirth');

    const holderNameControl = this.policyForm.get('firstName');
    const holderLastNameControl = this.policyForm.get('lastName');
    const holderDateOfBirthControl = this.policyForm.get('dateOfBirth');

    this.bookingService.bookingPathDataChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((bookingData) => {
        if (bookingData?.travelersDetails) {
          const formData = mapTravelersDetailsDTOToForm(bookingData.travelersDetails);
          this.travelersDetailsForm.patchValue(formData, { emitEvent: false });
        }
      });

    holderDateOfBirthControl.setValue(firstTravelerDateOfBirthControl.value, { emitEvent: false });

    if (this.userService.authorized && !this.bookingService.bookingPathData.travelersDetails) {
      firstTravelerControl.patchValue({
        firstName: this.userService.userData.firstName,
        lastName: this.userService.userData.lastName,
      }, { emitEvent: false });

      this.policyForm.patchValue({
        firstName: this.userService.userData.firstName,
        lastName: this.userService.userData.lastName,
        email: this.userService.userData.email,
      }, { emitEvent: false });
    }

    firstTravelerControl.valueChanges
      .pipe(
        startWith(firstTravelerControl.value),
        pairwise(),
        takeUntil(this.destroy$)
      )
      .subscribe(([prev, value]) => {
        if (prev.firstName === holderNameControl.value) holderNameControl.setValue(value.firstName, { emitEvent: false });
        if (prev.lastName === holderLastNameControl.value) holderLastNameControl.setValue(value.lastName, { emitEvent: false });
      });

    this.travelersDetailsForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.bookingService.changeLastAvailableStep(3));
  }

  private initPolicyForm(residence?: string) {
    this.policyForm = this.fb.group({
      address: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.maxLength(128), 'errors.CEAZ000_Size'),
        ],
        this.validationService.createValidator('travelersDetails.policyHolder.address')
      ],
      city: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.maxLength(128), 'errors.CEAZ000_Size'),
        ],
        this.validationService.createValidator('travelersDetails.policyHolder.city')
      ],
      postCode: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.maxLength(10), 'errors.CEAZ000_Size'),
          wrapValidator(Validators.pattern(POSTCODE_REGEX), 'errors.CEAZ000_Pattern'),
        ],
        this.validationService.createValidator('travelersDetails.policyHolder.postCode')
      ],
      email: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.maxLength(320), 'errors.CEAZ000_Size'),
          wrapValidator(emailValidator, 'errors.CEAZ000_Pattern'),
        ],
        this.validationService.createValidator('travelersDetails.policyHolder.email')
      ],
      phoneNumber: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(Validators.pattern(codeToPattern(this.languageService.countryCode)), 'errors.CEAZ000_Pattern'),
        ],
        this.validationService.createValidator('travelersDetails.policyHolder.phoneNumber')
      ],
      firstName: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.maxLength(79), 'errors.CEAZ000_Size'),
          wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern')
        ],
        this.validationService.createValidator('travelersDetails.policyHolder.firstName')
      ],
      lastName: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.maxLength(79), 'errors.CEAZ000_Size'),
          wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern')
        ],
        this.validationService.createValidator('travelersDetails.policyHolder.lastName')
      ],
      dateOfBirth: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(minDateValidator(this.birthDateLimits.from as Date), 'forms.BOOKING_PATH.TRIP_DETAILS.TRAVELERS.DATE_OF_BIRTH_PAST_ERROR'),
          wrapValidator(maxDateValidator(this.birthDateLimits.to as Date), 'forms.BOOKING_PATH.TRIP_DETAILS.TRAVELERS.DATE_OF_BIRTH_FUTURE_ERROR'),
        ],
        this.validationService.createValidator('travelersDetails.policyHolder.dateOfBirth')
      ],
      marketingAccept: false,
      residence: [
        { value: residence, disabled: true },
        null,
        this.validationService.createValidator('travelersDetails.policyHolder.country')
      ],
      termsAccept: [
        false,
        wrapValidator(Validators.requiredTrue, 'errors.CEAZ011'),
        this.validationService.createValidator('travelersDetails.termsAccept')
      ],
    });
  }

  private getCountryNames(codes: string[]): string {
    if (!codes || !codes.length) {
      return null;
    }

    return codes
      .map(code => this.translateService.instant(`countries.${code}`))
      .join(', ');
  }

  private initTravelersArray(travelersData?: TravelerInfo[]) {
    const travelers: any[] = travelersData ||
      this.bookingPathData.tripDetails.travellers;

    this.travelersArray = this.fb.array(travelers.map((traveler, index) => this.fb.group({
      firstName: [
        traveler?.firstName,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.maxLength(79), 'errors.CEAZ000_Size'),
          wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern')
        ],
        this.validationService.createValidator(`travelersDetails.travellers[${index}].firstName`)
      ],
      lastName: [
        traveler?.lastName,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.maxLength(79), 'errors.CEAZ000_Size'),
          wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern')
        ],
        this.validationService.createValidator(`travelersDetails.travellers[${index}].lastName`)
      ],
      dateOfBirth: new Date(traveler.dateOfBirth)
    })));
  }

  public getAge(dateOfBirth: Date | string): number {
    const travelerDate = new Date(dateOfBirth);
    const monthDiff = Date.now() - travelerDate.getTime();
    const ageMargin = new Date(monthDiff);
    const year = ageMargin.getUTCFullYear();

    return Math.abs(year - 1970);
  }

  public onProceed(): void {
    this.policyForm.markAllAsTouched();
    this.travelersArray.markAllAsTouched();

    if (!this.isValidForm(this.travelersDetailsForm)) {
      return;
    }

    const formData = this.travelersDetailsForm.getRawValue();

    const bookingData: BookingPath = {
      tripDetails: this.bookingPathData.tripDetails,
      selectedProduct: this.bookingPathData.selectedProduct,
      travelersDetails: mapTravelersDetailsToDTO(formData),
    };

    this.bookingService.completeStep(bookingData)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.cdr.markForCheck())
      )
      .subscribe(() => this.done.emit(null));
  }

  private isValidForm(form: UntypedFormGroup): boolean {
    return Object.keys(form.controls).every(field => {
      if (form.get(field) instanceof UntypedFormArray) {
        return (form.get(field) as UntypedFormArray).controls.every(control => !control.errors);
      }

      if (form.get(field) instanceof UntypedFormGroup) {
        return this.isValidForm(form.get(field) as UntypedFormGroup);
      }

      return !form.get(field).errors;
    });
  }
}
