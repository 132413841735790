import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { ApiPreviewService } from '@common/preview/api-preview.service';
import { UserService } from '@common/user/user.service';
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';
import { catchError, mapTo, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageService } from "@common/language/language.service";

@Injectable({
  providedIn: 'root'
})
export class ContentPreviewGuard  {
  constructor(
    private router: Router,
    private apiPreviewService: ApiPreviewService,
    private userService: UserService,
    private languageService: LanguageService,
    @Inject(IS_BROWSER_PLATFORM) private isBrowser: boolean,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    if (
      this.isBrowser &&
      this.userService.authorized &&
      environment.previewFeature &&
      route.queryParamMap.has('path')
    ) {
      const countryCode = this.languageService.countryCode;

      return this.apiPreviewService.refreshPreviewContentByCountryCode(countryCode).pipe(
        catchError(() => of(null)),
        mapTo(this.router.createUrlTree([route.queryParamMap.get('path')]))
      );
    }

    return true;
  }
}
