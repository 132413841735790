export enum DistinctionStatus {
  RED = 'red',
  GREEN = 'green',
  YELLOW = 'yellow',
  INFO = 'INFO',
  CORONAVIRUS = 'coronavirus',
}

enum DestinationEndpointType {
  ENTRY_REQUIREMENTS = 1,
  TRAVEL_RESTRICTIONS = 5,
}

const DESTINATION_ENTRY_REQUIREMENTS_STATUS: { [key: number]: { [key: number]: DistinctionStatus } } = {
  0: {
    0: DistinctionStatus.RED,
    1: DistinctionStatus.GREEN,
    2: DistinctionStatus.YELLOW,
    3: DistinctionStatus.YELLOW,
  },
  1: {
    0: DistinctionStatus.RED,
    1: DistinctionStatus.GREEN,
    2: DistinctionStatus.YELLOW,
    3: DistinctionStatus.YELLOW,
  },
  2: {
    0: DistinctionStatus.RED,
    1: DistinctionStatus.GREEN,
    2: DistinctionStatus.YELLOW,
    3: DistinctionStatus.YELLOW,
  },
  3: {
    0: DistinctionStatus.GREEN,
    1: DistinctionStatus.RED,
    2: DistinctionStatus.YELLOW,
    3: DistinctionStatus.YELLOW,
  },
  4: {
    0: DistinctionStatus.GREEN,
    1: DistinctionStatus.RED,
    2: DistinctionStatus.YELLOW,
    3: DistinctionStatus.YELLOW,
  },
  5: {
    0: DistinctionStatus.GREEN,
    1: DistinctionStatus.RED,
    2: DistinctionStatus.YELLOW,
    3: DistinctionStatus.YELLOW,
  },
  6: {
    0: DistinctionStatus.GREEN,
    1: DistinctionStatus.RED,
    2: DistinctionStatus.YELLOW,
    3: DistinctionStatus.YELLOW,
    6: DistinctionStatus.CORONAVIRUS,
  },
  7: {
    0: DistinctionStatus.GREEN,
    1: DistinctionStatus.RED,
    2: DistinctionStatus.YELLOW,
    3: DistinctionStatus.YELLOW,
  },
  8: {
    0: DistinctionStatus.GREEN,
    1: DistinctionStatus.RED,
    2: DistinctionStatus.YELLOW,
    3: DistinctionStatus.YELLOW,
  },
  9: {
    0: DistinctionStatus.GREEN,
    1: DistinctionStatus.RED,
    2: DistinctionStatus.YELLOW,
    3: DistinctionStatus.YELLOW,
  },
};

const DESTINATION_TRAVEL_RESTRICTION_STATUS: { [key: number]: { [key: number]: DistinctionStatus } } = {
  2: {
    '-1': DistinctionStatus.YELLOW,
    0: DistinctionStatus.GREEN,
    1: DistinctionStatus.YELLOW,
    2: DistinctionStatus.RED,
    3: DistinctionStatus.RED,
    10: DistinctionStatus.RED,
    11: DistinctionStatus.YELLOW,
    12: DistinctionStatus.GREEN,
    13: DistinctionStatus.YELLOW,
  },
  3: {
    '-1': DistinctionStatus.YELLOW,
    0: DistinctionStatus.GREEN,
    1: DistinctionStatus.YELLOW,
    2: DistinctionStatus.RED,
    3: DistinctionStatus.RED,
    10: DistinctionStatus.RED,
    11: DistinctionStatus.YELLOW,
    12: DistinctionStatus.GREEN,
    13: DistinctionStatus.YELLOW,
  },
  401: {
    '-1': DistinctionStatus.YELLOW,
    1: DistinctionStatus.YELLOW,
    0: DistinctionStatus.GREEN,
    2: DistinctionStatus.RED,
    3: DistinctionStatus.RED,
    10: DistinctionStatus.GREEN,
    11: DistinctionStatus.YELLOW,
    12: DistinctionStatus.RED,
    13: DistinctionStatus.YELLOW,
  },
  410: {
    '-1': DistinctionStatus.YELLOW,
    0: DistinctionStatus.GREEN,
    1: DistinctionStatus.YELLOW,
    2: DistinctionStatus.RED,
    3: DistinctionStatus.RED,
    10: DistinctionStatus.RED,
    11: DistinctionStatus.YELLOW,
    12: DistinctionStatus.GREEN,
    13: DistinctionStatus.YELLOW,
  },
}

export function matchDistinctionStatus<T extends { value?: number, type?: number, endpointType?: number }>(item: T): T {
  let status: DistinctionStatus;

  if (!item) {
    return item;
  }

  switch (item.endpointType) {
    case DestinationEndpointType.ENTRY_REQUIREMENTS:
      status = DESTINATION_ENTRY_REQUIREMENTS_STATUS[item?.type]?.[item?.value];
      break;
    case DestinationEndpointType.TRAVEL_RESTRICTIONS:
      status = DESTINATION_TRAVEL_RESTRICTION_STATUS[item?.type]?.[item?.value];
      break;
  
    default:
      status = DistinctionStatus.INFO;
      break;
  }

  return {
    ...item,
    status: status || DistinctionStatus.INFO,
  }
}