import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditTripComponent } from "@pages/trip/components/edit-trip/edit-trip.component";
import { TripHeroBlockModule } from "@pages/trip/components/trip-hero-block/trip-hero-block.module";
import { EditTripFromModule } from "@pages/trip/components/edit-trip-from/edit-trip-from.module";

@NgModule({
  declarations: [EditTripComponent],
  imports: [
    CommonModule,
    TripHeroBlockModule,
    EditTripFromModule
  ]
})
export class EditTripModule { }
