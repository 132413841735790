import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateMembershipPipe } from './translate-membership.pipe';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule
  ],
  declarations: [TranslateMembershipPipe],
  exports: [TranslateMembershipPipe]
})
export class TranslateMembershipPipeModule {}
