import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreatePromoCodeDTO, PromoCode, PromoCodeSearchParams, PromoCodeSearchResult } from './promocode.interface';
import { ApiPromoCodeService } from './api-promo-code.service';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService implements ApiPromoCodeService{
  private readonly baseUrl = environment.api.baseUrl;

  constructor(private readonly http: HttpClient) {}

  public getPromoCodes(search?: PromoCodeSearchParams): Observable<PromoCodeSearchResult> {
    if (search) {
      const searchParams = new URLSearchParams();

      if (search.page) searchParams.append('page', (search.page - 1).toString());
      if (search.partnerId) searchParams.append('partnerId', search.partnerId.toString());
      if (search.partnerName) searchParams.append('partnerName', search.partnerName);
      if (search.marketName) searchParams.append('marketName', search.marketName);
      if (search.promoCode) searchParams.append('promoCode', search.promoCode);
      if (search.status) searchParams.append('status', search.status);

      return this.http.get<PromoCodeSearchResult>(`${this.baseUrl}/promocodes?${searchParams.toString()}`);
    }

    return this.http.get<PromoCodeSearchResult>(`${this.baseUrl}/promocodes`);
  }

  public getPromoCode(id: string): Observable<PromoCode> {
    return this.http.get<PromoCode>(`${this.baseUrl}/promocodes/${id}`)
  }

  public createPromoCode(body: CreatePromoCodeDTO): Observable<{id: string}> {
    return this.http.post<{id: string}>(`${this.baseUrl}/promocodes`, body);
  }

  public editPromoCode(id: string, body: CreatePromoCodeDTO): Observable<PromoCode> {
    return this.http.put<PromoCode>(`${this.baseUrl}/promocodes/${id}`, body);
  }

  public deletePromoCode(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/promocodes/${id}`)
  }

  public getPromoCodeList(): Observable<Blob> {
    return this.http.get<Blob>(`${this.baseUrl}/promocodes/report`, {responseType: 'blob' as 'json'});
  }

  public validatePromoCode(promoCode: string, countryCode: string): Observable<void> {
    return this.http.get<any>(`${environment.api.publicProfile}/promocodes/validate/${promoCode}?countryCode=${countryCode}`);
  }
}
