import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';

@Component({
  selector: 'app-account-edit-form',
  templateUrl: './account-edit-form.component.html',
  styleUrls: ['./account-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemAccountEditFormComponent extends AemFragmentComponent {
}
