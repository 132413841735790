import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { IS_BROWSER_PLATFORM } from '../utils/ssr.utils';

@Injectable({
  providedIn: 'root'
})
export class GlobalLoaderService {
  private loadingCounter = 0;
  private _loading$ = new BehaviorSubject(Boolean(this.loadingCounter));

  public loading$ = this._loading$.asObservable();

  constructor(@Inject(IS_BROWSER_PLATFORM) private isBrowser: boolean) {}

  public show(): void {
    this.loadingCounter++;
    this.normalizeCounter();
    this._loading$.next(Boolean(this.loadingCounter));
    
  }

  public hide(): void {
    this.loadingCounter--;
    this.normalizeCounter();
    this._loading$.next(Boolean(this.loadingCounter));
  }

  private normalizeCounter(): void {
    this.loadingCounter = this.loadingCounter < 0 ? 0 : this.loadingCounter;
  }
}
