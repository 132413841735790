import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BlogArticleScreen } from '@common/model/blogArticleScreen';
import { Fragment } from '@common/model/fragment';
import { PlaceHolderMainComponent } from './placeholder-main.component';

@Component({
  selector: 'app-placeholder-top',
  templateUrl: './placeholder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceHolderTopComponent extends PlaceHolderMainComponent {
  protected override getContent(data: BlogArticleScreen): Fragment[] {
    return data.top || [];
  }
}
