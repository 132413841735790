import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { getAllRouterParams, setRouterData } from '@kit/utils/router.utils';

@Component({
  selector: 'app-open-policy-details',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenPolicyDetailsComponent {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly bootstrapService: BootstrapService,
  ) {}

  @HostListener('click')
  onClick() {
    this.router.navigate(
      [setRouterData(
        this.bootstrapService.link.openPolicyDetailsAction,
        getAllRouterParams(this.activatedRoute.snapshot.root),
      )]
    );
  }
}
