import { Inject, Injectable } from '@angular/core';
import { Screen, SEOAlternateHREFLANG } from '../model/models';
import { LinkService } from './alternate-href.service';
import { GoogleVerificationService } from './google-verification.service';
import { MetaTagsService } from './meta-tags.service';
import { LinkingDataService } from './linking-data.service';
import { IS_SERVER_PLATFORM } from 'src/app/kit/utils/ssr.utils';

@Injectable({ providedIn: 'root' })
export class SeoService {
  constructor(
    private readonly googleVerificationService: GoogleVerificationService,
    private readonly linkService: LinkService,
    private readonly metaTagsService: MetaTagsService,
    private readonly linkingDataService: LinkingDataService,
    @Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean
  ) {}

  public updateSeoData(screen: Screen, importantAlternates: SEOAlternateHREFLANG[]): void {
    this.metaTagsService.updateMetaTag(screen);

    if (this.isServer) {
      this.linkingDataService.updateJsonLD(screen);
      this.linkingDataService.updateBlogArticleJsonLD(screen);
      this.linkService.updateImportantAlternateHref(importantAlternates);
      this.linkService.updateAlternateHref(screen?.seo?.alternates);
      this.linkService.updateCanonical(screen?.seo?.canonical);
      this.googleVerificationService.updateGoogleVerificationCode(screen?.seo?.googleSiteVerificationCode);
    }
  }
}
