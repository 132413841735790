import { Component, ChangeDetectionStrategy, Input, inject } from '@angular/core';
import { CTAHideableExtendedLink } from '@common/model/cTAHideableExtendedLink';
import { TradedoublerService } from "@common/tradedoubler/tradedoubler.service";

const NOT_ROUTE_LINK_REGEXP = /^(mailto|tel|http|https):/;

@Component({
  selector: 'app-aem-hideable-extended-link',
  templateUrl: './aem-hideable-extended-link.component.html',
  styleUrls: ['./aem-hideable-extended-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemHideableExtendedLinkComponent {
  @Input() public item: CTAHideableExtendedLink;

  private tradedoublerService = inject(TradedoublerService);

  public isRoute(link: string): boolean {
    return !NOT_ROUTE_LINK_REGEXP.test(link);
  }

  public getLink(): string {
    if (!this.item.addTradedoublerParameters) {
      return this.item.link;
    }

    return `${this.item.link}?${this.tradedoublerService.getParamsString()}`;
  }
}
