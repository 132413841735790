import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UserType } from "@common/profile/profile.interfaces";

@Pipe({
  name: 'appTranslateMembership'
})
export class TranslateMembershipPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
  ) {}

  public transform(membership: UserType): string {
    return this.translateService.instant(`global.MEMBERSHIP_TYPE.${membership}`);
  }
}
