import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemRouterModule } from '../../../../common/routing/aem-router.module';
import { AemButtonModule } from '../../../../kit/aem-button/aem-button.module';
import { ButtonModule } from '@kit/button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { CheckboxModule } from '@kit/checkbox/checkbox.module';
import { FieldErrorModule } from '@kit/field-error/field-error.module';
import { BackButtonModule } from '@kit/back-button/back-button.module';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@kit/icon/icon.module';

@NgModule({
  declarations: [TermsAndConditionsComponent],
  exports: [],
  imports: [
    CommonModule,
    AemRouterModule,
    AemButtonModule,
    ButtonModule,
    TranslateModule,
    SafeHtmlModule,
    ImgAltDirectiveModule,
    CheckboxModule,
    FieldErrorModule,
    BackButtonModule,
    ReactiveFormsModule,
    IconModule
  ]
})
export class TermsAndConditionsModule { }
