import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AemFragmentComponent } from "@kit/aem-base-block/aem-fragment";
import { AEM_DATA } from "@pages/dynamic/dynamic-render/dynamic-render.const";
import { RichText } from "@common/model/rich-text";

@Component({
  selector: 'app-aem-rich-text',
  templateUrl: './aem-rich-text.component.html',
  styleUrls: ['./aem-rich-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemRichTextComponent extends AemFragmentComponent implements OnInit {
  public textSize: 'small' | 'normal' | 'large';

  constructor(@Inject(AEM_DATA) public override data: RichText) {
    super(data);
  }

  public ngOnInit(): void {
    this.textSize = this.data.size || 'normal';
  }
}
