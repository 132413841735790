import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CarouselCardsWithIcon } from '@common/model/carouselCardsWithIcon';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-carousel-cards-with-icon',
  templateUrl: './carousel-cards-with-icon.component.html',
  styleUrls: ['./carousel-cards-with-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemCarouselCardsWithIconComponent extends AemBaseBlockComponent {
  constructor(
    @Inject(AEM_DATA) public override data: CarouselCardsWithIcon,
  ) {
    super(data);
  }
}
