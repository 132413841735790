<app-dialog-wrapper>
  <ng-container dialogContent>
    <div class="dialog-content">
      <img class="dialog-content_icon" src="assets/img/success.svg">
      <div>
        <div class="h5 m-h3 dialog-content_title" *ngIf="data.title">
          {{ data.title }}
        </div>
        <div class="t-normal" *ngIf="data.message">
          {{ data.message }}
        </div>
      </div>
      <app-button id="action-dialog-close" 
                  size="l" 
                  *ngIf="data.closeTitle" 
                  class="dialog-content_action" 
                  type="big-width"
                  (click)="close()">
        {{ data.closeTitle }}
      </app-button>
    </div>
  </ng-container>
</app-dialog-wrapper>