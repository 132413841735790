import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { SeoBlockComponent } from './seo-block.component';

@NgModule({
  declarations: [SeoBlockComponent],
  exports: [SeoBlockComponent],
  imports: [
    CommonModule,
    SafeHtmlModule,
    ButtonModule,
    TranslateModule,
  ]
})
export class SeoBlockModule { }
