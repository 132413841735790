<section class="carousel-cards">
  <div class="shell">
    <div class="carousel-cards__header" *ngIf="data.title || data.text">
      <h2 class="zero-margin h2 m-h2">{{ data.title }}</h2>
      <div class="carousel-cards__label" *ngIf="data.text">
        <app-safe-html [content]="data.text" class="t-large m-t-normal"></app-safe-html>
      </div>
    </div>
      <app-slider
        [slidesPerGroup]="2"
        [showAllInMobile]="true"
        [itemsCentered]="true"
      >
        <ng-template *ngFor="let item of data.items" #swiperSlide>
          <app-slider-item
            class="entry-card"
            [navigateTo]="item.cta"
            [style.background-color]="item.colourBackground"
            [ngClass]="['view-mobile-' + data.viewMobile, 'view-desktop-' + data.viewDesktop]"
          >
            <app-icon
              [icon]="item.icon"
              [style.color]="item.colourIcon"
              class="entry-card__icon"
              theme="inherit"
              size="inherit"
            ></app-icon>
            <div class="entry-card__body">
              <h6 class="entry-card__title h6 weight-medium">{{ item.title }}</h6>
              <div *ngIf="item.text" class="entry-card__text" [style.color]="item.colourText">
                <app-safe-html [content]="item.text" class="t-small"></app-safe-html>
              </div>
            </div>
            <div class="entry-card__actions" *ngIf="item.cta">
              <app-aem-button
                [cta]="item.cta"
                type="icon-button"
                size="l"
                class="entry-card__action"
              ></app-aem-button>
            </div>
          </app-slider-item>
        </ng-template>
      </app-slider>
  </div>
</section>
