import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './slider.component';
import { SliderItemComponent } from './slider-item/slider-item.component';
import { AemRouterModule } from 'src/app/common/routing/aem-router.module';
import { SwiperModule } from 'swiper/angular';
import { TuiLetModule } from '@taiga-ui/cdk';

@NgModule({
  declarations: [
    SliderComponent,
    SliderItemComponent,
  ],
  exports: [
    SliderComponent,
    SliderItemComponent,
  ],
  imports: [
    CommonModule,
    AemRouterModule,
    SwiperModule,
    TuiLetModule,
  ]
})
export class SliderModule { }
