import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FAQFeedback } from 'src/app/common/model/fAQFeedback';
import { AemBaseBlockComponent } from 'src/app/kit/aem-base-block/aem-base-block';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AnalyticsService } from '@common/analytics/analytics.service';

@Component({
  selector: 'app-aem-faq-feedback',
  templateUrl: './helpful.component.html',
  styleUrls: ['./helpful.component.scss', './aem-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemFaqFeedbackComponent extends AemBaseBlockComponent {
  showHelpfulBlock = true;

  constructor(
    @Inject(AEM_DATA)
    public override readonly data: FAQFeedback,
    private analyticsService: AnalyticsService,
  ) {
    super(data);
  }

  public onClick(isHelpful: boolean): void {
    this.analyticsService.triggerAction({
      category: 'customer_care',
      action: 'clickFAQ',
      label: this.data.uuid,
      value: isHelpful ? 1 : 0,
    });

    this.showHelpfulBlock = false;
  }
}
