import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnimateHeightDirectiveModule } from '../animation/animate-height-directive/animate-height-directive.module';
import { ButtonModule } from '../button/button.module';
import { AccordionDirective } from './accordion-container.directive';
import { AccordionComponent } from './accordion.component';

@NgModule({
  declarations: [AccordionComponent, AccordionDirective],
  exports: [AccordionComponent, AccordionDirective],
  imports: [CommonModule, ButtonModule, AnimateHeightDirectiveModule]
})
export class AccordionModule {}
