import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { IS_BROWSER_PLATFORM } from "@kit/utils/ssr.utils";
import { Router } from "@angular/router";
import { BootstrapService } from "@common/bootstrap/bootstrap.service";
import { ServerValidationError, ServerValidationErrorResponse } from '@kit/server-errors/server-errors.interface';

@Injectable()
export class LegalConsentHttpInterceptor implements HttpInterceptor {
  constructor(
    @Inject(IS_BROWSER_PLATFORM) private isBrowser: boolean,
    private router: Router,
    private bootstrap: BootstrapService
  ) {
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 403 && (error.error as ServerValidationErrorResponse)?.fault?.errors.find((err: ServerValidationError) => err.errorCode === 'CEAZ123')) {
      this.router.navigateByUrl(this.bootstrap.link.termsAndConditionsConsentPage);

      return throwError(error);
    }

    return throwError(error);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isBrowser) {
      return next.handle(req).pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
    }

    return next.handle(req);
  }
}
