import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { IconModule } from '@kit/icon/icon.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemMembershipComponent } from './membership.component';
import { ImgAltDirectiveModule } from "@kit/aem/img-alt-directive/img-alternate-directive.module";
import { ButtonModule } from "@kit/button/button.module";

@NgModule({
  declarations: [
    AemMembershipComponent,
  ],
  imports: [
    CommonModule,
    AemButtonModule,
    IconModule,
    SafeHtmlModule,
    ImgAltDirectiveModule,
    ButtonModule,
  ],
})
export class AemMembershipCardsModule {}
