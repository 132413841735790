import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BookingPath, CompleteInsuranceInfo, QuotationResponse } from './booking-path.interfaces';

@Injectable({ providedIn: 'root' })
export abstract class ApiBookingPathService {
  abstract getBookingPathForm(): Observable<BookingPath>;
  abstract getQuote(bookingForm: BookingPath): Observable<QuotationResponse>;
  abstract updateTravelersDetails(bookingForm: BookingPath): Observable<string>;
  abstract selectPlan(bookingForm: BookingPath): Observable<void>;
  abstract commitContract(bookingForm: BookingPath): Observable<CompleteInsuranceInfo>;
  abstract clearQuoteCache(): Observable<void>;
}
