<section>
  <div class="shell">
    <div class="cards"
         *tuiLet="profile$ | async as profile">
      <div class="cards_item">
        <div class="cards_item_form">
          <h5 class="h5 m-h3 section__text cards_item_form-title">
            {{ 'pages.ACCOUNT_DETAILS.TITLES.ACCOUNT_DETAILS' | translate }}
          </h5>
          <p class="h6 m-h4 field label">{{ 'pages.ACCOUNT_DETAILS.LABELS.FIRST_NAME' | translate }}</p>
          <p class="t-large m-t-normal field"> {{ profile?.firstName }} </p>
          <p class="h6 m-h4 field label">{{ 'pages.ACCOUNT_DETAILS.LABELS.LAST_NAME' | translate }}</p>
          <p class="t-large m-t-normal field"> {{ profile?.lastName }} </p>
          <p class="h6 m-h4 field label">{{ 'pages.ACCOUNT_DETAILS.LABELS.CONTACT_NUMBER' | translate }}</p>
          <p class="t-large m-t-normal field"> {{ profile?.phone }} </p>
          <h5 class="h5 m-h3 section__text cards_item_form-title">{{ 'pages.ACCOUNT_DETAILS.TITLES.LOGIN_INFO' | translate }}</h5>
          <p class="h6 m-h4 field label">{{ 'pages.ACCOUNT_DETAILS.LABELS.EMAIL' | translate }}</p>
          <p class="t-large m-t-normal field"
             [appTooltip]="profile?.email"
             [tooltipOnlyExtraContent]="true">
            {{ profile?.email }}
          </p>
        </div>
        <app-button id="action-manage-account"
                    class="cards_item_action"
                    [appAemRouterLink]="bootstrapService.link.accountEdit">
          {{ 'pages.ACCOUNT_DETAILS.ACTIONS.MANGE_MY_ACCOUNT' | translate }}
        </app-button>
      </div>
      <div class="cards_item premium-block">
        <div class="cards_header">
          <h5 class="h5 m-h3 section__text">
            {{ 'pages.ACCOUNT_DETAILS.TITLES.CO_TRAVELERS' | translate }}
          </h5>
        </div>
        <app-safe-html class="section__text t-large m-t-normal" [content]="'pages.ACCOUNT_DETAILS.DESCRIPTIONS.CO_TRAVELERS' | translate">
        </app-safe-html>
        <app-button id="action-edit-cotravelers"
                    class="cards_item_action"
                    [appAemRouterLink]="bootstrapService.link.cotravelers">
          {{ 'pages.ACCOUNT_DETAILS.ACTIONS.EDIT_CO_TRAVELERS' | translate }}
        </app-button>
      </div>
    </div>
    <app-button icon="thrash"
                class="section_action"
                theme="bordered"
                id="action-account-delete"
                (click)="onDelete()">
      {{ 'pages.ACCOUNT_DETAILS.ACTIONS.DELETE_ACCOUNT' | translate }}
    </app-button>
  </div>
</section>
