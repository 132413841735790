import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalLoaderComponent } from './global-loader.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalLoaderInterceptor } from './global-loader.interceptor';



@NgModule({
  exports: [GlobalLoaderComponent],
  declarations: [GlobalLoaderComponent],
  imports: [CommonModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: GlobalLoaderInterceptor,
    multi: true,
  }]
})
export class GlobalLoaderModule {}
