import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BootstrapService } from '../bootstrap/bootstrap.service';

@Injectable({
  providedIn: 'root'
})
export class HeadScriptInject {
  private renderer: Renderer2;

  constructor(
    private readonly bootstrapService: BootstrapService,
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  injectHeadScripts(): void {
    if (!this.bootstrapService.headScripts) {
      return;
    }

    const container = this.renderer.createElement('div');
    this.renderer.setProperty(container, 'innerHTML', this.bootstrapService.headScripts);

    container.childNodes.forEach((node: HTMLElement) => {
      if (node.tagName === 'SCRIPT') {
        const element = this.renderer.createElement('script');

        node.getAttributeNames().forEach(attrName => {
          this.renderer.setAttribute(element, attrName, node.getAttribute(attrName));
        });
        this.renderer.setProperty(element, 'innerHTML', node.innerHTML);
        this.renderer.appendChild(this.document.head, element);
      }
    });
  }
}
