import { Component, ChangeDetectionStrategy, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { BehaviorSubject, Observable, of, takeUntil } from 'rxjs';
import { MaskedEmail, PolicyValidationData } from '../../policy.interface';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { InsurancePolicyTimerService } from '../../insurance-policy-timer.service';


@Component({
  selector: 'app-insurance-select-email',
  templateUrl: './insurance-select-email.component.html',
  styleUrls: ['./insurance-select-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class InsuranceSelectEmailComponent implements OnInit {
  @Input() policyData: PolicyValidationData;
  @Output() complete = new EventEmitter<PolicyValidationData>();
  @Output() expireTimer = new EventEmitter<void>();
  public emailForm = this.fb.group({ selectEmail: [null, [Validators.required]] });
  public emails$: Observable<MaskedEmail[]>;
  public isButtonDisable$ = new BehaviorSubject(true);
  public buttonIndex: number | null = null;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly destroy$: TuiDestroyService,
    private readonly policyService: ApiInsurancePolicyService,
    private readonly router: Router,
    private readonly bootstrapService: BootstrapService,
    private readonly insurancePolicyTimerService: InsurancePolicyTimerService
    ) { }

  public ngOnInit(): void {
    this.emails$ = of(this.policyData.maskedEmails);
    this.emailForm.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((email: string) => {
      if (email) {
        this.isButtonDisable$.next(false);
      }
    });
    this.setTimer();
  }

  public onSubmit(): void {
    this.emailForm.markAllAsTouched();

    if (this.emailForm.invalid) {
      return;
    }

    this.policyService.sendPinToEmail(this.policyData.code, this.policyData.maskedEmails[this.buttonIndex].id).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => this.complete.emit({
      code: this.policyData.code,
      maskedEmails: this.policyData.maskedEmails,
      selectedEmail: this.policyData.maskedEmails[this.buttonIndex]
    }));
  }

  private setTimer(): void {
    this.insurancePolicyTimerService.setTimer$().pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
      if (value === ConfirmationDialogComponent.CONFIRM) {
        this.expireTimer.emit();

        return;
      }

      this.router.navigateByUrl(this.bootstrapService.link.myInsurancePolicies);
    });
  }

}
