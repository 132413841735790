import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { Inject, Injectable, NgModule, Optional, TransferState } from '@angular/core';
import { IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { getAEMContentPath } from 'src/app/common/routing/routing.const';
import { environment } from 'src/environments/environment';
import { getCountryCode } from '@common/language/language.const';
import { MarkTranslationCompiler } from './mark-translation.compiler';
import { InterpolatedMissedTranslationHandler } from './missing-translation.handler';
import { CURRENT_LOCATION } from "@common/seo/seo.const";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { BUILD_TIME_STAMP_STATE } from "@common/start-up/start-up.const";

export function HttpLoaderFactory(
  httpBackend: HttpBackend,
  isServer: boolean,
  document: Document,
  optionalLocationService: OptionalLocationService,
  transfer: TransferState,
) {
  const buildTimeStamp: number = transfer.get(BUILD_TIME_STAMP_STATE, null);
  const host = isServer
    ? new URL(optionalLocationService.requestPath).host
    : document?.location?.host;
  const country = getCountryCode(host);
  const localDictionaryPath = (<any>environment).dictionaryPath + `${country.toLowerCase()}/`;
  const aemDictionaryPath = getAEMContentPath(country.toLowerCase(), '');

  return new MultiTranslateHttpLoader(httpBackend, [
    { prefix: localDictionaryPath, suffix: `.dictionary.${buildTimeStamp}.json` },
    { prefix: aemDictionaryPath, suffix: '/_dictionary.json' },
  ]);
}

@Injectable()
export class OptionalLocationService {
  constructor(@Optional() @Inject(CURRENT_LOCATION) public requestPath: string) {}
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxTranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: MarkTranslationCompiler,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: InterpolatedMissedTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend, IS_SERVER_PLATFORM, DOCUMENT, OptionalLocationService, TransferState],
      }
    })
  ],
  exports: [NgxTranslateModule],
  providers: [OptionalLocationService]
})
export class TranslateModule {}
