import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { AEM_DATA } from "@pages/dynamic/dynamic-render/dynamic-render.const";
import { AemFragmentComponent } from "@kit/aem-base-block/aem-fragment";
import { EmbeddedVideoPlayer } from "@common/model/embeddedVideoPlayer";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: 'app-aem-embedded-player',
  templateUrl: './aem-embedded-player.component.html',
  styleUrls: ['./aem-embedded-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemEmbeddedPlayerComponent extends AemFragmentComponent implements OnInit {
  @HostBinding('attr.id')
  get id() { return this.data.id || 'iframe' };

  public link: SafeResourceUrl;
  public width: number;
  public height: number;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly sanitizer: DomSanitizer,
    @Inject(AEM_DATA) public override data: EmbeddedVideoPlayer,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super(data)
  }

  ngOnInit(): void {
    this.link = this.buildLink();
    this.width = this.data.width || this.elementRef.nativeElement.width;
    this.height = this.data.height;
  }

  private buildLink(): SafeResourceUrl {
    const baseUrl = this.getBaseUrl();
    const videoId = this.getVideoId();

    if (!baseUrl || !videoId) {
      return null;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}${videoId}?${this.getQueryParams()}`);
  }

  private getBaseUrl(): string | null {
    if (this.data.link.includes('youtube')) return 'https://www.youtube.com/embed/';
    if (this.data.link.includes('vimeo')) return 'https://player.vimeo.com/video';

    return null;
  }

  private getVideoId(): string | null {
    if (this.data.link.includes('youtube')) {
      return new URL(this.data.link).searchParams.get('v');
    }

    if (this.data.link.includes('vimeo')) {
      return new URL(this.data.link).pathname;
    }

    return null;
  }

  private getQueryParams(): string {
    if (this.data.link.includes('youtube')) return this.getQueryParamsForYoutube();
    if (this.data.link.includes('vimeo')) return this.getQueryParamsForVimeo();

    return '';
  }

  private getQueryParamsForVimeo(): string {
    const params = new URLSearchParams();

    if (this.data.muted) params.set('muted', '1')
    if (this.data.hideControl) params.set('controls', '0');
    if (this.data.loop) params.set('loop', '1');
    if (this.data.autoplay) {
      params.set('autoplay', '1');
      params.set('muted', '1');
    }

    return params.toString();
  }

  private getQueryParamsForYoutube(): string {
    const params = new URLSearchParams();

    if (this.data.muted) params.set('mute', '1');
    if (this.data.hideControl) params.set('controls', '0');
    if (this.data.loop) {
      params.set('loop', '1');
      params.set('playlist', this.getVideoId());
    }
    if (this.data.autoplay) {
      params.set('autoplay', '1');
      params.set('mute', '1');
    }

    params.set('enablejsapi', '1');

    return params.toString();
  }
}
