<div class="shell logo-container">
  <div class="logo-container_wrapper">
    <app-back-button class="back-button" (goBack)="goBack()"></app-back-button>
    <a class="logo" routerLink="/">
      <img [src]="logo$ | async" alt="Allyz">
    </a>
  </div>
</div>

<div class="insurance-container"
     *ngIf="!bookingService.showConfirmationStep; else confirmationStep">
  <app-stepper *ngIf="isBrowser"
               [selectedStep]="bookingService.currentStepChange$ | async"
               [lastAvailableStep]="bookingService.lastAvailableStepChange$ | async"
               (selectedStepChange)="bookingService.selectStep($event)">
    <app-get-quote *stepperItem="'forms.BOOKING_PATH.TRIP_DETAILS.STEP' | translate"
                   (done)="resetScroll()">
    </app-get-quote>
    <app-choose-plan *stepperItem="'forms.BOOKING_PATH.SELECT_PLAN.STEP' | translate"
                     (done)="resetScroll()">
    </app-choose-plan>
    <app-travelers-details *stepperItem="'forms.BOOKING_PATH.TRAVELERS_DETAILS.STEP' | translate"
                           (done)="resetScroll()">
    </app-travelers-details>
    <app-payment *stepperItem="'forms.BOOKING_PATH.PAYMENT.STEP' | translate"
                 (done)="resetScroll()">
    </app-payment>
  </app-stepper>
</div>
<ng-template #confirmationStep>
  <app-booking-path-confirmation></app-booking-path-confirmation>
</ng-template>
