import { ChangeDetectionStrategy, Component, HostListener, Injector } from '@angular/core';
import { MembershipDialogNotificationComponent } from '@pages/membership/components/membership-dialog/membership-dialog-notification/membership-dialog-notification.component';
import { DialogService } from '@kit/dialog/dialog.service';

@Component({
  selector: 'app-upgrade-referral-code',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeReferralCodeComponent {
  constructor(
    private dialogService: DialogService,
    private injector: Injector,
  ) {}

  @HostListener('click')
  onClick() {
    this.dialogService.open(MembershipDialogNotificationComponent, null, this.injector);
  }
}
