import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { WindowRef } from '@common/window-service/window.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LanguageService } from '@common/language/language.service';

@Component({
  selector: 'app-open-insurance-request',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenInsuranceRequestComponent {
  constructor(
    private readonly windowRef: WindowRef,
    private readonly route: ActivatedRoute,
    private readonly languageService: LanguageService,
  ) {}

  @HostListener('click')
  navigation(): void {
    this.windowRef.nativeWindow.open(this.getUrl());
  }

  private getUrl(): string {
    const language = this.languageService.getCurrentLanguage();
    const policyNumber = this.route.firstChild.snapshot.params.policyId;

    const params: URLSearchParams = new URLSearchParams();
    if (language) params.append('language', language.toLowerCase());
    if (policyNumber) params.append('policyNumber', policyNumber);

    return `${environment.api.allianzProtection}/policy?${params.toString()}`;
  }
}
