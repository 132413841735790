import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { AemCtaBlockComponent } from './cta-block.component';

@NgModule({
  declarations: [AemCtaBlockComponent],
  exports: [AemCtaBlockComponent],
  imports: [
    CommonModule,
    AemButtonModule,
  ]
})
export class AemCtaBlockModule { }
