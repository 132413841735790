import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { DynamicComponentProfileLinks } from '@common/model/dynamicComponentProfileLinks';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { UserService } from '@common/user/user.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiProfileService } from "@common/profile/api-profile.service";
import { LoungeVoucher } from '../lounge-vouchers/lounge-vouchers.interface';

@Component({
  selector: 'app-aem-profile-links',
  templateUrl: './aem-profile-links.component.html',
  styleUrls: ['./aem-profile-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemProfileLinksComponent extends AemFragmentComponent implements OnInit{
  public isAdminButtonVisible$: Observable<boolean>;
  public loungeVouchers$: Observable<LoungeVoucher[]> = this.apiProfile.getLoungeAccessVouchers();

  constructor(
    private readonly userService: UserService,
    private readonly apiProfile: ApiProfileService,
    @Inject(AEM_DATA) public override data: DynamicComponentProfileLinks,
  ) {
    super(data);
  }

  ngOnInit(): void {
    this.initAdminButtonVisibility();
  }

  private initAdminButtonVisibility(): void {
    this.isAdminButtonVisible$ = combineLatest([
      this.userService.adminScopeAvailable$,
      this.userService.superAdminScopeAvailable$,
    ]).pipe(
      map(([isAdmin, isSuperAdmin]: [boolean, boolean]) => isAdmin || isSuperAdmin),
    )
  }
}
