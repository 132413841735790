import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, forkJoin, switchMap, takeUntil, timer } from 'rxjs';
import { MINUTE } from '@kit/date/date.const';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@kit/dialog/dialog.service';

const TIMER_INTERVAL = 29 * MINUTE;

@Injectable({
  providedIn: 'root'
})
export class InsurancePolicyTimerService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private readonly translateService: TranslateService,
    private readonly dialogService: DialogService,
    ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public setTimer$(): Observable<string> {
    return timer(TIMER_INTERVAL).pipe(
      switchMap(() => this.openExpiredTimeDialog$()),
      takeUntil(this.destroy$),
    );
  };

  private openExpiredTimeDialog$(): Observable<any> {
    return forkJoin([
      this.translateService.get('pages.MY_INSURANCE.TITLES.TIMER_EXPIRED'),
      this.translateService.get('pages.MY_INSURANCE.DESCRIPTIONS.TIMER_EXPIRED'),
      this.translateService.get('pages.MY_INSURANCE.ACTIONS.ADD_A_POLICY'),
      this.translateService.get('pages.MY_INSURANCE.ACTIONS.BACK_TO_POLICIES'),
    ]).pipe(
      switchMap(([message, info, confirmTitle, cancelTitle]: [string,string, string, string]) =>
        this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData> {
          message: message,
          info: info,
          confirmTitle: confirmTitle,
          cancelTitle: cancelTitle,
          reverseButtonPosition: true,
        }).afterClosed$
      ),
      takeUntil(this.destroy$),
    );
  }

}
