import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FlightInfo } from '@common/flight/flight.interfaces';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { getAllFormErrors } from "@kit/utils/form";
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'app-flight-selector',
  templateUrl: './flight-selector.component.html',
  styleUrls: ['./flight-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightSelectorComponent {
  @Input() flights: FlightInfo[] = [];
  @Output() flight = new EventEmitter<FlightInfo[]>();
  @Output() goPreviousStep = new EventEmitter<void>();

  public isButtonDisable$ = new BehaviorSubject(true);
  public form = this.formBuilder.group({ flight: [null, [Validators.required]] });
  public tripId = this.route.firstChild.snapshot.params.tripId;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute
  ) {
    this.formChangesSubscription();
  }

  public goBack(): void {
    this.goPreviousStep.emit();
  }

  public onSave(): void {
    this.form.markAllAsTouched();

    if (getAllFormErrors(this.form)?.length) {
      this.analyticsService.validationError(this.form, this.constructor.name);
      return;
    }

    const flight: FlightInfo = this.form.getRawValue().flight;
    this.flight.emit([flight]);
  }

  private formChangesSubscription(): void {
    this.form.valueChanges.subscribe((flight: FlightInfo) => {
      if (flight) {
        this.isButtonDisable$.next(false);
      }
    });
  }
}
