import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { tuiPure } from '@taiga-ui/cdk';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],
})
export class SelectComponent<T> implements ControlValueAccessor, OnChanges {
  @Input() public placeholder = 'Select element';
  @Input() public viewKey: keyof T = 'name' as keyof T;
  @Input() public valueKey: keyof T = null as keyof T;
  @Input() public selectKey: keyof T = null as keyof T;
  @Input() public options: T[] = [];
  @Input() public clearButton: boolean;
  @Input() public theme: 'normal' | 'small' = 'normal';
  @Input() public readonly = false;

  @Output() public touched = new EventEmitter<boolean>();

  public expanded = false;
  public disabled = false;
  public selectedValue: unknown = null;
  public selectedOption: T;

  get selectedView(): any {
    return this.selectedOption && this.selectedOption[this.viewKey];
  }

  public onChange = (value: unknown) => {};
  public onTouched = () => {};

  constructor(private cdr: ChangeDetectorRef) { }

  public writeValue(value: unknown): void {
    this.selectedValue = value;
    this.selectedOption = this.findSelected(this.options, !this.valueKey && this.selectKey ? value?.[this.selectKey]: value);
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  public changeActiveState(active: boolean): void {
    if (!active) {
      this.expanded = active;
      this.touched.emit(true);
      this.onTouched();
      this.cdr.detectChanges();
    }
  }

  public select(option: T): void {
    if (this.readonly) {
      return;
    }

    this.selectedOption = option;
    this.selectedValue = this.valueKey ? this.selectedOption[this.valueKey] : this.selectedOption;

    this.onChange(this.selectedValue);
    this.changeActiveState(false);
  }

  public toggleOption() {
    this.expanded = !this.expanded;
  }

  public clear(event: Event): void {
    event.stopPropagation();

    this.writeValue(null);
    this.onChange(null);
    this.onTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.writeValue(this.selectedValue);
    }
  }

  @tuiPure
  protected findSelected(options: T[], value: unknown): T {
    return options?.find((option) => option[this.selectKey || this.valueKey] === value) || null;
  }
}
