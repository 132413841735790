<div class="input form-field"
     [class.form-field_focused]="focused"
     [class.form-field_disabled]="disabled">
  <app-icon class="input_icon"
            size="large"
            icon="lock"
            theme="lighten">
  </app-icon>
  <input class="input_field text-small"
         [placeholder]="placeholder"
         [formControl]="innerControl"
         [type]="showText ? 'text' : 'password'"
         [attr.autocomplete]="autocomplete"
         (focus)="onFocus()"
         (blur)="onBlur()">
  <app-icon class="input_icon input_toggle"
            size="large"
            (click)="toggle()"
            [icon]="showText ? 'eye-off' : 'eye'"
            theme="lighten">
  </app-icon>
</div>
