import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemContentAndVideoComponent } from './content-and-video.component'
import { AemVideoPlayerModule } from "@kit/aem/common/aem-video-player/aem-video-player.module";
import { AemEmbeddedPlayerModule } from "@kit/aem/common/aem-embedded-player/aem-embedded-player.module";

@NgModule({
  declarations: [AemContentAndVideoComponent],
  exports: [AemContentAndVideoComponent],
  imports: [
    CommonModule,
    AemButtonModule,
    SafeHtmlModule,
    AemVideoPlayerModule,
    AemEmbeddedPlayerModule
  ]
})
export class AemContentAndVideoModule { }
