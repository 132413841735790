import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemRouterModule } from '../../../../common/routing/aem-router.module';
import { AemButtonModule } from '../../../../kit/aem-button/aem-button.module';
import { AemLoungeVouchersComponent } from './lounge-vouchers.component';
import { ButtonModule } from '@kit/button/button.module';
import { AccordionModule } from '@kit/accordion/accordtion.module';
import { VoucherAccordionComponent } from './voucher-accordion/voucher-accordion.component';
import { DateModule } from '@kit/date/date.module';
import { PaginationModule } from '@kit/pagination/pagination.module';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';

@NgModule({
  declarations: [AemLoungeVouchersComponent, VoucherAccordionComponent],
  exports: [],
  imports: [
    CommonModule,
    AemRouterModule,
    AemButtonModule,
    ButtonModule,
    AccordionModule,
    DateModule,
    PaginationModule,
    TuiLetModule,
    TranslateModule,
    SafeHtmlModule
  ]
})
export class AemLoungeVouchersModule { }
