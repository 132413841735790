import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { BreadcrumbsModule } from '@kit/breadcrumbs/breadcrumbs.module';
import { DateModule } from '@kit/date/date.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { ThematicModule } from '@kit/thematic/thematic.module';
import { AemShareBlockModule } from '../share-block/share-block.module';
import { AemBlogHeroComponent } from './aem-blog-hero.component';

@NgModule({
  declarations: [AemBlogHeroComponent],
  exports: [AemBlogHeroComponent],
  imports: [
    CommonModule,
    BreadcrumbsModule,
    AemButtonModule,
    AemRouterModule,
    SafeHtmlModule,
    AemShareBlockModule,
    ThematicModule,
    DateModule,
    ImgAltDirectiveModule,
    TranslateModule,
  ]
})
export class AemBlogHeroModule { }
