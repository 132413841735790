import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicComponent } from './dynamic.component';
import { PlaceHolderMainComponent } from './placeholder/placeholder-main.component';
import { PlaceHolderBottomComponent } from './placeholder/placeholder-bottom.component';
import { PlaceHolderTopComponent } from './placeholder/placeholder-top.component';

@NgModule({
  declarations: [
    DynamicComponent,
    PlaceHolderMainComponent,
    PlaceHolderTopComponent,
    PlaceHolderBottomComponent,
  ],
  exports: [
    DynamicComponent,
    PlaceHolderMainComponent,
    PlaceHolderTopComponent,
    PlaceHolderBottomComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class DynamicModule { }
