import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { AemRouterModule } from 'src/app/common/routing/aem-router.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemSideBySideWithCtaComponent } from './side-by-side-with-cta.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { ButtonModule } from '@kit/button/button.module';

@NgModule({
  declarations: [AemSideBySideWithCtaComponent],
  exports: [AemSideBySideWithCtaComponent],
  imports: [
    CommonModule,
    AemButtonModule,
    AemRouterModule,
    SafeHtmlModule,
    ImgAltDirectiveModule,
    TuiLetModule,
    ButtonModule
  ]
})
export class AemSideBySideWithCtaModule { }
