import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationKeysSwitcherComponent } from "@kit/translation-keys-switcher/translation-keys-switcher.component";
import { ButtonModule } from "@kit/button/button.module";

@NgModule({
  declarations: [TranslationKeysSwitcherComponent],
  exports: [TranslationKeysSwitcherComponent],
  imports: [CommonModule, ButtonModule]
})
export class TranslationKeysSwitcherModule { }
