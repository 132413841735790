import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemCodeSnippetComponent } from './code-snippet.component';

@NgModule({
  declarations: [AemCodeSnippetComponent],
  exports: [AemCodeSnippetComponent],
  imports: [
    CommonModule,
    SafeHtmlModule,
  ]
})
export class AemCodeSnippetModule { }
