<app-trip-hero-block (goBack)="back()">
  <div *ngIf="data.component?.title || data.component?.text" class="hero-component">
    <h5 class="hero-component-title h-5 m-h2" *ngIf="data.component?.title">
      {{data.component.title}}
    </h5>
    <div class="t-large m-t-large" *ngIf="data.component?.text">
      {{data.component.text}}
    </div>
  </div>
</app-trip-hero-block>
