<header class="header">
  <div class="header__main">
    <div class="header__shell shell">
      <app-breadcrumbs *ngIf="data.hasBreadcrumbs"></app-breadcrumbs>

      <div class="hero">
        <div class="hero__group">
          <h1 class="hero__title">{{ data.title }}</h1>          
          <app-safe-html class="hero__text" [content]="data.text"></app-safe-html>
        </div>
      </div>
    </div>
  </div>
</header>
