import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';
import { AnalyticsService } from '../analytics/analytics.service';
import { EventDigitalData } from '../analytics/digital-data.interface';
import { DigitalDataService } from '../analytics/digital-data.service';
import { ServerValidationErrorResponse } from '@kit/server-errors/server-errors.interface';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(
    private analyticsService: AnalyticsService,
    private digitalDataService: DigitalDataService,
    @Inject(IS_BROWSER_PLATFORM) private isBrowser: boolean,
  ) {}

  public handleError(error: Error & ServerValidationErrorResponse): void {
    if (this.isBrowser && !error?.handled) {
      const event: EventDigitalData = {
        category: 'error',
        action: this.digitalDataService.getPageName(),
        label: error?.message || error?.fault?.errors[0]?.description,
        value: 1
      };
      this.analyticsService.triggerAction(event);
    }

    console.error(error);
  }
}
