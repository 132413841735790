import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from '@pages/trip/trip.interfaces';
import { DestinationResponse } from './destination.interface';
import { EmergencyNumber } from './emengency-number.interface';

@Injectable({
  providedIn: 'root'
})
export abstract class ApiInformationService {
  abstract getCountries(withEmergencyNumbers?: boolean): Observable<Country[]>;
  abstract getLocalCountries(marketScope: string): Observable<Country[]>;
  abstract getDestination(destinationCountryCode: string, departureCountryCode: string): Observable<DestinationResponse>;
  abstract getEmergencyNumber(countryCode: string): Observable<EmergencyNumber>;
}
