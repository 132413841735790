import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, EMPTY, Observable } from 'rxjs';
import { RootPaths } from 'src/app/app.const';
import { TripDetails } from './trip.interfaces';
import { TripService } from './trip.service';

@Injectable({
  providedIn: 'root',
})
export class TripResolver  {
  constructor(
    private tripService: TripService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<TripDetails> {
    return this.tripService.getTripById(route.paramMap.get('tripId')).pipe(
      catchError((err) => {
        this.router.navigate([RootPaths.NotFound], { skipLocationChange: true });

        console.error(err);
        return EMPTY;
      })
    );
  }
}
