import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { UserService } from "@common/user/user.service";
import { TradedoublerService } from "@common/tradedoubler/tradedoubler.service";

export const portalLinks: Record<string, string> = {
  nl: 'https://www.allianzdirect.nl/reisverzekering/',
  de: 'https://www.allianzdirect.de/reiseversicherung/',
  fr: 'https://espace-client.allianz.fr/',
  it: 'https://geolocator.allianz.it/ricerca/agenzie/',
  es: 'https://www.allianz-assistance.es/TG/seguro-viaje-v.html',

}

@Component({
  selector: 'app-buy-travel-insurance-action',
  templateUrl: './buy-travel-insurance-action.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemBuyTravelInsuranceActionComponent implements OnInit {
  public link: string;

  private userService = inject(UserService);
  private tradedoublerService = inject(TradedoublerService);

  public ngOnInit(): void {
    this.initLink();
  }

  private initLink(): void {
    const portalLink = portalLinks[this.userService.location?.toLowerCase()];
    if (!portalLink) {
      this.link = '#';

      return;
    }

    const tradeboublerParams = this.tradedoublerService.getParamsString();
    this.link = tradeboublerParams
      ? `${portalLink}?${this.tradedoublerService.getParamsString()}`
      : portalLink;
  }
}
