import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, of, retry } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '@common/language/language.service';
import { WindowRef } from '@common/window-service/window.service';
import { UserService } from '@common/user/user.service';

const token = {
  "aesKey": "!UtEsnG^C8^x9dVHfsuB4iDZBYyJ&QZk",
  "userId": 2001593,
  "accessToken": "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGVuYyNyc2Etb2FlcCIsImVuYyI6IkExMjhDQkMtSFMyNTYiLCJraWQiOiIzNDUyMzkyNUZEMEI5QzY3MDEzQjBBMEFGRjNGNjg3RkQ2NkY0NEZBIiwidHlwIjoiSldUIn0.HqAsIW_yUNUnwW1qTFLKyPyrQr2u4qsn028VQabDSh5YGHmMG4IKfN1lmxZIFO_C4D5KKm-td8pMkwUsLy_eAogTknel_BpkNlgG0dwwwFIt8Bcc-MAS_T0Ua9QEx7l_eYdJfnG_E9NHS0vVa0VCBmk-Y1bA7uVcSSfEnJBA245aOXT_ynemjiXsGd22OotwNjU1XYG8rDkJSC-5L3P2INyDv6S_BHseBrleodLQ98y1KNVmn0yQZZJuJ_Z90wic-6WvyL4HRv_2vKcaDHviiYVt2EkK5khCyCckxZmshjz8XMtGAy3CboPLUnugZ4P1Qq2OX2_R8j0EKBqKULo9aA.z5cN1Vu42isLTCheNwa51Q.vbNywXPTjoR0WPjm1LRC0pdRUm5YP-YHc4OY_hR2SjtGVAsA1824TGZsGrKHt_R0NlDZLJnRRGRmKwBFB3QCfkdTmFZHmUPG4oyqAB2V3QKBoEaQiLY6msJnGPb8t3ma0OVUcdRiAMEh1gPLEUYiSIx57tE1n8Q0mXR9KoB1mHCyPD9k-qBuDlFePm6rmJiF1VgNraiMSYb2JZ7zYPRoxQPkGNKhaK0E4Nwu9imnDMiYtRyTMvYbB23-ZE0AsmP3sIYc1AIZkgFkZ7FCabSt12_ySpD_Bk1hK3vbY1kKflhECL1PQrMFuK5FuaYXao5XvakDCXTDRCZF4W3WlLyTgkxCsXHcUf8sG_AYto3lpHeL8u1og5CWnf0mhCOWpxTi1SD38E-qXnx_wbz_UMo-VAMiNWf9xbt0P23wkBdybhG8LCt4zEsdgo0MOHQU0lTmud3oung0i30DxGLSaJadJEw6DCg1sb-_TZK6ByyJjju9BWdlxQYMIQoyQ4gtREZx.tNfuh32iMwoKrhJD_y1FVg",
  "refreshToken": "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGVuYyNyc2Etb2FlcCIsImVuYyI6IkExMjhDQkMtSFMyNTYiLCJraWQiOiIzNDUyMzkyNUZEMEI5QzY3MDEzQjBBMEFGRjNGNjg3RkQ2NkY0NEZBIiwidHlwIjoiSldUIn0.XzX8oQ4SbWURIpsvZmt19mJxEvZ5PTKHWUMegHpmA-42ehcqT53wPO1VTdQUUGoppVzFOMIXVgHvLuKtGDNTUWZkryHiJzV8yqrkMvpHxNzmc8J9QPtTV9yZLbfoIJ5oalPQjr9rjYxwDmoJuUeeZzEOWT6Xrc0xFX5mh1DRg-gLQqzkb2_tB23z2OV3kAhfdBITbzaLV-QZiG_oTchdikGcjdXpYNbIsXld5Zgi6It4MDPa3D5QnPwWvMkNJI_WjZB7auS5gcZ1WOAW2q3Os2XTKbKfA08ZbdUNno6ePludlfg3t4hbp_wiIW_sWq12ceQhux7zH2OIHeiQ7Uq-kw.oeGdkVBMIOw9XdrVrljraA._8F1I2w1c1SYoiEIRS4-vadDHLT_YGSeOxuUf1qEc3hw4fxwldmbWNQvhe2J975xQPBK4wSl9nqBx81fXSnL31LADEZ0Akcz1CJZY89ChE8B1uJKpd-G8Bhgs9qe5lGqKmGv8BWAZEarFMKph32brCwmDCLUMXYSlTZrwVWhdMJS1m5nwDXjy4gXZh74jClrcE5wmHmR2Q2ytpuf9lFk4BreshbaN61P4gmyRanSQYpD53EXFNpoKBsT7CCsP4MLDUOlh0E0zifMTbkXwhY-iz76PMlxvT_rgeOuGTDYOoVVC17Cqt0n8OV262_9o4vCetN8G2kqN8XOTp63KFKEBauu2gXd6luVcl08tA0K1UX83g8kobhneIZTVI3Z0L-zmDsZxB22XlBW65A8HfOZRvkb6rJfESYiG5IBil4ZzN3xHvbJKXn1e6lPxRVJzUnCFAxGFwG1lzq48GoTINZerSO14az27NMUk7p_MBig4pc.QM2AyVZQ6qNA8NMBp_y7Tw",
  "expiresIn": "2024-08-13T11:10:53+00:00"
}
const partnerKey: string = 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGVuYyNyc2Etb2FlcCIsImVuYyI6IkExMjhDQkMtSFMyNTYiLCJraWQiOiIzNDUyMzkyNUZEMEI5QzY3MDEzQjBBMEFGRjNGNjg3RkQ2NkY0NEZBIiwidHlwIjoiSldUIn0.tWLxKTnWDrz_JjRSN1U2WGKaxSW83TQrYEgKFmUqFLT-5fZstZ7UykoJf-EYnZFTqBsgsTg8AiFWO01iBIbk7FuL8iuTdluCD31eJl6k9l0l8iqShFoV06HP9GAQ68m-12cc1tAdv5vqKrlqSxdUy27I_QWoLBjOehwBZn6FBvbZhm_Hfljj31d2U_QuauNNm_09h0vCOW5QSTx8CrIcQVIcI2hciKhxbR_g9wR7pLi96Ol9ezIuZpIvW2QdrPVMpgA5lCDV6XO6hYZdoarrOyRwpMThbkQI8Mvh5xEp9m9mxQTckuaJh_B4F92BsxZy1XP97VciDmYhJ_eCXEhqVA.co8J8Pjh9me8Z_i2GKhUqg.hMggzan-FWVznM8DF89H18n7cBGheQVTok9amN8rScP1-5oXgLBIzKEdvS6Ye87kVAAZMZQcOszBu8pmRMZunX8r35QE8YAAJUebTfuH-T3ESKSdHLqKcvmBuFcgi-gfQPkYfRf_0QtPJPKcCu_h_CYTzFfQzuQxYedM2aoB3tiT6LDZfs4kGpQWmAJRsm8Bac4qyLZLW67adXRy3FX3JpNaepmk-e5zFsGbLONnwGTz6PTgKyW4ZPnFSPjVT-uoH6n_xQVgayQYiYx2HvsFOR4U3nRHViSb7BT_Lsvbeor_WWvJdco4IXOf6NJT11d4A2UeceRsG5XyRAmzE0cE8kfX81cszfcWyEIsv9-sskltTeHXl5PBw_c0stYdlaBw9DR-71FLZrCK54-ufglUGru6sUrBq8WTVKl1HUdJlUVLHMXJmG8MC9tYcgvWKWCLITTF9d6tXZkrxgp4b30-Znnu9vxCIx5uLHRHvtpeyjoRlJfGhf7BxEyZxp2gHEKz9DXMtRWFnRDoaSQ1Zo8m_3uZqRnMQeIjjVcHkmp6Wnk.lqpIipn-Ii9QuYcgmsVDqw'; // NOSONAR

@Injectable({
  providedIn: 'root'
})
export class LumiWidgetService {
  private apiPath: string = environment.lumi.mainPath;

  constructor(
    private readonly http: HttpClient,
    private readonly languageService: LanguageService,
    private readonly windowRef: WindowRef,
    private readonly userService: UserService,
  ) { }

  public getLumiToken$(): any {
    const headers = {
      'Authorization': partnerKey,
      'langId': '1', // NOSONAR
    };
    const userEmail: string = this.userService.userEmail;

    return this.http.post(
      `${this.apiPath}/allyz/mds/api/partner/v1.0/memberauthentication`,
      { login: userEmail }, { headers: headers })
      .pipe(
        retry(2),
        catchError(() => of(token)),
      )
  }

  public initWidget(name: string, wrapper: string, token: any): void {
    const languageCode: string =  this.languageService.languageCode;
    const countryCode: string = this.languageService.countryCode;
    const config = {
      elementID: wrapper,
      userAccessToken: token,
      partnerToken: partnerKey,
      lumiWidgetConfig: {
        userAccessServices: {
          SYMPTOM_CHECKER: true,
          TELECONSULTATION: true,
          MEDICATION_DELIVERY: true,
          APPOINTMENT_BOOKING: true,
          DOCTOR_CHAT: true,
          SMO: true,
        },
        screenRedirect: name,
        screenParams: {},
        partnerCountry: countryCode.toLowerCase(),
        language: `${languageCode.toLowerCase()}-${languageCode.toUpperCase()}`,
        partnerPlatform: 'Android',
        encKey: token.aesKey,
        isEmbeddedWidget: true,
        drChatUserDataNickName: "Allyz User",
        drChatHideSupportButton: true,
        drChatTitle: "Dr. Chat",
      }
    }

    // @ts-ignore
    this.windowRef.nativeWindow.window.lumi.initWidget(config);
  }
}
