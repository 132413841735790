import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject } from '@angular/core';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { ServiceCards } from '@common/model/serviceCards';
import { UserService } from '@common/user/user.service';
import { AemBaseBlockComponent } from '../aem-base-block/aem-base-block';
import { ServiceCard } from "@common/model/serviceCard";
import { TradedoublerService } from '@common/tradedoubler/tradedoubler.service';
import { portalLinks } from '@kit/aem-button/buy-travel-insurance-action/buy-travel-insurance-action';

@Component({
  selector: 'app-aem-service-cards',
  templateUrl: './aem-service-cards.component.html',
  styleUrls: ['./aem-service-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemServiceCardsComponent extends AemBaseBlockComponent implements AfterViewInit {
  public userSubscription$ = this.userService.userSubscription$;

  private link: string;
  private selector = '#insurance-travel-buy-link';

  constructor(
    @Inject(AEM_DATA) public override data: ServiceCards,
    private userService: UserService,
    private elementRef: ElementRef,
    private tradedoublerService: TradedoublerService,
  ) {
    super(data);
  }

  public ngAfterViewInit(): void {
    this.initLink();
    this.updateLinksForBuyInsurance();
  }

  public getHeaderType(item: ServiceCard): string {
    const header = item.header;

    if (header?.member && header?.plusTravel) return 'memberPlusHeader';
    if (header?.plusTravel && header?.plusCyber) return 'plusCyberHeader';
    if (header?.plusTravel) return 'plusHeader';

    return 'memberHeader';
  }

  private initLink(): void {
    const portalLink = portalLinks[this.userService.location?.toLowerCase()];

    this.link = portalLink ? `${portalLink}?${this.tradedoublerService.getParamsString()}` : '#';
  }

  private updateLinksForBuyInsurance(): void {
    this.elementRef.nativeElement.querySelectorAll(this.selector).forEach(
      (element: HTMLLinkElement) => element.href = this.link
    );
  }
}
