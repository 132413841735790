import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { ArticleModule } from '@kit/article/article.module';
import { ButtonModule } from '@kit/button/button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { SelectModule } from '@kit/select/select.module';
import { ThematicModule } from '@kit/thematic/thematic.module';
import { AemArticleListComponent } from './articles-list.component';
import { PaginationModule } from "@kit/pagination/pagination.module";

@NgModule({
  declarations: [AemArticleListComponent],
  exports: [AemArticleListComponent],
  imports: [
    CommonModule,
    SafeHtmlModule,
    AemRouterModule,
    SelectModule,
    AemButtonModule,
    ButtonModule,
    ReactiveFormsModule,
    ArticleModule,
    ThematicModule,
    TranslateModule,
    PaginationModule,
  ]
})
export class AemArticleListModule {}
