import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, Observable } from 'rxjs';
import { DynamicContent } from '@pages/dynamic/dynamic-render/dynamic-render.interface';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { DynamicRenderService } from '@pages/dynamic/dynamic-render/dynamic-render.service';
import { UserService } from '@common/user/user.service';
import { map } from 'rxjs/operators';
import { UserProfile, UserType } from '@common/profile/profile.interfaces';
import { UserTypeContentWrapper } from '@common/model/userTypeContentWrapper';
import { AuthService } from '@common/auth/auth.service';
import { Block } from '@common/model/block';

@Component({
  selector: 'app-user-type-content-wrapper',
  templateUrl: './user-type-content-wrapper.component.html',
  styleUrls: ['./user-type-content-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserTypeContentWrapperComponent implements OnInit {
  public dynamicContent$: Observable<DynamicContent[]>;

  constructor(
    @Inject(AEM_DATA) public aemData: UserTypeContentWrapper,
    private dynamicRenderService: DynamicRenderService,
    private userService: UserService,
    private readonly authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.initDynamicContent();
  }

  private initDynamicContent(): void {
    if (!this.authService.authorized) {
      this.dynamicContent$ = this.userService.userSubscription$.pipe(
        distinctUntilChanged(),
        map((userSubscription: UserType) =>
          this.dynamicRenderService.prepareContent(this.aemData.anonymousContent, userSubscription)
        ),
      )

      return;
    }

    this.dynamicContent$ = combineLatest([
      this.userService.reloadUserInfo$(),
      this.userService.userSubscription$,
    ]).pipe(
      map(([userData, userSubscription]: [UserProfile, UserType]) => {
        const isUserDirect = userData.owner.includes('allianzdirect');
        const contentFragments: Block[] = isUserDirect ? this.aemData.directUsersContent : this.aemData.defaultContent;

        return this.dynamicRenderService.prepareContent(contentFragments, userSubscription);
      })
    );
  }
}
