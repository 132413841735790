import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appCurrency',
})
export class CurrencySymbolPipe implements PipeTransform {
  constructor(
    private readonly currencyPipe: CurrencyPipe,
  ) {}

  transform(value: string | number, currency: string): unknown {
    const symbol = this.currencyPipe
      .transform(0, currency, 'symbol', '1.0-0')
      .replace('0', '');

    return `${value} ${symbol}`;
  }
}
