import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, EMPTY, map, Observable, of } from 'rxjs';
import { RootPaths } from 'src/app/app.const';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { BlogArticleScreen } from '../model/blogArticleScreen';
import { FAQArticleScreen } from '../model/fAQArticleScreen';
import { RouterConfigurationPage } from '../model/routerConfigurationPage';
import { RouterConfigurationRedirect } from '../model/routerConfigurationRedirect';
import { Screen } from '../model/screen';

type AllScreen = Screen & BlogArticleScreen & FAQArticleScreen;

@Injectable({
  providedIn: 'root',
})
export class RouterDataResolver  {
  static dataUrls = new Map<string, RouterConfigurationPage>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private bootstrapService: BootstrapService,
  ) {}

  static setRouterDataPath(data: (RouterConfigurationRedirect | RouterConfigurationPage)[]) {
    RouterDataResolver.dataUrls = data.reduce((acc, data) => {
      acc.set(data.path.replace(/^\//, ''), data);
      return acc;
    }, new Map());
  }

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<AllScreen> {
    const router = RouterDataResolver.dataUrls.get(route.routeConfig.path);
    if (!router.dataPath) {
      return of(null);
    }

    return this.http.get<AllScreen>(router.dataPath).pipe(
      map(data => {
        const template = this.bootstrapService.templates[router.template];
        if (!template) {
          return data;
        }

        return {...data, template };
      }),
      catchError((err: Error) => {
        this.router.navigate([RootPaths.NotFound], { skipLocationChange: true });
        console.error(err);
        return EMPTY;
      })
    );
  }
}
