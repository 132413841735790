import { ChangeDetectionStrategy, Component, HostListener, Injector } from '@angular/core';
import { AirDoctorDialogComponent } from '@kit/aem-air-doctor-hero/air-doctor-dialog/air-doctor-dialog.component';
import { AirDoctorDialogData } from '@kit/aem-air-doctor-hero/air-doctor-dialog/air-doctor-dialog.interface';
import { AirDoctorService } from '@kit/aem-air-doctor-hero/air-doctor.service';
import { DialogService } from '@kit/dialog/dialog.service';


@Component({
  selector: 'app-air-doctor',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AirDoctorComponent {
  constructor(
    private readonly dialogService: DialogService,
    private readonly airDoctorService: AirDoctorService,
    private readonly injector: Injector,
  ) {
  }

  @HostListener('click')
  openDialog(): void {
    this.airDoctorService.resolveLinks$().subscribe(link => {
      const data: AirDoctorDialogData = {
        link: link,
        service: 'Doctor Chat'
      };

      this.dialogService.open(AirDoctorDialogComponent, data, this.injector);
    });
  }
}
