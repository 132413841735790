import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { DynamicContent } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.interface';
import { DynamicRenderService } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.service';
import { Hero } from '@common/model/hero';
import { TripHero } from '@common/model/tripHero';
import { AemFragmentComponent } from '../aem-base-block/aem-fragment';

@Component({
  selector: 'app-hero-block',
  templateUrl: './aem-hero-block.component.html',
  styleUrls: ['./aem-hero-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemHeroBlockComponent extends AemFragmentComponent implements OnInit {
  @Input() showExtra = true;

  public extra: DynamicContent;
  public videoComponent: DynamicContent;

  constructor(
    @Inject(AEM_DATA) public override data: Hero,
    private dynamicRenderService: DynamicRenderService,
  ) {
    super(data);
  }

  ngOnInit(): void {
    this.extra = this.dynamicRenderService.getComponent((this.data as TripHero)?.component);
    this.videoComponent = this.dynamicRenderService.getComponent(this.data?.video);
  }
}
