import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Inject, Injectable, Optional } from '@angular/core';
import { IS_SERVER_PLATFORM } from "../utils/ssr.utils";

@Injectable()
export class NotFoundService {
  constructor(
    @Optional() @Inject(RESPONSE) private response: any,
    @Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean,
  ) {}

  public setStatus(): void {
    if (this.isServer && this.response) {
      this.response.statusCode = 404;
      this.response.statusMessage = 'Not Found';
    }
  }
}
