<div class="manage_container">
  <div class="manage_form" [formGroup]="userInfoForm">
    <h2 class="manage_form_title h4 m-h2">{{ 'pages.MANAGE_MY_ACCOUNT.TITLES.MANAGE_MY_ACCOUNT' | translate }}</h2>
    <section class="manage_form_section">
      <h3
        class="manage_form_header t-large weight-medium">{{ 'pages.MANAGE_MY_ACCOUNT.TITLES.ACCOUNT_DETAILS' | translate }}</h3>
      <div class="half-fields">
        <app-label [label]="'pages.MANAGE_MY_ACCOUNT.LABELS.FIRST_NAME' | translate">
          <app-input
            id="account-first-name"
            autocomplete="off"
            inputName="first-name"
            [placeholder]="'pages.MANAGE_MY_ACCOUNT.PLACEHOLDERS.FIRST_NAME' | translate"
            [maxLength]="50" formControlName="firstName">
          </app-input>
          <app-field-error controlName="firstName"></app-field-error>
        </app-label>
        <app-label [label]="'pages.MANAGE_MY_ACCOUNT.LABELS.LAST_NAME' | translate">
          <app-input
            id="account-last-name"
            autocomplete="off"
            inputName="second-name"
            [placeholder]="'pages.MANAGE_MY_ACCOUNT.PLACEHOLDERS.LAST_NAME' | translate"
            [maxLength]="50" formControlName="lastName">
          </app-input>
          <app-field-error controlName="lastName"></app-field-error>
        </app-label>
      </div>
      <app-label [label]="'pages.MANAGE_MY_ACCOUNT.LABELS.MOBILE_NUMBER' | translate">
        <app-phone
          id="account-mobile-number"
          autocomplete="off"
          [placeholder]="'pages.MANAGE_MY_ACCOUNT.PLACEHOLDERS.MOBILE_NUMBER' | translate"
          [disclaimer]="'controls.PHONE.DISCLAIMER' | translate"
          formControlName="phone">
        </app-phone>
        <app-field-error controlName="phone"></app-field-error>
      </app-label>
    </section>
    <section class="manage_form_section">
      <h3
        class="manage_form_header t-large weight-medium">{{ 'pages.MANAGE_MY_ACCOUNT.TITLES.LOGIN_INFO' | translate }}</h3>
      <app-label [label]="'pages.MANAGE_MY_ACCOUNT.LABELS.EMAIL' | translate">
        <app-input
          id="account-email"
          [placeholder]="'pages.MANAGE_MY_ACCOUNT.PLACEHOLDERS.EMAIL' | translate"
          [clearButton]="true"
          autocomplete="off"
          formControlName="email"
          inputName="email"
          [appTooltip]="userInfoForm.controls?.email?.value"
          [tooltipDisabled]="!userInfoForm.controls?.email?.disabled"
        ></app-input>
        <app-field-error controlName="email"></app-field-error>
      </app-label>
    </section>

    <section class="manage_form_section"
             [formGroup]="passwordForm">
      <h3
        class="manage_form_header t-large weight-medium">{{ 'pages.MANAGE_MY_ACCOUNT.TITLES.CHANGE_PASSWORD' | translate }}</h3>
      <app-label [label]="'pages.MANAGE_MY_ACCOUNT.LABELS.PASSWORD' | translate">
        <app-password id="account-password"
                      formControlName="password"
                      autocomplete="off"
                      [placeholder]="'pages.MANAGE_MY_ACCOUNT.PLACEHOLDERS.PASSWORD' | translate">
        </app-password>
        <app-field-error controlName="password"></app-field-error>
        <app-field-error [control]="passwordForm"></app-field-error>
      </app-label>

      <ng-container [formGroup]="newPasswordForm">
        <app-label [label]="'pages.MANAGE_MY_ACCOUNT.LABELS.NEW_PASSWORD' | translate">
          <app-password id="account-new-password"
                        formControlName="newPassword"
                        autocomplete="off"
                        [placeholder]="'pages.MANAGE_MY_ACCOUNT.PLACEHOLDERS.NEW_PASSWORD' | translate">
          </app-password>
          <app-field-error controlName="newPassword"></app-field-error>
        </app-label>

        <app-label [label]="'pages.MANAGE_MY_ACCOUNT.LABELS.REPEAT_PASSWORD' | translate">
          <app-password id="account-repeat-password"
                        formControlName="repeatPassword"
                        autocomplete="off"
                        [placeholder]="'pages.MANAGE_MY_ACCOUNT.PLACEHOLDERS.REPEAT_PASSWORD' | translate">
          </app-password>
          <app-field-error controlName="repeatPassword"></app-field-error>
        </app-label>
        <app-field-error [control]="newPasswordForm"></app-field-error>
        <app-password-security controlName="newPassword"></app-password-security>
      </ng-container>
    </section>
    <app-button id="action-account-save-information"
                type="full-width"
                [disabled]="disableButton | async"
                (click)="saveChanges()">
      {{ 'pages.MANAGE_MY_ACCOUNT.ACTIONS.SAVE_INFORMATION' | translate }}
    </app-button>
  </div>
</div>
