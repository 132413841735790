import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, forkJoin, map, mapTo, Observable, switchMap, tap } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from 'src/app/kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from 'src/app/kit/dialog/dialog.service';
import { AppStorage, IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';
import { DELETED_ACCOUNT_EMAIL_KEY } from '@pages/account/components/aem-my-account/my-account.component';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { AuthActions } from './auth.const';
import { AuthService } from './auth.service';
import { RegistrationService } from "@common/registration/registration.service";
import { ApiNotificationService } from '@common/notification/api-notification.service';

const ACTION_ROUTE_KEY = 'action';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {
  constructor(
    private readonly auth: AuthService,
    private readonly registrationService: RegistrationService,
    private readonly router: Router,
    private readonly storage: AppStorage,
    private readonly bootstrap: BootstrapService,
    private readonly dialog: DialogService,
    private readonly translateService: TranslateService,
    private readonly apiNotificationService: ApiNotificationService,
    @Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (this.isServer) {
      return true;
    }

    this.tryToDeleteEmail();

    if (route.queryParamMap.has(ACTION_ROUTE_KEY)) {
      switch (route.queryParamMap.get(ACTION_ROUTE_KEY)) {
        case AuthActions.activate: {
          return this.activate(route);
        }

        case AuthActions.registration: {
          return this.router.createUrlTree([this.bootstrap.link.register]);
        }

        case AuthActions.login: {
          return this.login();
        }

        case AuthActions.notificationSettings: {
          return this.router.createUrlTree([this.bootstrap.link.accountNotificationSettings]);
        }

        case AuthActions.acceptMarketing: {
          return this.acceptMarketing(route);
        }

        default:
          return true;
      }
    }

    return this.auth.loginResult$.pipe(
      map(() => true)
    );
  }

  private activate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const { action, ...activationParams } = route.queryParams;

    return this.registrationService.activateUserAccount(activationParams).pipe(
      mapTo(true),
      tap(() => this.login()),
      catchError(() => this.dialog.open(ConfirmationDialogComponent, <ConfirmDialogData>{
        message: this.translateService.instant('errors.CEAZ006')
      }).afterClosed$.pipe(
        map(() => this.router.createUrlTree(['/'])),
      )),
    );
  }

  private login(): boolean {
    this.auth.authorize();

    return true;
  }

  private acceptMarketing(route: ActivatedRouteSnapshot): Observable<boolean> {
    const message$ = this.translateService.get('global.ACCEPTED_MARKETING_DIALOG.TITLE');
    const info$ = this.translateService.get('global.ACCEPTED_MARKETING_DIALOG.MESSAGE');
    const confirmTitle$ = this.translateService.get('global.ACCEPTED_MARKETING_DIALOG.CONFIRM');

    return forkJoin([message$, info$, confirmTitle$]).pipe(
      tap(([message, info, confirmTitle]) => {
        this.dialog.open(ConfirmationDialogComponent, <ConfirmDialogData>{
          message: message,
          info: info,
          confirmTitle: confirmTitle,
        });
      }),
      switchMap(() => this.apiNotificationService.acceptDoubleOptIn(route.queryParamMap.get('id'))),
      mapTo(true),
    );
  }

  private tryToDeleteEmail(): void {
    if (this.router.getCurrentNavigation().extras.state?.isTermsAndConditions) {
      return;
    }

    const deletedEmail = this.storage.getItem(DELETED_ACCOUNT_EMAIL_KEY);

    if (deletedEmail) {
      this.auth.notifyAboutSuccessDeletion(deletedEmail);
      this.storage.removeItem(DELETED_ACCOUNT_EMAIL_KEY);
    }
  }
}
