<section>
  <div class="section__shell shell">
    <div class="cards">
      <div *ngFor="let item of data.items"
           [class.unset-bg-color]="item.alternativeCardDesign"
           class="cards_item">
        <ng-container [ngTemplateOutlet]="item.alternativeCardDesign ? alternativeCard : card"
                      [ngTemplateOutletContext]="{ item: item }">
        </ng-container>
      </div>
    </div>
  </div>
</section>

<ng-template let-item="item" #card>
  <img *ngIf="item.image"
       [src]="item.image.imagePath"
       [appImgAlt]="item.image.altText"
       [appImgAltData]="item"
       class="cards_image">
  <div class="cards_header">
    <app-icon
      *ngIf="item.icon"
      class="cards__icon"
      [icon]="item.icon"
      size="inherit"
      theme="inherit"
      [style.color]="item.iconColor || null"
    ></app-icon>

    <h5 class="zero-margin h5 m-h2">{{ item.title }}</h5>
  </div>
  <app-safe-html *ngIf="item.text" class="t-normal" [content]="item.text"></app-safe-html>

  <app-safe-html *ngIf="item.bottomText" class="cards_item_text-amplify t-large" [content]="item.bottomText"></app-safe-html>

  <div class="cards_item_actions">
    <app-aem-button
      *ngFor="let cta of item.ctas" [cta]="cta"
      size="m"
      type="full-width"
    ></app-aem-button>
  </div>
</ng-template>

<ng-template let-item="item" #alternativeCard>
  <img *ngIf="item.image"
       [src]="item.image.imagePath"
       [appImgAlt]="item.image.altText"
       [appImgAltData]="item"
       class="cards_image">
  <div class="cards_header">
    <h5 class="zero-margin h5 m-h2 text-align-center">{{ item.title }}</h5>
  </div>
  <app-safe-html *ngIf="item.text" class="t-normal text-align-center" [content]="item.text"></app-safe-html>
  <div class="cards_item_mobile-links">
    <a *ngIf="item.appleApplicationLink"
       [href]="item.appleApplicationLink"
       target="_blank"
       id="apple-app-link">
      <img [src]="appleStoreImagePath">
    </a>
    <a *ngIf="item.androidApplicationLink"
       [href]="item.androidApplicationLink"
       target="_blank"
       id="google-app-link">
      <img [src]="googlePlayImagePath">
    </a>
  </div>
  <div *ngIf="item.ctas && item.ctas.length" class="cards_item_regular-links">
    <app-aem-button
      *ngFor="let cta of item.ctas" [cta]="cta"
      size="m"
      type="normal-width"
    ></app-aem-button>
  </div>
</ng-template>
