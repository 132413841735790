import { Component, Inject } from '@angular/core';
import { MembershipPromotions } from '@common/model/membershipPromotions';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-aem-membership-promotions',
  templateUrl: './aem-membership-promotions.component.html',
  styleUrls: ['./aem-membership-promotions.component.scss']
})
export class AemMembershipPromotionsComponent extends AemBaseBlockComponent {
  constructor(
    @Inject(AEM_DATA) public override data: MembershipPromotions,
  ) {
    super(data);
  }
}
