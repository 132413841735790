import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ApiProfileService } from '@common/profile/api-profile.service';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { Observable, concatMap, switchMap, takeUntil, catchError, throwError, from, tap } from 'rxjs';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@common/auth/auth.service';
import { Footer } from '@common/model/footer';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppStorage } from '@kit/utils/ssr.utils';
import { DELETED_ACCOUNT_EMAIL_KEY } from '../aem-my-account/my-account.component';
import { UserService } from "@common/user/user.service";
import { getCountryCode } from '@common/language/language.const';
import { DialogService } from '@kit/dialog/dialog.service';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { NotificationService } from '../notification/notification.service';
import { MarketingPartner, UserProfile } from '@common/profile/profile.interfaces';
import { LanguageService } from '@common/language/language.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class TermsAndConditionsComponent implements OnInit, AfterViewChecked, OnDestroy {
  public currentStep = 0;
  public data$: Observable<Footer> = this.bootstrapService.footer$;
  public year = new Date().getFullYear().toString();
  public consentsForm: UntypedFormGroup;
  public isLoaded = false;
  public isMarketingConsentGlobal: boolean;
  private homeRoute = this.languageService.isVisaEnUrl() ? '/en-fr' : '/';

  constructor(
    private readonly apiProfileService: ApiProfileService,
    private readonly destroy$: TuiDestroyService,
    private readonly analyticsService: AnalyticsService,
    private readonly router: Router,
    private readonly bootstrapService: BootstrapService,
    private readonly authService: AuthService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly translateService: TranslateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly storage: AppStorage,
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    private readonly notificationService: NotificationService,
    private readonly languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public ngOnInit(): void {
    this.checkUserTermsAndConditions();
    this.initMarketingConsentType();
    this.initForm();
    this.updateHeader();
    this.updateFooter();
  }

  public ngAfterViewChecked(): void {
    this.configMarketingConsentPopInLinks();
  }

  public ngOnDestroy(): void {
    if (this.userService.location === 'DE' || this.userService.location === 'AT') {
      this.listenDoubleOptInPopIn();

      return;
    }

    window.location.reload();
  }

  public acceptTermsAndConditions(): void {
    this.consentsForm.markAllAsTouched();

    if (this.consentsForm.invalid) {
      return;
    }

    this.apiProfileService.acceptTermsAndConditions().pipe(
      switchMap(() => from(this.authService.refreshToken())),
      switchMap(() => this.apiProfileService.getUserProfile()),
      tap((profile: UserProfile) => this.userService.userData$.next(profile)),
      concatMap(() => {
        const marketingConsentsPayload = this.notificationService.getMarketingSettings(
          this.consentsForm,
          this.isMarketingConsentGlobal,
          false,
        );

        return this.notificationService.saveNotificationSettings$(marketingConsentsPayload, MarketingPartner.ALLYZ)
      }),
      catchError((err) => {
        this.authService.safeLogout();
        return throwError(err);
      }),
      takeUntil(this.destroy$),
    ).subscribe(() => this.router.navigate([this.homeRoute]));
  }

  public deleteAccount(): void {
    const message = this.translateService.instant('global.NOTIFICATION_DIALOG.MESSAGES.YOUR_ACCOUNT');
    this.apiProfileService.deleteUserProfile().pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.storage.setItem(DELETED_ACCOUNT_EMAIL_KEY, message);

      this.analyticsService.triggerAction({
        category: 'member_account',
        action: 'accountDeleted',
        label: '',
        value: 1,
      });

      this.authService.logOut();
      this.router.navigate([this.homeRoute], { state: { isTermsAndConditions: true } });
    })
  }

  public goBack(): void {
    if (this.currentStep) {
      this.currentStep = 0;

      return;
    }

    this.logOut();
  }

  private initForm(): void {
    this.consentsForm = this.formBuilder.group({
      termsOfUse: [false, Validators.requiredTrue],
      marketingManagementGlobal: [false],
      marketingManagementInternal: [false],
      marketingManagementExternal: [false],
    });
  }

  private updateHeader(): void {
    ['toolbar_burger', 'toolbar_nav'].forEach(this.setDisplayNone.bind(this));
    const appToolbarRight = this.getElement('toolbar_right-action');

    if (appToolbarRight) {
      appToolbarRight.innerHTML = '';
      appToolbarRight.appendChild(this.createMobileBackButton());
    }
  }

  private createMobileBackButton(): HTMLElement {
    const newHeaderElement = this.document.createElement('div');
    const content = this.document.createElement('div');
    content.textContent = this.translateService.instant('pages.TERMS_AND_CONDITIONS.ACTIONS.BACK');
    content.classList.add('m-t-normal');
    newHeaderElement.append(this.getElement('back-icon'), content);

    newHeaderElement.classList.add('tc__back__mobile');
    newHeaderElement.style.display = 'flex';
    newHeaderElement.setAttribute('id', 'action-log-out');
    newHeaderElement.addEventListener('click', () => this.goBack());

    return newHeaderElement;
  }

  private setDisplayNone(elementClass: string): void {
    const element = this.getElement(elementClass);

    if (element) {
      element.style.display = 'none';
    }
  }

  private getElement(elementClass: string): HTMLElement | null {
    const element = this.document.getElementsByClassName(elementClass);

    return element[0] ? element[0] as HTMLElement : null;
  }

  private logOut(): void {
    this.authService.logOut();
    this.router.navigate([this.homeRoute]);
  }

  private updateFooter(): void {
    const appFooter = this.document.getElementsByTagName('app-footer')[0] as HTMLElement;
    appFooter?.classList.add('footer__toolbar');
  }

  private checkUserTermsAndConditions(): void {
    this.apiProfileService.getUserProfileAndScope()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
          this.router.navigate([this.homeRoute]);
        },
        (err) => {
          if (err.status === 403) {
            this.isLoaded = true;
            this.cdr.markForCheck();

            return;
          }
          this.router.navigate([this.homeRoute]);
        }
      )
  }

  private initMarketingConsentType(): void {
    const market = getCountryCode(this.document.location.host);

    this.isMarketingConsentGlobal = ['DE', 'NL', 'AT'].includes(market);
  }

  private configMarketingConsentPopInLinks(): void {
    const allianzGroupLink = this.document?.getElementById('mark-description');

    allianzGroupLink?.setAttribute('style', 'text-decoration: underline');
    allianzGroupLink?.addEventListener('click', (event: MouseEvent) => {
      this.openMarketingPopIn(event);
    });
  }

  private openMarketingPopIn(event: Event): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      title: this.translateService.instant('pages.TERMS_AND_CONDITIONS.DIALOG.TITLE'),
      info: this.translateService.instant('pages.REGISTRATION.MARKETING_MANAGEMENT_POP_IN'),
      confirmTitle: this.translateService.instant('pages.TERMS_AND_CONDITIONS.DIALOG.CONFIRM'),
    });
  }

  private listenDoubleOptInPopIn(): void {
    setTimeout(() => {
      const confirmButton = this.document?.getElementById('action-dialog-confirm');
      const cancelIcon = this.document?.getElementById('action-dialog-close');
      const overlay = this.getElement('modal-overlay');

      if (!confirmButton) {
        window.location.reload();

        return;
      }

      [confirmButton, cancelIcon, overlay].forEach(element => element?.addEventListener('click', () => {
        window.location.reload();
      }))
    }, 0)
  }
}
