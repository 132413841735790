import { ChangeDetectionStrategy, Component, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent {
  public displayMenu: boolean = false;

  constructor(private readonly elRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  clickHandler(event: MouseEvent): void {
    if(!this.elRef.nativeElement.contains(event.target)) {
      this.displayMenu = false;
    }
  }

  public toggleMenu(): void {
    this.displayMenu = !this.displayMenu;
  }
}
