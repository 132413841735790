import { Inject, Injectable } from '@angular/core';
import { filter, firstValueFrom, mapTo } from 'rxjs';
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';
import { AnalyticsService } from '../analytics/analytics.service';
import { AuthService } from '../auth/auth.service';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { HeadScriptInject } from '../head-script-inject/head-script-inject.service';
import { Bootstrap, Breadcrumbs, RouterConfigurations } from '../model/models';
import { RoutingService } from '../routing/routing.service';
import { UserService } from '../user/user.service';
import { ActivationUserAnalyticsService } from "@common/analytics/activation-user-analytics.service";
import { LanguageMappingURLService } from '@common/language-mapping-url/language-mapping-url.service';

@Injectable({
  providedIn: 'root',
})
export class StartUpService {
  constructor(
    private analyticsService: AnalyticsService,
    private activationUserAnalyticsService: ActivationUserAnalyticsService,
    private routingService: RoutingService,
    private bootstrapService: BootstrapService,
    private authService: AuthService,
    private userService: UserService,
    private headScriptInject: HeadScriptInject,
    private languageMappingURLService: LanguageMappingURLService,
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean,
  ) { }

  public initApp(bootstrap: Bootstrap, breadcrumbs: Breadcrumbs, routerConfig: RouterConfigurations): void {
    this.bootstrapService.setBootstrapData(bootstrap);
    this.bootstrapService.setBreadCrumbs(breadcrumbs);
    this.languageMappingURLService.mapRoutes(routerConfig);
    this.routingService.setRouterConfig(routerConfig);
    this.headScriptInject.injectHeadScripts();

    if (this.isBrowser) {
      this.preloadUserData();
      this.analyticsService.initAnalytics();
      this.activationUserAnalyticsService.initActivationUserAnalytic();
    }
  }

  private preloadUserData(): Promise<null> {
    return this.authService.tryLogin()
      .then(isAuth => isAuth
        ? firstValueFrom(this.userService.userData$.pipe(filter(Boolean), mapTo(null)))
        : null
      )
  }
}
