import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LanguageService } from "@common/language/language.service";
import { environment } from "../../../environments/environment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: 'app-teleconsultation-widget',
  templateUrl: './teleconsultation-widget.component.html',
  styleUrls: ['./teleconsultation-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeleconsultationWidgetComponent implements OnInit{
  public widgetPath: SafeResourceUrl;

  constructor(
    private languageService: LanguageService,
    private readonly domSanitizer: DomSanitizer,
  ) { }

  public ngOnInit() {
    this.widgetPath = this.domSanitizer.bypassSecurityTrustResourceUrl(
      `${environment.teleconsultationWidgetPath}&language=${this.languageService.getCurrentLanguage().toLowerCase()}`
    );
  }
}
