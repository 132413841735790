import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AemImageComponent } from './aem-image.component';
import { ImgAltDirectiveModule } from "@kit/aem/img-alt-directive/img-alternate-directive.module";

@NgModule({
  declarations: [AemImageComponent],
  imports: [CommonModule, ImgAltDirectiveModule],
})
export class AemImageModule {}
