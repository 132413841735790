export enum Language {
  FR = 'FR',
  DE = 'DE',
  ES = 'ES',
  EN = 'EN',
  IT = 'IT',
  NL = 'NL',
  PL = 'PL'
}

export enum Market {
  FR = 'FR',
  DE = 'DE',
  ES = 'ES',
  US = 'US',
  IT = 'IT',
  NL = 'NL',
  AT = 'AT',
  PL = 'PL'
}

export const languageName: Record<string, string> = {
  EN: 'English',
  NL: 'Dutch',
  FR: 'French',
  DE: 'German',
  IT: 'Italian',
  ES: 'Spanish',
  PL: 'Polish',
}

export const languageCode: Record<string, string> = {
  EN: 'en_GB',
  FR: 'fr_FR',
  DE: 'de_DE',
  IT: 'it_IT',
  ES: 'es_ES',
  NL: 'nl_NL',
  AT: 'de_AT',
  PL: 'pl_PL',
}

const DEFAULT_COUNTRY: Market = Market.FR;

export const LANGUAGE_REGEXP: RegExp = /(?:^|-)([a-z]{2})$/;
export const DOMAIN_REGEXP: RegExp = /(?<subdomain>.*-)?(?<language>[a-zA-Z]{2})(?<domain>\.allyz\.com)/;

export function getCountryCode(host: string): string {
  if (!host) return DEFAULT_COUNTRY;

  const [first]: string[] = host.split('.');
  const countryCode: string = first.match(LANGUAGE_REGEXP)?.[1];

  return countryCode?.toUpperCase() || DEFAULT_COUNTRY;
}

export function getLanguageByCountry(country: string, url?: string): string {
  if (url?.includes('en-fr')) return Language.EN;
  if (country.toUpperCase() === 'US') return Language.EN;
  if (country.toUpperCase() === 'AT') return Language.DE;

  return country;
}

export function getDomainByResidence(language: string): string {
  const domain: string = window?.location.origin;

  return domain.replace(DOMAIN_REGEXP, `$<subdomain>${language.toLowerCase()}$<domain>`);
}
