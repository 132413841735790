import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { DIALOG_DATA, DIALOG_REF } from '@kit/dialog/dialog.const';
import { DialogRef } from '@kit/dialog/dialog.interfaces';
import { AlertInfoDialogData } from './alert-info-dialog-data';

@Component({
  selector: 'app-alert-info-dialog',
  templateUrl: './alert-info-dialog.component.html',
  styleUrls: ['./alert-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertInfoDialogComponent {
  constructor(
    @Inject(DIALOG_REF) private readonly dialog: DialogRef<AlertInfoDialogComponent>,
    @Inject(DIALOG_DATA) public readonly dialogData: AlertInfoDialogData,
    public readonly bootstrapService: BootstrapService
  ) { }

  public close(): void {
    this.dialog.close();
  }
}
