import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { DIALOG_REF } from '@kit/dialog/dialog.const';
import { DialogRef } from '@kit/dialog/dialog.interfaces';

@Component({
  selector: 'app-notifications-info-dialog',
  templateUrl: './notifications-info-dialog.component.html',
  styleUrls: ['./notifications-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsInfoDialogComponent {
  constructor(
    public readonly bootstrapService: BootstrapService,
    @Inject(DIALOG_REF)
    private readonly dialog: DialogRef<NotificationsInfoDialogComponent>,
  ) {}

  public close(): void {
    this.dialog.close();
  }
}
