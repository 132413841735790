<div class="file__container">
  <app-icon theme="inherit"
            size="normal"
            [icon]="this.iconName"
            class="file__img">
  </app-icon>
  <div class="file__name">{{ file.name }}.{{ file.extension }}</div>
  <div class="file__date text-tiny">{{ file.creationDate | date: "dd MMM y" }}</div>
  <app-context-menu class="file__menu">
    <div (click)="download(file)" class="file__menu-item t-btn-normal m-t-btn-normal">
      {{'pages.DOCUMENT_VAULT.ACTIONS.DOWNLOAD' | translate}}
    </div>
    <div (click)="delete(file)" class="file__menu-item warning-text t-btn-normal m-t-btn-normal">
      {{'pages.DOCUMENT_VAULT.ACTIONS.DELETE' | translate}}
    </div>
  </app-context-menu>
</div>
