import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconList, IconSize, IconTheme, IconType } from './icon.interface';
import { IconService } from "@kit/icon/icon.service";

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() icon: IconList;
  @Input() type: IconType = 'normal';
  @Input() theme: IconTheme = 'normal';
  @Input() size: IconSize = 'normal';

  public get iconSpriteLink(): string {
    return this.iconService.iconSpriteLink;
  }

  constructor(public iconService: IconService) {}
}
