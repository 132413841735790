import { Injectable } from '@angular/core';
import { filter, finalize, forkJoin, map, Observable, of, switchMap, take, tap } from "rxjs";
import { AccountNotification, MarketingPartner, ServicePartner, UserProfile } from "@common/profile/profile.interfaces";
import { UserService } from "@common/user/user.service";
import { Market } from "@common/language/language.const";
import { ConfirmationDialogComponent } from "@kit/dialog/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogData } from "@kit/dialog/confirmation-dialog/confirmation-dialog.interface";
import { DialogService } from "@kit/dialog/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import {
  PhoneAddDialogComponent
} from "@pages/account/components/notification/phone-add-dialog/phone-add-dialog.component";
import { AnalyticsService } from "@common/analytics/analytics.service";
import { ConsentType } from './notification-options.consts';
import { UntypedFormGroup } from '@angular/forms';
import { ApiNotificationService } from '@common/notification/api-notification.service';

@Injectable()
export class NotificationService {

  constructor(
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly analyticsService: AnalyticsService,
    private readonly apiNotificationService: ApiNotificationService,
  ) { }

  public get userNotificationSettings$(): Observable<AccountNotification[]> {
    return this.userService.userNotification$.asObservable();
  }

  private get userProfile$(): Observable<UserProfile> {
    return this.userService.userData$.asObservable();
  }

  public saveNotificationSettings$(payload: AccountNotification[], partnerName: ServicePartner | MarketingPartner): Observable<AccountNotification[]> {
    const isMarketing = Object.values(MarketingPartner).includes(partnerName as MarketingPartner);
    const isSmsSelected = this.isSmsSelected(payload, partnerName);

    return this.updateNotifications$(payload, partnerName, isSmsSelected, isMarketing);
  }

  public unsubscribeFromNotification(payload: AccountNotification[], partnerName: ServicePartner | MarketingPartner): Observable<AccountNotification[]> {
    return this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      title: this.translateService.instant('pages.NOTIFICATION.UNSUBSCRIBE_DIALOG.TITLE'),
      message: this.translateService.instant('pages.NOTIFICATION.UNSUBSCRIBE_DIALOG.MESSAGE'),
      info: this.translateService.instant('pages.NOTIFICATION.UNSUBSCRIBE_DIALOG.INFO'),
      confirmTitle: this.translateService.instant('pages.NOTIFICATION.UNSUBSCRIBE_DIALOG.CONFIRM_TITLE'),
      icon: 'bell',
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.saveNotificationSettings$(payload, partnerName)),
    );
  }

  public unsubscribeServiceNotification$(payload: AccountNotification[], partnerName: MarketingPartner | ServicePartner): Observable<AccountNotification[]> {
    return this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      title: this.translateService.instant('pages.NOTIFICATION.UNSUBSCRIBE_DIALOG.TITLE'),
      message: this.translateService.instant('pages.NOTIFICATION.UNSUBSCRIBE_DIALOG.MESSAGE'),
      info: this.translateService.instant('pages.NOTIFICATION.UNSUBSCRIBE_DIALOG.INFO'),
      confirmTitle: this.translateService.instant('pages.NOTIFICATION.UNSUBSCRIBE_DIALOG.CONFIRM_TITLE'),
      icon: 'bell',
    }).afterClosed$.pipe(
      switchMap(value => {
        if (value !== ConfirmationDialogComponent.CONFIRM) {
          return this.userService.reloadUserNotificationInfo$();
        }

        return this.userNotificationSettings$
          .pipe(
            take(1),
            switchMap(() => this.apiNotificationService.updateNotifications(payload)),
            tap((notification: AccountNotification[]) => this.userService.userNotification$.next(notification)),
            tap((notificationSettings: AccountNotification[]) => this.updateServicesConsentAnalytics(notificationSettings, partnerName as ServicePartner)),
          )
      }),
    );
  }

  public switchOnTripNotifications$(): Observable<AccountNotification[]> {
    return this.userProfile$
      .pipe(
        map((userProfile: UserProfile): AccountNotification[] => {
          return [
            {
              consentType: ConsentType.EMAIL,
              consentValue: true,
              partnerName: ServicePartner.CRISIS24,
              isBlocked: false
            },
            {
              consentType: ConsentType.SMS,
              consentValue: !!userProfile?.phone,
              partnerName: ServicePartner.CRISIS24,
              isBlocked: false
            }
          ];
        }),
        switchMap((payload: AccountNotification[]) => this.apiNotificationService.updateNotifications(payload)),
        tap((notification: AccountNotification[]) => this.userService.userNotification$.next(notification)),
        tap((notificationSettings: AccountNotification[]) => this.updateServicesConsentAnalytics(notificationSettings, ServicePartner.CRISIS24)),
      )
  }

  public getMarketingSettings(form: UntypedFormGroup, isMarketingConsentGlobal: boolean, declined: boolean): AccountNotification[] {
    if (isMarketingConsentGlobal) {
      return [
        {
          consentType: ConsentType.GLOBAL_MARKETING,
          partnerName: MarketingPartner.ALLYZ,
          consentValue: declined ? false : form.get('marketingManagementGlobal').value,
        },
        {
          consentType: ConsentType.MARKETING_CAMPAIGN,
          partnerName: MarketingPartner.ALLYZ,
          consentValue: true,
        },
      ]
    }

    return [
      {
        consentType: ConsentType.INTERNAL_MARKETING,
        partnerName: MarketingPartner.ALLYZ,
        consentValue: declined ? false : form.get('marketingManagementInternal').value,
      },
      {
        consentType: ConsentType.EXTERNAL_MARKETING,
        partnerName: MarketingPartner.ALLYZ,
        consentValue: declined ? false : form.get('marketingManagementExternal').value,
      },
      {
        consentType: ConsentType.MARKETING_CAMPAIGN,
        partnerName: MarketingPartner.ALLYZ,
        consentValue: true,
      },
    ]
  }

  private updateNotifications$(payload: AccountNotification[], partnerName: MarketingPartner | ServicePartner, smsOption: boolean, isMarketing: boolean): Observable<AccountNotification[]> {
    return of(null).pipe(
      switchMap(() => {
        if (smsOption) {
          return this.isUserHasPhoneNumber$()
        }

        return of(true)
      }),
      filter(Boolean),
      map(() => this.isDoubleOptInMarketing(payload, isMarketing)),
      switchMap(() => this.apiNotificationService.updateNotifications(payload)),
      tap((notification: AccountNotification[]) => this.userService.userNotification$.next(notification)),
      tap((notificationSettings: AccountNotification[]) => {
        if (isMarketing) {
          this.updateMarketingConsentAnalytics(payload);
        } else {
          this.updateServicesConsentAnalytics(notificationSettings, partnerName as ServicePartner);
        }
      })
    )
  }

  private isDoubleOptInMarketing(payload: AccountNotification[], isMarketing: boolean): void {
    if (!isMarketing) return;

    const profile = this.userService.userData;
    const location = profile?.residence || this.userService.location;
    const isRelevantMarket = location === Market.DE.toUpperCase() || location === Market.AT.toUpperCase();

    if (isRelevantMarket) {
      const isChecked = payload.find((notification: AccountNotification) =>
        notification.consentType === ConsentType.GLOBAL_MARKETING)?.consentValue;

      if (isChecked) {
        this.showMarketingConsentDialog();
      }
    }
  }

  private showMarketingConsentDialog(): void {
    const titleTranslation$ = this.translateService.get('pages.NOTIFICATION.MARKETING_CONSENT_DIALOG.TITLE');
    const messageTranslation$ = this.translateService.get('pages.NOTIFICATION.MARKETING_CONSENT_DIALOG.MESSAGE');
    const confirmTitleTranslation$ = this.translateService.get('pages.NOTIFICATION.MARKETING_CONSENT_DIALOG.CONFIRM_TITLE');

    forkJoin([titleTranslation$, messageTranslation$, confirmTitleTranslation$])
      .pipe(
        map(([title, message, confirmTitle]) => {
          this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
            message: title,
            info: message,
            confirmTitle: confirmTitle,
          });
        })
      ).subscribe();
  }

  private isUserHasPhoneNumber$(): Observable<boolean> {
    return this.userProfile$.pipe(
      take(1),
      switchMap((userProfile: UserProfile) => {
        if (userProfile?.phone) {
          return of(true);
        }

        return this.dialogService.open(PhoneAddDialogComponent).afterClosed$
          .pipe(
            finalize(() => {
              this.userService.reloadUserNotificationInfo$();
              this.userService.reloadUserInfo$();
            })
          );
      }),
    );
  }

  private isSmsSelected(payload: AccountNotification[], partnerName: ServicePartner | MarketingPartner): boolean {
    return payload.find(((profileConsent: AccountNotification) => {
      return profileConsent.consentType === ConsentType.SMS && profileConsent.partnerName === partnerName;
    }))?.consentValue || false;
  }

  private updateMarketingConsentAnalytics(notification: AccountNotification[]): void {
    const email = notification[0].consentValue;
    let action = 'none';
    let label = 'none';

    if (email) label = 'email only';

    if (notification[0].consentType === ConsentType.GLOBAL_MARKETING) action = 'globalMarketingConsent';
    if (notification[0].consentType === ConsentType.INTERNAL_MARKETING) action = 'internalMarketingConsent';
    if (notification[0].consentType === ConsentType.EXTERNAL_MARKETING) action = 'externalMarketingConsent';

    this.analyticsService.triggerAction({
      category: 'notification_center',
      action: action,
      label: label,
      value: 1,
    });
  }

  private updateServicesConsentAnalytics(notificationSettings: AccountNotification[], partnerName: ServicePartner): void {
    let label = 'none';
    let action = 'none';

    const email = notificationSettings.find(notification => notification.partnerName === partnerName && notification.consentType === ConsentType.EMAIL).consentValue;
    const sms = notificationSettings.find(notification => notification.partnerName === partnerName && notification.consentType === ConsentType.SMS).consentValue;

    if (email && sms) label = 'both email & sms';
    if (email && !sms) label = 'email only';
    if (!email && sms) label = 'sms only';

    if (partnerName === ServicePartner.CRISIS24) action = 'safetyAlert';
    if (partnerName === ServicePartner.AMADEUS) action = 'flightDelay';
    if (partnerName === ServicePartner.COLLINSON) action = 'loungeAccess';
    if (partnerName === ServicePartner.FLIGHT_RIGHT) action = 'checkCompensation';

    this.analyticsService.triggerAction({
      category: 'notification_center',
      action: action,
      label: label,
      value: 1,
    });
  }
}
