<div class="travel-planner">
  <ng-container *ngFor="let action of currentTrip.actions">
    <ng-container [ngSwitch]="action.type">
      <ng-container *ngSwitchCase="ActivityType.FLIGHT">
        <ng-container *ngTemplateOutlet="flightOutlet; context: { $implicit: action }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ActivityType.ACTIVITY">
        <ng-container *ngTemplateOutlet="activityOutlet; context: { $implicit: action }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ActivityType.HOTEL">
        <ng-container *ngTemplateOutlet="hotelOutlet; context: { $implicit: action }"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<div class="travel-planner__add-element" *ngIf="showAddButton">
  <app-button icon="plus"
              [appAemRouterLink]="bootstrapService.link.existingFlightsPage"
              [aemRouterData]="{ tripId }"
              id="action-add-flight">
    {{ 'forms.TRIP_PLANNER.ACTIONS.ADD_A_NEW_TRIP_ELEMENT' | translate }}
  </app-button>
</div>

<ng-template #flightOutlet let-flight>
  <app-trip-planner-flight [flight]="flight"
                           [currentTrip]="currentTrip"
                           [boundUpdateCurrentTrip]="boundUpdateCurrentTrip">
  </app-trip-planner-flight>
</ng-template>

<ng-template #activityOutlet let-activity>
  <app-trip-planner-activity [activity]="activity"
                             [currentTrip]="currentTrip"
                             [boundUpdateCurrentTrip]="boundUpdateCurrentTrip">
  </app-trip-planner-activity>
</ng-template>

<ng-template #hotelOutlet let-hotel>
  <app-trip-planner-hotel [hotel]="hotel"
                          [currentTrip]="currentTrip"
                          [boundUpdateCurrentTrip]="boundUpdateCurrentTrip">
  </app-trip-planner-hotel>
</ng-template>
