import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';

@Component({
  selector: 'app-password-creations-form',
  templateUrl: './password-creations-form.component.html',
  styleUrls: ['./password-creations-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemPasswordCreationsFormComponent extends AemFragmentComponent {
}
