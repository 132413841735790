<app-dialog-wrapper>
  <div dialogTitle class="m-h5">
    <app-icon icon="shield-protected" 
              theme="invert" 
              size="large"></app-icon>
    {{ 'pages.MY_TRIPS.TRIP_ALERT_DIALOG.DIALOG_TITLE' | translate }}
  </div>
  <ng-container dialogContent>
    <div class="h5 m-h3">
      {{ 'pages.MY_TRIPS.TRIP_ALERT_DIALOG.CONTENT_TITLE' | translate }}
    </div>
    <p class="t-normal m-t-small">
      {{ 'pages.MY_TRIPS.TRIP_ALERT_DIALOG.MESSAGE' | translate }}
    </p>

    <app-button id="action-go-to-notification"
                type="full-width"
                [appAemRouterLink]="bootstrapService.link.accountNotificationSettings"
                (click)="close()">
      {{ 'pages.MY_TRIPS.TRIP_ALERT_DIALOG.DIALOG_ACTION' | translate }}
    </app-button>
  </ng-container>
</app-dialog-wrapper>
