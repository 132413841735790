export function flattenWith<T>(selector: (item: T) => T[] | undefined, list: T[]): T[] {
  if (!list) {
    return [];
  }

  return list.reduce((acc, next) => {
    const children = selector(next);
    const flattenChildren = flattenWith(selector, children);

    return [...acc, next, ...flattenChildren];
  }, []);
}
