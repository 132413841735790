import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MembershipCards } from '@common/model/membershipCards';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { LanguageService } from "@common/language/language.service";

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemMembershipComponent extends AemBaseBlockComponent implements OnInit{
  public language: string;

  get appleStoreImagePath(): string {
    return `assets/img/app-store/${this.language}/app-store-badge-black.svg`;
  }

  get googlePlayImagePath(): string {
    return `assets/img/google-play/${this.language}/google-play-badge-black.png`;
  }

  constructor(
    @Inject(AEM_DATA) public override data: MembershipCards,
    private languageService: LanguageService,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.language = this.languageService.getCurrentLanguage();
  }
}
