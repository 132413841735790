import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldErrorComponent } from './field-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@kit/icon/icon.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';

@NgModule({
  declarations: [FieldErrorComponent],
  exports: [FieldErrorComponent],
  imports: [CommonModule, TranslateModule, IconModule, SafeHtmlModule]
})
export class FieldErrorModule {}
