import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemCardsWithTextComponent } from './cards-with-text.component';

@NgModule({
  declarations: [AemCardsWithTextComponent],
  exports: [AemCardsWithTextComponent],
  imports: [CommonModule, AemButtonModule, SafeHtmlModule]
})
export class AemCardsWithTextModule {}
