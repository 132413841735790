import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TuiDestroyService, tuiPure } from '@taiga-ui/cdk';
import { UserService } from 'src/app/common/user/user.service';
import { setRouterQueryData } from 'src/app/kit/utils/router.utils';
import { AuthService } from '@common/auth/auth.service';
import { CompleteInsuranceInfo, QuotationResponseContractDocuments } from '@common/booking-path/booking-path.interfaces';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { BookingService } from '../booking.service';
import { BookingPathStep } from '../booking-path-step.enum';
import { EventDigitalData } from '@common/analytics/digital-data.interface';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { filter, takeUntil } from 'rxjs';
import { DigitalDataService } from '@common/analytics/digital-data.service';

@Component({
  selector: 'app-booking-path-confirmation',
  templateUrl: './booking-path-confirmation.component.html',
  styleUrls: ['./booking-path-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class BookingPathConfirmationComponent implements OnInit {
  public get authorized(): boolean {
    return this.userService.authorized;
  }

  public get insuranceInfo(): CompleteInsuranceInfo {
    return this.bookingService.completeInsuranceInfo;
  }

  public get policyHolderName(): string {
    return `${this.insuranceInfo.insuranceDetails.travelersDetails.policyHolder.firstName} ${this.insuranceInfo.insuranceDetails.travelersDetails.policyHolder.lastName}`;
  }

  public get userName(): string {
    return `${this.userService.userData.firstName} ${this.userService.userData.lastName}`;
  }

  public get travelersNames(): string[] {
    return this.insuranceInfo.insuranceDetails.travelersDetails.travellers.map(
      ({ firstName, lastName }) => `${firstName} ${lastName}`
    );
  }

  public get destinations(): string {
    return this.mapDestinationsNames(this.insuranceInfo.insuranceDetails.tripDetails.destinationLocation.names);
  }

  public get residence(): string {
    return this.translateService.instant(`countries.${this.insuranceInfo.insuranceDetails.tripDetails.residenceCountryCode}`);
  }

  public get documents(): QuotationResponseContractDocuments[] {
    return this.insuranceInfo.insuranceDetails.selectedProduct.documents;
  }

  public get planDetailsLink(): string {
    return this.bootstrapService.link.membershipPremium;
  }

  constructor(
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly bookingService: BookingService,
    private readonly translateService: TranslateService,
    private readonly bootstrapService: BootstrapService,
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService,
    private readonly digitalDataService: DigitalDataService,
    private readonly destroy$: TuiDestroyService,
  ) {}

  ngOnInit(): void {
    this.digitalDataService.updatePageName(BookingPathStep.CONFIRMATION);
    this.analyticsService.bookingPathStepEvent();

    this.userService.isAuth$.pipe(
      filter((isAuth: boolean) => isAuth),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      const event: EventDigitalData = {
        category: 'travel_insurance',
        action: 'memberUpgraded',
        label: this.insuranceInfo.contractNumber,
        value: 1,
        timeStamp: new Date().toISOString(),
      };

      this.analyticsService.triggerAction(event);
    });
  }

  public toRegistration(): void {
    this.router.navigateByUrl(this.bootstrapService.link.register);
  }

  public addPolicy(): void {
    this.router.navigateByUrl(setRouterQueryData(
      this.bootstrapService.link.insuranceAddExistingPolicy,
      { contractNumber: btoa(this.insuranceInfo.contractNumber) }
    ));
  }

  public toLogin(): void {
    this.authService.authorize();
  }

  @tuiPure
  private mapDestinationsNames(destinations: string[]): string {
    return destinations.map(dest => this.translateService.instant(`countries.${dest}`)).join(', ');
  }
}
