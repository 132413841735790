import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { LinkingDataService } from 'src/app/common/seo/linking-data.service';
import { FAQCollapsible } from '@common/model/fAQCollapsible';
import { FAQCollapsibleGroup } from '@common/model/fAQCollapsibleGroup';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';


@Component({
  selector: 'app-faq-collapsible-group',
  templateUrl: './faq-collapsible-group.component.html',
  styleUrls: ['./faq-collapsible-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemFaqCollapsibleGroupComponent extends AemBaseBlockComponent {
  items: FAQCollapsible[] = this.data.items?.filter(item => item._type === 'FAQCollapsible');

  constructor(
    private readonly structuredService: LinkingDataService,
    @Inject(AEM_DATA) public override data: FAQCollapsibleGroup,
  ) {
    super(data);
    this.structuredService.updateFAQStructuredData(data.items.flatMap((faq) => faq.items));
  }
}
