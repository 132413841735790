import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideToggleComponent } from './slide-toggle.component';
import { IconModule } from "@kit/icon/icon.module";

@NgModule({
  declarations: [SlideToggleComponent],
  exports: [SlideToggleComponent],
  imports: [CommonModule, IconModule],
})
export class SlideToggleModule { }
