import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleBasedContentWrapperComponent } from './role-based-content-wrapper.component';

@NgModule({
  declarations: [RoleBasedContentWrapperComponent],
  exports: [RoleBasedContentWrapperComponent],
  imports: [CommonModule],
})
export class RoleBasedContentWrapperModule { }
