import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TripHeroBlockComponent } from "@pages/trip/components/trip-hero-block/trip-hero-block.component";
import { BackButtonModule } from "@kit/back-button/back-button.module";
import { SafeHtmlModule } from "@kit/safe-html/safe-html.module";
import { AemButtonModule } from "@kit/aem-button/aem-button.module";

@NgModule({
  declarations: [TripHeroBlockComponent],
  imports: [
    CommonModule,
    BackButtonModule,
    SafeHtmlModule,
    AemButtonModule
  ],
  exports: [TripHeroBlockComponent],
})
export class TripHeroBlockModule { }
