<app-trip-hero-block (goBack)="back()">
  <div class="flights-existing">
    <div *ngFor="let flight of flights$ | async" class="flights-existing_flight-items">
      <app-flight-card-detailed [flight]="flight"
                                (delete)="deleteFlight($event)">
      </app-flight-card-detailed>
    </div>
    <app-safe-html [content]="data.component.text" class="t-large t-normal"></app-safe-html>
    <div (click)="addMoreFlights()" class="flights-existing_add-more">
      <app-icon class="flights-existing_add-more-icon" icon="plane" theme="inherit" size="large"></app-icon>
      <span class="flights-existing_add-more-text t-small m-t-normal">
        {{'pages.ADDED_FLIGHTS.ADD_MORE_FLIGHTS' | translate}}
      </span>
      <app-icon class="flights-existing_add-more-plus" icon="plus" size="large" theme="inherit"></app-icon>
    </div>
  </div>
</app-trip-hero-block>
