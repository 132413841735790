import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './stepper.component';
import { StepperItemDirective } from './stepper-item.directive';
import { BackButtonModule } from '../back-button/back-button.module';
import { RouterModule } from '@angular/router';
import { IconModule } from '../icon/icon.module';
import { TuiLetModule } from '@taiga-ui/cdk';

@NgModule({
  declarations: [
    StepperComponent,
    StepperItemDirective,
  ],
  exports: [
    StepperComponent,
    StepperItemDirective,
  ],
  imports: [
    CommonModule,
    IconModule,
    RouterModule,
    BackButtonModule,
    TuiLetModule,
  ],
})
export class StepperModule { }
