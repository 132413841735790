import { UntypedFormGroup } from "@angular/forms";
import { UserProfile, UserProfileDTO, UserProfileForm, UserType, MembershipStatus } from "./profile.interfaces";
import { getLanguageByCountry } from "@common/language/language.const";

export function mapUserProfileFromDto(userDto: UserProfileDTO): UserProfile {
  return {
    ...userDto,
    membershipStatus: subscriptionToAemFormat(userDto.membershipStatus),
    language: userDto.language?.substring(0, 2) || getLanguageByCountry(userDto.residence)
  }
}

export function subscriptionToAemFormat(subscription: MembershipStatus): UserType {
  switch (subscription) {
    case MembershipStatus.STANDARD:
      return UserType.Freemium;
    case MembershipStatus.PLUS:
      return UserType.Premium;

    default: return UserType.Anonymous;
  }
}

export function normalizeUserProfileForm(form: UntypedFormGroup): UserProfileForm {
  const source = form.value;

    return {
      email: source.email,
      firstName: source.firstName?.trim(),
      lastName: source.lastName?.trim(),
      phone: source.phone,
    }
}