import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemMembershipPromotionsComponent } from './aem-membership-promotions.component';


@NgModule({
  declarations: [
    AemMembershipPromotionsComponent,
  ],
  imports: [
    CommonModule,
    SafeHtmlModule,
    AemButtonModule,
  ],
})
export class AemMembershipPromotionsModule { }
