import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemColumnComponent } from './column.component';

@NgModule({
  declarations: [AemColumnComponent],
  exports: [AemColumnComponent],
  imports: [
    CommonModule,
  ]
})
export class AemColumnModule { }
