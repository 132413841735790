<div class="folder__container">
  <div class="folder__img">
    <app-icon theme="normal"
              size="large"
              [icon]="folderIcon"
              class="folder__icon">
    </app-icon>
  </div>
  <div class="folder__footer">
    <div class="folder__name">{{ folderNameTranslationKey | translate }}</div>
    <div class="folder__amount">
      <ng-container *ngIf="folder.filesCount; else empty">
        {{folder.filesCount}} {{'pages.DOCUMENT_VAULT.LABELS.FILES' | translate}}
      </ng-container>
    </div>
  </div>
</div>

<div class="mobile__container">
  <div class="mobile__img">
    <app-icon theme="normal"
              size="normal"
              [icon]="folderIcon"
              class="folder__icon">
    </app-icon>
  </div>
  <div class="mobile__folder">
    <div class="m-t-large mobile__folder__name">{{ folderNameTranslationKey | translate }}</div>
    <div class="m-t-normal">
      <ng-container *ngIf="folder.filesCount; else empty">
        {{folder.filesCount}} {{'pages.DOCUMENT_VAULT.LABELS.FILES' | translate}}
      </ng-container>
    </div>
  </div>
  <app-icon icon="arrow-right" theme="amped" size="x-large" class="mobile__arrow"></app-icon>
</div>

<ng-template #empty>
  {{ 'pages.DOCUMENT_VAULT.LABELS.EMPTY' | translate }}
</ng-template>
