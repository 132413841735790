import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional, Renderer2, RendererFactory2 } from '@angular/core';
import { LanguageService } from '../language/language.service';
import { SEOAlternateHREFLANG } from '../model/models';
import { CURRENT_LOCATION } from './seo.const';

@Injectable({ providedIn: 'root' })
export class LinkService {
  private previousAlternatesTags: HTMLElement[] = [];
  private previousImportantAlternatesTags: HTMLElement[] = [];
  private previousCanonicalTag: HTMLElement = null;
  private renderer: Renderer2;

  constructor(
    private readonly rendererFactory: RendererFactory2,
    private readonly languageService: LanguageService,
    @Inject(DOCUMENT)
    private readonly document: Document,
    @Optional() @Inject(CURRENT_LOCATION)
    private readonly currentLocation: string,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public updateImportantAlternateHref(hrefAlternates: SEOAlternateHREFLANG[] = []): void {
    const currentUrl = new URL(this.currentLocation);

    this.previousImportantAlternatesTags.forEach(tag => tag.remove());
    this.previousImportantAlternatesTags = hrefAlternates?.map((tagConfig: SEOAlternateHREFLANG) => {
      const parsedHreflang = tagConfig.hreflang.split('-');
      const lang = parsedHreflang[parsedHreflang.length - 1]?.toLowerCase() || '';
      const host = currentUrl.host.replace(/(en|fr|de|it|nl|es)\./, `${lang}.`);

      const updatedTagConfig: SEOAlternateHREFLANG = {
        ...tagConfig,
        href: `https://${host}${tagConfig.href}`,
      }

      return this.createAlternateTag(updatedTagConfig)
    }) || [];
  }

  public updateAlternateHref(hrefAlternates: SEOAlternateHREFLANG[] = []): void {
    this.previousAlternatesTags.forEach(tag => tag.remove());

    if (hrefAlternates?.length) {
      this.previousAlternatesTags = hrefAlternates.map(tagConfig => this.createAlternateTag(tagConfig));
    } else {
      const tag = this.createAlternateTag({
        href: this.currentLocation,
        hreflang: this.languageService.countryCode === this.languageService.languageCode
          ? this.languageService.countryCode.toLowerCase()
          : `${this.languageService.languageCode}-${this.languageService.countryCode}`.toLowerCase()
      });

      this.previousAlternatesTags = [tag];
    }
  }

  public updateCanonical(canonical: string): void {
    this.previousCanonicalTag?.remove();

    if (canonical) {
      this.previousCanonicalTag = this.createCanonicalTag(canonical);
    } else {
      this.previousCanonicalTag = this.createCanonicalTag(this.currentLocation);
    }
  }

  private createAlternateTag(tagConfig: SEOAlternateHREFLANG): HTMLElement {
    const tag = this.renderer.createElement('link');
    this.renderer.setAttribute(tag, 'rel', 'alternate');
    this.renderer.setAttribute(tag, 'hreflang', tagConfig.hreflang);
    this.renderer.setAttribute(tag, 'href', tagConfig.href);
    this.renderer.appendChild(this.document.head, tag);

    return tag;
  }

  private createCanonicalTag(canonical: string): HTMLElement {
    const tag = this.renderer.createElement('link');
    this.renderer.setAttribute(tag, 'rel', 'canonical');
    this.renderer.setAttribute(tag, 'href', canonical);
    this.renderer.appendChild(this.document.head, tag);

    return tag;
  }
}
