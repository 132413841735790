import { Injectable } from '@angular/core';
import { setCookie } from '@kit/utils/cookies';
import { AFFILIATE_CODE_COOKIE, AFFILIATE_ID_3RD_PARTY_COOKIE, USER_ID_3RD_PARTY_COOKIE } from './analytics.const';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsCookiesService {
  private affiliateCode: string;
  private affiliateCodeId: string;
  private userId3drParty: string;

  public initTargetCookies(): void {
    const urlParams = new URLSearchParams(window.location.search);
    this.affiliateCode = urlParams.get('ac');
    this.affiliateCodeId = urlParams.get('affId');
    this.userId3drParty = urlParams.get('tduid');
    this.setTargetCookies();
  }

  private setTargetCookies(): void {
    if (this.affiliateCode) {
      setCookie(AFFILIATE_CODE_COOKIE, this.affiliateCode, 90);
    }

    if (this.affiliateCodeId) {
      setCookie(AFFILIATE_ID_3RD_PARTY_COOKIE, this.affiliateCodeId, 365);
    }

    if (this.userId3drParty) {
      setCookie(USER_ID_3RD_PARTY_COOKIE, this.userId3drParty, 365);
    }
  }
}
