import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Activity } from '../../../activity-element/activity-element.interface';
import { ActivityCategoryConfiguration } from '@common/model/activityCategoryConfiguration';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';

@Component({
  selector: 'app-trip-details-accordion-activity',
  templateUrl: './trip-details-accordion-activity.component.html',
  styleUrls: ['./trip-details-accordion-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripDetailsAccordionActivityComponent {
  @Input() activity: Activity;

  public activityConfiguration = this.bootstrapService.activityConfiguration;
  public readonly defaultActivityConfiguration: ActivityCategoryConfiguration = {
    code: null,
    icon: 'activities',
    images: [{ imagePath: '/assets/img/default/activity.webp', altText: '', _type: '' }]
  }

  constructor(public readonly bootstrapService: BootstrapService) {
  }

}
