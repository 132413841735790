import { Injectable } from '@angular/core';
import { TranslateFakeCompiler } from '@ngx-translate/core';
import { AppStorage } from "@kit/utils/ssr.utils";

export const SHOW_TRANSLATION_KEYS_STORAGE_KEY = 'showTranslationKeys';

function markAllTranslationKeys(obj: any, showTranslationKeys: boolean, prevKey?: string): any {
  return Object.fromEntries(
    Object.entries(obj).map(([key, translation]) => {
      const fullKey = [prevKey, key].filter(Boolean).join('.');
      const value = translation === Object(translation)
        ? markAllTranslationKeys(translation, showTranslationKeys, fullKey)
        : showTranslationKeys ? `[${translation} | ${fullKey}]` : translation;

      return [key, value];
    })
  );
}

@Injectable({
  providedIn: 'root'
})
export class MarkTranslationCompiler extends TranslateFakeCompiler {
  constructor(
    private readonly storage: AppStorage,
  ) {
    super();
  }

  public override compile(value: string): string | Function {
    return typeof value === 'string'
      ? 'Ⓓ[' + value + ']'
      : value;
  }

  public override compileTranslations(translations: any): any {
    const showTranslationKeys = JSON.parse(this.storage.getItem(SHOW_TRANSLATION_KEYS_STORAGE_KEY))?.value;

    return typeof translations === 'string'
      ? 'Ⓓ' + translations
      : markAllTranslationKeys(translations, showTranslationKeys);
  }
}
