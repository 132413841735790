<header class="header">
  <div class="header__main">
    <div class="header__shell shell">
      <app-breadcrumbs *ngIf="data.hasBreadcrumbs"></app-breadcrumbs>

      <div class="hero">
        <div class="hero__group">
          <h1 class="hero__title">{{ data.title }}</h1>
          <img  *ngIf="data.image?.imagePath"
                class="hero__img-mobile"
                [src]="data.image.imagePath"
                [appImgAlt]="data.image?.altText"
                [appImgAltData]="data"/>

          <app-safe-html class="hero__text" [content]="data.text"></app-safe-html>

          <div class="hero__thematics" *ngIf="thematics?.length && thematicLinks">
            <a
              [appAemRouterLink]="thematicLinks[thematic.id].link"
              *ngFor="let thematic of thematics"
              class="hero__link"
              ref="nofollow"
            >
              <app-thematic class="hero__thematics-item" [selected]="true">{{ thematic.title }}</app-thematic>
            </a>
          </div>
          <div class="hero__date t-large m-t-normal" *ngIf="data.date">
            {{ 'pages.BLOG.LABELS.LAST_UPDATED_DATE' | translate }} {{ data.date | dateFormat: 'fullSpelled' }}
          </div>
        </div>
        <img *ngIf="data.image?.imagePath"
             class="hero__img"
             [src]="data.image.imagePath"
             [appImgAlt]="data.image?.altText"
             [appImgAltData]="data"/>
      </div>
    </div>
  </div>
</header>
