<ng-container *ngIf="currentLanguage$ | async as currentLanguage">
  <ng-container *ngIf="!isMobileView">
    <div class="switcher__container"
         (click)="toggleMenu()">
      <app-icon icon="planet"
                class="switcher__icon"
                theme="invert"
                size="large">
      </app-icon>
      <span class="switcher__lang">{{ currentLanguage }}</span>
    </div>

    <ng-container *ngIf="displayMenu">
      <div class="switcher__menu" [ngClass]="{'menu-visible': displayMenu}">
        <div *ngFor="let lang of languages$ | async as languages"
            class="switcher__item text-tiny m-t-small"
            (click)="switchLanguage(lang)">
          <app-icon [ngClass]="{'icon-invisible': lang !== currentLanguage}"
                    icon="planet"
                    class="switcher__icon"
                    theme="invert"
                    size="large">
          </app-icon>
          <span class="switcher__lang">{{ lang }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="isMobileView"
       class="switcher__mobile">
    <app-icon icon="planet"
              class="switcher__icon"
              theme="invert"
              size="large">
    </app-icon>
    <div *ngFor="let lang of languages$ | async as languages"
        class="switcher__mobile__item text-tiny m-t-small"
        (click)="switchLanguage(lang)">
      <span class="switcher__mobile__lang"
            [ngClass]="{'lang-active': lang === currentLanguage}">
            {{ lang }}
      </span>
    </div>
  </div>
</ng-container>

