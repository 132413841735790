import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { FormAccommodationEdit } from '@common/model/formAccommodationEdit';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from '@kit/dialog/dialog.service';
import { wrapValidator } from '@kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from '@kit/server-errors/server-errors-validation.service';
import { setRouterData } from '@kit/utils/router.utils';
import { notBlankValidator, rangeWithTimeValidator } from '@kit/utils/validators';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { Hotel } from '../hotel.interface';
import { mapHotelSourceToDto, mapHotelToSource } from '../hotel.mapper';
import { HotelService } from '../hotel.service';
import { getAllFormErrors } from "@kit/utils/form";
import { Observable } from "rxjs";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-hotel',
  templateUrl: './edit-hotel.component.html',
  styleUrls: ['./edit-hotel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TuiDestroyService,
    ServerErrorsValidationService,
  ],
})
export class EditHotelComponent extends AemFragmentComponent implements OnInit {
  form = this.formBuilder.group({
    hotelName: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
        wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
        wrapValidator(Validators.maxLength(35), 'errors.CEAZ000_Size'),
      ],
      this.validationService.createValidator('hotelName'),
    ],
    hotelAddress: [
      null,
      [
        wrapValidator(Validators.maxLength(160), 'errors.CEAZ000_Size'),
      ],
      this.validationService.createValidator('hotelAddress'),
    ],
    checkInDate: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      ],
      this.validationService.createValidator('checkInDate'),
    ],
    checkInHour: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      ],
      this.validationService.createValidator('checkInHour'),
    ],
    checkOutDate: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      ],
      this.validationService.createValidator('checkOutDate'),
    ],
    checkOutHour: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      ],
      this.validationService.createValidator('checkOutHour'),
    ],
    bookingReference: [
      null,
      [
        wrapValidator(Validators.maxLength(15), 'errors.CEAZ000_Size'),
      ],
      this.validationService.createValidator('bookingReference'),
    ],
  });

  tripId = this.route.firstChild.snapshot.params.tripId;

  constructor(
    private bootstrapService: BootstrapService,
    private formBuilder: UntypedFormBuilder,
    private hotelService: HotelService,
    private validationService: ServerErrorsValidationService,
    private onDestroy$: TuiDestroyService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    @Inject(AEM_DATA) data: FormAccommodationEdit,
  ) {
    super(data);
  }

  ngOnInit(): void {
    this.form.addAsyncValidators(this.validationService.createValidator());
    this.form.addValidators(wrapValidator(
      rangeWithTimeValidator(
        this.form.get('checkInDate'),
        this.form.get('checkInHour'),
        this.form.get('checkOutDate'),
        this.form.get('checkOutHour'),
      ),
      'errors.CEAZ024'
    ));

    this.form.patchValue(mapHotelToSource(this.route.firstChild.snapshot.data.hotel));
  }

  public goBack(): void {
    this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId: this.tripId }));
  }

  public onSave(): void {
    this.form.markAllAsTouched();

    if (getAllFormErrors(this.form)?.length) {
      this.analyticsService.validationError(this.form, this.constructor.name);
      return;
    }

    const hotel: Hotel = {
      ...this.route.firstChild.snapshot.data.hotel,
      ...mapHotelSourceToDto(this.form.value)
    };
    const { tripId, accommodationId } = this.route.firstChild.snapshot.params;

    this.hotelService.save(tripId, accommodationId, hotel).pipe(
      catchError(err => this.handleServiceError(err)),
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId }));
    });
  }

  public onDelete(): void {
    const { tripId, accommodationId } = this.route.firstChild.snapshot.params;

    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      title: this.translateService.instant('global.CONFIRMATION_DIALOG.TITLES.CONFIRMATION'),
      message: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DELETE_HOTEL'),
      info: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DISCLAIMER_DELETE'),
      cancelTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.CANCEL'),
      confirmTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.DELETE'),
      reverseButtonPosition: true
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.hotelService.delete(tripId, accommodationId)),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId }));
    });
  }

  private handleServiceError(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    return this.validationService.handleServerError(err?.error);
  }
}
