import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldWrapperComponent } from './label.component';
import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';

@NgModule({
  declarations: [
    FieldWrapperComponent
  ],
  exports: [
    FieldWrapperComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    TooltipModule,
  ]
})
export class LabelModule { }
