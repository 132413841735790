<div class="burger__container"
     [ngClass]="{'burger__container__opened' : displayMenu }"
     (click)="toggleMenu()">
  <app-icon icon="burger"
            class="burger__icon"
            size="large"
            [theme]="displayMenu ? 'normal' : 'invert'">
  </app-icon>
</div>

<div class="burger__menu" [ngClass]="{'menu-visible': displayMenu}">
  <ng-container *ngIf="displayMenu">
    <div *ngFor="let link of links; index as i"
         class="burger__item burger__link text-tiny m-t-small"
         (click)="navigate(link.link)"
         (touchstart)="toggleMenuAndAnimation($event, i)"
         (mousedown)="toggleMenuAndAnimation($event, i)">
      <span class="burger__link__text">{{ link.label }}</span>
      <span class="burger__item__span" [ngClass]="{
                'left-top': clickPositionX === 'left' && clickPositionY === 'top',
                'left-middle': clickPositionX === 'left' && clickPositionY === 'middle',
                'left-bottom': clickPositionX === 'left' && clickPositionY === 'bottom',
                'right-top': clickPositionX === 'right' && clickPositionY === 'top',
                'right-middle': clickPositionX === 'right' && clickPositionY === 'middle',
                'right-bottom': clickPositionX === 'right' && clickPositionY === 'bottom'
              }">
        </span>
    </div>
  </ng-container>
</div>
