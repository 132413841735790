import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectRandomImage'
})
export class SelectRandomImagePipe implements PipeTransform {
  transform<T>(images: T[], id: number): T {
    const index = id % images?.length;
    return Number.isNaN(index) ? null : images[index];
  }
}
