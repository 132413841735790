import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { TranslateModule } from '@kit/translate/translate.module';
import { ArticleModule } from '@kit/article/article.module';
import { AemRelatedArticlesComponent } from './related-articles.component';

@NgModule({
  declarations: [AemRelatedArticlesComponent],
  exports: [AemRelatedArticlesComponent],
  imports: [
    CommonModule,
    AemButtonModule,
    ArticleModule,
    TranslateModule,
  ]
})
export class AemRelatedArticlesModule { }
