import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SurveyBanner } from 'src/app/common/model/models';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-survey-banner',
  templateUrl: './survey-banner.component.html',
  styleUrls: ['./survey-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemSurveyBannerComponent extends AemBaseBlockComponent {
  constructor(
    @Inject(AEM_DATA) public override data: SurveyBanner,
  ) {
    super(data);
  }
}
