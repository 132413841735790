<section class="faq-collapsible-group">
  <div class="shell">
    <div class="faq-collapsible-group__container" appAccordionContainer>
      <app-accordion #accordion *ngFor="let data of items; let index = index" theme="faq" [isOpen]="index === 0" class="faq-collapsible-group__item">
        <div accordionTitle class="faq-collapsible-group__header">
          <h5 class="zero-margin h5 m-h2">{{ data.title }}</h5>
          <p 
            class="zero-margin t-normal m-t-normal faq-collapsible-group__question" 
            [class.open]="accordion.isExtended">{{ 'pages.FAQ.QUESTIONS' | translate: { count: data.items.length} }}</p>
        </div>
      
        <div class="faq-collapsible-group__content" appAccordionContainer>
          <app-accordion 
            size="child" 
            theme="faq" 
            class="faq-collapsible-group__child-item" 
            *ngFor="let item of data.items; let childIndex = index"
            [isOpen]="index === 0 && childIndex === 0">
            <ng-container accordionTitle>
              <h6 class="zero-margin h6 m-h4">{{ item.question }}</h6>
            </ng-container>

            <app-safe-html class="faq-collapsible-group__child-answer t-small m-t-normal" [content]="item.answer"></app-safe-html>
      
            <app-aem-button *ngIf="item.cta" [cta]="item.cta" size="m"></app-aem-button>
            <app-helpful *ngIf="!item.hideFeedback" [faqId]="item.uuid"></app-helpful>
          </app-accordion>
        </div>
      </app-accordion>
    </div>
  </div>
</section>
