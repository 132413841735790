import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FolderContent } from '@common/documents-vault/api-documents.interface';
import { Icon } from '@common/model/icon';

@Component({
  selector: 'app-document-vault-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentVaultFolderComponent {
  @Input() public folder: FolderContent;

  public get folderIcon(): Icon {
    const folderName = this.folder.name;

    return folderName === 'My documents' ? 'id-files' as Icon : folderName.split(' ').join('-').toLowerCase() as Icon;
  }

  public get folderNameTranslationKey(): string {
    const folderName = this.folder.name.replace(' ', '_').toUpperCase();

    return `pages.DOCUMENT_VAULT.FOLDER_NAME.${folderName}`;
  }
}
