<app-dialog-wrapper>
  <div dialogTitle class="m-h5">
    <app-icon icon="bell"
              theme="invert"
              size="large">
    </app-icon>
      {{ 'pages.MY_TRIPS.TRIP_NOTIFICATIONS_DIALOG.DIALOG_TITLE' | translate }}
  </div>
  <div dialogContent>
    <h3 class="h5 m-h3">{{ 'pages.MY_TRIPS.TRIP_NOTIFICATIONS_DIALOG.FLIGHT_DELAYS_TITLE' | translate }}</h3>
    <p class="t-normal m-t-small">{{ 'pages.MY_TRIPS.TRIP_NOTIFICATIONS_DIALOG.FLIGHT_DELAYS_CONTENT' | translate }}</p>

    <h3 class="h5 m-h3">{{ 'pages.MY_TRIPS.TRIP_NOTIFICATIONS_DIALOG.FLIGHT_NOTIFICATIONS_TITLE' | translate }}</h3>
    <p class="t-normal m-t-small">{{ 'pages.MY_TRIPS.TRIP_NOTIFICATIONS_DIALOG.FLIGHT_NOTIFICATIONS_CONTENT' | translate }}</p>

    <app-button id="action-go-to-notification"
                class="manage-button"
                type="big-width"
                icon="edit"
                [appAemRouterLink]="bootstrapService.link.accountNotificationSettings"
                (click)="close()">
      {{ 'pages.MY_TRIPS.TRIP_NOTIFICATIONS_DIALOG.DIALOG_ACTION' | translate }}
    </app-button>
  </div>
</app-dialog-wrapper>
