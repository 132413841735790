import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatedFieldValueComponent } from './updated-field-value.component';

@NgModule({
  declarations: [UpdatedFieldValueComponent],
  exports: [UpdatedFieldValueComponent],
  imports: [CommonModule]
})
export class UpdatedFieldValueModule {}
