import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { ButtonModule } from '@kit/button/button.module';
import { ContextMenuModule } from '@kit/context-menu/context-menu.module';
import { FieldErrorModule } from '@kit/field-error/field-error.module';
import { IconModule } from '@kit/icon/icon.module';
import { InputModule } from '@kit/input/input.module';
import { LabelModule } from '@kit/label/label.module';
import { AemCoTravelersPageComponent } from './co-travelers.component';
import { AemManageTravalersComponent } from './manage-travalers/manage-travalers.component';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';


@NgModule({
  declarations: [
    AemCoTravelersPageComponent,
    AemManageTravalersComponent,
  ],
  exports: [
    AemCoTravelersPageComponent,
    AemManageTravalersComponent,
  ],
  imports: [
    CommonModule,
    ContextMenuModule,
    IconModule,
    TranslateModule,
    ButtonModule,
    AemRouterModule,
    ReactiveFormsModule,
    InputModule,
    LabelModule,
    FieldErrorModule,
    SafeHtmlModule
  ],
})
export class AemCoTravelerModule { }
