import {
  CustomerCareCategory,
  CustomerCareCategoryDTO,
  CustomerCareRequests,
  CustomerCareSubcategories,
} from '@common/customer-care/customer-care.interface';
import { ContactUsFormSource } from './contact-us-form.interfaces';
import { languageName } from '@common/language/language.const';

export function mapCategoriesToContactForm(
  category: CustomerCareCategoryDTO
): CustomerCareCategory {
  const mappedCategory = {
    categoryCode: category.categoryCode,
    categoryId: category.categoryId,
    categoryName: category.categoryName,
    subcategories: [] as CustomerCareCategory[],
  };

  mappedCategory.subcategories = category.subcategories.map((subcategories: CustomerCareSubcategories) =>
    mapSubcategoriesToContactForm(subcategories, mappedCategory)
  );

  return mappedCategory;
}

export function mapSubcategoriesToContactForm(subcategories: CustomerCareSubcategories, parent: CustomerCareCategory): CustomerCareCategory {
  return {
    parent,
    categoryCode: subcategories.subcategoryCode,
    categoryDescription: subcategories.subcategoryDescription,
    categoryId: subcategories.subcategoryId,
    categoryName: subcategories.subcategoryName,
  };
}

export function mapContactFormToSource(
  customerRequest: ContactUsFormSource,
  countryCode: string,
  language: string,
): CustomerCareRequests {
  return {
    email: customerRequest.email,
    firstName: customerRequest.firstName,
    lastName: customerRequest.lastName,
    message: formatMessageText(customerRequest.message),
    category: customerRequest.subcategory.parent.categoryCode,
    categoryName: customerRequest.subcategory.parent.categoryName,
    subcategory: customerRequest.subcategory.categoryCode,
    subcategoryName: customerRequest.subcategory.categoryName,
    language: languageName[language],
    source: 102,
    country: countryCode,
  };
}

function formatMessageText(messageText: string): string {
  const sanitizedString = sanitizeString(messageText);
  const searchString = new RegExp('\n', 'g');

  return `<div>${sanitizedString.replace(searchString, '<br />')}</div>`;
}

function sanitizeString(text: string): string {
  return text.replace(/<[^>]*>/g, '');
}
