import { Component, ChangeDetectionStrategy, Inject, OnInit } from '@angular/core';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { MobileAppContentCards } from '@common/model/mobileAppContentCards';
import { LanguageService } from "@common/language/language.service";

@Component({
  selector: 'app-mobile-app-content-cards',
  templateUrl: './mobile-app-content-cards.component.html',
  styleUrls: ['./mobile-app-content-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileAppContentCardsComponent extends AemBaseBlockComponent implements OnInit {
  public language: string;

  public get appleStoreImagePath(): string {
    return `assets/img/app-store/${this.language}/app-store-badge-black.svg`;
  }

  public get googlePlayImagePath(): string {
    return `assets/img/google-play/${this.language}/google-play-badge-black.png`;
  }

  constructor(
    private languageService: LanguageService,
    @Inject(AEM_DATA) public override data: MobileAppContentCards,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.language = this.languageService.getCurrentLanguage();
  }
}
