import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';
import { isAbsoluteUrl } from 'src/app/kit/utils/router.utils';
import { IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';
import { WindowRef } from '../window-service/window.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard  {
  constructor(
    private router: Router,
    private readonly windowRef: WindowRef,
    @Inject(IS_SERVER_PLATFORM) private isServer: boolean,
    @Optional() @Inject(RESPONSE) private response: Response,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const { redirectStatus, redirectLocation } = route.data;

    if (this.isServer) {
      this.response.status(redirectStatus);
      this.response.setHeader('Location', redirectLocation)
      return false;
    }

    if (isAbsoluteUrl(redirectLocation)) {
      this.windowRef.nativeWindow.location = redirectLocation;
      return false;
    }

    return this.router.createUrlTree([redirectLocation]);
  }

}
