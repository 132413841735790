import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TradedoublerService } from "@common/tradedoubler/tradedoubler.service";

@Injectable({
  providedIn: 'root'
})
export class RouterTradedoublerResolver  {
  constructor(private readonly tradedoublerService: TradedoublerService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (route.queryParamMap.get('ac') || route.queryParamMap.get('affId') || route.queryParamMap.get('tduid')) {
      this.tradedoublerService.saveTradedoublerData(route);
    }

    return of(true);
  }
}
