import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DAY } from '../date/date.const';

export const PHONE_NUMBER_REGEX = /^[\+]?[\d]{7,50}$/;
export const USERNAME_REGEX = /^[A-Za-zÀ-ÖØ-öø-ÿ\\\-\/\.\&\'\s]*$/;
export const PROMO_CODE_REGEX = /^[A-Za-z0-9]+$/;
export const EMAIL_REGEX = /^([A-Z0-9!#$%&'*+/=?^_`{|}~-]{1}[.A-Z0-9!#$%&'*+/=?^_`{|}~-]{1,63}[A-Z0-9!#$%&'*+/=?^_`{|}~-]{1}|[A-Z0-9!#$%&'*+/=?^_`{|}~-]{1}[+.A-Z0-9!#$%&'*+/=?^_`{|}~-]{1}|[A-Z0-9!#$%&'*+/=?^_`{|}~-]{1})@([A-Z0-9]{1}[A-Z0-9.-]+[A-Z0-9]{1}|[A-Z0-9]{1,2})\.[A-Z]+$/;

export function rangeValidator(
  startDateControl: AbstractControl,
  endDateControl: AbstractControl,
  notSameDate?: boolean
): ValidatorFn {
  return (): ValidationErrors | null =>
    endDateControl.value && startDateControl.value &&
    (
      notSameDate
        ? startDateControl.value >= endDateControl.value
        : startDateControl.value > endDateControl.value
    )
      ? { range: true }
      : null;
}

export function rangeMoreThenValidator(
  startDateControl: AbstractControl,
  endDateControl: AbstractControl,
  days: number,
): ValidatorFn {
  return (): ValidationErrors | null =>
    endDateControl.value &&
    startDateControl.value &&
    (endDateControl.value.valueOf() - startDateControl.value.valueOf()) > (days * DAY)
      ? { range: true }
      : null;
}

function getDate(date: Date | string, time: string,): Date {
  const result: Date = date instanceof Date ? new Date(date.getTime()) : new Date(date);
  const [hour, minutes] = time.split(':');
  result.setHours(+hour, +minutes);

  return result;
}

export function rangeWithTimeValidator(
  startDateControl: AbstractControl,
  starTimeControl: AbstractControl,
  endDateControl: AbstractControl,
  endTimeControl: AbstractControl,
): ValidatorFn {
  return (): ValidationErrors | null => {
    if (
      startDateControl.value &&
      starTimeControl.value &&
      endDateControl.value &&
      endTimeControl.value &&
      getDate(startDateControl.value, starTimeControl.value) >= getDate(endDateControl.value, endTimeControl.value)
    ) {
      return { rangeTime: true }
    }

    return null;
  }
}

export function notBlankValidator(control: AbstractControl): ValidationErrors | null {
  return control?.value && !control.value.toString().trim()
    ? { notBlank: true }
    : null;
}

export function repeatPasswordGroupValidator(passwordControl: AbstractControl, repeatPasswordControl: AbstractControl): ValidatorFn {
  return (_control: AbstractControl) =>
    passwordControl?.value &&
    passwordControl.value !== repeatPasswordControl.value
      ? { repeatPass: true }
      : null;
}

export function minDateValidator(min: Date): ValidatorFn {
  return (control: AbstractControl) =>
    control?.value &&
    control.value < min
      ? { minDate: true }
      : null;
}

export function maxDateValidator(max: Date): ValidatorFn {
  return (control: AbstractControl) =>
    control?.value &&
    control.value > max
      ? { maxDate: true }
      : null;
}

export function moreThanCurrentDateValidator(control: AbstractControl): ValidationErrors {
  const currentDate = new Date();

  currentDate.setHours(0, 0, 0, 0);

  return control?.value && control.value.valueOf() <= currentDate.valueOf()
    ? { wrongDate: true }
    : null;
}

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  const controlValue: string = control.value;

  if (!controlValue) {
    return null;
  }

  const [localPart, domainPart] = controlValue.split('@');

  return EMAIL_REGEX.test(controlValue?.toUpperCase()) &&
    !controlValue?.includes('..') &&
    !controlValue?.includes(',,') &&
    localPart?.length < 65 &&
    domainPart?.length < 256 &&
    controlValue?.length < 321
      ? null
      : { email: true };
}
