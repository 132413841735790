import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { LanguageService } from "@common/language/language.service";
import { environment } from "../../../environments/environment";
import { AEM_DATA } from "@pages/dynamic/dynamic-render/dynamic-render.const";
import { AltairWidget } from "@common/model/altairWidget";
import { Language } from "@common/language/language.const";
import { BehaviorSubject } from "rxjs";

const AVAILABLE_LANGUAGES: string[] = [Language.EN, Language.FR, Language.DE, Language.NL, Language.PL];
const widgetPath: Record<string, string> = {
  'destination-explorer-widget': 'destination-explorer',
  'hospital-finder-widget': 'hospital-finder',
}

@Component({
  selector: 'app-altair-widget',
  templateUrl: './altair-widget.component.html',
  styleUrls: ['./altair-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AltairWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('componentContainerRef') componentContainerRef: ElementRef;
  @HostBinding('attr.id') get id() { return this.aemData.id || 'altair-widget'; }

  public componentTagName = '';
  public isAvailable$ = new BehaviorSubject<boolean>(true);

  private currentLanguage: string = this.languageService.getCurrentLanguage();

  constructor(
    private renderer: Renderer2,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(AEM_DATA) public aemData: AltairWidget,
  ) {
  }

  public ngOnInit(): void {
    if (!AVAILABLE_LANGUAGES.includes(this.currentLanguage)) {
      this.isAvailable$.next(false);

      return;
    }

    this.loadJsScript().onload = () => {
      this.renderComponentWhenDefined();
    }
  }

  public ngOnDestroy(): void {
    this.deleteScript();
    this.disableWidgetStyles(true);
  }

  private loadJsScript(): HTMLScriptElement {
    const script = this.renderer.createElement('script');

    script.type = 'text/javascript';
    script.id = 'altairWidget'
    script.src = this.getWidgetFilePath();

    this.renderer.appendChild(this.document.body, script);

    return script;
  }

  private renderComponentWhenDefined(): void {
    const language: string = this.currentLanguage === Language.PL ? Language.EN : this.currentLanguage;

    this.componentTagName = this.aemData.widgetName;
    this.disableWidgetStyles(false);

    customElements.whenDefined(this.componentTagName).then(() => {
      const element = this.renderer.createElement(this.componentTagName);

      this.renderer.setAttribute(element, 'language', language);
      this.renderer.setAttribute(element, 'country', this.languageService.countryCode);
      this.renderer.setAttribute(element, 'theme', 'allyz');
      this.renderer.setAttribute(element, 'config-url', environment.altairScriptConfig)
      this.renderer.appendChild(this.componentContainerRef.nativeElement, element);
    });
  }

  private disableWidgetStyles(status: boolean): void {
    // @ts-ignore
    [...this.document.styleSheets]
      .filter(styleSheet => styleSheet?.href && (styleSheet.href as string).includes('widgets'))
      .forEach((styleSheet) => styleSheet.disabled = status);
  }

  private deleteScript(): void {
    const widgetScript = this.document.getElementById('altairWidget');

    if (widgetScript) {
      this.renderer.removeChild(this.document.body, widgetScript);
    }
  }

  private getWidgetFilePath(): string {
    const widgetName = widgetPath[this.aemData.widgetName];

    return `${environment.altairScriptPath}/${widgetName}/index.js`;
  }
}
