<div class="trip-card_avatar"
     [appAemRouterLink]="bootstrapService.link.tripWallet"
     [aemRouterData]="{ tripId: data.tripId }">
  <img [src]="countryImagePath"
       (error)="onImageLoadError()"
       [appImgAlt]="data?.name">
</div>
<div class="trip-card_title-container"
     [appAemRouterLink]="bootstrapService.link.tripWallet"
     [aemRouterData]="{ tripId: data.tripId }">
  <div class="trip-card_title headline-5"
       [appTooltip]="data?.name"
       [tooltipOnlyExtraContent]="true">
    {{ data?.name }}
  </div>
</div>
<div class="trip-card_details">
  <div class="trip-card_details-date text-large">
    <app-icon icon="calendar" theme="inherit"></app-icon>

    {{ data | dateRange }}
  </div>

  <div class="trip-card_details-actions">
    <app-button id="action-trip-view-details"
                icon="eye"
                type="full-width"
                size="inherit"
                theme="bordered"
                [appAemRouterLink]="bootstrapService.link.tripWallet"
                [aemRouterData]="{ tripId: data.tripId }">
      {{ 'forms.TRIP.ACTIONS.VIEW_TRIP_IN_DETAILS' | translate }}
      <app-icon *ngIf="data.hasFlightsNotification"
                class="updates-icon"
                icon="info-fill"
                size="large"
                theme="inherit">
      </app-icon>
    </app-button>
    <app-button id="action-trip-delete"
                icon="thrash"
                type="full-width"
                size="inherit"
                (click)="deleteTrip(data.tripId)"
                theme="bordered">
      {{ 'forms.TRIP.ACTIONS.DELETE_TRIP' | translate }}
    </app-button>
    <app-button *ngIf="currentAndFutureTripCounter > 1"
                id="action-trip-merge"
                icon="merge"
                type="full-width"
                size="inherit"
                (click)="mergeTrip()"
                theme="bordered">
      {{ 'forms.TRIP.ACTIONS.MERGE_TRIP' | translate }}
    </app-button>
  </div>
</div>
<div class="trip-card_extra">
  <app-button id="action-notification"
              icon="bell"
              type="icon-button"
              size="s"
              theme="bordered"
              (click)="openNotificationsInfoDialog()">
  </app-button>
  <app-button *ngIf="showShieldIcon$ | async"
              id="action-alert"
              icon="shield-protected"
              type="icon-button"
              size="s"
              theme="bordered"
              (click)="openAlertInfoDialog()">
  </app-button>
</div>
