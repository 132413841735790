import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { Footer } from 'src/app/common/model/footer';

const NOT_ROUTE_LINK_REGEXP = /^(mailto|tel|http|https):/;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  public data$: Observable<Footer> = this.bootstrapService.footer$;
  public year = new Date().getFullYear().toString();

  constructor(private bootstrapService: BootstrapService) {}

  public isRoute(link: string): boolean {
    return !NOT_ROUTE_LINK_REGEXP.test(link);
  }
}
