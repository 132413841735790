import { DAY } from '../date/date.const';

export function dateToNativeDateInputFormat(date: Date): string {
  const isoDate = date.toISOString();
  const [nativeFormatDate] = isoDate.split('T');

  return nativeFormatDate;
}

export function dateToNativeDateWithTimeInputFormat(date: Date): string {
  const isoDate = date.toISOString();
  const [nativeFormatDate] = isoDate.split('Z');

  return nativeFormatDate;
}

export function isValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(date as any);
}

export function dateToEndDay(date: string | number | Date): Date {
  const endDayDate = new Date(date);
  endDayDate.setUTCHours(23, 59, 59, 999);

  return endDayDate;
}

export function getTomorrowDate(): Date {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate()+1);

  return tomorrow;
}

export function dateToStartDay(date: string | number | Date): Date {
  const startDayDate = new Date(date);
  startDayDate.setUTCHours(0, 0, 0, 0);

  return startDayDate;
}

export function applyDaysOffset(date: string | number | Date, offset: number): Date {
  const updated = new Date(date);
  updated.setDate(updated.getDate() + offset);

  return updated;
}

export function addYearsToDate(date: Date, years: number): Date {
  return new Date(new Date().setFullYear(new Date().getFullYear() + years));
}

export function endDatePassed(endDate: string): boolean {
  return dateToEndDay(endDate) < new Date();
}

export function getAge(dateString: string | number | Date): number {
  const today = new Date();
  const birthDate = new Date(dateString);
  const m = today.getMonth() - birthDate.getMonth();
  let age = today.getFullYear() - birthDate.getFullYear();

  return m < 0 || (m === 0 && today.getDate() < birthDate.getDate())
   ? age - 1
   : age;
}

export function getDate(date: Date): string {
  if (!date) {
    return null;
  }

  return date.toISOString().split('T')[0];
}

export function getRangeInDays(startDate: Date, endDate: Date): number {
  return Math.ceil((endDate.valueOf() - startDate.valueOf()) / DAY)
}
