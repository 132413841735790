import { Component, HostBinding, Inject } from '@angular/core';
import { Fragment } from '@common/model/fragment';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';

@Component({
  template: '',
})
export abstract class AemFragmentComponent {
  @HostBinding('attr.id')
  private elementId: string = this.data.id;

  constructor(
    @Inject(AEM_DATA) public data: Fragment,
  ) { }
}
