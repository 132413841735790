<section class="content-video">
  <div class="shell">
    <div class="content-video__grid" [ngClass]="'content-video__grid_position-' + data.videoAlignment">
      <div class="content-video__column">
        <div class="content-video__header">
          <h4 class="content-video__title h4 m-h2">{{ data.title }}</h4>
        </div>
        <app-safe-html class="t-small m-t-normal" [content]="data.text"></app-safe-html>

        <div class="content-video__button" *ngIf="data.ctas">
          <app-aem-button
            *ngFor="let cta of data.ctas"
            [cta]="cta"
            size="m"
            type="big-width"
          ></app-aem-button>
        </div>
        <app-safe-html class="t-tiny m-t-small" *ngIf="data.bottomText" [content]="data.bottomText"></app-safe-html>
      </div>
      <div class="content-video__illustration" *ngIf="videoComponent">
        <ng-container *ngComponentOutlet="videoComponent.component; injector: videoComponent.injector"></ng-container>
      </div>
    </div>
  </div>
</section>
