<app-trip-hero-block (goBack)="goBack()">
  <div class="add-activity-element_form" [formGroup]="form">
    <app-label [label]="'forms.ACTIVITY.LABELS.NAME' | translate" [required]="true">
      <app-input id="activity-name" [placeholder]="'forms.ACTIVITY.PLACEHOLDERS.NAME' | translate" formControlName="name" [maxLength]="35">
      </app-input>
      <app-field-error controlName="name"></app-field-error>
    </app-label>

    <app-label [label]="'forms.ACTIVITY.LABELS.LOCATION' | translate">
      <app-input id="activity-location" [placeholder]="'forms.ACTIVITY.PLACEHOLDERS.LOCATION' | translate" formControlName="location" [maxLength]="35">
      </app-input>
      <app-field-error controlName="location"></app-field-error>
    </app-label>

    <app-label [label]="'forms.ACTIVITY.LABELS.DATE' | translate" [required]="true">
      <app-date-picker id="activity-date" formControlName="start"></app-date-picker>
      <app-field-error controlName="start"></app-field-error>
    </app-label>

    <app-label [label]="'forms.ACTIVITY.LABELS.TIME' | translate">
      <app-time-picker id="activity-time" formControlName="hour"></app-time-picker>
      <app-field-error controlName="hour"></app-field-error>
    </app-label>
  
    <app-label [label]="'forms.ACTIVITY.LABELS.CATEGORY' | translate" [required]="true">
      <app-select-with-child id="activity-category" [placeholder]="'forms.ACTIVITY.PLACEHOLDERS.CATEGORY' | translate"
                  formControlName="activityCategory"
                  viewKey="activityName"
                  selectKey="activityCategoryId"
                  optionsKey="subcategories"
                  [options]="optionsCategory$ | async">
      </app-select-with-child>
      <app-field-error controlName="activityCategory"></app-field-error>
    </app-label>

    <app-label [label]="'forms.ACTIVITY.LABELS.BOOKING_NUMBER' | translate">
      <app-input id="activity-booking-number" [placeholder]="'forms.ACTIVITY.PLACEHOLDERS.BOOKING_NUMBER' | translate" formControlName="bookingReference" [maxLength]="15">
      </app-input>
      <app-field-error controlName="bookingReference"></app-field-error>
    </app-label>
    <app-field-error [control]="form"></app-field-error>
  
    <button id="action-activity-save" class="button" (click)="onCreate()">
      {{ 'forms.ACTIVITY.ACTIONS.SAVE_MY_ACTIVITY_DETAILS' | translate }}
    </button>
  </div>
</app-trip-hero-block>