import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { Observable } from 'rxjs';
import { LoungeVouchersService } from './lounge-vouchers.service';
import { RegroupedLoungeVoucher } from './lounge-vouchers.interface';

@Component({
  selector: 'app-lounge-vouchers',
  templateUrl: './lounge-vouchers.component.html',
  styleUrls: ['./lounge-vouchers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemLoungeVouchersComponent implements OnInit {
  public loungeVouchers$: Observable<RegroupedLoungeVoucher[]> = this.loungeVouchersService.loungeVouchers$;

  constructor(
    public readonly bootstrapService: BootstrapService,
    private readonly loungeVouchersService: LoungeVouchersService) {
    }

  public ngOnInit(): void {
    this.loungeVouchersService.getUserLoungeVouchers();
  }

  public trackBy(index: number, item: RegroupedLoungeVoucher): string {
    return item.travelerName;
  }

  public viewLounges(): void {
    window.open('https://loungefinder.loungekey.com/Pass', '_blank');
  }
}
