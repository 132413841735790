import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { RootPaths } from './app.const';
import { NotFoundComponent } from '@kit/not-found/not-found.component';
import { NotFoundModule } from '@kit/not-found/not-found.module';
import { LangGuard } from '@kit/translate/lang.guard';
import { ForbiddenComponent } from '@kit/forbidden/forbidden.component';
import { ForbiddenModule } from '@kit/forbidden/forbidden.module';

export const mainRoutes: Routes = [
  {
    path: RootPaths.Admin,
    loadChildren: () => import('./pages/admin/admin.module')
      .then(m => m.AdminModule),
  }
]

export const notFoundRoute = [
  {
    path: RootPaths.NotFound,
    component: NotFoundComponent,
  },
  {
    path: RootPaths.Forbidden,
    component: ForbiddenComponent,
  },
  {
    path: '**',
    redirectTo: RootPaths.NotFound,
  }
];

export const langRoute: Route = {
  path: ':lang',
  canActivate: [LangGuard],
  children: [],
};

@NgModule({
  imports: [
    RouterModule.forRoot([...mainRoutes],
    {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'disabled',
    }),
    NotFoundModule,
    ForbiddenModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
