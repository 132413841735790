import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WoosmapSearchComponent } from "@kit/woosmap-search/woosmap-search.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "@kit/button/button.module";
import { TuiLetModule } from "@taiga-ui/cdk";
import { IconModule } from "@kit/icon/icon.module";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [WoosmapSearchComponent],
  exports: [WoosmapSearchComponent],
  imports: [CommonModule, ReactiveFormsModule, ButtonModule, TuiLetModule, IconModule, TranslateModule],
})
export class WoosmapSearchModule { }
