import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { AemButtonModule } from '../aem-button/aem-button.module';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { AemHeroBlockComponent } from './aem-hero-block.component';

@NgModule({
  declarations: [AemHeroBlockComponent],
  exports: [AemHeroBlockComponent],
  imports: [
    CommonModule,
    BreadcrumbsModule,
    AemButtonModule,
    AemRouterModule,
    SafeHtmlModule,
    ImgAltDirectiveModule,
  ]
})
export class AemHeroBlockModule { }
