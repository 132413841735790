import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, shareReplay } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Country } from '@pages/trip/trip.interfaces';
import { LanguageService } from '../language/language.service';
import { ApiInformationService } from './api-information.service';
import { matchDistinctionStatus } from './destination-status.const';
import { DestinationResponse } from './destination.interface';
import { EmergencyNumber, EmergencyNumberDto } from './emengency-number.interface';

@Injectable({
  providedIn: 'root'
})
export class InformationService implements ApiInformationService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
    private readonly languageService: LanguageService,
    private readonly translationService: TranslateService
  ) { }

  public getCountries(withEmergencyNumbers = false): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.baseUrl}/countries`, { params: { withEmergencyNumbers } }).pipe(
      map(countries =>  this.getCountriesTranslation(countries)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  public getLocalCountries(marketScope: string): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.baseUrl}/countries/${marketScope}`).pipe(
      map(countries =>  this.getCountriesTranslation(countries)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  public getDestination(destinationCountryCode: string, departureCountryCode: string): Observable<DestinationResponse> {
    const headers = new HttpHeaders().set('Accept-Language', this.languageService.languageCode.toLowerCase());

    return this.http.get<DestinationResponse>(`${this.baseUrl}/destination-explorer`, {
      headers,
      params: {
        destinationCountryCode: destinationCountryCode,
        originCountryCode: departureCountryCode,
      }
    }).pipe(
      map(distinction => ({
        ...distinction,
        countryCovidAndQuarantine: distinction.countryCovidAndQuarantine?.map(item => matchDistinctionStatus(item)),
        countryEntryRequirements: distinction.countryEntryRequirements?.map(item => matchDistinctionStatus(item)),
        whenYouInCountry: {
          ...distinction.whenYouInCountry,
          airline: matchDistinctionStatus(distinction.whenYouInCountry?.airline),
          curfew: matchDistinctionStatus(distinction.whenYouInCountry?.curfew),
          careService: matchDistinctionStatus(distinction.whenYouInCountry?.careService),
          internalTransit: matchDistinctionStatus(distinction.whenYouInCountry?.internalTransit),
          nonEssentialStore: matchDistinctionStatus(distinction.whenYouInCountry?.nonEssentialStore),
          other: matchDistinctionStatus(distinction.whenYouInCountry?.other),
          thingsToDo: distinction.whenYouInCountry?.thingsToDo?.map(item => matchDistinctionStatus(item))
        }
      }))
    );
  }

  public getEmergencyNumber(countryCode: string): Observable<EmergencyNumber> {
    return this.http.get<EmergencyNumberDto[]>(`${this.baseUrl}/emergency-number/${countryCode}`)
      .pipe(map(value => value.reduce((acc, emergencyNumber) => {
        acc[emergencyNumber.type] = emergencyNumber.number;
        return acc;
      }, <EmergencyNumber>{})));
  }

  private getCountriesTranslation(countries: Country[]): Country[] {
    return countries.map(country => ({
      ...country,
      name: this.translationService.instant(`countries.${country.code}`)
    })).sort((a, b) => a.name.localeCompare(b.name));
  }
}
