import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiLetModule } from '@taiga-ui/cdk';
import { AccordionModule } from '../accordion/accordtion.module';
import { AemButtonModule } from '../aem-button/aem-button.module';
import { AemHeroBlockModule } from '../aem-hero-block/aem-hero-block.module';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { ButtonModule } from '../button/button.module';
import { FieldErrorModule } from '../field-error/field-error.module';
import { IconModule } from '../icon/icon.module';
import { LabelModule } from '../label/label.module';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { SearchSelectModule } from '../search-select/search-select.module';
import { AemDestinationHeroComponent } from './destination-hero.component';
import { DestinationsComponent } from './destinations/destinations.component';
import { DateModule } from '../date/date.module';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownPipeModule } from '../markdown-pipe/markdown.module';

@NgModule({
  declarations: [AemDestinationHeroComponent, DestinationsComponent],
  exports: [AemDestinationHeroComponent],
  imports: [
    CommonModule,
    BreadcrumbsModule,
    AemHeroBlockModule,
    LabelModule,
    SearchSelectModule,
    AemButtonModule,
    ButtonModule,
    FieldErrorModule,
    ReactiveFormsModule,
    AccordionModule,
    IconModule,
    SafeHtmlModule,
    TuiLetModule,
    DateModule,
    TranslateModule,
    MarkdownPipeModule,
  ]
})
export class AemDestinationHeroModule { }
