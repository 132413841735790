import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ImageBlock } from 'src/app/common/model/models';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-image-block',
  templateUrl: './image-block.component.html',
  styleUrls: ['./image-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemImageBlockComponent extends AemBaseBlockComponent {
  constructor(
    @Inject(AEM_DATA) public override data: ImageBlock,
  ) {
    super(data);
  }
}
