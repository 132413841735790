<div class="shell">
  <div class="activity_list">
    <ng-container *ngFor="let cta of data.ctas">
      <ng-container *ngTemplateOutlet="cta._type !== 'CTALoungeVouchersLink' ? button: vouchers; context: {$implicit: cta}">
      </ng-container>
    </ng-container>
    <app-tag *ngIf="isAdminButtonVisible$ | async"
             icon="door"
             content="Admin dashboard"
             theme="purple"
             routerLink="admin">
    </app-tag>
  </div>
</div>

<ng-template #button let-cta>
  <app-aem-button type="tag"
                  size="l"
                  [cta]="cta">
  </app-aem-button>
</ng-template>

<ng-template #vouchers let-cta>
  <app-tag [icon]="cta.icon"
           [content]="cta.label"
           [theme]="cta.colour"
           [routerLink]="cta.link"
           [counter]="(loungeVouchers$ | async)?.length">
  </app-tag>
</ng-template>
