import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@kit/button/button.module';
import { DocumentButtonModule } from '@kit/document-button/document-button.module';
import { AemFileDownloadsComponent } from './file-downloads.component';

@NgModule({
  declarations: [AemFileDownloadsComponent],
  exports: [AemFileDownloadsComponent],
  imports: [
    CommonModule,
    ButtonModule,
    DocumentButtonModule,
  ]
})
export class AemFileDownloadsModule { }
