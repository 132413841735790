import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Column } from 'src/app/common/model/models';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { DynamicContent } from '@pages/dynamic/dynamic-render/dynamic-render.interface';
import { DynamicRenderService } from '@pages/dynamic/dynamic-render/dynamic-render.service';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';

@Component({
  selector: 'app-column',
  templateUrl: './column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemColumnComponent extends AemFragmentComponent {
  contents: DynamicContent[] = this.data.container.map(item => this.dynamicRenderService.getComponent(item));

  constructor(
    @Inject(AEM_DATA) public override data: Column,
    private dynamicRenderService: DynamicRenderService,
  ) {
    super(data);
  }
}
