import { Component, ChangeDetectionStrategy, Input, forwardRef, Injector, OnInit, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioButtonComponent),
    multi: true
  }],
})
export class RadioButtonComponent implements ControlValueAccessor, OnInit {
  @Input() inputId: string;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() value: any;
  @Input() required: string;

  private control: NgControl | null;

  get controlName(): string | null {
    return this.control?.name?.toString() ?? null;
  }

  get computedName(): string | null {
    return this.name || this.controlName || null;
  }

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
     this.control = this.injector.get(NgControl);
  }

  public onChange = (value: string) => {};
  public onTouched = () => {};

  onInputClick() {
    this.onTouched();
  }

  onInputChange() {
    this.onChange(this.value);
    this.onTouched();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.checked = value === this.value;
    this.cdr.markForCheck();
  }
}
