import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, Observable, Subject, switchMap } from 'rxjs';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { EmergencyNumber, EmergencyNumberType } from '@common/information/emengency-number.interface';
import { ApiInformationService } from '@common/information/api-information.service';
import { EmergencyNumbersHero } from '@common/model/emergencyNumbersHero';
import { AemFragmentComponent } from '../aem-base-block/aem-fragment';
import { IS_BROWSER_PLATFORM } from '../utils/ssr.utils';
import { Country } from '@pages/trip/trip.interfaces';

@Component({
  selector: 'app-emergency-number-hero',
  templateUrl: './emergency-number.component.html',
  styleUrls: [
    '../aem-hero-block/aem-hero-block.component.scss',
    './emergency-number.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemEmergencyNumbersHeroComponent extends AemFragmentComponent implements OnInit{
  public countries$: Observable<Country[]>;
  public emergencyNumber$: Observable<EmergencyNumber>;
  public countryCodeForm = new FormControl(null);
  public readonly EmergencyNumberType = EmergencyNumberType;

  private select$ = new Subject<string>();

  constructor(
    @Inject(AEM_DATA) public override data: EmergencyNumbersHero,
    @Inject(IS_BROWSER_PLATFORM) private isBrowser: boolean,
    private informationService: ApiInformationService,
  ) {
    super(data);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.countries$ = this.informationService.getCountries(true);
    }

    this.emergencyNumber$ = this.select$.pipe(
      distinctUntilChanged(),
      switchMap(countryCode => this.informationService.getEmergencyNumber(countryCode))
    );
  }

  public select(): void {
    if (this.countryCodeForm.value) {
     this.select$.next(this.countryCodeForm.value);
    }
  }
}
