<div class="accordion" [ngClass]="['accordion__size-'+ size, 'accordion__theme-' + theme]">
  <div class="accordion__header"
       [class.accordion_open]="isExtended"
       (click)="toggle(!isExtended)">
    <div class="accordion__header_content">
      <ng-content select="[accordionTitle]"></ng-content>
    </div>
    <div class="toggle-panel">
      <ng-content select="[accordionTitleRight]"></ng-content>
      <app-button [theme]="getToggleTheme()"
                  class="accordion__header_toggle"
                  icon="chevron-down"
                  type="icon-button"
                  size="inherit">
      </app-button>
    </div>
  </div>
  <div appAnimateHeight
       class="accordion__container"
       [class.expanded]="isExtended"
       [appAnimateHeightExpanded]="isExtended">
    <div class="accordion__content">
      <ng-content select=":not([accordionContent])"></ng-content>
    </div>
  </div>
</div>
