import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { dateToEndDay } from 'src/app/kit/utils/date.utils';
import { environment } from 'src/environments/environment';
import { CreateTripDto, TripDetails, TripIdentificator, TripsList } from './trip.interfaces';

@Injectable({
  providedIn: 'root'
})
export class TripService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
  ) {}

  public createTrip(dto: CreateTripDto): Observable<TripIdentificator> {
    return this.http.post<TripIdentificator>(`${this.baseUrl}/trips`, dto);
  }

  public updateTrip(tripId: number, dto: CreateTripDto): Observable<number> {
    return this.http.put<number>(`${this.baseUrl}/trips/${tripId}`, dto);
  }

  public deleteTrip(tripId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/trips/${tripId}`);
  }

  public getTrips(): Observable<TripsList> {
    return this.http.get<TripDetails[]>(`${this.baseUrl}/trips`).pipe(
      map(trips => {
        const separateTrips = trips.reduce((acc, trip) => {
          if (new Date(trip.startDate) <= new Date() && dateToEndDay(trip.endDate) > new Date()) {
            acc.currentTrips.push(trip);
          } else if (new Date(trip.startDate) > new Date()) {
            acc.upcomingTrips.push(trip);
          } else {
            acc.pastTrips.push(trip);
          }

          return acc;
        }, { currentTrips: [], upcomingTrips: [], pastTrips: [] });

        separateTrips.currentTrips.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate));
        separateTrips.upcomingTrips.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate));
        separateTrips.pastTrips.sort((a, b) => Date.parse(b.startDate) - Date.parse(a.startDate));

        return separateTrips;
      })
    );
  }

  public getTripById(selectedId: string): Observable<TripDetails> {
    return this.http.get<TripDetails>(`${this.baseUrl}/trips/${selectedId}`);
  }

  public mergeTrips(tripsId: number[]): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(`${this.baseUrl}/trips/merge`, tripsId);
  }
}
