import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule } from '@kit/accordion/accordtion.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { ButtonModule } from '@kit/button/button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { TranslateModule } from '@kit/translate/translate.module';
import { AemFaqComponent } from './faq-block/faq-block.component';
import { AemFaqCollapsibleComponent } from './faq-collapsible-block/faq-collapsible.component';
import { AemFaqCollapsibleGroupComponent } from './faq-collapsible-group/faq-collapsible-group.component';
import { FaqHelpfulComponent } from './helpful/helpful.component';
import { AemFaqThematicsComponent } from './faq-thematics/faq-thematics.component';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { IconModule } from '@kit/icon/icon.module';
import { AemFaqFeedbackComponent } from './helpful/aem-feedback.component';

@NgModule({
  declarations: [
    AemFaqComponent,
    AemFaqCollapsibleComponent,
    AemFaqCollapsibleGroupComponent,
    FaqHelpfulComponent,
    AemFaqThematicsComponent,
    AemFaqFeedbackComponent,
  ],
  imports: [
    CommonModule,
    AccordionModule,
    SafeHtmlModule,
    ButtonModule,
    AemButtonModule,
    TranslateModule,
    AemRouterModule,
    IconModule,
  ]
})
export class AemFaqModule { }
