import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CTABlock } from 'src/app/common/model/models';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-cta-block',
  templateUrl: './cta-block.component.html',
  styleUrls: ['./cta-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemCtaBlockComponent extends AemBaseBlockComponent implements OnInit {
  public ctas: any;

  constructor(@Inject(AEM_DATA) public override data: CTABlock) {
    super(data);
  }

  public ngOnInit(): void {
    this.ctas = Array.isArray(this.data.cta) ? this.data.cta : [this.data.cta];
  }
}
