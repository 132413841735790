<div class="field-error text-small" *ngIf="showMessage && !isInBlock">
  <app-safe-html [content]="errorMessage | translate"></app-safe-html>
</div>
<div *ngIf="showMessage && isInBlock && errorMessage" class="block-error text-normal">
  <app-icon icon="round-warning"
            theme="warning"
            size="x-large">
  </app-icon>
  <app-safe-html [content]="errorMessage | translate"></app-safe-html>
</div>
