import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from "@common/user/user.service";
import { DialogService } from '@kit/dialog/dialog.service';
import { AirDoctorDialogComponent } from '@kit/aem-air-doctor-hero/air-doctor-dialog/air-doctor-dialog.component';
import { AirDoctorDialogData } from '@kit/aem-air-doctor-hero/air-doctor-dialog/air-doctor-dialog.interface';
import { Market } from "@common/language/language.const";
import { AirDoctorService } from '@kit/aem-air-doctor-hero/air-doctor.service';

@Component({
  selector: 'app-healthcare-professional',
  templateUrl: './healthcare-professional-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthcareProfessionalActionComponent {
  public link: string;

  constructor(
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    private readonly airDoctorService: AirDoctorService,
    private readonly injector: Injector,
  ) { }

  public openDialog(): void {
    if (this.userService.location === Market.DE) {
      this.airDoctorService.resolveLinks$().subscribe(link => {
        const data: AirDoctorDialogData = {
          link: link,
          service: 'Telemedizin'
        }

      this.dialogService.open(AirDoctorDialogComponent, data, this.injector);
      });

      return;
    }

    this.link = (<any>environment.teleconsultationPortal)[this.userService.location?.toLowerCase()] || '#';
    window.open(this.link, '_blank');
  }
}
