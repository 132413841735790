import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BootstrapService } from "@common/bootstrap/bootstrap.service";
import { catchError, map, Observable, takeUntil } from "rxjs";
import { TripDetails, TripsList } from "@pages/trip/trip.interfaces";
import { AemFragmentComponent } from "@kit/aem-base-block/aem-fragment";
import { AEM_DATA } from "@pages/dynamic/dynamic-render/dynamic-render.const";
import { TripHero } from "@common/model/tripHero";
import { MergeTrip } from "@common/model/mergeTrip";
import { TripService } from "@pages/trip/trip.service";
import { TuiDestroyService } from "@taiga-ui/cdk";
import { HttpErrorResponse } from '@angular/common/http';
import { ServerErrorsValidationService } from '@kit/server-errors/server-errors-validation.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@kit/dialog/dialog.service';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { AnalyticsService } from '@common/analytics/analytics.service';

@Component({
  selector: 'app-merge-trip',
  templateUrl: './merge-trip.component.html',
  styleUrls: ['./merge-trip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService, ServerErrorsValidationService],
})
export class MergeTripComponent extends AemFragmentComponent implements OnInit {
  public trips$: Observable<TripDetails[]>;

  private selectedTrips: number[] = [];

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly bootstrapService: BootstrapService,
    private readonly tripService: TripService,
    private readonly destroy$: TuiDestroyService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly validationService: ServerErrorsValidationService,
    private readonly analyticsService: AnalyticsService,
    @Inject(AEM_DATA) public override data: TripHero & { component: MergeTrip }
  ) {
    super(data)
  }

  public ngOnInit(): void {
    this.trips$ = this.getTrips$();
    this.checkTripsAmount();
  }

  public back(): void {
    this.router.navigateByUrl(this.bootstrapService.link.trips);
  }

  public getTrips$(): Observable<TripDetails[]> {
    return this.tripService.getTrips()
      .pipe(
        map((list: TripsList) => [...list.upcomingTrips, ...list.currentTrips]),
        map((list: TripDetails[]) => list.sort((a: TripDetails, b: TripDetails) =>
          Date.parse(a.startDate) - Date.parse(b.startDate))),
      )
  }

  public checkBoxClick(event: Event, tripId: number): void {
    event.stopPropagation();

    const isIncluded = !!this.selectedTrips.find((element: number) => element === tripId);

    if (isIncluded) {
      this.selectedTrips = this.selectedTrips.filter((element: number) => element !== tripId);

      return;
    }

    this.selectedTrips.push(tripId);
  }

  public mergeTrips(): void {
    if (this.selectedTrips.length >= 2) {
      this.tripService.mergeTrips(this.selectedTrips)
        .pipe(
          catchError(err => this.handleServerError$(err)),
          takeUntil(this.destroy$)
        )
        .subscribe(() => this.router.navigateByUrl(this.bootstrapService.link.mergeTripsSuccessfulPage));
    }
  }

  private checkTripsAmount(): void {
    this.trips$.pipe(takeUntil(this.destroy$))
    .subscribe((trips) => {
      if(trips.length <= 1) {
        this.back();
      }
    })
  }

  private handleServerError$(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    if (err.status === 400) {
      this.showErrorPopUp();
    }

    return this.validationService.handleServerError(err?.error);
  }

  private showErrorPopUp(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      info: this.translateService.instant('errors.CEAZ138'),
      confirmTitle: 'OK',
    });
  }

}
