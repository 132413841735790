<app-trip-hero-block (goBack)="goBack()">
  <div class="trips">
    <div class="h4 m-h2 trips_title">{{ 'pages.MERGE_TRIPS.SUCCESS.VIEW_YOUR_TRIPS' | translate }}</div>
    <div *ngFor="let trip of trips$ | async" class="trips_items">
      <div class="trip">
        <a [appAemRouterLink]="bootstrapService.link.tripWallet"
           [aemRouterData]="{ tripId: trip.tripId }"
           class="trip_card">
          <div class="trip_details">
            <div class="h6 m-h4">{{ trip.name }}</div>
            <div class="t-small m-h5">
              {{ trip.destinationPoints.length }} {{ 'pages.MERGE_TRIPS.SUCCESS.DESTINATIONS' | translate }}
              - {{ trip.startDate | date: 'MMM dd'}} - {{ trip.endDate |
              date: 'MMM dd, yyyy'}}
            </div>
          </div>
          <app-icon size="large"
                    icon="chevron-right"
                    theme="lighten"
                    class="trip_icon">
          </app-icon>
        </a>
      </div>
    </div>
    <app-button (click)="goBackToMyTrips()"
                id="merge-trip-action-back"
                type="full-width"
                size="l"
                theme="black"
                class="trips_button">
      {{ 'pages.MERGE_TRIPS.SUCCESS.BACK_TO_TRIPS' | translate }}
    </app-button>
  </div>
</app-trip-hero-block>

