<div class="travel-planner__column"
  *tuiLet="activityConfiguration[activity.activitySubcategory?.activityCode] || defaultActivityConfiguration as activityData">
  <div class="travel-planner__row">
    <div class="travel-planner__detail">
      <div class="travel-planner__cart">
        <div class="travel-planner__detail-info">
          <h5 class="travel-planner__title" *ngIf="activity.activitySubcategory">
            {{ 'forms.ACTIVITY_CATEGORY_REQUEST.ACTIVITY_SUBCATEGORY.' + activity.activitySubcategory.activityCode |
            translate }}
          </h5>
          <div class="travel-planner__text_break-all travel-planner__text text-large">
            <app-icon theme="inherit" size="large" [icon]="activityData?.icon || 'activities'"
              class="travel-planner__icon">
            </app-icon>
            {{ activity.name }}
          </div>
          <div class="travel-planner__text_break-all travel-planner__text text-large" *ngIf="activity.location">
            {{ activity.location }}
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="travel-planner__row travel-planner__row-center travel-planner__row-details">
    <ng-container *ngIf="activity.bookingReference">
      <p class="travel-planner__text-green travel-planner__text text-large">
        {{ 'forms.TRIP_PLANNER.LABELS.STATUS_CONFIRMED_BOOKING' | translate }}
      </p>
      <p class="travel-planner__text text-normal">
        {{ 'forms.TRIP_PLANNER.LABELS.RESERVATION_NUMBER' | translate }}
        <b>{{ activity.bookingReference }}</b>
      </p>
    </ng-container>
    <p class="travel-planner__text text-normal">
      {{ 'forms.TRIP_PLANNER.LABELS.CHECK_IN_DATE' | translate }}
      <b>{{ activity.startDate | dateFormat: 'fullSpelled' }}</b>
    </p>
    <p class="travel-planner__text text-normal" *ngIf="activity.startTime">
      {{ 'forms.TRIP_PLANNER.LABELS.CHECK_IN_TIME' | translate }}
      <b>{{ activity.startTime | dateFormat: 'time' }}</b>
    </p>
  </div>
</div>
