import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { WindowRef } from '@common/window-service/window.service';

declare global {
  interface Window {
    OneTrust?: { ToggleInfoDisplay: () => void },
  }
}

@Component({
  selector: 'app-onetrust-cookies-settings',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneTrustCookiesSettingsComponent {
  constructor(private windowRef: WindowRef) { }

  @HostListener('click')
  onClick() {
   this.windowRef.nativeWindow.OneTrust?.ToggleInfoDisplay();
  }
}
