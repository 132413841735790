import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, ViewChild } from '@angular/core';
import { SEOBlock } from 'src/app/common/model/sEOBlock';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '../aem-base-block/aem-base-block';

@Component({
  selector: 'app-seo-block',
  templateUrl: './seo-block.component.html',
  styleUrls: ['./seo-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeoBlockComponent extends AemBaseBlockComponent implements AfterViewInit {
  @ViewChild('content', { read: ElementRef }) content: ElementRef<HTMLElement>;
  showAll = false;

  constructor(
    @Inject(AEM_DATA) public override data: SEOBlock,
    private cdr: ChangeDetectorRef,
  ) {
    super(data);
  }

  ngAfterViewInit(): void {
    if (this.content.nativeElement.clientHeight >= this.content.nativeElement.scrollHeight) {
      this.showAll = true;
      this.cdr.detectChanges();
    }
  }

  showMore() {
    this.showAll = true;
  }
}
