import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AemRouterModule } from 'src/app/common/routing/aem-router.module';
import { AemButtonModule } from '../aem-button/aem-button.module';
import { IllustrationModule } from '../illustration/illustration.module';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { ContentAndShapedImageComponent } from './content-and-shaped-image.component';

@NgModule({
  declarations: [ContentAndShapedImageComponent],
  exports: [ContentAndShapedImageComponent],
  imports: [
    CommonModule,
    IllustrationModule,
    AemButtonModule,
    AemRouterModule,
    SafeHtmlModule,
  ]
})
export class ContentAndShapedImageModule { }
