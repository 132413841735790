import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { Information } from 'src/app/common/model/information';
import { TripHero } from 'src/app/common/model/tripHero';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';

@Component({
  selector: 'app-co-traveler',
  templateUrl: './co-traveler.component.html',
  styleUrls: ['./co-traveler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoTravelerComponent extends AemFragmentComponent {
  private readonly tripId = this.route.firstChild.snapshot.params.tripId;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly bootstrapService: BootstrapService,
    @Inject(AEM_DATA) public override data: TripHero & { component: Information }
  ) {
    super(data);
  }

  public back(): void {
    this.router.navigateByUrl(setRouterData(this.bootstrapService.link.existingFlightsPage, { tripId: this.tripId }));
  }
}
