<app-trip-hero-block (goBack)="goBack()">
  <div class="add-trip-element">
    <div class="add-trip-element__list">
      <div *ngFor="let cta of aemData.component.ctas"
           [appAemRouterLink]="getLink(cta)"
           [aemRouterData]="{ tripId }"
           [aemRouterQueryParams]="isNewTrip ? { newTrip: true } : null"
           [attr.id]="cta.id"
           class="add-trip-element__list-item">
        <app-icon class="add-trip-element__list-icon" [icon]="cta.icon" theme="inherit" size="large"></app-icon>
        <span class="add-trip-element__list-item-text">{{ cta.label }}</span>
        <app-icon class="add-trip-element__list-arrow" icon="arrow-right" size="large" theme="inherit"></app-icon>
      </div>
    </div>
    <app-button (click)="goBack()"
                class="add-trip-element__go-back"
                type="full-width"
                size="m">
      {{'forms.TRIP.ACTIONS.COMPLETE_TRIP' | translate}}
    </app-button>
  </div>
</app-trip-hero-block>
