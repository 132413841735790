<section class="related-articles" *ngIf="relatedArticles?.length">
  <div class="shell">
    <h5 class="related-articles_title h5 m-h-3">{{ 'pages.BLOG.TITLES.SAME_TOPIC' | translate }}</h5>
    
    <div class="related-articles_content">
      <app-slider [spaceBetween]="40">
        <ng-template *ngFor="let article of relatedArticles" #swiperSlide>
          <app-slider-item [navigateTo]="article?.link">
            <app-article class="related-articles_item"
                         [article]="article"
                         [thematicLinks]="thematicLinks">
            </app-article>
          </app-slider-item>
        </ng-template>
      </app-slider>
    </div>
  </div>
</section>
