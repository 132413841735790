<div class="plans-container" *ngIf="plans?.length; else noPlans">
  <div class="h5 m-h3 step-title">{{ 'forms.BOOKING_PATH.SELECT_PLAN.TITLE' | translate }}</div>

  <div class="slider-holder">
  <app-slider [initialSlide]="initialSlide" [itemsCentered]="true">
      <ng-template *ngFor="let plan of plans" #swiperSlide>
        <app-slider-item>
          <div class="plan"
               [class.best-plan]="plan.productOffering.highlightedOffer">
            <div class="best t-large weight-bold" *ngIf="plan.productOffering.highlightedOffer">{{ 'forms.BOOKING_PATH.SELECT_PLAN.BEST_CHOICE_TEXT' | translate }}</div>
            <div class="plan_title h3 m-h3 zero-margin"> {{plan.productOfferingName}} </div>
            <div class="plan_price h3">
              {{ plan.recommendedPremium?.grossPremium?.amount | appCurrency: plan.recommendedPremium?.grossPremium?.currency }}
            </div>
            <app-button type="full-width"
                        id="choose-best-plan-action-select"
                        class="first-action"
                        (click)="selectPlan(plan)">
              {{ 'forms.BOOKING_PATH.SELECT_PLAN.SELECT_BUTTON' | translate }}
            </app-button>

            <div>
              <p class="t-small m-t-small zero-margin"><b>{{ 'forms.BOOKING_PATH.SELECT_PLAN.BENEFITS_TITLE' | translate }}</b></p>
              <app-safe-html class="benefits t-small m-t-small"
                              [content]="plan.productOfferingDescription | markdown">
              </app-safe-html>
            </div>

            <h6 class="zero-margin h6 m-h4 benefits-details">{{ 'forms.BOOKING_PATH.SELECT_PLAN.DETAILS_TITLE' | translate }}</h6>
            <div appAccordionContainer class="accordion-container">
              <app-accordion class="faq-block__item"
                             theme="white"
                             size="child"
                             *ngFor="let coverage of plan.coverages">
                <ng-container accordionTitle>
                  <h6 class="zero-margin h6 m-h4">{{ coverage.name }}</h6>
                </ng-container>

                <app-safe-html class="faq-block__answer t-small m-t-normal"
                               [content]="coverage.description | markdown">
                </app-safe-html>
              </app-accordion>
            </div>

            <app-button type="full-width"
                        id="choose-plan-action-select"
                        class="second-action"
                        (click)="selectPlan(plan)">
              {{ 'forms.BOOKING_PATH.SELECT_PLAN.SELECT_BUTTON' | translate }}
            </app-button>
            <div class="download">
              <a *ngFor="let document of plan.documents" [href]="document.documentInfo?.url" target="_blank" class="download-item">
                <app-icon type="bubble" icon="download" theme="inherit"></app-icon>
                <div class="t-small"> {{ document.documentInfo?.documentType | appDocumentTypeTranslate }} </div>
              </a>
            </div>
          </div>
        </app-slider-item>
      </ng-template>
    </app-slider>
  </div>
</div>

<ng-template #noPlans>
  <div class="no-plans-container">
    <h2 class="sorry-title zero-margin m-h3">{{ 'forms.BOOKING_PATH.SELECT_PLAN.EMPTY_LIST.TITLE' | translate }}</h2>
    <div class="sorry-content m-t-small">{{ 'forms.BOOKING_PATH.SELECT_PLAN.EMPTY_LIST.MESSAGE' | translate }}</div>
    <app-button id="choose-plan-action-back" type="full-width" (click)="goBack()">
      {{ 'forms.BOOKING_PATH.SELECT_PLAN.EMPTY_LIST.BUTTON' | translate }}
    </app-button>
  </div>
</ng-template>
