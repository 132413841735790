/**
 * Content API
 * The API which will provide the content for Angular application to render Allyz websites
 *
 * OpenAPI spec version: v0.1
 * Contact: tbd@allyz.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlockRestricted } from './blockRestricted';
import { CTAAction } from './cTAAction';
import { CTAHideableLink } from './cTAHideableLink';
import { CTALink } from './cTALink';
import { DynamicComponentFlightDelayAndCancelation } from './dynamicComponentFlightDelayAndCancelation';
import { DynamicComponentRegistrationForm } from './dynamicComponentRegistrationForm';

export interface DynamicComponentWrapperBlock extends BlockRestricted {
    /**
     * Title
     */
    title?: string;
    /**
     * Hero Text. Rich Text.
     */
    text?: string;
    /**
     * List of buttons.
     */
    ctas?: Array<CTALink | CTAAction | CTAHideableLink>;
    component?: DynamicComponentRegistrationForm | DynamicComponentFlightDelayAndCancelation;
    /**
     * Alignment of the dynamic component in the content block
     */
    componentAlignment?: DynamicComponentWrapperBlock.ComponentAlignmentEnum;
}
export namespace DynamicComponentWrapperBlock {
    export type ComponentAlignmentEnum = 'left' | 'right';
    export const ComponentAlignmentEnum = {
        Left: 'left' as ComponentAlignmentEnum,
        Right: 'right' as ComponentAlignmentEnum
    };
}
