<div class="shell wrapper">
  <div class="app-buttons">
    <div *ngIf="data.appleApplicationLink" class="app-button">
      <a [href]="data.appleApplicationLink" target="_blank" id="apple-app-link">
        <img [src]="appleStoreImagePath" class="app-button-apple">
      </a>
    </div>
    <div *ngIf="data.androidApplicationLink" class="app-button">
      <a [href]="data.androidApplicationLink" target="_blank" id="google-app-link">
        <img [src]="googlePlayImagePath" class="app-button-google">
      </a>
    </div>
  </div>
</div>
