import { DestroyRef, Injectable, Injector, Type } from '@angular/core';
import { DialogRef } from './dialog.interfaces';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';

type CreateDialogHandler = <C>(component: Type<C>, data?: any, injector?: Injector) => DialogRef<C>

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private handler: CreateDialogHandler;
  private activeDialog: DialogRef<any>;

  constructor(
    private router: Router,
    private destroyRef: DestroyRef
  ) {
    this.router.events.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(event => {
      if(event instanceof NavigationStart && event.navigationTrigger !== 'imperative') {
        this.closeActiveDialog();
      }
    })
  }

  public registerCreateHandler(handler: CreateDialogHandler): void {
    this.handler = handler;
  }

  public open<C>(component: Type<C>, data?: any, injector?: Injector): DialogRef<C> {
    if (!this.isSameComponentOpen(component)) {
      this.activeDialog = this.handler(component, data, injector);
      this.activeDialog.afterClosed$.pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(() => {
        this.activeDialog = null;
      });
    }

    return this.activeDialog;
  }

  private isSameComponentOpen<C>(component: Type<C>): boolean {
    return this.activeDialog?.componentRef?.componentType === component;
  }

  private closeActiveDialog(): void {
    if(this.activeDialog) {
      this.activeDialog.close();
      this.activeDialog = null;
    }
  }
}
