import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentActionType } from './document-button.const';

@Component({
  selector: 'app-document-button',
  templateUrl: './document-button.component.html',
  styleUrls: ['./document-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentButtonComponent {
  @Input() public fullyClicked = true;
  @Input() public label: string;
  @Input() public link: string;
  @Input() public type: string;
  @Input() public size: string;
  @Input() public action: 'download' | 'new-tab' = DocumentActionType.download;

  @Output() public download = new EventEmitter<void>();

  public ActionType = DocumentActionType;

  public onClick(): void {
    this.download.emit();
  }
}
