import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightInfo, FlightStatus } from '@common/flight/flight.interfaces';

@Component({
  selector: 'app-trip-details-accordion-flight',
  templateUrl: './trip-details-accordion-flight.component.html',
  styleUrls: ['./trip-details-accordion-flight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripDetailsAccordionFlightComponent {
  @Input() flight: FlightInfo;
  public readonly FlightStatus = FlightStatus;

  constructor() {
  }

}
