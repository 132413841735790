import { LanguageService } from '@common/language/language.service';
import { Language } from '@common/language/language.const';

export const LOCALE_DATE: { [key:string]: string } = {
  [Language.EN]: 'en-US',
  [Language.FR]: 'fr-FR',
  [Language.ES]: 'es-ES',
  [Language.DE]: 'de-DE',
  [Language.IT]: 'it-IT',
  [Language.NL]: 'nl-NL',
  [Language.PL]: 'pl-PL',
};

export const dateLocaleFactory = (languageService: LanguageService) => languageService.localizationCode;
