import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, map, Observable, of } from "rxjs";
import { LanguageService } from "@common/language/language.service";
import { WoosmapAutocompleteData, WoosmapDetails } from "@kit/woosmap-search/woosmap-search.interface";

@Injectable({
  providedIn: 'root'
})
export class WoosmapSearchService {

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
  ) { }

  public autocomplete(input: string): Observable<WoosmapAutocompleteData[]> {
    const params = new URLSearchParams();

    params.append('input', input);
    params.append('key', 'woos-adb7bc5b-b38b-3ada-8f21-55a85016db4c');
    params.append('types', 'country|locality');
    params.append('language', this.languageService.getCurrentLanguage().toLowerCase());

    return this.http.get<{ localities: WoosmapAutocompleteData[]}>(`https://api.woosmap.com/localities/autocomplete?${params.toString()}`)
      .pipe(
        map( ({ localities }) =>  localities),
        catchError(() => of([])),
      );
  }

  public getDetails(input: string): Observable<WoosmapDetails> {
    const params = new URLSearchParams();

    params.append('public_id', input);
    params.append('key', 'woos-adb7bc5b-b38b-3ada-8f21-55a85016db4c');

    return this.http.get<{ result: WoosmapDetails}>(`https://api.woosmap.com/localities/details?${params.toString()}`)
      .pipe(map(({ result }) => result));
  }
}
