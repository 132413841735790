<div class="social" [ngClass]="['social__theme-' + theme]">
  <p class="social__text t-small" *ngIf="text">{{ text }}</p>
  <div class="social__actions">
    <a class="twitter"
       id="share-twitter"
       [href]="'https://twitter.com/intent/tweet?url=' + url" target="_blank"
       (click)="sendAnalytics('twitter')"
    >
      <app-button
        size="m"
        type="icon-button"
        class="social__button"
        icon="twitter"
        theme="inherit"
      ></app-button>
    </a>

    <a class="facebook"
       id="share-facebook"
       [href]="'https://www.facebook.com/sharer/sharer.php?u=' + url" target="_blank"
       (click)="sendAnalytics('facebook')"
    >
      <app-button
        size="m"
        type="icon-button"
        class="social__button"
        icon="facebook"
        theme="inherit"></app-button>
    </a>

    <a class="linkedin"
       id="share-linkedin"
       [href]="'https://www.linkedin.com/sharing/share-offsite/?url=' + url" target="_blank"
       (click)="sendAnalytics('linkedin')"
    >
      <app-button
        size="m"
        type="icon-button"
        class="social__button"
        icon="linkedin"
        theme="inherit"></app-button>
      </a>
  </div>
</div>
