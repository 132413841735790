import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, distinctUntilChanged, Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { DynamicRenderService } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.service';
import { SeoService } from '@common/seo/seo.service';
import { UserService } from '@common/user/user.service';
import { DynamicContent } from './dynamic-render/dynamic-render.interface';

@Component({
  selector: 'app-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicComponent implements OnInit {
  dynamicContent$: Observable<DynamicContent[]>;

  constructor(
    private route: ActivatedRoute,
    private bootstrapService: BootstrapService,
    private dynamicRenderService: DynamicRenderService,
    private userService: UserService,
    private seoService: SeoService,
  ) {}

  ngOnInit(): void {
    this.dynamicContent$ = combineLatest([
      this.userService.userSubscription$.pipe(distinctUntilChanged()),
      this.route.data.pipe(pluck('routeData')),
      this.route.data.pipe(pluck('alternates')),
    ]).pipe(
      map(([userSubscription, data, alternates]) => {
        this.seoService.updateSeoData(data, alternates);
        this.bootstrapService.setTheme(data.theme);
        return this.dynamicRenderService.prepareContent(data.template || data.container, userSubscription);
      })
    );
  }
}
