<section class="section section_centered">
  <div class="section__shell shell">
    <div class="section__header">
      <h2 class="section__title">{{ aemData.title }}</h2>
      <p class="text-large">{{ aemData.text }}</p>
    </div>
    <div class="flight-delay">
      <app-slider class="flight-delay__items">
        <ng-template *ngFor="let item of aemData.items" #swiperSlide>
          <app-slider-item
            [attr.id]="item.id"
            class="flight-delay__item">
            <app-icon [icon]="item.icon" class="flight-delay__icon" theme="inherit" size="inherit"></app-icon>
            <div class="flight-delay__item-content">
              <app-safe-html [content]="item.text"></app-safe-html>
            </div>
          </app-slider-item>
        </ng-template>
      </app-slider>

      <app-aem-button
        *ngIf="aemData.cta"
        [cta]="aemData.cta"
        id="flight-delay-action"
        size="l"
      ></app-aem-button>
    </div>
  </div>
</section>
