import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { AemRouterModule } from 'src/app/common/routing/aem-router.module';
import { AccordionModule } from '@kit/accordion/accordtion.module';
import { BackButtonModule } from '@kit/back-button/back-button.module';
import { ButtonModule } from '@kit/button/button.module';
import { SliderModule } from '@kit/carousel/slider.module';
import { CheckboxModule } from '@kit/checkbox/checkbox.module';
import { CounterModule } from '@kit/counter/counter.module';
import { CurrencyModule } from '@kit/currency/currency.module';
import { DateModule } from '@kit/date/date.module';
import { DialogModule } from '@kit/dialog/dialog.module';
import { DocumentButtonModule } from '@kit/document-button/document-button.module';
import { FieldErrorModule } from '@kit/field-error/field-error.module';
import { IconModule } from '@kit/icon/icon.module';
import { InputModule } from '@kit/input/input.module';
import { LabelModule } from '@kit/label/label.module';
import { MarkdownPipeModule } from '@kit/markdown-pipe/markdown.module';
import { PhoneModule } from '@kit/phone/phone.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { SearchSelectModule } from '@kit/search-select/search-select.module';
import { StepperModule } from '@kit/stepper/stepper.module';
import { TooltipModule } from '@kit/tooltip/tooltip.module';
import { BookingPathConfirmationComponent } from './components/booking-path-confirmation/booking-path-confirmation.component';
import { AemBookingPathComponent } from './components/booking-path/booking-path.component';
import { ChoosePlanComponent } from './components/choose-plan/choose-plan.component';
import { GetQuoteComponent } from './components/get-quote/get-quote.component';
import { PaymentComponent } from './components/payment/payment.component';
import { TravelersDetailsComponent } from './components/travelers-details/travelers-details.component';
import { DocumentTypeTranslatePipe } from './pipes/document-type-translate.pipe';

@NgModule({
  declarations: [
    AemBookingPathComponent,
    GetQuoteComponent,
    ChoosePlanComponent,
    TravelersDetailsComponent,
    PaymentComponent,
    BookingPathConfirmationComponent,
    DocumentTypeTranslatePipe
  ],
  imports: [
    RouterModule,
    CommonModule,
    InputModule,
    BackButtonModule,
    ReactiveFormsModule,
    FieldErrorModule,
    SafeHtmlModule,
    CheckboxModule,
    AccordionModule,
    SliderModule,
    DateModule,
    ButtonModule,
    LabelModule,
    SearchSelectModule,
    TooltipModule,
    TuiLetModule,
    IconModule,
    StepperModule,
    CounterModule,
    TranslateModule,
    DialogModule,
    MarkdownPipeModule,
    CurrencyModule,
    PhoneModule,
    DocumentButtonModule,
    AemRouterModule,
  ],
  providers: [TranslateService],
  exports: [AemBookingPathComponent]
})
export class AemInsuranceModule { }
