import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppStorage } from "@kit/utils/ssr.utils";
import { SHOW_TRANSLATION_KEYS_STORAGE_KEY } from "@kit/translate/mark-translation.compiler";
import { UserService } from "@common/user/user.service";

@Component({
  selector: 'app-translation-keys-switcher',
  templateUrl: './translation-keys-switcher.component.html',
  styleUrls: ['./translation-keys-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationKeysSwitcherComponent implements OnInit {
  public showTranslationKeys: string;
  public isButtonAvailable$ = this.userService.isContentManager$;

  constructor(
    private readonly storage: AppStorage,
    private readonly userService: UserService,
  ) { }

  ngOnInit(): void {

    this.showTranslationKeys = JSON.parse(this.storage.getItem(SHOW_TRANSLATION_KEYS_STORAGE_KEY))?.value;
  }

  public click(): void {
    this.storage.setItem(
      SHOW_TRANSLATION_KEYS_STORAGE_KEY,
      this.showTranslationKeys ? JSON.stringify({value: 0}) : JSON.stringify({value: 1}))
    location.reload();
  }
}
