<div class="shell">
  <div class="gradient-cards__content">
    <div *ngIf="data.title"
        class="headline-2 gradient-cards__title">
        {{ data.title }}
    </div>
    <app-safe-html *ngIf="data.text"
                  [content]="data.text"
                  class="gradient-cards__text">
    </app-safe-html>
    <div *ngIf="data.cards"
        class="gradient-cards__container">
      <div *ngFor="let card of data.cards"
          class="gradient-card__container">
        <div class="h5 gradient-card__title">{{ card.title }}</div>
        <app-safe-html [content]="card.text"
                      class="gradient-card__text">
        </app-safe-html>
      </div>
    </div>
    <app-safe-html *ngIf="data.bottomText"
                  [content]="data.bottomText"
                  class="gradient-cards__text">
    </app-safe-html>
  </div>
</div>
