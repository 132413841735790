import { ActivatedRouteSnapshot } from "@angular/router";
import { BlockRestricted } from "@common/model/blockRestricted";
import { Entitlements } from "@common/model/entitlements";
import { UserType } from "@common/profile/profile.interfaces";

export type DataParams = {[key: string]: any };

export function setRouterData(url: string , params: DataParams): string {
  return (url || '').split('/').map(value => {
    if (value.startsWith(':')) {

      return params[value.slice(1)];
    }

    return value;
  }).join('/');
}

export function setRouterQueryData(url: string, params: DataParams): string {
  const searchParams = new URLSearchParams(params);

  return `${url}?${searchParams.toString()}`;
}

export function getAllRouterParams(route: ActivatedRouteSnapshot, data: DataParams = {}): DataParams {
  const mergedData = Object.assign(data, route.params);
  if (route.firstChild) {
    return getAllRouterParams(route.firstChild, mergedData);
  }

  return mergedData;
}
export function filterByEntitlements<T extends { entitlements?: Entitlements; } | BlockRestricted>(
  container: T[],
  userSubscription: UserType
): T[] {
  return container.filter((item) => {
    if (!item?.entitlements) {
      return true;
    }

    return item.entitlements[userSubscription];
  })
}

export function isAbsoluteUrl(url: string): boolean {
  return /^(mailto|tel|http|https):/i.test(url);
}
