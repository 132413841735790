export enum TradedoublerEvent{
  registration = 'REGISTRATION',
  activation = 'ACTIVATION',
  insurancePurchase = 'INSURANCE_PURCHASE',
}

export interface Tradedoubler {
  residence: string;
  eventType: TradedoublerEvent;
  affiliateId: string;
  userId: string;
  affiliateCode?: string;
  orderNumber?: string;
  orderValue?: number;
  promoCode?: string;
}

export interface TradedoublerRouteData {
  affId: string;
  tduid?: string;
  ac?: string;
  pc?: string;
}
