<div class="input form-field"
     [class.form-field_focused]="focused"
     [class.form-field_disabled]="disabled">
  <app-icon class="input_icon"
            size="large"
            icon="phone"
            theme="lighten">
  </app-icon>
  <div class="input_code text-small">{{ code }}</div>
  <input class="input_field text-small"
         name="phone"
         readonly
         [placeholder]="placeholder"
         [formControl]="innerControl"
         [attr.autocomplete]="autocomplete"
         [attr.name]="inputName"
         (touchstart)="onFocus($event)"
         (focus)="onFocus($event)"
         (blur)="onBlur()">
</div>
<div *ngIf="formatDisclaimerNeeded" class="input_disclaimer t-tiny m-t-tiny">
   {{ 'controls.PHONE.EXAMPLE' | translate : {code: code} }}
</div>

<div *ngIf="disclaimer" class="input_disclaimer t-tiny m-t-tiny">
  {{ disclaimer }}
</div>
