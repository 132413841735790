import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoungeVoucher, RegroupedLoungeVoucher } from './lounge-vouchers.interface';
import { ApiProfileService } from '@common/profile/api-profile.service';

@Injectable({
  providedIn: 'root'
})
export class LoungeVouchersService {
  private userLoungeVouchers$ = new Subject<RegroupedLoungeVoucher[]>;

  constructor(
    private readonly apiProfileService: ApiProfileService
  ) { }

  public get loungeVouchers$(): Observable<RegroupedLoungeVoucher[]> {
    return this.userLoungeVouchers$.asObservable();
  }

  public getUserLoungeVouchers(): void {
    this.apiProfileService.getLoungeAccessVouchers().subscribe((vouchers: LoungeVoucher[]) => {
      const sortedArray = this.regroupAndSortArray(vouchers);
      this.userLoungeVouchers$.next(sortedArray);
    });
  }

  private regroupAndSortArray(vouchers: LoungeVoucher[]): RegroupedLoungeVoucher[] {
    const regroupedArray = this.regroupByTravelerName(vouchers);
    const sortedArray = this.sortByTravelerName(regroupedArray);

    return sortedArray;
  }

  private regroupByTravelerName(vouchers: LoungeVoucher[]): { [key: string]: LoungeVoucher[] } {
    const regroupedVouchers: { [key: string]: LoungeVoucher[] } = {};

    vouchers.forEach((voucher: LoungeVoucher) => {
      if (!regroupedVouchers[voucher.travelerName]) {
        regroupedVouchers[voucher.travelerName] = [];
      }
      regroupedVouchers[voucher.travelerName].push(voucher);
    });

    return regroupedVouchers;
  }

  private sortByTravelerName(regroupedArray: { [key: string]: LoungeVoucher[] }): RegroupedLoungeVoucher[] {
    return Object.keys(regroupedArray)
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map((travelerName) => ({
        travelerName: travelerName,
        vouchers: this.sortVouchersByCreationDate(regroupedArray[travelerName]),
      }));
  }

  private sortVouchersByCreationDate(vouchers: LoungeVoucher[]): LoungeVoucher[] {
    return vouchers.sort((a: LoungeVoucher, b: LoungeVoucher) => a.creationDate.localeCompare(b.creationDate));
  }
}
