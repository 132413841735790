export interface Document {
  content: File[];
  folderId?: number;
  pageNumber?: number;
  pageSize? : number;
  totalElements?: number
}

export interface Folder {
  content: FolderContent[];
  pageNumber: number;
  pageSize: number;
  totalElements: number
}

export interface VaultData {
  folder: Folder;
  document: Document
}

export interface FolderContent {
  id: number;
  name: string;
  filesCount?: number;
}

export interface File {
  id: string;
  name: string;
  extension?: string;
  creationDate?: string;
}

export interface DownloadLink {
  downloadLink: string;
}

export interface UploadLink {
  uploadLink: string;
  id: string;
}

export interface DocumentStatus {
  status: Status;
  statusDate: string;
}

export enum Status {
  PENDING = 'PENDING',
  UPLOADED = 'UPLOADED',
  INFECTED = 'INFECTED',
  FAILED = 'FAILED',
  DELETED = 'DELETED'
}
