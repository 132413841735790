<div class="select form-field"
     [class.form-field_focused]="expanded"
     [class.form-field_disabled]="disabled"
     (tuiActiveZoneChange)="changeActiveState($event)">
  <div class="select_wrapper" (click)="toggleOption()">
    <input readonly
           class="select_input text-small"
           [placeholder]="placeholder"
           [value]="selectedOption && selectedOption[viewKey]">
    <div class="select__action">
      <app-icon *ngIf="!disabled && clearButton && selectedView"
                (click)="clear($event)"
                class="select__action-icon icon"
                icon="close"
                size="large"
                theme="lighten">
      </app-icon>
      <app-icon class="select__action-icon select__action-toggle icon"
                icon="chevron-down"
                size="large"
                theme="lighten">
      </app-icon>
    </div>
  </div>

  <div class="select_options text-tiny" *ngIf="expanded">
    <ng-container
      *ngIf="options?.length; else emptyPlaceholder"
      [ngTemplateOutlet]="optionsTemplate"
      [ngTemplateOutletContext]="{ $implicit: options }"
    >
    </ng-container>

    <ng-template #optionsTemplate let-options>
      <ng-container *ngFor="let option of options">
        <div class="select_options-item"
          #optionRef
          [class.active]="option === selectedOption"
          [class.options-open]="option?.[optionsKey]?.includes(selectedOption)"
          [class.options]="optionsKey && option[optionsKey]?.length"
          (click)="clickOnSelect(option, optionRef)">
          <div class="select_options-item-content">
            {{ option[viewKey] }}
          </div>
          <div class="select_option-toggle" *ngIf="optionsKey && option[optionsKey]?.length">
            <app-icon class="icon"
                      icon="chevron-down"
                      size="large"
                      theme="lighten">
            </app-icon>
          </div>
        </div>
        <div class="select_options-child" *ngIf="optionsKey && option[optionsKey]?.length;">
          <ng-container
            [ngTemplateOutlet]="optionsTemplate"
            [ngTemplateOutletContext]="{ $implicit: option[optionsKey] }"
          >
          </ng-container>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #emptyPlaceholder>
      <div class="select_options-item-empty">
        {{ 'forms.CREATE_TRIP.PLACEHOLDERS.EMPTY_SEARCH_RESULT' | translate }}
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="expanded"
     class="select_toggle-area">
</div>
