<section class="side-by-side" [style.background-color]="data.colour">
  <div class="side-by-side__shell shell">
    <div class="side-by-side__grid">
      <div class="side-by-side__column">
        <h4 class="side-by-side__title zero-margin h2 m-h2">{{ data.title }}</h4>
      </div>
      <div class="side-by-side__column side-by-side__description t-large m-t-normal">
        <app-safe-html [content]="data.text"></app-safe-html>
      </div>
    </div>
    <div class="side-by-side__grid">
      <div class="side-by-side__column side-by-side__column-travel"
           *ngFor="let item of data.items"
           [attr.id]="item.id">
        <div class="travel-cards">
          <div class="travel-cards__image">
            <img [src]="item.image.imagePath"
                 [alt]="item.image.altText"
                 [appImgAlt]="item.image.altText"
                 [appImgAltData]="item">
          </div>
          <div class="travel-cards__title h5 m-h3 zero-margin">{{ item.title }}</div>
          <app-safe-html class="travel-cards__text t-normal m-t-normal"
                         *ngIf="item.text" 
                         [content]="item.text">
          </app-safe-html>
          <div class="travel-cards__button">
            <app-aem-button *ngIf="item.cta" 
                            [cta]="item.cta" 
                            size="l" 
                            type="full-width">
            </app-aem-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
