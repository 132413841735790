import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FlightInfo } from "@common/flight/flight.interfaces";

@Component({
  selector: 'app-flight-card-detailed',
  templateUrl: './flight-card-detailed.component.html',
  styleUrls: ['./flight-card-detailed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightCardDetailedComponent {
  @Input() flight: FlightInfo;

  @Output() delete = new EventEmitter<number>();
}
