<app-icon icon="chevron-left" theme="inherit" size="inherit" class="back-icon"></app-icon>
  <div class="container" *ngIf="isLoaded">
    <div class="container__wrapper">
      <app-back-button (goBack)="goBack()" class="tc__back"></app-back-button>
      <div class="section__container">
        <ng-container [ngSwitch]="currentStep">
          <ng-container *ngSwitchCase="0">
            <ng-container *ngTemplateOutlet="firstStepOutlet"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="1">
            <ng-container *ngTemplateOutlet="secondStepOutlet"></ng-container>
          </ng-container>
        </ng-container>

        <ng-template #firstStepOutlet>
          <div class="tc__header">
            <div class="tc__header__title weight-medium">{{ 'pages.TERMS_AND_CONDITIONS.TITLES.T&C' | translate }}</div>
            <app-safe-html class="text-small m-t-normal tc__header__text" [content]="'pages.TERMS_AND_CONDITIONS.SUBTITLES.T&C' | translate"></app-safe-html>
          </div>
          <div class="tc__body tc__consent" [formGroup]="consentsForm">
            <div>
              <app-checkbox id="terms-and-conditions-consent" formControlName="termsOfUse">
                <app-safe-html class="text-small m-t-normal" [content]="'pages.TERMS_AND_CONDITIONS.CONSENTS.T&C' | translate"></app-safe-html>
              </app-checkbox>
              <div class="tc__consent__error text-tiny">
                <app-safe-html class="text-tiny" *ngIf="consentsForm?.get('termsOfUse')?.touched && consentsForm?.get('termsOfUse')?.errors" [content]="'pages.TERMS_AND_CONDITIONS.CONSENTS.ERROR' | translate"></app-safe-html>
              </div>
              <app-checkbox *ngIf="isMarketingConsentGlobal; else marketingConsents"
                            class="tc__checkbox"
                            id="terms-and-conditions-marketing-management-global"
                            formControlName="marketingManagementGlobal">
                <app-safe-html class="text-small m-t-normal" [content]="'pages.REGISTRATION.MARKETING_MANAGEMENT_GLOBAL' | translate"></app-safe-html>
              </app-checkbox>

              <ng-template #marketingConsents>
                <app-checkbox class="tc__checkbox" id="terms-and-conditions-marketing-management-internal" formControlName="marketingManagementInternal">
                  <app-safe-html class="text-small m-t-normal" [content]="'pages.REGISTRATION.MARKETING_MANAGEMENT_INTERNAL' | translate"></app-safe-html>
                </app-checkbox>
                <app-checkbox class="tc__checkbox" id="terms-and-conditions-marketing-management-external" formControlName="marketingManagementExternal">
                  <app-safe-html class="text-small m-t-normal" [content]="'pages.REGISTRATION.MARKETING_MANAGEMENT_EXTERNAL' | translate"></app-safe-html>
                </app-checkbox>
              </ng-template>
            </div>

          </div>
          <div class="button__container tc__body">
            <app-button size="m"
                        theme="black"
                        id="terms-and-conditions-action-accept"
                        type="full-width"
                        (click)="acceptTermsAndConditions()">
              {{ 'pages.TERMS_AND_CONDITIONS.ACTIONS.ACCEPT' | translate }}
            </app-button>
          </div>

          <div class="tc__footer">
            <div (click)="currentStep = 1"
                id="terms-and-conditions-action-go-to-delete"
                class="tc__footer__button__delete text-small m-t-normal">
                {{ 'pages.TERMS_AND_CONDITIONS.ACTIONS.GO_TO_DELETE' | translate }}
            </div>
          </div>
        </ng-template>

        <ng-template #secondStepOutlet>
          <div class="tc__header">
            <app-safe-html class="tc__header__title tc__header__title__second-step weight-medium" [content]="'pages.TERMS_AND_CONDITIONS.TITLES.DELETE_ACCOUNT' | translate"></app-safe-html>
            <div class="tc__body__second-step"><app-safe-html class="text-normal m-t-normal" [content]="'pages.TERMS_AND_CONDITIONS.DESCRIPTION.DELETE_ACCOUNT' | translate"></app-safe-html></div>
          </div>
          <div class="tc__footer tc__footer__second-step">
            <app-button size="m" theme="black" id="terms-and-conditions-action-back" (click)="currentStep = 0" type="full-width"
              class="tc__footer__button">
              {{ 'pages.TERMS_AND_CONDITIONS.ACTIONS.KEEP_ACCOUNT' | translate }}
            </app-button>
            <app-button size="m" theme="pure-bordered" type="full-width" id="terms-and-conditions-action-delete-account"
              (click)="deleteAccount()" class="tc__footer__button">
              {{ 'pages.TERMS_AND_CONDITIONS.ACTIONS.DELETE_ACCOUNT' | translate }}
            </app-button>
          </div>
            <div class="tc__body">
              <div class="button__container button__container__second-step">
                <app-button size="m"
                          theme="black"
                          id="terms-and-conditions-action-back"
                          (click)="currentStep = 0"
                          type="full-width">
                  {{ 'pages.TERMS_AND_CONDITIONS.ACTIONS.KEEP_ACCOUNT' | translate }}
                </app-button>
              </div>
              <div class="button__container button__container__second-step margin-30">
                <app-button size="m"
                          theme="pure-bordered"
                          type="full-width"
                          id="terms-and-conditions-action-delete-account"
                          (click)="deleteAccount()">
                  {{ 'pages.TERMS_AND_CONDITIONS.ACTIONS.DELETE_ACCOUNT' | translate }}
                </app-button>
              </div>
            </div>


        </ng-template>

      </div>
    </div>

  </div>

  <footer class="footer" *ngIf="(data$ | async) as data">
    <div class="footer__container">
        <a class="footer__logo" [routerLink]="data.logoLink.link">
        <img class="footer__logo-img"
            [src]="data.logo.imagePath"
            [appImgAlt]="data.logo.altText"
            [appImgAltData]="data">
      </a>
      <div class="footer__copyright copyright">
        <img class="copyright__img"
            [src]="data.copyrightLogo.imagePath"
            [appImgAlt]="data.copyrightLogo.altText"
            [appImgAltData]="data">
        <div class="copyright__text t-small m-t-small">{{ data.copyright | translate: {year} }}</div>
      </div>
      <div *ngIf="data.legalNote"
          class="footer__legal-note shell text-small m-t-small">
        {{ data.legalNote }}
      </div>
    </div>
  </footer>
