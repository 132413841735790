import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Hotel, HotelDto } from './hotel.interface';

@Injectable({
  providedIn: 'root',
})
export class HotelService {
  private baseUrl = environment.api.baseUrl;
  constructor(private http: HttpClient) {}

  get(tripId: string, activityId: string): Observable<Hotel> {
    return this.http.get<Hotel>(`${this.baseUrl}/trips/${tripId}/hotels/${activityId}`);
  }

  create(tripId: string, hotel: HotelDto): Observable<Hotel> {
    return this.http.post<Hotel>(`${this.baseUrl}/trips/${tripId}/hotels`, hotel);
  }

  save(tripId: string, hotelId: string, hotel: Hotel): Observable<Hotel> {
    return this.http.put<Hotel>(`${this.baseUrl}/trips/${tripId}/hotels/${hotelId}`, hotel);
  }

  delete(tripId: string, hotelId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/trips/${tripId}/hotels/${hotelId}`);
  }
}
