/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, ElementRef, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';
import { filter, fromEvent, Subject, take, takeUntil } from 'rxjs';
import { IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';

@Directive({
  selector: '[appAnimateHeight]'
})
export class AnimateHeightDirective implements OnDestroy {
  @Input('appAnimateHeightExpanded') public set expanded(expanded: boolean) {
    if (expanded) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  @Input('appAnimateHeightMin') public minHeight = 0;
  @Input('appAnimateHeightMax') public maxHeight: number;

  private get computedHeight(): number {
    return this.nativeElement.scrollHeight;
  }

  private get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  private destroy$ = new Subject<void>();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    @Inject(IS_SERVER_PLATFORM)
    private readonly isServer: boolean,
  ) {
    this.renderer.setStyle(this.nativeElement, 'height', 0);
    this.renderer.addClass(this.nativeElement, 'animated-height');
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private collapse(): void {
    if (this.isServer) {
      this.renderer.setStyle(this.nativeElement, 'height', `${this.minHeight}px`);

      return;
    }

    if (this.nativeElement.style.height === 'auto') {
      this.renderer.setStyle(this.nativeElement, 'height', `${this.maxHeight || this.computedHeight}px`);
      requestAnimationFrame(() => this.renderer.setStyle(this.nativeElement, 'height', `${this.minHeight}px`));
    } else {
      this.renderer.setStyle(this.nativeElement, 'height', `${this.minHeight}px`);
    }
  }

  private expand(): void {
    if (this.isServer) {
      this.renderer.setStyle(this.nativeElement, 'height', 'auto');

      return;
    }

    this.renderer.setStyle(this.nativeElement, 'height', `${this.maxHeight || this.computedHeight}px`);

    fromEvent(this.nativeElement, 'transitionend')
      .pipe(
        filter((event) => event.target === this.nativeElement),
        take(1),
        takeUntil(this.destroy$)
      ).subscribe(() => {
        if (this.nativeElement.style.height === `${this.minHeight}px`) {
          return;
        }

        this.renderer.setStyle(this.nativeElement, 'height', 'auto');
      });
  }
}
