<section class="insurance-carrousel">
  <div class="shell">
    <div class="insurance-carrousel__header" *ngIf="!data.hideHeader">
      <ng-container *ngIf="data.items?.length > 1">
        <h5 class="zero-margin h5 m-h3">{{ data.title }}</h5>

        <div class="insurance-carrousel__type-product">
          <button
            *ngFor="let item of data.items"
            [class.selected]="item === selected"
            (click)="selectCategory(item)"
            class="insurance-carrousel__type-product-button"
          >{{ item.name }}</button>
        </div>
      </ng-container>

      <div class="insurance-carrousel__catch">
        <app-icon [icon]="selected.catchLineIcon" size="normal" theme="inherit"></app-icon>
        <p class="zero-margin t-tiny">{{ selected.catchLine }}</p>
      </div>

      <h4 class="insurance-carrousel__title h4 m-h2">{{ selected.title }}</h4>

      <app-aem-button class="insurance-carrousel__button" *ngIf="selected.link" [cta]="selected.link"></app-aem-button>
    </div>
    <div class="insurance-carrousel__body">
      <app-slider [itemsCentered]="true">
        <ng-template *ngFor="let product of selected.products" #swiperSlide>
          <app-slider-item>
            <div class="insurance-carrousel__card">
              <h5 class="zero-margin h5 m-h2">{{ product.title }}</h5>
              <div class="t-small m-t-normal">{{ product.text }}</div>

              <div class="insurance-carrousel__price">
                <div class="insurance-carrousel__price-value">
                  <h4 class="zero-margin h4 m-h2">{{ product.pricePart1 }}</h4>
                  <p class="zero-margin t-large m-t-normal">{{ product.pricePart2 }}</p>
                  <app-icon
                    *ngIf="product.priceTooltip"
                    [appTooltip]="product.priceTooltip"
                    class="insurance-carrousel__price-value-icon"
                    icon="info"
                    size="inherit"
                    theme="inherit"
                  ></app-icon>
                </div>
                <div class="disclamer t-tiny m-t-small" *ngIf="product.priceDisclaimer">
                  {{ product.priceDisclaimer }}
                </div>
              </div>

              <app-aem-button *ngIf="product.ctaBooking" [cta]="product.ctaBooking" type="full-width"></app-aem-button>

              <app-safe-html *ngIf="product.mainBenefits" [content]="product.mainBenefits"></app-safe-html>

              <app-aem-button *ngIf="product.ctaDetails" [cta]="product.ctaDetails" type="full-width" class="insurance-carrousel__details-action"></app-aem-button>
            </div>
          </app-slider-item>
        </ng-template>
      </app-slider>
    </div>
  </div>
</section>
