import { Country } from "../trip/trip.interfaces";
import {
  BookingTravelersDetails,
  BookingTripDetails,
  CoverageType,
  DateOfBirth,
  PaymentDetailsDTO,
  QuotationResponseContractContractList,
  SelectedProduct
} from "@common/booking-path/booking-path.interfaces";
import { BookingTravelersDetailsForm, BookingTripDetailsForm, PaymentFormResponse } from './booking.interfaces';

export function mapGetQuoteForm(source: BookingTripDetailsForm, marketScope: string): BookingTripDetails {
  return {
    marketScope: marketScope,
    tripBookDate: source.purchaseDate.toISOString(),
    coverage: { coverageType: mapCoverageOption(source.coverOptions) },
    destinationLocation: mapFormCountriesToCodeNames(source.destinations as Country[]),
    startDate: source.from.toISOString(),
    endDate: source.to.toISOString(),
    promoCode: source.promo,
    residenceCountryCode: (source.residence as Country).code,
    totalTripPrice: source.price || 0,
    travellers: mapTravellersToDateBirth(source.travelers)
  }
}

export function mapFormCountriesToCodeNames(countries: Country[]): { names: string[] } {
  return { names: countries.map(country => country.code) };
}

export function mapTravellersToDateBirth(data: Date[]): DateOfBirth[] {
  return data.map((date: Date) => ({ dateOfBirth: date.toISOString() }));
}

export function mapTripDetailsToForm(source: BookingTripDetails): BookingTripDetailsForm {
  return {
    from: new Date(source.startDate),
    to: new Date(source.endDate),
    purchaseDate: new Date(source.tripBookDate),
    destinations: source.destinationLocation.names,
    travelers: source.travellers.map(traveler => new Date(traveler.dateOfBirth)),
    coverOptions: mapCoverageOption(source.coverage.coverageType),
    price: source.totalTripPrice,
    residence: source.residenceCountryCode,
    promo: source.promoCode
  };
}

export function mapCoverageOption(coverage: CoverageType): CoverageType {
  return coverage === CoverageType.CANCELLATION
    ? CoverageType.CANCELLATION
    : CoverageType.NOT_SURE;
}

export function mapPlanToDTO(plan: QuotationResponseContractContractList): SelectedProduct {
  return {
    productOfferingSign: plan.productOfferingSign,
    productOfferingName: plan.productOfferingName,
    documents: plan.documents,
    sign: plan.productOffering.sign[0],
    grossPremium: plan.recommendedPremium.grossPremium,
    originalGrossPremium: plan.recommendedPremium.originalGrossPremium,
  }
}

export function mapTravelersDetailsToDTO(source: BookingTravelersDetailsForm): BookingTravelersDetails {
  return {
    travellers: source.travelers.map((traveler) => ({
      ...traveler,
      dateOfBirth: traveler.dateOfBirth.toISOString()
    })),
    policyHolder: {
      firstName: source.policy.firstName,
      lastName: source.policy.lastName,
      email: source.policy.email,
      dateOfBirth: source.policy.dateOfBirth.toISOString(),
      address: source.policy.address,
      postCode: source.policy.postCode,
      country: source.policy.residence,
      city: source.policy.city,
      phoneNumber: source.policy.phoneNumber,
    },
    termsAccept: source.policy.termsAccept,
    marketingAccept: source.policy.marketingAccept,
  }
}

export function mapTravelersDetailsDTOToForm(source: BookingTravelersDetails): BookingTravelersDetailsForm {
  return {
    travelers: source.travellers.map((traveler) => ({
      ...traveler,
      dateOfBirth: new Date(traveler.dateOfBirth)
    })),
    policy: {
      firstName: source.policyHolder.firstName,
      lastName: source.policyHolder.lastName,
      email: source.policyHolder.email,
      dateOfBirth: new Date(source.policyHolder.dateOfBirth),
      address: source.policyHolder.address,
      postCode: source.policyHolder.postCode,
      city: source.policyHolder.city,
      termsAccept: source.termsAccept,
      marketingAccept: source.marketingAccept,
      phoneNumber: source.policyHolder.phoneNumber,
      residence: source.policyHolder.country,
    },
  };
}

export function mapPaymentFormRespToDTO(formResp: PaymentFormResponse): PaymentDetailsDTO {
  return {
    paymentResponse: {
      status: formResp.status,
      sessionID: formResp.sessionID,
      transactionID: formResp.transactionID,
      paymentTokenId: formResp.paymentTokenId,
      expireDate: formResp.expiryDate,
      paymentHolder: formResp.paymentHolder
    }
  };
}
