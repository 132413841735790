<app-hero-block [showExtra]="false">
  <div class="destinations_form">
    <app-label [label]="'pages.DESTINATIONS.LABELS.DESTINATION' | translate">
      <app-search-select
        id="desctination-country"
        [placeholder]="'pages.DESTINATIONS.PLACEHOLDERS.DESTINATION' | translate"
        [formControl]="selectedCountry"
        viewKey="name"
        selectKey="countryId"
        [options]="countries$ | async"
      ></app-search-select>
      <app-field-error [control]="selectedCountry"></app-field-error>
    </app-label>

    <app-label [label]="'pages.DESTINATIONS.LABELS.DEPARTURE' | translate">
      <app-search-select
        id="departure-country"
        [placeholder]="'pages.DESTINATIONS.PLACEHOLDERS.DEPARTURE' | translate"
        [formControl]="departureCountry"
        viewKey="name"
        selectKey="countryId"
        [options]="countries$ | async"
      ></app-search-select>
      <app-field-error [control]="departureCountry"></app-field-error>
    </app-label>

    <app-button
      id="action-show-destination-info"
      icon="form"
      size="l"
      theme="black"
      type="full-width"
      (click)="loadData()"
    >{{ 'pages.DESTINATIONS.ACTIONS.SHOW_DESTINATION' | translate }}</app-button>
  </div>
</app-hero-block>
<app-destinations [countryName]="countryName" [destination]="destination"></app-destinations>
