import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';
import { ToolbarComponent } from './toolbar.component';
import { BurgerMenuModule } from '@kit/burger-menu/burger-menu.module';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSwitcherModule } from '@kit/language-switcher/language-switcher.module';

@NgModule({
  declarations: [
    ToolbarComponent
  ],
  exports: [
    ToolbarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    ButtonModule,
    BurgerMenuModule,
    TranslateModule,
    LanguageSwitcherModule
  ]
})
export class ToolbarModule { }
