import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { DIALOG_REF } from '../dialog.const';
import { DialogRef } from '../dialog.interfaces';

@Component({
  selector: 'app-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogWrapperComponent {
  @Input() public closeButton = true;


  constructor(
    @Inject(DIALOG_REF)
    private readonly dialogRef: DialogRef<unknown>
  ) {}

  public close(): void {
    this.dialogRef.close();
  }
}
