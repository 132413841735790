import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AemHideableExtendedLinkComponent } from './aem-hideable-extended-link.component';
import { IconModule } from '../icon/icon.module';
import { ButtonModule } from '../button/button.module';
import { AemRouterModule } from '@common/routing/aem-router.module';

@NgModule({
  declarations: [AemHideableExtendedLinkComponent],
  exports: [AemHideableExtendedLinkComponent],
  imports: [CommonModule, IconModule, ButtonModule, AemRouterModule],
})
export class AemHideableExtendedLinkModule {
}
