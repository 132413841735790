import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { filter, map, Subject, switchMap } from "rxjs";
import { FlightInfo } from "@common/flight/flight.interfaces";
import { ActivatedRoute, Router } from "@angular/router";
import { BootstrapService } from "@common/bootstrap/bootstrap.service";
import { TripService } from "@pages/trip/trip.service";
import { DialogService } from "@kit/dialog/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { ApiFlightService } from "@common/flight/api-flight.service";
import { AEM_DATA } from "@pages/dynamic/dynamic-render/dynamic-render.const";
import { TripHero } from "@common/model/tripHero";
import { setRouterData } from "@kit/utils/router.utils";
import { ConfirmationDialogComponent } from "@kit/dialog/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogData } from "@kit/dialog/confirmation-dialog/confirmation-dialog.interface";
import { Activity, TripDetails } from "@pages/trip/trip.interfaces";
import { ActivityType } from "@pages/trip/trip.const";
import { AemFragmentComponent } from "@kit/aem-base-block/aem-fragment";
import { DynamicComponentExistingFlights } from "@common/model/dynamicComponentExistingFlights";

@Component({
  selector: 'app-flights-existing',
  templateUrl: './flights-existing.component.html',
  styleUrls: ['./flights-existing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightsExistingComponent extends AemFragmentComponent implements OnInit {
  public flights$: Subject<FlightInfo[]> = new Subject<FlightInfo[]>();

  private tripId: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly bootstrapService: BootstrapService,
    private readonly tripService: TripService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly flightService: ApiFlightService,
    @Inject(AEM_DATA) public override data: TripHero & { component: DynamicComponentExistingFlights }
  ) {
    super(data);
  }

  ngOnInit(): void {
    this.tripId = this.activatedRoute.firstChild.snapshot.params.tripId;
    this.refreshFlights();
  }

  public addMoreFlights(): void {
    this.router.navigateByUrl(setRouterData(this.bootstrapService.link.flightAdd, { tripId: this.tripId }));
  }

  public back(): void {
    this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId: this.tripId }));
  }

  public deleteFlight(flightId: number): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DELETE_FLIGHT'),
      info: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DISCLAIMER_DELETE'),
      cancelTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.CANCEL'),
      confirmTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.DELETE'),
      reverseButtonPosition: true
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.flightService.deleteFlight(this.tripId, flightId)),
    ).subscribe(() => this.refreshFlights());
  }

  private refreshFlights(): void {
    this.tripService.getTripById(this.tripId)
      .pipe(
        map((trip: TripDetails) =>
          ((trip?.actions?.filter((action: Activity) => action.type === ActivityType.FLIGHT)) || []) as FlightInfo[]
        ),
      )
      .subscribe((flights: FlightInfo[]) => this.flights$.next(flights));
  }
}
