import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { ButtonModule } from '@kit/button/button.module';
import { AemSurveyBannerComponent } from './survey-banner.component';

@NgModule({
  declarations: [AemSurveyBannerComponent],
  exports: [AemSurveyBannerComponent],
  imports: [
    CommonModule,
    ButtonModule,
    AemButtonModule,
    ImgAltDirectiveModule,
  ]
})
export class AemSurveyBannerModule { }
