
<div class="counter">
   <app-button icon="minus" 
               type="icon-button" 
               theme="inherit" 
               size="inherit" 
               [disabled]="disabled || innerControl.value === min"
               (click)="decrease()"
               class="counter-action">
   </app-button>
   <div class="t-large"> {{ innerControl.value }} </div>
   <app-button icon="plus" 
               type="icon-button" 
               theme="inherit" 
               size="inherit" 
               [disabled]="disabled || innerControl.value === max"
               (click)="increase()"
               class="counter-action">
   </app-button>
</div>