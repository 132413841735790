import { Injectable } from '@angular/core';
import { RouterConfigurationPage } from '@common/model/routerConfigurationPage';
import { RouterConfigurations } from '@common/model/routerConfigurations';
import { MappedRoutes } from './language-mapping-url.interface';
import { LanguageService } from '@common/language/language.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LanguageMappingURLService {
  private mappedRoutes: MappedRoutes = {};

  constructor(private languageService: LanguageService) {}

  public mapRoutes(routerConfig: RouterConfigurations): void {
    const result: MappedRoutes = {};
    const data = routerConfig['data'];

    Object.keys(data).forEach((key: string) => {
      const routeConfig = data[key] as RouterConfigurationPage;

      if (routeConfig.languageCopies) {
        result[key] = routeConfig.languageCopies;
      }
    });

    this.mappedRoutes = result;
  }

  public getMappedRoute(language: string, route: ActivatedRouteSnapshot): string | null {
    const params: Record<string, string> = Object.keys(route.params).reduce((acc: Record<string, string>, key: string) => {
      acc[key] = route?.params[key] ? encodeURIComponent(route?.params[key]) : null;

      return acc;
    },{});
    let sanitizedRoute = this.replaceRouteParams(route.routeConfig.path);

    if (this.mappedRoutes[sanitizedRoute] && this.mappedRoutes[sanitizedRoute][language]) {
      const mappedRoute = this.mappedRoutes[sanitizedRoute][language];
      const updatedMappedRoute = this.insertParams(mappedRoute, params);
      const isQueryParamsExist = Object.keys(route.queryParams).length;
      const queryParams = new URLSearchParams(route.queryParams);

      if (isQueryParamsExist) {
        Object.entries(route.queryParams).forEach(([key, value]) => {
          queryParams.set(key, value);
        })
      }


      return isQueryParamsExist ? `${updatedMappedRoute}?${queryParams.toString()}` : updatedMappedRoute;
    }

    return null;
  }

  public getPossibleLanguages(url: string): string[] {
    const currentRoute = url;
    const currentLanguage = this.languageService.getCurrentLanguage().toUpperCase();
    const languages = new Set<string>();

    languages.add(currentLanguage);

    Object.entries(this.mappedRoutes).forEach(([route, languageCopies]) => {
      if (route === currentRoute || !this.mappedRoutes[currentRoute]) {
        Object.keys(languageCopies).forEach(language => {
          languages.add(language.toUpperCase());
        });
      }
    });

    return Array.from(languages);
  }

  private replaceRouteParams(route: string): string {
    return route.startsWith('/') ? route : '/' + route;
  }

  private insertParams(mappedRoute: string, params: { [key: string]: string }): string {
    let result = mappedRoute;

    Object.keys(params).forEach(key => {
      result = result.replace(`:${key}`, params[key]);
    })

    return result;
  }
}
