<header class="shell">
  <div class="hero">
    <div class="hero__group">
      <h1 class="hero__title h1 m-h1 insurance__form_username">
        {{ 'forms.BOOKING_PATH.CONFIRMATION.TITLE' | translate : { name: authorized ? userName : policyHolderName } }}
      </h1>

      <p class="hero__text hero__subtitle text-normal m-t-small">{{ 'forms.BOOKING_PATH.CONFIRMATION.SUBTITLE' | translate }}</p>
      <p class="hero__text text-normal m-t-small">{{ 'forms.BOOKING_PATH.CONFIRMATION.DESCRIPTION' | translate }}</p>
      <p class="hero__text hero__text-value text-normal m-t-small">{{ 'forms.BOOKING_PATH.CONFIRMATION.PLAN_ID' | translate }} <b>{{ insuranceInfo.contractNumber }}</b></p>
      <p class="hero__text hero__text-value text-normal m-t-small">{{ 'forms.BOOKING_PATH.CONFIRMATION.ISSUE_DATE' | translate }} <b>{{ insuranceInfo.insuranceDetails.selectedProduct.requestDate | dateFormat }}</b></p>

      <ng-container *ngIf="authorized; else unauthorized">
        <div class="hero__action-list hero__action-list">
          <app-button class="hero__action_button" size="m" type="big-width" (click)="addPolicy()">
            {{ 'forms.BOOKING_PATH.CONFIRMATION.ADD_INSURANCE_BUTTON.TEXT' | translate }}
          </app-button>
        </div>

        <h6 class="hero__button-text h6 m-h5">
          {{ 'forms.BOOKING_PATH.CONFIRMATION.AUTHORIZED_MARKETING_MESSAGE' | translate }}
        </h6>
        <a [appAemRouterLink]="planDetailsLink" class="hero__action-link">
          <h6 class="hero__action-title h6 m-h5">{{ 'forms.BOOKING_PATH.CONFIRMATION.PLAN_DETAILS_LINK' | translate }}</h6>
        </a>
      </ng-container>

      <ng-template #unauthorized>
        <h6 class="hero__action-title h6 m-h5">{{ 'forms.BOOKING_PATH.CONFIRMATION.REGISTRATION_BUTTON.DESCRIPTION' | translate }}</h6>
        <div class="hero__action-list hero__action-list">
          <app-button class="hero__action_button" size="m" type="big-width" (click)="toRegistration()">
            {{ 'forms.BOOKING_PATH.CONFIRMATION.REGISTRATION_BUTTON.TEXT' | translate }}
          </app-button>
        </div>

        <h6 class="hero__action-title h6 m-h5">{{ 'forms.BOOKING_PATH.CONFIRMATION.LOGIN_BUTTON.DESCRIPTION' | translate }}</h6>
        <div class="hero__action-list hero__action-list">
          <app-button class="hero__action_button" size="m" type="big-width" theme="bordered" (click)="toLogin()">
            {{ 'forms.BOOKING_PATH.CONFIRMATION.LOGIN_BUTTON.TEXT' | translate }}
          </app-button>
        </div>

        <h6 class="hero__button-text h6 m-h5">
          {{ 'forms.BOOKING_PATH.CONFIRMATION.MARKETING_MESSAGE' | translate }}
        </h6>
      </ng-template>
    </div>

    <div class="hero-component">
      <div class="insurance__form">
        <div class="insurance__form-title">
          <h5 class="h5 m-h3 zero-margin">{{ insuranceInfo.insuranceDetails.selectedProduct.productOfferingName }}</h5>
          <h4 class="insurance__form-price h4 m-h1 zero-margin">
            {{ insuranceInfo.insuranceDetails.selectedProduct.grossPremium.amount | appCurrency: insuranceInfo.insuranceDetails.selectedProduct.grossPremium.currency }}
          </h4>
        </div>

        <p class="insurance__form-text zero-margin texm-small m-t-small insurance__form_username"
           *ngFor="let traveler of travelersNames">
          {{ 'forms.BOOKING_PATH.CONFIRMATION.INSURANCE_FORM.TRAVELER' | translate }}
          <b>{{ traveler }}</b>
        </p>

        <div class="insurance__form-trip texm-small m-t-small">
          <p class="insurance__form-subtitle zero-margin weight-bold">
            {{ 'forms.BOOKING_PATH.CONFIRMATION.INSURANCE_FORM.PLANS_DETAILS_TITLE' | translate }}
          </p>
          <p class="insurance__form-text zero-margin">
            {{ 'forms.BOOKING_PATH.CONFIRMATION.INSURANCE_FORM.COVERED_MESSAGE' | translate : {plan: insuranceInfo.insuranceDetails.selectedProduct.productOfferingName} }}
          </p>
          <p class="insurance__form-text zero-margin">
            {{ 'forms.BOOKING_PATH.CONFIRMATION.INSURANCE_FORM.TRIP_INFO' | translate : {
              startDate: insuranceInfo.insuranceDetails.tripDetails.startDate | dateFormat,
              endDate: insuranceInfo.insuranceDetails.tripDetails.endDate | dateFormat,
              destinations: destinations
            } }}
          </p>
          <p class="insurance__form-text zero-margin">
            {{ 'forms.BOOKING_PATH.CONFIRMATION.INSURANCE_FORM.TRAVELERS_COUNT' | translate : {count: insuranceInfo.insuranceDetails.travelersDetails.travellers.length} }}
          </p>
          <p class="insurance__form-text zero-margin">
            {{ 'forms.BOOKING_PATH.CONFIRMATION.INSURANCE_FORM.TRIP_RESIDENCE' | translate : { residence } }}
          </p>
        </div>

        <div class="insurance__form-holder texm-small m-t-small">
          <p class="insurance__form-subtitle zero-margin weight-bold">{{ 'forms.BOOKING_PATH.CONFIRMATION.INSURANCE_FORM.HOLDER_DETAILS_TITLE' | translate }}</p>
          <p class="insurance__form-text zero-margin insurance__form_username">{{policyHolderName}}</p>
          <p class="insurance__form-text zero-margin">{{insuranceInfo.insuranceDetails.travelersDetails.policyHolder.dateOfBirth | dateFormat}}</p>
          <p class="insurance__form-text zero-margin">{{insuranceInfo.insuranceDetails.travelersDetails.policyHolder.email}}</p>
          <p class="insurance__form-text zero-margin">
            {{insuranceInfo.insuranceDetails.travelersDetails.policyHolder.address}},
            {{insuranceInfo.insuranceDetails.travelersDetails.policyHolder.postCode}}
            {{insuranceInfo.insuranceDetails.travelersDetails.policyHolder.city}},
            {{residence}}
          </p>
        </div>
      </div>

      <h6 class="h6 m-h3">{{ 'forms.BOOKING_PATH.CONFIRMATION.INSURANCE_FORM.DOCUMENTS_TITLE' | translate }}</h6>

      <div class="insurance__form-documents">
        <app-document-button *ngFor="let document of documents"
                             class="insurance__form-documents-item"
                             action="new-tab"
                             type="PDF"
                             [label]="document.documentInfo.documentType | appDocumentTypeTranslate"
                             [link]="document.documentInfo.url">
        </app-document-button>
      </div>
    </div>
  </div>
</header>