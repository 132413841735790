import { FormControl } from "@angular/forms";
import { Observable, of } from "rxjs";
import { TripNotificationSelectOption } from "./notification.component";
import { ServicePartner } from "@common/profile/profile.interfaces";

export enum NotificationOptions {
  'none',
  'sms',
  'email',
  'sms_and_email'
}

export enum LanguageOptions {
  'EN',
  'FR'
}

export enum ConsentType {
  'INITIATED_MARKETING' = 'INITIATED_MARKETING',
  'GLOBAL_MARKETING' = 'GLOBAL_MARKETING',
  'INTERNAL_MARKETING' = 'INTERNAL_MARKETING',
  'EXTERNAL_MARKETING' = 'EXTERNAL_MARKETING',
  'MARKETING_CAMPAIGN' = 'MARKETING_CAMPAIGN',
  'EMAIL' = 'EMAIL',
  'SMS' = 'SMS',
  'PUSH' = 'PUSH'
}

export interface MarketingConsents {
  'GLOBAL_MARKETING'?: boolean,
  'INTERNAL_MARKETING'?: boolean,
  'EXTERNAL_MARKETING'?: boolean,
}

export interface ConsentValue {
  EMAIL: string;
  SMS: string;
  PUSH: string;
}

export interface SelectedConsent {
  consentType: ConsentType;
  consentValue: boolean;
  isBlocked: boolean;
}

export interface NotificationSelect {
  name: string,
  partnerName: string,
  label?: string,
  tooltip?: string,
  selectedConsent: { consentType: string, consentValue: boolean, isBlocked?: boolean; }[];
  selectOptions$?: Observable<TripNotificationSelectOption[]>,
  formControl: FormControl,
  defaultOptions: NotificationSelectOption[]
}

interface NotificationSelectOption {
  title: string,
  value: number
}

export const NOTIFICATION_SELECTS: NotificationSelect[] = [
  {
    name: 'tripNotification',
    partnerName: ServicePartner.CRISIS24,
    selectedConsent: [],
    label: 'pages.NOTIFICATION.LABELS.SECURITY_ALERTS',
    tooltip: 'pages.NOTIFICATION.TOOLTIP.SECURITY_ALERTS',
    selectOptions$: of([]),
    formControl: null,
    defaultOptions: [
      {
        title: 'pages.NOTIFICATION.OPTIONS.EMAIL_AND_SMS',
        value: NotificationOptions.sms_and_email
      },
      {
        title: 'pages.NOTIFICATION.OPTIONS.EMAIL_ONLY',
        value: NotificationOptions.email
      },
      {
        title: 'pages.NOTIFICATION.OPTIONS.NONE',
        value: NotificationOptions.none
      }
    ]
  },
  {
    name: 'flightNotification',
    partnerName: ServicePartner.AMADEUS,
    selectedConsent: [],
    label: 'pages.NOTIFICATION.LABELS.FLIGHT_ALERTS',
    tooltip: 'pages.NOTIFICATION.TOOLTIP.FLIGHT_ALERTS',
    selectOptions$: of([]),
    formControl: null,
    defaultOptions: [
      {
        title: 'pages.NOTIFICATION.OPTIONS.EMAIL_AND_SMS',
        value: NotificationOptions.sms_and_email
      },
      {
        title: 'pages.NOTIFICATION.OPTIONS.EMAIL_ONLY',
        value: NotificationOptions.email
      },
      {
        title: 'pages.NOTIFICATION.OPTIONS.SMS_ONLY',
        value: NotificationOptions.sms
      },
      {
        title: 'pages.NOTIFICATION.OPTIONS.NONE',
        value: NotificationOptions.none
      }
    ]
  },
  {
    name: 'loungeAccess',
    partnerName: ServicePartner.COLLINSON,
    selectedConsent: [],
    label: 'pages.NOTIFICATION.LABELS.LOUNGE',
    tooltip: 'pages.NOTIFICATION.TOOLTIP.LOUNGE',
    selectOptions$: of([]),
    formControl: null,
    defaultOptions: [
      {
        title: 'pages.NOTIFICATION.OPTIONS.EMAIL_AND_SMS',
        value: NotificationOptions.sms_and_email
      },
      {
        title: 'pages.NOTIFICATION.OPTIONS.EMAIL_ONLY',
        value: NotificationOptions.email
      }
    ]
  },
  {
    name: 'flightClaim',
    partnerName: ServicePartner.FLIGHT_RIGHT,
    selectedConsent: [],
    label: 'pages.NOTIFICATION.LABELS.FLIGHT_CLAIM',
    tooltip: 'pages.NOTIFICATION.TOOLTIP.FLIGHT_CLAIM',
    selectOptions$: of([]),
    formControl: null,
    defaultOptions: [
      {
        title: 'pages.NOTIFICATION.OPTIONS.EMAIL_AND_SMS',
        value: NotificationOptions.sms_and_email
      },
      {
        title: 'pages.NOTIFICATION.OPTIONS.EMAIL_ONLY',
        value: NotificationOptions.email
      },
      {
        title: 'pages.NOTIFICATION.OPTIONS.SMS_ONLY',
        value: NotificationOptions.sms
      }
    ]
  }
]

