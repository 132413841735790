import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Renderer2, Inject } from '@angular/core';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { takeUntil } from 'rxjs';
import { DISABLE_GLOBAL_SCROLL_CLASS } from '../../app.const';
import { GlobalLoaderService } from './global-loader.service';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class GlobalLoaderComponent {
  constructor(
    public loaderService: GlobalLoaderService,
    private destroy$: TuiDestroyService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.loaderService.loading$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((loading) => {
      if (loading) {
        this.renderer.addClass(this.document.body, DISABLE_GLOBAL_SCROLL_CLASS);
      } else {
        this.renderer.removeClass(this.document.body, DISABLE_GLOBAL_SCROLL_CLASS);
      }
    });
  }
}
