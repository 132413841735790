<div class="article">
  <img class="article_item-image" 
       [src]="article.image.imagePath" 
       [appAemRouterLink]="article.link.link"
       [appImgAlt]="article.image.altText"
       [appImgAltData]="article">
  <div class="article_content">
    <div *ngIf="thematicLinks" class="article_item-labels">
      <a *ngFor="let thematic of article.thematics"
         class="article_link"
         ref="nofollow"
         [appAemRouterLink]="thematicLinks[thematic.id].link"
         (click)="$event.stopPropagation()">
        <app-thematic [selected]="true" size="small">{{ thematic.title }}</app-thematic>
      </a>
    </div>
    <h5 class="zero-margin h5 m-h3" [appAemRouterLink]="article.link.link">
      {{ article.title }}
    </h5>
    <div class="article_item-synopsis t-small m-t-small" [appAemRouterLink]="article.link.link">
      {{ article.synopsis }}
    </div>
    <app-aem-button size="s" 
                    class="article_item-action"
                    type="full-width"
                    [cta]="{
                      _type: article.link._type,
                      link: article.link.link,
                      uuid: article.link.uuid,
                      label: 'pages.BLOG.ACTIONS.READ_MORE' | translate,
                      colour: 'bordered'
                    }">
    </app-aem-button>
  </div>
</div>
