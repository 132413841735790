import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DateRangePipe } from './date-range/date-range.pipe';
import { DateFormatPipe } from './date-formats/date-format.pipe';
import { HasTimeGapPipe } from './time-gap/has-time-gap.pipe';
import { CombineDateTimePipe } from './time-gap/combine-date-time.pipe';
import { AgePipe } from './age-pipe/age.pipe';

@NgModule({
  declarations: [
    DatePickerComponent,
    DateRangePipe,
    DateFormatPipe,
    CombineDateTimePipe,
    HasTimeGapPipe,
    AgePipe,
  ],
  exports: [
    DatePickerComponent,
    DateRangePipe,
    DateFormatPipe,
    CombineDateTimePipe,
    HasTimeGapPipe,
    AgePipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  providers: [DatePipe, HasTimeGapPipe]
})
export class DateModule {}
