<footer class="footer" *ngIf="(data$ | async) as data">
  <div class="footer__grid shell">
    <a class="footer__logo" [routerLink]="data.logoLink.link">
      <img class="footer__logo-img"
           [src]="data.logo.imagePath"
           [appImgAlt]="data.logo.altText"
           [appImgAltData]="data">
    </a>
    <nav class="footer-nav">
      <div class="footer-nav__column">
        <div class="footer-nav__link" *ngFor="let link of data.linksColumn1">
          <ng-container *ngTemplateOutlet="isRoute(link.link) ? route : hrefLink; context: {$implicit: link}"></ng-container>
        </div>
      </div>
      <div class="footer-nav__column">
        <div class="footer-nav__link" *ngFor="let link of data.linksColumn2">
          <ng-container *ngTemplateOutlet="isRoute(link.link) ? route : hrefLink; context: {$implicit: link}"></ng-container>
        </div>
      </div>
    </nav>
    <div class="footer__copyright copyright">
      <img class="copyright__img"
           [src]="data.copyrightLogo.imagePath"
           [appImgAlt]="data.copyrightLogo.altText"
           [appImgAltData]="data">
      <div class="copyright__text t-small m-t-small">{{ data.copyright | translate: {year} }}</div>
    </div>
  </div>
  <div *ngIf="data.legalNote" class="footer__legal-note shell text-small m-t-small">
    {{ data.legalNote }}
  </div>
</footer>

<ng-template let-link #route>
  <a class="footer-nav__link-label t-small"
     [attr.rel.nofollow]="link?.nofollow"
     [routerLink]="link?.link"
     [attr.id]="link?.id">
    {{ link?.label }}
  </a>
</ng-template>

<ng-template let-link #hrefLink>
  <a [attr.href]="link.link"
     [attr.rel]="link.nofollow ? 'nofollow' : null"
     [attr.id]="link?.id"
     class="footer-nav__link-label t-small"
     target="_blank">
    {{ link.label }}
  </a>
</ng-template>
