import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found.component';
import { NotFoundService } from "./not-found.service";
import { ButtonModule } from '../button/button.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [NotFoundComponent],
  exports: [NotFoundComponent],
  imports: [CommonModule, ButtonModule, TranslateModule, RouterModule],
  providers: [NotFoundService],
})
export class NotFoundModule {}
