import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ThematicComponent } from './thematic.component';

@NgModule({
  declarations: [ThematicComponent],
  exports: [ThematicComponent],
  imports: [CommonModule],
})
export class ThematicModule { }
