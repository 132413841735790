export const ITEM_ON_PAGE = 9;
export const DEFAULT_THEMATIC = {
  name: 'pages.BLOG.LABELS.ALL_THEMATIC',
  id: 'DEFAULT_ARTICLE',
  thematic: null as any,
};

export enum SortingType {
  timeAsc = 'timeASC',
  timeDesc = 'timeDESC',
}
