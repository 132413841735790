import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { catchError, delay, filter, finalize, map, Observable, switchMap, takeUntil, tap } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { ApiInformationService } from '@common/information/api-information.service';
import { FormFlightAddManually } from '@common/model/formFlightAddManually';
import { ApiFlightService } from '@common/flight/api-flight.service';
import { Airport, FlightInfo } from '@common/flight/flight.interfaces';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { wrapValidator } from '@kit/field-error/field-error.utils';
import { GlobalLoaderService } from '@kit/global-loader/global-loader.service';
import { ServerErrorsValidationService } from '@kit/server-errors/server-errors-validation.service';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { mapFlightDtoToFlightInfo } from '../../../flight.mapper';
import { Country } from '../../../trip.interfaces';
import { FLIGHT_NUMBER_REGEXP } from '../flight.const';
import { getAllFormErrors } from "@kit/utils/form";
import { HttpErrorResponse } from '@angular/common/http';



@Component({
  selector: 'app-add-flight-manually',
  templateUrl: './add-flight-manually.component.html',
  styleUrls: ['./add-flight-manually.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TuiDestroyService,
    ServerErrorsValidationService,
  ],
})
export class AddFlightManuallyComponent extends AemFragmentComponent implements OnInit {
  public flightForm: UntypedFormGroup;
  public countries$: Observable<Country[]> = this.informationService.getCountries();
  public departureAirport$: Observable<Airport[]>;
  public arrivalAirport$: Observable<Airport[]>;

  protected tripId = this.route.firstChild.snapshot.params.tripId;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly informationService: ApiInformationService,
    protected readonly analyticsService: AnalyticsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected bootstrapService: BootstrapService,
    protected readonly flightService: ApiFlightService,
    protected readonly validationService: ServerErrorsValidationService,
    protected readonly onDestroy$: TuiDestroyService,
    protected readonly loaderService: GlobalLoaderService,
    @Inject(AEM_DATA) data: FormFlightAddManually,
  ) {
    super(data);
  }

  ngOnInit(): void {
    this.flightForm = this.formBuilder.group({
      departureCountry: [null, wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank')],
      departureAirport: [null, wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank')],
      departureDate: [null, wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank')],
      departureTime: [null, wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank')],
      arrivalCountry: [null, wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank')],
      arrivalAirport: [null, wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank')],
      arrivalDate: [null, wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank')],
      arrivalTime: [null, wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank')],
      flightNumber: [
        this.route.snapshot.queryParams.flightNumber,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.pattern(FLIGHT_NUMBER_REGEXP), 'errors.CEAZ000_Pattern'),
        ]
      ],
      bookingNumber: [null],
    });

    this.flightForm.get('departureAirport').disable();
    this.flightForm.get('arrivalAirport').disable();

    this.flightForm.addAsyncValidators(this.validationService.createValidator());

    this.resetControlWhenChangeParent(this.flightForm.get('departureCountry'), this.flightForm.get('departureAirport'));
    this.resetControlWhenChangeParent(this.flightForm.get('arrivalCountry'), this.flightForm.get('arrivalAirport'));

    this.departureAirport$ = this.getAirports(this.flightForm.get('departureCountry'));
    this.arrivalAirport$ = this.getAirports(this.flightForm.get('arrivalCountry'));

    this.setErrorNoAirports(this.departureAirport$, this.flightForm.get('departureAirport'));
    this.setErrorNoAirports(this.arrivalAirport$, this.flightForm.get('arrivalAirport'));
  }

  goBack() {
    this.router.navigateByUrl(setRouterData(this.bootstrapService.link.flightAdd, { tripId: this.tripId }));
  }

  public addFlight(): void {
    this.flightForm.markAllAsTouched();

    if (getAllFormErrors(this.flightForm)?.length) {
      this.analyticsService.validationError(this.flightForm, this.constructor.name);
      return;
    }

    const { tripId } = this.route.firstChild.snapshot.params;
    const flight = mapFlightDtoToFlightInfo(this.flightForm.value);

    this.flightService.addFlightToTrip(tripId, flight).pipe(
      tap(() => this.loaderService.show()),
      delay(3000),
      switchMap(({ flightId }) => this.flightService.getFlight(tripId, flightId.toString())),
      catchError(err => this.handleServerError$(err)),
      finalize(() => this.loaderService.hide()),
      takeUntil(this.onDestroy$),
    ).subscribe((flight: FlightInfo) => {
      this.sendFlightAddedAnalytics(flight);
      this.navigateAfterFlightAdding(flight)
    });
  }

  private getAirports(control: AbstractControl) {
    return control.valueChanges.pipe(
      filter(country => !!country),
      switchMap(country => this.flightService.getAirports(country.code)),
      map(airports => airports.map((airport) => ({
        ...airport,
        name: `${airport.name} (${airport.code}) - ${airport.city}`
      }))),
    );
  }

  private resetControlWhenChangeParent(trigger: AbstractControl, change: AbstractControl) {
    trigger.valueChanges.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((value) => {
      value ? change.enable() : change.disable();
      change.reset();
    });
  }

  private setErrorNoAirports(trigger: Observable<Airport[]>, change: AbstractControl) {
    trigger.pipe(
      filter(airports => !airports?.length),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      change.markAsTouched();
      change.setErrors({ err: 'errors.CEAZ005' });
    })
  }

  private sendFlightAddedAnalytics(flight: FlightInfo): void {
    this.analyticsService.triggerAction({
      category: 'travel_wallet',
      action: 'flightAdded',
      label: `${flight.departure.date}_${flight.arrival.date}_${flight.arrival.country.name}`,
      value: 1,
    });
  }

  private navigateAfterFlightAdding(flight: FlightInfo): void {
    const canAddCotravelers = !flight.lessThan24hBeforeDeparture;

    this.router.navigateByUrl(setRouterData(
      canAddCotravelers
        ? this.bootstrapService.link.flightBenefits
        : this.bootstrapService.link.flightsAddedPage,
      { tripId: this.tripId, flightId: flight.flightId }
    ));
  }

  private handleServerError$(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    return this.validationService.handleServerError(err?.error);
  }
}
