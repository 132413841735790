import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PasswordComponent),
    multi: true
  }],
})
export class PasswordComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public placeholder = '';
  @Input() public autocomplete: 'new-password' | 'current-password' | 'off' = 'current-password';

  public innerControl = new UntypedFormControl(null);
  public focused = false;
  public disabled = false;
  public showText = false;
  private destroy$ = new Subject<null>();

  constructor() {}

  public ngOnInit(): void {
    this.innerControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => this.onChange(value));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public onChange = (value: string) => {};
  public onTouched = () => {};

  public writeValue(value: string): void {
    this.innerControl.setValue(value, { emitEvent: false });
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if(isDisabled) {
      this.innerControl.disable();
    } else {
      this.innerControl.enable();
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.onTouched();
  }

  public toggle(): void {
    this.showText = !this.showText;
  }
}
