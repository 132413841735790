import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CodeSnippet } from 'src/app/common/model/models';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';

@Component({
  selector: 'app-code-snippet',
  templateUrl: './code-snippet.component.html',
  styleUrls: ['./code-snippet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemCodeSnippetComponent extends AemBaseBlockComponent implements OnInit {
  @ViewChild('contentPlaceholder', { static: true }) private contentPlaceholder: ElementRef;

  constructor(
    private renderer: Renderer2,
    @Inject(AEM_DATA) public override data: CodeSnippet,
  ) {
    super(data);
  }

  ngOnInit() {
    const container = this.renderer.createElement('div');
    this.renderer.setProperty(container, 'innerHTML', this.data.code);

    container.childNodes.forEach((node: HTMLElement) => {
      const element = this.renderer.createElement(node.localName);
      node.getAttributeNames().forEach(attrName => {
        this.renderer.setAttribute(element, attrName, node.getAttribute(attrName));
      });
      this.renderer.setProperty(element, 'innerHTML', node.innerHTML);

      this.renderer.appendChild(this.contentPlaceholder.nativeElement, element);
    });
  }
}
