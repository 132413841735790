import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AltairWidgetComponent } from "@kit/altair-widget/altair-widget.component";

@NgModule({
  declarations: [AltairWidgetComponent],
  exports: [AltairWidgetComponent],
  imports: [CommonModule]
})
export class AltairWidgetModule { }
