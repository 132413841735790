import { Component, Input, OnInit } from '@angular/core';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { TripDetails } from 'src/app/pages/trip/trip.interfaces';
import { CountyImageService } from "@common/country-image/county-image.service";

@Component({
  selector: 'app-trip-card',
  templateUrl: './trip-card.component.html',
  styleUrls: ['./trip-card.component.scss']
})
export class TripCardComponent implements OnInit {
  @Input() public data: TripDetails;
  @Input() public allowMedia = true;

  public countryImagePath: string

  constructor(
    public readonly bootstrapService: BootstrapService,
    public readonly countyImageService: CountyImageService,
  ) { }

  ngOnInit(): void {
    this.countryImagePath = this.countyImageService.getCountyImagePath(this.data.destinationPoints[0]?.country?.code);
  }

  public onImageLoadError(): void {
    this.countryImagePath = this.countyImageService.getDefaultImagePath();
  }
}
