import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { TripDetails, TripsList } from '@pages/trip/trip.interfaces';
import { TripService } from '@pages/trip/trip.service';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { Observable, map, takeUntil } from 'rxjs';
import { TripHero } from 'src/app/common/model/tripHero';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';

@Component({
  selector: 'app-merge-trip-successfully',
  templateUrl: './merge-trip-successfully.component.html',
  styleUrls: ['./merge-trip-successfully.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class MergeTripSuccessfullyComponent extends AemFragmentComponent implements OnInit {
  public trips$: Observable<TripDetails[]>;

  constructor(
    public readonly bootstrapService: BootstrapService,
    private readonly router: Router,
    private readonly tripService: TripService,
    private readonly destroy$: TuiDestroyService,
    @Inject(AEM_DATA) public override data: TripHero & { component: MergeTripSuccessfullyComponent },
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.trips$ = this.getTrips$();
  }

  public getTrips$(): Observable<TripDetails[]> {
    return this.tripService.getTrips()
      .pipe(
        map((list: TripsList) => [...list.upcomingTrips, ...list.currentTrips]),
        map((list: TripDetails[]) => list.sort((a: TripDetails, b: TripDetails) =>
          Date.parse(a.startDate) - Date.parse(b.startDate))),
      )
  }

  public goBack(): void {
    this.trips$.pipe(takeUntil(this.destroy$)).subscribe(trips => {
      if(trips.length <= 1) {
        this.goBackToMyTrips();

        return;
      }

      this.router.navigateByUrl(this.bootstrapService.link.mergeTripsPage);
    })
  }

  public goBackToMyTrips(): void {
    this.router.navigateByUrl(this.bootstrapService.link.trips);
  }
}
