<div class="shell">
  <div [ngClass]="!aemData.fullWidth ? 'widget-border' : ''" class="widget-wrapper">
    <div *ngIf="aemData.title" class="h1 widget-title">{{aemData.title}}</div>
    <section class="shell">
      <div *ngIf="isAvailable$ | async; else: notAvailable" #componentContainerRef></div>
    </section>
  </div>
</div>


<ng-template #notAvailable>
  This widget is not available in your country
</ng-template>
