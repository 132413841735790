import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnalyticsService } from '@common/analytics/analytics.service';

@Component({
  selector: 'app-helpful',
  templateUrl: './helpful.component.html',
  styleUrls: ['./helpful.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqHelpfulComponent {
  @Input() faqId: string;

  showHelpfulBlock = true;

  constructor(
    private analyticsService: AnalyticsService,
  ) { }

  onClick(isHelpful: boolean): void {
    this.analyticsService.triggerAction({
      category: 'customer_care',
      action: 'clickFAQ',
      label: this.faqId,
      value: isHelpful ? 1 : 0,
    });

    this.showHelpfulBlock = false;
  }
}
