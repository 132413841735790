<div [appAemRouterLink]="bootstrapService.link.tripWallet"
     [aemRouterData]="{ tripId: data?.tripId }"
     [class.allowMedia]="allowMedia"
     class="card-container">
  <div class="trip-card_avatar">
    <img [src]="countryImagePath"
         (error)="onImageLoadError()"
         [appImgAlt]="data?.name">
  </div>
  <div class="trip-card_title-container">
    <div class="trip-card_title"
         [appTooltip]="data?.name"
         [tooltipOnlyExtraContent]="true">
      {{ data?.name }}
    </div>
  </div>
  <div class="trip-card_date text-normal">
    <div class="trip-card_date-text">
      {{ data | dateRange }}
    </div>
    <app-icon *ngIf="data.hasFlightsNotification"
              class="updates-icon"
              icon="info-fill"
              size="large"
              theme="inherit">
    </app-icon>
  </div>
  <div class="trip-card_actions">
    <app-button id="action-trip-go-to-edit"
                class="large-button"
                type="full-width"
                size="m"
                theme="black">
      {{ 'forms.TRIP.ACTIONS.SEE_TRIP' | translate }}
      <app-icon *ngIf="data.hasFlightsNotification"
                class="updates-icon"
                icon="info-fill"
                size="large"
                theme="inherit">
      </app-icon>
    </app-button>
    <app-icon icon="arrow-right"
              class="small-button"
              theme="inherit"
              size="large">
    </app-icon>
  </div>
</div>
