import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { EventDigitalData } from '@common/analytics/digital-data.interface';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareComponent {
  @Input() text: string;
  @Input() theme: 'black' | 'bordered' = 'black';

  url = this.document.location.href;

  constructor(
    private readonly analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public sendAnalytics(socialMedia: string): void {
    const event: EventDigitalData = {
      category: 'social_media',
      action: 'allyzShared',
      label: socialMedia,
      value: 1,
    };

    this.analyticsService.triggerAction(event);
  }
}
