import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, shareReplay } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { ActiveInsurancePolicies } from 'src/app/common/model/activeInsurancePolicies';
import { AemBaseBlockComponent } from 'src/app/kit/aem-base-block/aem-base-block';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';
import { Policy } from '../../policy.interface';
import { setRouterData } from '@kit/utils/router.utils';
import { dateToEndDay } from "@kit/utils/date.utils";

@Component({
  selector: 'app-insurance-active-policies',
  templateUrl: './insurance-active-policies.component.html',
  styleUrls: ['./insurance-active-policies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceActivePoliciesComponent extends AemBaseBlockComponent {
  public policies$: Observable<Policy[]> = this.policyService.getPolicies().pipe(
    map((policies: Policy[]) => this.filterPoliciesByEndDate(policies)),
    map((policies: Policy[]) => this.sortPolicyByDate(policies)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    @Inject(AEM_DATA) public aemData: ActiveInsurancePolicies,
    public readonly bootstrapService: BootstrapService,
    private readonly policyService: ApiInsurancePolicyService,
    private readonly router: Router,
  ) {
    super(aemData);
  }

  public onViewDetails(policyNumber: string, policeOrigin: string, openPolicyNumber: string): void {
    if (openPolicyNumber) {
      this.policyService.showOpenPolicyPopUp();

      return;
    }

    const policyCode: string = btoa(policyNumber);

    this.policyService.getPolicyDetails(policyNumber, policeOrigin)
      .subscribe(() => this.router.navigate([setRouterData(
        this.bootstrapService.link.insurancePolicy,
        { policyId: policyCode },
      )]));
  }

  private filterPoliciesByEndDate(policies: Policy[]): Policy[] {
    return policies.filter((policy: Policy) =>
      dateToEndDay(new Date(policy.endDate)).valueOf() >= dateToEndDay(new Date()).valueOf());
  }

  private sortPolicyByDate(policies: Policy[]): Policy[] {
    return policies.sort((a: Policy, b: Policy) =>
      new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf());
  }
}
