import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnInit,
  Renderer2
} from '@angular/core';
import { AemFragmentComponent } from "@kit/aem-base-block/aem-fragment";
import { AEM_DATA } from "@pages/dynamic/dynamic-render/dynamic-render.const";
import { UniversalHero } from "@common/model/universalHero";
import { distinctUntilChanged, map, Observable, of } from "rxjs";
import { UserService } from "@common/user/user.service";
import { DynamicContent } from "@pages/dynamic/dynamic-render/dynamic-render.interface";
import { DynamicRenderService } from "@pages/dynamic/dynamic-render/dynamic-render.service";
import { DOCUMENT } from '@angular/common';
import { WindowRef } from '@common/window-service/window.service';
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';
import { UserProfile, UserType } from '@common/profile/profile.interfaces';

@Component({
  selector: 'app-aem-universal-hero',
  templateUrl: './aem-universal-hero.component.html',
  styleUrls: ['./aem-universal-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemUniversalHeroComponent extends AemFragmentComponent implements OnInit, AfterViewInit {
  public dynamicLeftSideContent$: Observable<DynamicContent[]>;
  public dynamicRightSideContent$: Observable<DynamicContent[]>;
  public titleAlignment: string = this.data?.titleAlignment || 'left';
  public userData$: Observable<UserProfile>;
  public leftBlockOnlyTitle: boolean = false;

  constructor(
    public userService: UserService,
    private dynamicRenderService: DynamicRenderService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private windowRef: WindowRef,
    @Inject(DOCUMENT) private document: Document,
    @Inject(IS_BROWSER_PLATFORM) private isBrowser: boolean,
    @Inject(AEM_DATA) public override data: UniversalHero,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.initDynamicComponents();
    this.initResizeListener();
    this.userData$ = this.getUserDataIfNeed$();
    this.leftBlockOnlyTitle = (this.data.leftColumnComponents.length === 0) && !this.data.text && !!this.data.title;
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => this.resizeTextBlock(), 0);
    });
  }

  private initDynamicComponents(): void {
    this.dynamicLeftSideContent$ = this.userService.userSubscription$
      .pipe(
        distinctUntilChanged(),
        map((userSubscription: UserType) =>
          this.dynamicRenderService.prepareContent(this.data.leftColumnComponents, userSubscription)
        )
      );

    this.dynamicRightSideContent$ = this.userService.userSubscription$
      .pipe(
        distinctUntilChanged(),
        map((userSubscription: UserType) =>
          this.dynamicRenderService.prepareContent(this.data.rightColumnComponents, userSubscription)
        )
      );
  }

  private initResizeListener(): void {
    if (this.isBrowser) {
      this.windowRef.nativeWindow.addEventListener('resize', () => {
        this.resizeTextBlock();
      });
    }
  }

  private resizeTextBlock(): void {
    const richText = this.document.getElementById('header-rich-text');

    if (!richText?.firstChild) return;

    Array.from(richText.children).forEach((element: Element) => {
      this.renderer.setAttribute(element, 'class', 'header-text-element');
    });

    const headerTextElements = richText.getElementsByClassName('header-text-element');
    let blockHeight = 0;

    Array.from(headerTextElements).forEach((element: Element) => {
      blockHeight = blockHeight + ((element as HTMLElement)?.offsetHeight || 0);
    });

    const contentBlock = this.document.getElementById('header-content');

    if (!blockHeight || !contentBlock) return;

    const marginValue = (headerTextElements.length + 1) * 18;
    this.renderer.setStyle(contentBlock, 'grid-template-rows', `auto ${blockHeight + marginValue}px auto`);
    this.cdr.detectChanges();
  }

  private getUserDataIfNeed$(): Observable<UserProfile> {
    return this.data?.text?.includes('userMembership')
      ? this.userService.reloadUserInfo$()
      : of({} as UserProfile);
  }
}
