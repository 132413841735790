<app-button id="action-open-context-menu" 
            class="context-button" 
            type="icon-button" 
            [theme]="displayMenu ? 'purple' : 'white'" 
            (click)="toggleMenu()" 
            size="inherit">
  <div class="dots">...</div>
</app-button>

<div *ngIf="displayMenu" class="context-menu">
  <ng-content></ng-content>
</div>

