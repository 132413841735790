import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSelectMultipleComponent } from "@kit/search-select-multiple/search-select-multiple.component";
import { SearchSelectModule } from "@kit/search-select/search-select.module";
import { ButtonModule } from "@kit/button/button.module";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [SearchSelectMultipleComponent],
  exports: [SearchSelectMultipleComponent],
  imports: [
    CommonModule,
    SearchSelectModule,
    ButtonModule,
    ReactiveFormsModule
  ]
})
export class SearchSelectMultipleModule { }
