import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { takeUntil } from 'rxjs';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { ThematicLinks } from '@common/blog/blog.interface';
import { BlogService } from '@common/blog/blog.service';
import { BlogHero } from '@common/model/blogHero';
import { Tag } from '@common/model/tag';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';


@Component({
  selector: 'app-aem-blog-hero',
  templateUrl: './aem-blog-hero.component.html',
  styleUrls: [
    '../../../aem-hero-block/aem-hero-block.component.scss',
    './aem-blog-hero.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class AemBlogHeroComponent extends AemFragmentComponent implements OnInit {
  public thematics: Tag[];
  public thematicLinks: ThematicLinks;

  constructor(
    @Inject(AEM_DATA) public override data: BlogHero,
    private blogService: BlogService,
    private activatedRoute: ActivatedRoute,
    private onDestroy$: TuiDestroyService,
    private cdr: ChangeDetectorRef,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.loadBlogData();
  }

  private loadBlogData(): void {
    this.blogService.data$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(data => {
      const uuid = this.activatedRoute.snapshot.firstChild.data?.uuid;
      this.thematics = data.articles.data[uuid]?.thematics.sort((a, b) => a.title.localeCompare(b.title));

      this.thematicLinks = Object.values(data.thematics.data).reduce<ThematicLinks>((acc, item) => {
        acc[item.tag.id] = item.link;
        return acc;
      }, {});

      this.cdr.detectChanges();
    });
  }
}
