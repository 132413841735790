import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { GlobalLoaderService } from './global-loader.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalLoaderInterceptor implements HttpInterceptor {
  constructor(
    private readonly loaderService: GlobalLoaderService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.show();
    return next.handle(request).pipe(
      finalize(() => this.loaderService.hide())
    );
  }
}
