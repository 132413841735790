import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { catchError, EMPTY, filter, switchMap, takeUntil } from 'rxjs';
import { ApiProfileService } from 'src/app/common/profile/api-profile.service';
import { CoTraveler } from 'src/app/common/profile/profile.interfaces';
import { UserService } from 'src/app/common/user/user.service';
import { wrapValidator } from 'src/app/kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { DynamicComponentAddCotravelerForm } from '@common/model/dynamicComponentAddCotravelerForm';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { getAllRouterParams, setRouterData } from '@kit/utils/router.utils';
import { notBlankValidator } from '@kit/utils/validators';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { CREDENTIAL_REGEXP } from '../co-travelers.const';

@Component({
  selector: 'app-manage-travalers',
  templateUrl: './manage-travalers.component.html',
  styleUrls: ['./manage-travalers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ServerErrorsValidationService,
    TuiDestroyService
  ],
})
export class AemManageTravalersComponent extends AemFragmentComponent implements OnInit {
  public manageTravalersForm!: UntypedFormGroup;
  public cotravelerId: number;

  private cotraveler: CoTraveler;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly validationService: ServerErrorsValidationService,
    private readonly onDestroy$: TuiDestroyService,
    private readonly cotravelersService: ApiProfileService,
    private readonly analyticsService: AnalyticsService,
    private readonly userService: UserService,
    public readonly bootstrapService: BootstrapService,
    @Inject(AEM_DATA) data: DynamicComponentAddCotravelerForm,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.initForm();
    this.cotravelerId = +this.route.firstChild.snapshot.params.cotravelerId;

    if (this.cotravelerId) {
      this.cotravelersService.getCotravelerById(this.cotravelerId).pipe(
        filter(Boolean),
        takeUntil(this.onDestroy$)
      ).subscribe(data => {
        this.cotraveler = data;
        this.setInitData();
      });
    }
  }

  public save(): void {
    this.manageTravalersForm.markAllAsTouched();

    if (!this.manageTravalersForm.invalid) {
      return this.cotraveler ? this.edit() : this.add();
    }

    this.analyticsService.validationError(this.manageTravalersForm, this.constructor.name);
  }

  private initForm(): void {
    this.manageTravalersForm = this.fb.group({
      name: [
        null, [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.maxLength(50), 'errors.CEAZ000_Size'),
          wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern')
        ],
        this.validationService.createValidator('name'),
      ],
      surname: [
        null, [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotNull'),
          wrapValidator(Validators.maxLength(50), 'errors.CEAZ000_Size'),
          wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern')
        ],
        this.validationService.createValidator('surname'),
      ]
    });

    this.manageTravalersForm.addAsyncValidators(this.validationService.createValidator());
  }

  private edit(): void {
    this.manageTravalersForm.markAllAsTouched();

    if (!this.manageTravalersForm.invalid) {
      this.cotravelersService.editCotraveler({
        id: this.cotraveler.id,
        ...this.manageTravalersForm.value
      }).pipe(
        catchError(err => {
          this.analyticsService.validationServerError(err?.error, this.constructor.name);
          this.validationService.handleServerError(err?.error);

          return EMPTY;
        }),
        takeUntil(this.onDestroy$)
      ).subscribe(() => {
        this.router.navigateByUrl(setRouterData(this.bootstrapService.link.cotravelers, getAllRouterParams(this.route.snapshot.root)));
      });

      return;
    }

    this.analyticsService.validationError(this.manageTravalersForm, this.constructor.name);
  }

  private add(): void {
    this.manageTravalersForm.markAllAsTouched();

    if (!this.manageTravalersForm.invalid) {
      this.cotravelersService.addCotraveler({
        name: this.manageTravalersForm.value.name?.trim(),
        surname: this.manageTravalersForm.value.surname?.trim()
      }).pipe(
        catchError(err => {
          this.analyticsService.validationServerError(err?.error, this.constructor.name);
          this.validationService.handleServerError(err?.error);

          return EMPTY;
        }),
        switchMap(() => this.userService.reloadUserInfo$()),
        takeUntil(this.onDestroy$)
      ).subscribe(() => {
        this.router.navigateByUrl(setRouterData(this.bootstrapService.link.cotravelers, getAllRouterParams(this.route.snapshot.root)));
      });

      return;
    }

    this.analyticsService.validationError(this.manageTravalersForm, this.constructor.name);
  }

  private setInitData(): void {
    this.manageTravalersForm.setValue({
      name: this.cotraveler.name,
      surname: this.cotraveler.surname
    }, {
      emitEvent: false
    });
  }
}
