import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { catchError, filter, fromEvent, map, Observable, switchMap, take, takeUntil, tap } from 'rxjs';
import { BookingPath, QuotationResponseContractContractList } from '@common/booking-path/booking-path.interfaces';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from '@kit/dialog/dialog.service';
import { mapPaymentFormRespToDTO } from '../../booking-path.mapper';
import { BookingService } from '../booking.service';
import { BookingPathStep } from '../booking-path-step.enum';
import { DigitalDataService } from '@common/analytics/digital-data.service';
import { DialogRef } from "@kit/dialog/dialog.interfaces";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService]
})
export class PaymentComponent implements OnInit {
  @Output() public done = new EventEmitter<void>();

  public get bookingPathData(): BookingPath {
    return this.bookingService.bookingPathData;
  };

  public get paymentUrl(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.bookingService.paymentUrl);
  }

  public get currentPlan(): QuotationResponseContractContractList {
    return this.bookingService.selectedPlanDetails;
  }

  public destinations: string;

  private paymentMessage$ = fromEvent(window, 'message').pipe(
    map((event: any) => event.data?.paymentResponse),
    filter(resp => resp && resp?.status === 'Success'),
  );

  constructor(
    private readonly bookingService: BookingService,
    private readonly sanitizer: DomSanitizer,
    private readonly destroy$: TuiDestroyService,
    private readonly translateService: TranslateService,
    private readonly dialogService: DialogService,
    private readonly router: Router,
    private readonly digitalDataService: DigitalDataService,
  ) {}

  public ngOnInit(): void {
    this.destinations = this.getCountryNames(this.bookingPathData?.tripDetails?.destinationLocation?.names);

    this.paymentMessage$
      .pipe(
        take(1),
        switchMap((formResp: any) => this.onSuccessPayment(formResp)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.digitalDataService.updatePageName(BookingPathStep.PAYMENT_INFO);
  }

  private onSuccessPayment(formResp: any): Observable<void> {
    return this.bookingService.completeStep({
      ...this.bookingPathData,
      iframeResponse: mapPaymentFormRespToDTO(formResp),
    }).pipe(
      tap(() => this.done.emit(null)),
      catchError(() => this.handleError(formResp.transactionID)),
    );
  }

  private handleError(transactionId: string): Observable<any> {
    return this.showFailureDialog(transactionId)
      .afterClosed$.pipe(tap((value) => this.handleFailureDialogAction(value)))
  }

  private showFailureDialog(transactionId: string): DialogRef<ConfirmationDialogComponent> {
    return this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      title: this.translateService.instant('forms.BOOKING_PATH.PAYMENT.FAILURE_DIALOG.TITLE'),
      info: this.translateService.instant('forms.BOOKING_PATH.PAYMENT.FAILURE_DIALOG.MESSAGE', { transactionId: transactionId }),
      confirmTitle: this.translateService.instant('forms.BOOKING_PATH.PAYMENT.FAILURE_DIALOG.BUTTON'),
    })
  }

  private handleFailureDialogAction(value: string): void {
    if (value === ConfirmationDialogComponent.CONFIRM) {
      this.router.navigateByUrl('/');

      return;
    }

    this.bookingService.newQuote();
  }

  public selectStep(step: number): void {
    this.bookingService.selectStep(step);
  }

  private getCountryNames(codes: string[]): string {
    return codes
      .map(code => this.translateService.instant(`countries.${code}`))
      .join(', ');
  }
}
