<section class="service" *tuiLet="(userSubscription$ | async) as userSubscription">
  <div class="shell">
    <div class="service__header" *ngIf="data.title">
      <h2 class="service__title">{{ data.title }}</h2>
    </div>
    <div class="service__body">
      <div class="service-cards">
        <div *ngFor="let item of data.items"
             [attr.id]="item.id"
             [appAemRouterLink]="item.entitlements[userSubscription] ? item.link.link : null"
             [class.service-card_disabled]="!item.entitlements[userSubscription]"
             class="service-card">
          <ng-container [ngSwitch]="getHeaderType(item)">
            <ng-container *ngSwitchCase="'memberHeader'" [ngTemplateOutlet]="memberHeader"></ng-container>
            <ng-container *ngSwitchCase="'plusHeader'" [ngTemplateOutlet]="plusHeader"></ng-container>
            <ng-container *ngSwitchCase="'memberPlusHeader'" [ngTemplateOutlet]="memberPlusHeader"></ng-container>
            <ng-container *ngSwitchCase="'plusCyberHeader'" [ngTemplateOutlet]="plusCyberHeader"></ng-container>
            <ng-container *ngSwitchDefault [ngTemplateOutlet]="memberHeader"></ng-container>
          </ng-container>
          <div class="service-card__body">
            <div>
              <div class="h5 m-h3 service-card__title">{{ item.title }}</div>
              <img *ngIf="item.image"
                   [src]="item.image.imagePath"
                   [appImgAlt]="item.image.altText"
                   [appImgAltData]="item"
                   class="service-card__img">
              <div class="service-card_text t-normal m-t-normal">
                <app-safe-html [content]="item.text"></app-safe-html>
              </div>
            </div>
            <div class="service-card__footer">
              <app-safe-html [content]="$any(item)[userSubscription + 'Text']"
                             class="service-card__footer_content t-normal m-t-normal">
              </app-safe-html>
              <app-button [icon]="item.entitlements[userSubscription] ? item.iconAllowed : item.iconDenied"
                          [ngClass]="{'service-card__button_disabled' : !item.entitlements[userSubscription] }"
                          [theme]="item.entitlements[userSubscription] ? 'black' : 'white'"
                          class="service-card__button"
                          size="s"
                          type="icon-button">
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #memberHeader>
  <div class="service-card__header">
    <div class="service-card__header__block lilac">
      <span class="service-card__header__text t-small">{{ 'global.ALLYZ_MEMBER' | translate }}</span>
    </div>
  </div>
</ng-template>

<ng-template #plusHeader>
  <div class="service-card__header">
    <div class="service-card__header__block blue">
      <app-icon icon="allyz" class="service-card__header__icon"></app-icon>
      <span class="service-card__header__text t-small">Plus Travel</span>
    </div>
  </div>
</ng-template>

<ng-template #memberPlusHeader>
  <div class="service-card__header two-frames">
    <div class="service-card__header__block-left lilac">
      <span class="service-card__header__text t-small">{{ 'global.ALLYZ_MEMBER' | translate }}</span>
    </div>
    <div class="service-card__header__block-right blue">
      <app-icon icon="allyz" class="service-card__header__icon"></app-icon>
      <span class="service-card__header__text t-small">Plus Travel</span>
    </div>
  </div>
</ng-template>

<ng-template #plusCyberHeader>
  <div class="service-card__header two-frames">
    <div class="service-card__header__block-left blue">
      <app-icon icon="allyz" class="service-card__header__icon"></app-icon>
      <span class="service-card__header__text t-small">Plus Travel</span>
    </div>
    <div class="service-card__header__block-right aquamarine">
      <app-icon icon="allyz" class="service-card__header__icon"></app-icon>
      <span class="service-card__header__text t-small">Plus Cyber</span>
    </div>
  </div>
</ng-template>
