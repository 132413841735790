import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { RouterConfigurationPage } from '../model/routerConfigurationPage';
import { UserService } from '../user/user.service';
import { RouterDataResolver } from './router.data.resolver';
import { RootPaths } from '../../app.const';
import { IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';

@Injectable({
  providedIn: 'root'
})
export class CanRestrictedRouteGuard  {
  constructor(
    private userService: UserService,
    private router: Router,
    @Inject(IS_SERVER_PLATFORM) private isServer: boolean,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean {
    if (this.isServer) {
      return true;
    }

    const data: Partial<RouterConfigurationPage> = RouterDataResolver.dataUrls.get(route.routeConfig.path);
    const termsAndConditionsPaths = ['allyz-mises-a-jour', 'aggiornamenti-recenti-di-allyz', 'nieuwe-updates-van-allyz', 'allyz-actualizaciones', 'neue-updates-von-allyz', 'en-fr/allyz-new-updates'];

    if (data.entitlements) {
      if (termsAndConditionsPaths.includes(route.routeConfig.path)) {
        return true;
      }
      return this.userService.userSubscription$.pipe(map(userSubscription => {
        if (!data.entitlements[userSubscription]) {
          return this.router.createUrlTree([data.location || RootPaths.Forbidden], {
            queryParams: route.queryParams
          });
        }

        return true;
      }))
    }

    return true;
  }

}
