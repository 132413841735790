import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { TuiLetModule } from '@taiga-ui/cdk';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { ButtonModule } from '../button/button.module';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { AemServiceCardsComponent } from './aem-service-cards.component';
import { IconModule } from "@kit/icon/icon.module";
import { TranslateModule } from "@kit/translate/translate.module";

@NgModule({
  declarations: [AemServiceCardsComponent],
  exports: [AemServiceCardsComponent],
  imports: [
    CommonModule,
    ButtonModule,
    SafeHtmlModule,
    AemRouterModule,
    TuiLetModule,
    ImgAltDirectiveModule,
    IconModule,
    TranslateModule,
  ]
})
export class AemServiceCardsModule { }
