import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from './auth.service';
import { anonymousAllowedUrls } from "@common/auth/auth.const";

@Injectable()
export class AnonymousTokenInterceptor implements HttpInterceptor {
  private get authService(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(private injector: Injector) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isAllowedUrl(req.url)) {
      return next.handle(req);
    }

    return this.authService.loginResult$.pipe(
      switchMap(() => {
        const anonymousToken = !this.authService.hasValidOfflineAnonymousToken
          ? this.authService.refreshAnonymousToken()
          : of(null);

        return anonymousToken.pipe(
          switchMap(() => this.authService.anonymousToken$),
          switchMap((token: string) => next.handle(this.injectAnonymousToken(req, token))),
        );
      })
    );
  }

  private isAllowedUrl(url: string): boolean {
    return anonymousAllowedUrls.some(allowed => url.startsWith(allowed));
  }

  private injectAnonymousToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    const headers = req.headers.set('Authorization', 'Bearer ' + token);

    return req.clone({ headers });
  }
}
