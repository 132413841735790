import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Activity, ActivityCategory, ActivityDto, ActivityIdentification } from './activity-element.interface';

@Injectable({
  providedIn: 'root',
})
export class ActivityElementService {
  private baseUrl = environment.api.baseUrl;
  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService
  ) { }

  public get(tripId: string, activityId: string): Observable<Activity> {
    return this.http.get<Activity>(`${this.baseUrl}/trips/${tripId}/activities/${activityId}`);
  }

  public create(tripId: string, activity: ActivityDto): Observable<ActivityIdentification> {
    return this.http.post<Activity>(`${this.baseUrl}/trips/${tripId}/activities`, activity);
  }

  public save(tripId: string, activityId: string, activity: ActivityDto): Observable<Activity> {
    return this.http.put<Activity>(`${this.baseUrl}/trips/${tripId}/activities/${activityId}`, activity);
  }

  public delete(tripId: string, activityId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/trips/${tripId}/activities/${activityId}`);
  }

  public getActivityCategory(): Observable<ActivityCategory[]> {
    return this.http.get<ActivityCategory[]>(`${this.baseUrl}/activity-categories`).pipe(
      map((categories) => {
        return categories.map((category) => {
          const transtatedSubcategories = category.subcategories.map(
            (subcategory) => {
              return {
                ...subcategory,
                activityName: this.translateService.instant(
                  `forms.ACTIVITY_CATEGORY_REQUEST.ACTIVITY_SUBCATEGORY.${subcategory.activityCode}`
                ),
              };
            }
          );
          return {
            ...category,
            subcategories: transtatedSubcategories,
            activityCategoryId: null,
            activityName: this.translateService.instant(
              `forms.ACTIVITY_CATEGORY_REQUEST.ACTIVITY_CATEGORY.${category.activityCode}`
            ),
          };
        });
      })
    );
  }
}
