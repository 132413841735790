import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TitledImage } from '@common/model/titledImage';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemBaseBlockComponent } from '../aem-base-block/aem-base-block';

@Component({
  selector: 'app-aem-titled-image',
  templateUrl: './aem-titled-image.component.html',
  styleUrls: ['./aem-titled-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AemTitledImageComponent extends AemBaseBlockComponent {
  constructor(
    @Inject(AEM_DATA) public override data: TitledImage,
  ) {
    super(data);
  }
}
