import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DIALOG_DATA, DIALOG_REF } from '../dialog.const';
import { DialogRef } from '../dialog.interfaces';
import { ConfirmDialogData } from './confirmation-dialog.interface';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent {
  static CANCEL = 'cancel';
  static CONFIRM = 'confirm';

  constructor(
    @Inject(DIALOG_DATA) public data: ConfirmDialogData,
    @Inject(DIALOG_REF) private dialogRef: DialogRef<ConfirmationDialogComponent>,
  ) { }

  cancel(): void {
    this.dialogRef.close(ConfirmationDialogComponent.CANCEL);
  }

  confirm(): void {
    this.dialogRef.close(ConfirmationDialogComponent.CONFIRM);
  }
}
